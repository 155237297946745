import { mapCartItemsAsGtmItem, pushGtmEvent } from './gtm';
import { mapCartItemsWithOfferAsGtmItem } from './gtm-offer';

export const sendAddToCartEvent = (plans, addedItem) => {
  let event; // TODO: OFFER
  try {
    event = {
      event: 'PDPAddToCart',
      items: mapCartItemsAsGtmItem({ items: [addedItem] }),
    };
  } catch (error) {
    event = {
      event: 'PDPAddToCart',
      items: mapCartItemsWithOfferAsGtmItem({ items: Array.isArray(addedItem) ? addedItem : [addedItem] }, plans),
    };
  }

  pushGtmEvent(event);
};

export const sendRemoveFromCartEvent = (removedItems, plans) => {
  let event; // TODO: OFFER
  try {
    event = {
      event: 'removeFromCart',
      items: mapCartItemsAsGtmItem({ items: removedItems }),
    };
  } catch (error) {
    event = {
      event: 'removeFromCart',
      items: mapCartItemsWithOfferAsGtmItem({ items: removedItems }, plans),
    };
  }

  pushGtmEvent(event);
};

export const sendCartContentEvent = (cart, plans) => {
  let event; // TODO: OFFER
  try {
    event = {
      event: 'cartContent',
      cart: mapCartItemsAsGtmItem(cart),
    };
  } catch (error) {
    event = {
      event: 'cartContent',
      cart: mapCartItemsWithOfferAsGtmItem(cart, plans),
    };
  }

  pushGtmEvent(event);
};
