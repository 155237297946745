import React from 'react';

import { useCart } from '@hooks/cart';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';
import { buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutAddress.module.scss';

export const CheckoutAddressDetails = ({ deliveryAddressTitle, editText }) => {
  const { data: user } = useUser();
  const { data: cart } = useCart();
  const { locale } = useLocale();

  const editPath = buildPathForLocale({
    pageId: Paths.CheckoutCustomerDetails,
    locale,
  });

  if (!user) {
    return false;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <div className={styles.userDetails}>
          <div>{`${user.firstName} ${user.lastName}`}</div>
          <div className={styles.emailElipsis}>{user.email}</div>
          <div>{user.phone}</div>
        </div>

        <div className={styles.deliveryAddress}>
          <div className={styles.deliveryAddressTitle}>{deliveryAddressTitle}</div>
          <div>{`${cart?.shippingAddress?.firstName} ${cart?.shippingAddress?.lastName}`}</div>
          <div>{`${cart?.shippingAddress?.street} ${cart?.shippingAddress?.houseNumber} ${cart?.shippingAddress
            ?.houseNumberAddon || ''}`}</div>
          <div>{`${cart?.shippingAddress?.postalCode} ${cart?.shippingAddress?.city}`}</div>
        </div>
      </div>

      <div>
        <a href={editPath} className={styles.editText}>
          {editText}
        </a>
      </div>
    </div>
  );
};
