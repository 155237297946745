import { useCookies } from 'react-cookie';

export const CART_STORAGE_KEY = '_cart_id_plans_v2';

export const useCartId = () => {
  const [cookies] = useCookies([CART_STORAGE_KEY]);
  const cartId = cookies[CART_STORAGE_KEY];

  return cartId ?? undefined;
};
