import React from 'react';

import { Label } from '@components/Label/Label';

import styles from './LabelRow.module.scss';

const BLUE_LIGHT_COLOR = '#AAD6FF';

export const LabelRow = ({ machineCode, pricingsKeys }) => {
  return (
    <div className={styles.labelContainer}>
      {machineCode && <div className={styles.machineCodeContainer}>{machineCode}</div>}
      <div className={styles.labelContent}>
        {pricingsKeys.map(item => (
          <Label
            key={item.key}
            text={item.condition}
            showDot={false}
            labelBackgroundColor={BLUE_LIGHT_COLOR}
            className={styles.pricingLabel}
          />
        ))}
      </div>
    </div>
  );
};
