import * as Yup from 'yup';

export function getValidationSchema({ messages: { min1Subscription } }) {
  return {
    items: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          subscriptionToMove: Yup.boolean().required(),
        })
      )
      .test('min1Subscription', min1Subscription, arr => {
        const selectedSubscriptions = arr.filter(subscription => subscription.subscriptionToMove);

        return selectedSubscriptions.length !== 0;
      })
      .required(),
  };
}

export function getDefaultValues(subscriptionIds) {
  return { items: subscriptionIds?.map(subscriptionId => ({ id: subscriptionId, subscriptionToMove: false })) };
}
