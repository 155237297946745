import React from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';

import { Image } from '@components/Image/Image';
import Loader from '@components/Loader/Loader';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';
import { buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './AccountCard.module.scss';

export const AccountCard = ({
  accountIcon,
  editText,
  addressText,
  deliverDetailsText,
  noIcon = false,
  className,
  showDeliveryInfo = true,
  showAddress = true,
}) => {
  const router = useRouter();
  const { locale } = useLocale();
  const { data: user, isLoading: isUserLoading } = useUser();

  if (!user || isUserLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const { email, birthdate, billingAddress } = user;
  const shippingAddress = user?.subscriptions?.find(subscription => subscription.id === router.query.id)?.address;

  const isDifferentShippingAddress = !isEqual(billingAddress, shippingAddress);
  const editPath = buildPathForLocale({ pageId: Paths.UserPanelChangeAddress, locale });

  return (
    <div className={cn(styles.container, className)}>
      {!noIcon && (
        <div className={styles.iconContainer}>
          <Image image={accountIcon} mobileImage={accountIcon} className={styles.accountIcon} />
        </div>
      )}

      <div className={styles.addressContainer}>
        <div className={styles.billingAddress}>
          <span className={styles.bold}>
            {billingAddress.firstName} {billingAddress.lastName} ({birthdate && dayjs(birthdate).format('DD-MM-YYYY')})
          </span>

          <span className={styles.email}>{email}</span>

          {showAddress && (
            <>
              <span>{billingAddress.phone}</span>

              <span className={cn(styles.bold, styles.topMargin)}>{addressText}</span>

              <span>
                {billingAddress.street} {billingAddress.houseNumber}
                {billingAddress.houseNumberAddon && `/${billingAddress.houseNumberAddon}`}
              </span>

              <span>
                {billingAddress.postalCode} {billingAddress.city}
              </span>
            </>
          )}
          {isDifferentShippingAddress && showDeliveryInfo && shippingAddress && (
            <div className={cn(styles.shippingAddress, styles.topMargin)}>
              <span className={styles.bold}>{deliverDetailsText}</span>

              <span>
                {shippingAddress.street} {shippingAddress.houseNumber}
                {shippingAddress.houseNumberAddon && `/${shippingAddress.houseNumberAddon}`}
              </span>

              <span>
                {shippingAddress.postalCode} {shippingAddress.city}
              </span>
            </div>
          )}
        </div>
      </div>

      {showAddress && (
        <div>
          <a href={editPath} className={styles.editText}>
            {editText}
          </a>
        </div>
      )}
    </div>
  );
};
