import React from 'react';

import * as dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';

import styles from './CancellationSummaryText.module.scss';

import { RichText } from '@components/RichText/RichText';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';

export const CancellationSummaryText = ({ mdaSummaryText, sdaSummaryText, notActiveSummaryText }) => {
  const router = useRouter();
  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });
  const { getValues } = useFormContext();

  const status = subscription?.status;
  const groupType = subscription?.plan?.category?.groupType;
  const pickupDate = getValues('selectedPickupDate');

  return (
    <div className={styles.container}>
      {status === 'active' && groupType === 'MDA' && (
        <RichText document={mdaSummaryText} contentVariables={{ pickUpDate: dayjs(pickupDate).format('DD-MM-YYYY') }} />
      )}

      {status === 'active' && groupType === 'SDA' && (
        <RichText document={sdaSummaryText} contentVariables={{ pickUpDate: dayjs(pickupDate).format('DD-MM-YYYY') }} />
      )}

      {status === 'future' && (
        <RichText
          document={notActiveSummaryText}
          contentVariables={{ pickUpDate: dayjs(pickupDate).format('DD-MM-YYYY') }}
        />
      )}
    </div>
  );
};
