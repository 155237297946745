import * as Yup from 'yup';

import { OFF_SESSION_PAYMENT_METHODS } from '@helpers/payment';

// TODO handle generic payment provider
export function getValidationSchema({ messages: { required } }) {
  return {
    agreeTermsAndConditions: Yup.bool().oneOf([true], required).required(required),
    sepaAccountOwner: Yup.string().when('monthlyPaymentMethod', {
      is: OFF_SESSION_PAYMENT_METHODS.SEPA,
      then: schema => schema.required(required),
    }),
    creditCardOwner: Yup.string().when('monthlyPaymentMethod', {
      is: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
      then: schema => schema.required(required),
    }),
    recurringPaymentMandate: Yup.bool().oneOf([true], required).required(required),
  };
}

export const getDefaultValues = ({ user }) => {
  return user;
};
