export const monthsYearsFormatter = ({ month: monthUnit, months: monthsUnit, year: yearUnit, years: yearsUnit }) => ({
  monthsValue,
}) => {
  const years = Math.floor(monthsValue / 12);
  const remainingMonths = monthsValue % 12;
  const yearString = years > 0 ? `${years} ${years === 1 ? yearUnit : years < 5 ? yearsUnit : yearUnit}` : '';
  const monthString = remainingMonths > 0 ? `${remainingMonths} ${remainingMonths === 1 ? monthUnit : monthsUnit}` : '';

  return [yearString, monthString].filter(Boolean).join(' and ') || `1 ${monthsUnit}`;
};
