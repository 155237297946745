import React from 'react';

import { RadioButton } from '@refactoredComponents/inputs/RadioGroup/RadioButton';
import { PaymentPlan } from 'types/frontend-financial-product.types';
import { OfferItemOneTimeRecurringPricing } from 'types/offer.types';
import { PricingVariant } from 'types/plan.types';

import { Link } from '@components/Link/Link';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './OfferCardConditions.module.scss';

const formatPriceDifference = (price: number, currencyFormatter: any) => {
  return (
    !Number.isNaN(price) &&
    `${price > 0 ? '+' : '-'} ${currencyFormatter({
      price: Math.abs(price),
    })}`
  );
};

export const OfferCardConditions = ({
  conditionText,
  conditionsLink,
  selectedMainProductPricing,
  setSelectedMainProductPricing,
  groupedPricings,
  currencyFormatter,
  readMoreText,
  setSelectedPaymentPlan,
  paymentPlan,
}: {
  conditionText: string;
  conditionsLink: any;
  groupedPricings: Partial<Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>>;
  currencyFormatter: any;
  readMoreText: string;
  setSelectedMainProductPricing: any;
  selectedMainProductPricing: OfferItemOneTimeRecurringPricing;
  setSelectedPaymentPlan: any;
  paymentPlan: PaymentPlan;
}) => {
  const conditionPhrases = usePhrases({ name: 'conditions' });
  const generalTranslations = usePhrases({ name: 'general' });

  const options = Object.keys(groupedPricings).map(pricingCondition => {
    const conditionOption = pricingCondition.toLowerCase();
    const conditionPricePerMonth = groupedPricings[conditionOption]?.find(
      p => p.months === selectedMainProductPricing.months
    )?.recurringAmount;
    const diffPricePerMonth = conditionPricePerMonth - selectedMainProductPricing.recurringAmount;
    const outOfStockText = generalTranslations?.outOfStock || 'OUT OF STOCK';
    const isConditionOptionAvailable = groupedPricings[conditionOption].length > 0;
    const isConditionSelected = conditionOption === selectedMainProductPricing.variant;

    const optionValue = isConditionOptionAvailable ? (
      !isConditionSelected && formatPriceDifference(diffPricePerMonth, currencyFormatter)
    ) : (
      <span className={styles.outOfStock}>{outOfStockText}</span>
    );

    return {
      id: pricingCondition,
      name: 'conditions',
      label: conditionPhrases[conditionOption],
      value: optionValue,
      checked: isConditionSelected,
      dataAttribute: { 'data-test-params-condition': conditionOption, 'data-test-id': 'condition' },
      onChange: () => {
        setSelectedMainProductPricing(groupedPricings[conditionOption][0]);
        setSelectedPaymentPlan(paymentPlan);
      },
    };
  });

  return (
    <div className={styles.conditionsContainer}>
      <div className={styles.conditionsHeader}>
        <span className={styles.conditionHeaderText}>{conditionText}</span>

        <Link
          {...conditionsLink?.[0]}
          className={styles.link}
          dataAttributes={{
            'data-analytics-read-more': conditionText,
            'data-analytics-modal-content': 'Refurbished vs New',
          }}
        >
          {readMoreText}
        </Link>
      </div>

      {options.map(option => (
        <RadioButton key={option.id} {...option} />
      ))}
    </div>
  );
};
