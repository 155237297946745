/* eslint-disable import/no-cycle */
import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import CloseIcon from '@assets/icons/new/close.svg';
import { useSheetContext } from '@context/sheet';
import { useOnClickOutside } from '@hooks/useClickOnOutside';
import { useClient } from '@hooks/useClient';
import { hasAttributeOrParentWithAttribute } from '@utils/hasParentWithAttribute';

import styles from './PageSheet.module.scss';
import { RichText } from '../RichText/RichText';

export default function PageSheet() {
  const { sheetContext } = useSheetContext();
  const containerRef = useRef(null);
  const { isClient } = useClient();
  const { isOpen, setIsOpen } = sheetContext;

  useEffect(() => {
    if (sheetContext.sheetBody) {
      setIsOpen(true);
    }
  }, [sheetContext.sheetBody]);

  useOnClickOutside(containerRef, event => {
    const target = event.target;

    if (
      !hasAttributeOrParentWithAttribute({
        target,
        attributeName: 'outsideclickmarker',
        attributeValue: 'sheetLink',
      })
    ) {
      setIsOpen(false);
    }
  });

  return (
    <div key={sheetContext?.sheetBody?.uid}>
      {isClient && (
        <div
          ref={containerRef}
          className={cn(styles.container, styles.containerRight, {
            [styles.containerRightOpen]: isOpen,
          })}
        >
          <div className={styles.contentContainer}>
            {sheetContext?.sheetBody?.headingTitle && (
              <div className={styles.heading}>
                <div>
                  <span className={styles.titleText}>{sheetContext?.sheetBody?.headingTitle}</span>
                </div>
                <div className={styles.closeIconWrapper}>
                  <CloseIcon
                    className={styles.closeIcon}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
            )}
            {sheetContext?.sheetBody?.children || (
              <RichText document={sheetContext?.sheetBody?.content} className={styles.content} />
            )}
          </div>
          <div className={styles.shadowLeft} />
        </div>
      )}
    </div>
  );
}
