import React from 'react';

import cn from 'classnames';

import { Label } from '@components/Label/Label';

import { LabelColorVariant } from '../../BlogFilterLabel/BlogFilterLabel';
import styles from '../BlogFilter.module.scss';

export const BlogFilterButtonAllCategories = ({ isSheet, filter, onClick, allCategoryFilterLabelText }) => {
  return (
    <button
      className={cn({
        [styles.sheetButton]: isSheet,
        [styles.labelButton]: !isSheet,
        [styles.inactiveCategory]: !!filter,
      })}
      onClick={onClick}
      type="button"
    >
      <Label
        className={styles.allCategoriesLabelButtonText}
        fontColor={LabelColorVariant.blue.font}
        labelBackgroundColor={LabelColorVariant.blue.background}
        showDot={false}
        text={allCategoryFilterLabelText}
      />
    </button>
  );
};
