import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import ProfileIcon from '@assets/icons/new/profile.svg';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { RichText } from '@components/RichText/RichText';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useUserLoginMutation } from '@hooks/user/useUserLoginMutation';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { getValidationFields } from '@utils/getValidationFields';

import styles from './CheckoutLoginForm.module.scss';
import { validationSchema } from './validation';

import { Button, ButtonVariant } from '@base-components/Button';

export const CheckoutLoginForm = ({
  emailLabelText,
  passwordLabelText,
  loginButtonText,
  logoutButtonText,
  loggedInTitleText,
  alreadyLoginText,
  haveAnAccountTitleText,
  haveAnAccountDescriptionText,
  ...props
}) => {
  const { logger } = useLogger({ context: 'CheckoutLoginForm' });
  const [isError, setIsError] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { data: user } = useUser();
  const userLoginMutation = useUserLoginMutation();
  const userLogoutMutation = useUserLogoutMutation();
  const validationMessages = getValidationFields(props);
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const formOptions = {
    resolver: yupResolver(validationSchema(validationMessages)),
  };
  const methods = useForm(formOptions);

  const logoutUser = async () => {
    try {
      logger.info({
        message: 'User is trying to logout',
        params: { userId: user.id },
      });

      await userLogoutMutation.mutateAsync();
      // TODO: Hack here, for clearing register form inputs we should use useform/reset method
      window.location.reload();
    } catch (error) {
      logger.error({
        message: 'There is a problem with logout',
        params: { userId: user?.id, errorMessage: error.message },
      });

      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onSubmit = async data => {
    const { loginEmail, loginPassword } = data;

    setIsError(false);

    try {
      logger.info({
        message: `User ${loginEmail} is trying to login`,
        params: { userId: user?.id, userEmail: loginEmail },
      });

      await userLoginMutation.mutateAsync({ username: loginEmail, password: loginPassword });
    } catch (error) {
      const errorMessage = error.message || error.data?.errors?.[0]?.message;
      if (errorMessage === 'invalidCredentials') {
        toast(errorPhrases?.['invalid-credentials']?.value || 'Invalid credentials', {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });

        logger.info({
          message: 'User passed wrong credentials',
          params: { userEmail: data.email, errorMessage },
        });

        setIsError(true);
      } else {
        toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });

        logger.error({
          message: `There is a problem with login ${data.email}`,
          params: { userEmail: data.email, errorMessage },
        });

        setIsError(true);
      }
    }

    setShowLogin(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardContentContainer}>
          <div className={styles.cartTop}>
            <div className={styles.cardIcon}>
              <ProfileIcon />
            </div>
            <div className={styles.cardTitle}>
              {user && (
                <>
                  <span className={styles.cardTitleText}>{loggedInTitleText}</span>
                  <div className={styles.alreadyLoginTextContainer}>
                    <RichText className={styles.alreadyLoginText} document={alreadyLoginText} contentVariables={user} />
                    <span className={styles.logoutText} onClick={logoutUser}>
                      {logoutButtonText}
                    </span>
                  </div>
                </>
              )}

              {!user && (
                <>
                  <span className={styles.cardTitleText}>{haveAnAccountTitleText}</span>
                  <div
                    className={styles.cardDescriptionText}
                    onClick={() => setShowLogin(!showLogin)}
                    data-checkout="user-login"
                  >
                    <RichText document={haveAnAccountDescriptionText} />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.cardContent}>
            {!user && (showLogin || isError) && (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.loginForm} id="checkoutLoginForm">
                  <TextInput
                    controllerName="loginEmail"
                    labelText={emailLabelText}
                    dataAttribute={{ 'data-test-id': 'email-input' }}
                  />
                  <TextInput
                    controllerName="loginPassword"
                    labelText={passwordLabelText}
                    password
                    dataAttribute={{ 'data-test-id': 'password-input' }}
                  />

                  {isError && (
                    <NotificationBar
                      variant="error"
                      shadow="grounded"
                      content={errorPhrases?.['invalid-credentials']?.value || 'Invalid credentials'}
                    />
                  )}
                  <Button
                    as="button"
                    variant={ButtonVariant.Icon}
                    icon="none"
                    type="submit"
                    className={styles.submitButton}
                  >
                    {loginButtonText}
                  </Button>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
