import React, { useState, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { usePhrases } from '@hooks/context/usePhrases';
import { useDeAddressValidationMutation } from '@hooks/useAddressValidation';
import { useUser } from '@hooks/user/useUser';
import { useUserPanelUpdateAddressMutation } from '@hooks/user-panel/useUserPanelUpdateAddressMutation';

import { existingCustomerValidationSchema } from './validation';
import styles from '../ChangeAddressForm.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';


const fieldsNames = {
  postalCode: 'postalCode',
  street: 'street',
  city: 'city',
  houseNumber: 'houseNumber',
  houseNumberAddon: 'houseNumberAddon',
  phoneNumber: 'phoneNumber',
};

export const ChangeAddressFormDe = ({
  successToastMessage,
  changePhoneNumberText,
  changeAddressText,
  streetText,
  houseNumberText,
  cityText,
  submitText,
  didYouMeanText,
  applyChangesText,
  postalCodeText,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'CheckoutRegisterFormDe' });
  const userChangeAddressAndPhoneMutation = useUserPanelUpdateAddressMutation();
  const deAddressValidationMutation = useDeAddressValidationMutation();
  const [addressSuggestion, setAddressSuggestion] = useState({});
  const { data: user } = useUser();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const formOptions = {
    defaultValues: {
      phoneNumber: user.billingAddress.phone || '',
      postalCode: user.billingAddress.postalCode || '',
      street: user.billingAddress.street || '',
      city: user.billingAddress.city || '',
      houseNumber: user.billingAddress.houseNumber || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(existingCustomerValidationSchema(validationMessages)),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };
  const methods = useForm(formOptions);

  const checkAddress = useCallback(async () => {
    const postalCode = methods.getValues(fieldsNames.postalCode);
    const street = methods.getValues(fieldsNames.street);
    const city = methods.getValues(fieldsNames.city);
    const houseNumber = methods.getValues(fieldsNames.houseNumber);

    if (postalCode !== '' || street !== '' || city !== '' || houseNumber !== '') {
      try {
        const address = await deAddressValidationMutation.mutateAsync({
          postalCode: postalCode.toUpperCase().replace(/\s/g, ''),
          houseNumber: parseInt(houseNumber, 0),
          street,
          city,
        });

        if (address) {
          setAddressSuggestion(address);
        }
      } catch (error) {
        logger.error({ message: 'Error in checkAddress in ChangeAddressFormDe', params: error });
      }
    }
  }, []);

  const setAddressValues = () => {
    methods.setValue(fieldsNames.street, addressSuggestion.street, { shouldTouch: true });
    methods.setValue(fieldsNames.city, addressSuggestion.city, { shouldTouch: true });
    methods.setValue(fieldsNames.houseNumber, addressSuggestion.houseNumber, { shouldTouch: true });
    methods.setValue(fieldsNames.postalCode, addressSuggestion.postalCode, { shouldTouch: true });
  };

  const onSubmitHandler = async data => {
    try {
      let address;
      if (data.postalCode || data.street || data.city || data.houseNumber) {
        address = await deAddressValidationMutation.mutateAsync({
          postalCode: data.postalCode,
          street: data.street,
          city: data.city,
          houseNumber: data.houseNumber,
        });
      }

      if (address) {
        await userChangeAddressAndPhoneMutation.mutateAsync({
          postalCode: data.postalCode,
          street: data.street,
          city: data.city,
          houseNumber: data.houseNumber,
          phone: data.phoneNumber,
        });
      }
      toast(successToastMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
      });
    } catch (error) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.container}>
        <div className={styles.formGroupWrapper}>
          <span className={styles.changeTextFont}>{changePhoneNumberText}</span>
          <TextInput controllerName={fieldsNames.phoneNumber} />
        </div>
        <div className={styles.formGroupWrapper}>
          <span className={styles.changeTextFont}>{changeAddressText}</span>
          <div className={styles.inputRow}>
            <TextInput controllerName={fieldsNames.street} labelText={streetText} onBlur={checkAddress} />
            <TextInput controllerName={fieldsNames.houseNumber} labelText={houseNumberText} onBlur={checkAddress} />
          </div>
          <div className={styles.inputRow}>
            <TextInput controllerName={fieldsNames.postalCode} labelText={postalCodeText} onBlur={checkAddress} />
            <TextInput controllerName={fieldsNames.city} labelText={cityText} onBlur={checkAddress} />
          </div>
        </div>
        {Object.keys(addressSuggestion).length > 0 && (
          <div className={styles.hintContainer}>
            {didYouMeanText}: {Object.values(addressSuggestion).join(' ')}?
            <div className={styles.optionButtonContainer}>
              <span className={styles.optionButton} onClick={setAddressValues}>
                {applyChangesText}
              </span>
            </div>
          </div>
        )}
        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Icon}
          icon="none"
          type="submit"
          isFullWidth
          isLoading={methods.formState.isSubmitting}
          disabled={!methods.formState.isValid}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
