import React from 'react';

import { Image } from '@components/Image/Image';

import styles from './TeamCard.module.scss';

export const TeamCard = ({ image, name, title }) => {
  return (
    <div className={styles.container}>
      <Image image={image} alt={`person-${name}`} className={styles.image} backgroundImage={true} />

      <div className={styles.itemContainer}>
        <span className={styles.name}>{name}</span>
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
};
