import { ExecutionType } from 'types/additional-service.types';

export const getRecurringAdditionalServices = (additionalServices, additionalServicesContent = []) => {
  return additionalServices
    ?.filter(as => as.executionType === ExecutionType.Recurring)
    .map(as => {
      return {
        ...as,
        label: additionalServicesContent[as?.contentSlug]?.label,
      };
    });
};
