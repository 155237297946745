import { useState } from 'react';

import cn from 'classnames';

import { Modal } from '@components/modals/Modal/Modal';
import { useContentContext } from '@context/content';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useModal } from '@hooks/component/useModal';
import { usePhrases } from '@hooks/context/usePhrases';
import { useUser } from '@hooks/user/useUser';

import styles from './LocalizationChangeButton.module.scss';
import { flagsMap, handleLocalizationChange } from '../utils/localizationUtils';

import { Button, ButtonVariant } from '@base-components/Button';
import { Country, Language } from 'types/common.types';
import { Localization } from 'types/localization.types';

type LocalizationChangeButtonProps = {
  countryCode: Country;
  languageCode: Language;
  currentLanguage?: Language;
  currentCountry?: Country;
  classNames?: string;
};

const LocalizationChangeButton = ({
  countryCode,
  languageCode,
  currentLanguage,
  currentCountry,
  classNames,
}: LocalizationChangeButtonProps) => {
  const phrases = usePhrases({ name: 'localization-change' });

  const { data: user } = useUser();
  const { data: cart } = useCartWithOffer();
  const [usedLocalizationCodes, setUsedLocalizationCodes] = useState<Localization>({});
  const [isWarningModalOpen, openWarningModal, closeWarningModal] = useModal();
  const { currentPageId, currentPageParams } = useContentContext();

  const handleLocalizationChangeClick = (language: Language, country: Country) => () => {
    if (language === currentLanguage && country === currentCountry) {
      return;
    }

    if ((user || cart?.items?.length) && country !== currentCountry) {
      setUsedLocalizationCodes({ country: countryCode, language: languageCode });
      openWarningModal();
    } else {
      handleLocalizationChange({
        language: language || usedLocalizationCodes.language,
        country: country || usedLocalizationCodes.country,
        currentCountry,
        currentPageId,
        currentPageParams,
      });
    }
  };

  const ItemCountryFlagIcon = flagsMap[countryCode];

  return (
    <>
      <Modal
        footer={{
          rejectButtonText: phrases['modal-cancel-text'],
          confirmButtonText: phrases['modal-continue-text'],
          onReject: closeWarningModal,
          buttonVariant: ButtonVariant.Basic,
          onConfirm: () =>
            handleLocalizationChange({
              language: languageCode,
              country: countryCode,
            }),
        }}
        isOpen={isWarningModalOpen}
        closeModal={closeWarningModal}
        title={phrases['warning-modal-title']}
      >
        {phrases['modal-description-text']}
      </Modal>
      <Button
        variant={ButtonVariant.Basic}
        as="button"
        coloredIcon={true}
        key={`${countryCode}-${languageCode}`}
        className={cn(styles.languageOption, classNames)}
        onClick={handleLocalizationChangeClick(languageCode, countryCode)}
      >
        <ItemCountryFlagIcon className={styles.icon} alt={`${countryCode} country`} />
        <span>({languageCode.toUpperCase()})</span>
        {languageCode === currentLanguage && countryCode === currentCountry && <span>- {phrases['selected']}</span>}
      </Button>
    </>
  );
};

export default LocalizationChangeButton;
