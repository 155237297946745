import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';

import { CART_QUERY_ID } from './useCart';
import { useCartId } from './useCartId';

import { sendRemoveFromCartEvent } from '@services/gtm/cart';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export const useRemoveItem = () => {
  const { logger } = useLogger({ context: 'useRemoveItem' });
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useMutation(
    async cartItem => {
      const { data } = await httpService.delete(`/cart/${cartId}/item/${cartItem.id}`);

      return data;
    },
    {
      onSuccess: (data, cartItem) => {
        sendRemoveFromCartEvent([cartItem], plans);
        queryClient.setQueryData(CART_QUERY_ID, { ...data.cart, sendGtmEvent: false });

        logger.info({
          message: 'Items are removed from cart',
          params: { cartId: data.cart.id, cartItem, allCartItems: data.cart.items },
        });
      },
    }
  );
};
