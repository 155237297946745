/* eslint-disable import/no-cycle */
import React from 'react';

import * as dayjs from 'dayjs';

import { Image } from '@components/Image/Image';
import { Label } from '@components/Label/Label';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './InvoiceCard.module.scss';
import { useDownloadInvoice } from '../../hooks/useDownloadInvoice';
import { InvoicePaymentButton } from '../InvoicePaymentButton/InvoicePaymentButton';

import { Button, ButtonVariant } from '@base-components/Button';

export const InvoiceCard = ({
  invoice,
  dateIcon,
  downloadInvoiceText,
  changePaymentPreferenceButtonLabel,
  changePaymentPreferenceButtonIcon,
  invoicePaymentButtonLabel,
  invoicePaymentButtonExpiredLabel,
  isPaymentCta = false,
}) => {
  const { status, dateDue, amount, invoiceNumber, paymentLinks } = invoice;
  const { currencyFormatter } = useCurrencyFormatter();
  const { locale, language } = useLocale();
  const invoiceStatusesTranslations = usePhrases({ name: 'invoice-statuses', rawObject: true });

  const { refetch } = useDownloadInvoice({ invoiceNumber });

  const [notExpiredPaymentLink] = paymentLinks
    .filter(paymentLink => !paymentLink.expired)
    .sort((a, b) => (dayjs(b.createdDate).isAfter(a.createdDate) ? -1 : 1));

  const downloadInvoiceTrigger = async () => {
    const { data: invoiceUrl, isLoading: isLoadingInvoiceUrl } = await refetch();

    if (!isLoadingInvoiceUrl && invoiceUrl) {
      const link = document.createElement('a');
      link.href = invoiceUrl.pdf;
      link.target = '_blank';
      link.download = `bluemovement-invoice-${invoiceNumber}.pdf`;
      link.click();
    }
  };

  const statusesColor = {
    paid: '#8AD861',
    not_paid: '#FFBF00',
    default: '#FFBF00',
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Image image={dateIcon} mobileImage={dateIcon} className={styles.dateIcon} />
          <span className={styles.dateText}>
            {dayjs(dateDue)
              .locale(language)
              .format('MMMM YYYY')}
          </span>
        </div>

        <div className={styles.headerRight}>
          <span className={styles.amountText}>{currencyFormatter({ price: amount })}</span>
          <Label
            text={invoiceStatusesTranslations[status]?.value || status}
            showDot={false}
            labelBackgroundColor={statusesColor[status] || statusesColor.default}
            className={styles.label}
          />
        </div>
      </div>

      <span className={styles.downloadText} onClick={downloadInvoiceTrigger}>
        {downloadInvoiceText}
      </span>

      {status === 'not_paid' && (
        <div className={styles.actionButtons}>
          <div className={styles.button}>
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              icon="payment"
              onClick={() => {
                window.location.href = buildPath({
                  locale,
                  pageId: Paths.UserPanelChangePaymentMethod,
                });
              }}
            >
              {changePaymentPreferenceButtonLabel}
            </Button>
          </div>

          {isPaymentCta && (
            <div className={styles.button}>
              <InvoicePaymentButton
                paymentLink={notExpiredPaymentLink}
                invoice={invoice}
                label={invoicePaymentButtonLabel}
                expiredLabel={invoicePaymentButtonExpiredLabel}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
