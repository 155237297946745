import React from 'react';

import IdealIcon from '@assets/icons/new/payment/ideal.svg';
import KlarnaIcon from '@assets/icons/new/payment/klarna.svg';
import MastercardIcon from '@assets/icons/new/payment/mastercard.svg';
import VisaIcon from '@assets/icons/new/payment/visa.svg';

import styles from './PaymentOptions.module.scss';

const Icons = {
  ideal: IdealIcon,
  mastercard: MastercardIcon,
  visa: VisaIcon,
  klarna: KlarnaIcon,
};

export const PaymentOptions = ({ options }) => {
  return (
    <div className={styles.container}>
      {options.map(option => {
        const PaymentOption = Icons[option];

        return <PaymentOption key={option} className={styles.icon} />;
      })}
    </div>
  );
};
