import React from 'react';

import cn from 'classnames';

import Logo from '@assets/icons/new/logo/bluemovementSmallLogo.svg';

import styles from './LogoLoader.module.scss';

const LogoLoader = ({ className }: { className?: string }) => {
  return (
    <div className={cn(styles.container, className)} data-loader="loader">
      <Logo className={styles.pulse} />
    </div>
  );
};

export default LogoLoader;
