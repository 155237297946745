import React from 'react';

import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';

import { useClient } from '@hooks/useClient';

import styles from './ImageContentBlock.module.scss';
import { Image } from '../Image/Image';
import { RichText } from '../RichText/RichText';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

export const ImageContentBlock = ({
  isRightPositioned,
  isWide,
  isNarrow,
  isInset,
  theme = 'blueBright',
  image,
  mobileImage,
  content,
  video,
}) => {
  const isDarkTheme = ['blueBright', 'blueDark'].includes(theme);
  const isLightTheme = ['blueLight', 'brandYellow', 'brandGreen', 'brandPink'].includes(theme);
  const { isClient } = useClient();

  return (
    <div className={cn(styles.container, { [styles.containerNarrow]: isNarrow })}>
      <div
        className={cn(styles.contentContainer, styles[`contentContainerBackground${upperFirst(theme)}`], {
          [styles.contentContainerRight]: isRightPositioned,
          [styles.contentContainerWide]: isWide,
          [styles.contentContainerNarrow]: isNarrow,
          [styles.contentContainerNarrowRight]: isNarrow && isRightPositioned,
          [styles.contentContainerInset]: isInset,
          [styles.contentContainerInsetRight]: isInset && isRightPositioned,
          [styles.contentContainerLightTheme]: isLightTheme,
          [styles.contentContainerDarkTheme]: isDarkTheme,
          [styles.borderRadiusForVideo]: video?.length > 0,
        })}
      >
        <RichText document={content} className={styles.richTextContentContainer} />
      </div>
      <div
        className={cn(styles.imageContainer, {
          [styles.imageContainerRight]: isRightPositioned,
          [styles.imageContainerWide]: isWide,
          [styles.imageContainerWideRight]: isWide && isRightPositioned,
          [styles.imageContainerNarrow]: isNarrow,
          [styles.imageContainerNarrowRight]: isNarrow && isRightPositioned,
          [styles.imageContainerInset]: isInset,
          [styles.videoContainer]: video?.length > 0,
        })}
      >
        {isClient && video?.length > 0 && <VideoPlayer className={styles.playerFullHeight} video={video[0]} />}
        {image?.filename && <Image image={image} mobileImage={mobileImage} alt="background" className={styles.image} />}
      </div>
    </div>
  );
};
