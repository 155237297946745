import React, { useContext, useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { useLocale } from '@hooks/useLocale';
import { interpolateContent } from '@utils/interpolate-content';
import { buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';
import { useLogger } from '@hooks/useLogger';
import styles from './TakeoverPaymentForm.module.scss';
import { TakeoverReceiverConfirmationCheckbox } from '../TakeoverReceiverConfirmationCheckbox/TakeoverReceiverConfirmationCheckbox';
import { paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';
import { Button, ButtonVariant } from '@base-components/Button';
import { CHECKOUT_PAYMENT_INPUTS_DE } from '@modules/Checkout/components/CheckoutPaymentInputs/de/CheckoutPaymentInputsDe';
import { CHECKOUT_PAYMENT_INPUTS_NL } from '@modules/Checkout/components/CheckoutPaymentInputs/nl/CheckoutPaymentInputsNl';
import { buildPath } from '@utils/paths/build-paths';
import { useUser } from '@hooks/user/useUser';
import usePaymentConfiguration from '@hooks/payment/usePaymentConfiguration';
import { useAcceptTakeoverMutation } from '@hooks/user-panel/takeover/useAcceptTakeoverMutation';
import { AdyenContext } from '@context/adyen';
import { usePaymentAttemptMutation } from '@hooks/payment/usePaymentAttemptMutation';
import { usePhrases } from '@hooks/context/usePhrases';
import {
  CheckoutPaymentInputs,
  getPaymentMethodByComponent,
} from '@modules/Checkout/components/CheckoutPaymentInputs/CheckoutPaymentInputs';
import { useTakeoverForm } from '@modules/UserPanel/Takeover/page/TakeoverFormContext/TakeoverFormContext';
import { usePaymentMutation } from '@hooks/payment/usePaymentMutation';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { useUserPaymentMethods } from '@hooks/payment/useUserPaymentMethods';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useFormContext } from 'react-hook-form';

export const TakeoverPaymentForm = ({
  activeTakeover,
  recurringConsentText,
  alreadyExistingPaymentMethodInfoBarTitle,
  alreadyExistingPaymentMethodInfoBarMessage,
  showConsent = true,
  termsAndConditionConsent,
  termsAndConditionConsentDataAgreementLinkText,
  termsAndConditionConsentLinkTex,
  submitButtonText,
  confirmationCheckboxText,
  sepaDescription,
  sepaName,
  creditCardDescription,
  creditCardName,
  submitText,
  successToastMessage,
  failedToastMessage,
  validationMessages,
}) => {
  const { locale, country } = useLocale();
  const termsAndConditionsPath = buildPathForLocale({ pageId: Paths.TermsAndConditions, locale });
  const dataAgreementPath = buildPathForLocale({ pageId: Paths[`PrivacyPolicy${country.toUpperCase()}`], locale });
  const takeoverDate = activeTakeover.takeoverDate && dayjs(activeTakeover.takeoverDate).format('DD-MM-YYYY');
  const { data: user } = useUser();
  const { data: paymentSources } = useUserPaymentMethods(user?.cbId);
  const acceptTakeoverMutation = useAcceptTakeoverMutation();
  const adyenContext = useContext(AdyenContext);
  const paymentAttemptMutation = usePaymentAttemptMutation();
  const { logger } = useLogger({ context: 'TakeoverPaymentForm' });
  const { setNextStep } = useTakeoverForm();
  const paymentMutation = usePaymentMutation();
  const phrases = usePhrases({ name: 'notifications' });

  const {
    getValues,
    formState: { isValid },
    trigger,
  } = useFormContext();

  const contentVariables = {
    originalUserName: activeTakeover.originalUser.firstName,
    originalUserLastName: activeTakeover.originalUser.lastName,
    takeoverDate,
  };

  const onSubmit = async () => {
    trigger();

    if (!isValid) {
      return;
    }

    if (!paymentSources) {
      await adyenContext.attachedPaymentMethod.submit();
    } else {
      handleTakeoverAccept();
    }
  };

  const { reccuringPaymentMandate, agreeTermsAndConditions } = getValues();

  const handleTakeoverAccept = async () => {
    await acceptTakeoverMutation.mutateAsync({
      userId: user?.id,
      takeoverToken: activeTakeover.token,
      reccuringPaymentMandate,
      agreeTermsAndConditions,
    });

    setNextStep();
  };

  const { configuration, isSubmitting, setSubmitting, validationError, setValidationError } = usePaymentConfiguration({
    customAmountValue: 0,
    cart: null,
    user,
    customOnSubmit: async (state, component, actions) => {
      setSubmitting(true);

      try {
        logger.info({ message: 'Submitting takeover receiver accept ', params: { userId: user?.id } });

        const paymentMethodByComponent = getPaymentMethodByComponent(component);

        const newPaymentAttempt = await paymentAttemptMutation.mutateAsync({
          depositPaymentMethod: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
          redirectPath: buildPath({
            pageId: Paths.PaymentStatus,
            locale,
          }),
          depositAmount: 0,
          userId: user?.id,
          paymentType: 'checkout',
        });

        const paymentAttemptUuid = newPaymentAttempt.id;

        const statusPagePath = buildPath({
          pageId: Paths.PaymentStatus,
          locale: locale.toLowerCase(),
        });

        const APP_URL = getRuntimeConfig('APP_URL');

        const redirectUrl = `${APP_URL}/redirect?url=${encodeURIComponent(
          `${APP_URL}${statusPagePath
            .replace(':paymentBMId', paymentAttemptUuid)
            .replace(':transactionId', paymentAttemptUuid)}`
        )}`;

        const paymentMutationResult = await paymentMutation.mutateAsync({
          userId: user?.id,
          paymentMethod: state.data.paymentMethod,
          riskData: state.data.riskData || {},
          amountValue: 0,
          redirectPath: redirectUrl,
          paymentAttemptUuid,
          browserInfo: state.data.browserInfo,
          paymentType: 'change-payment-method',
        });

        const { resultCode, action, order, donationToken } = paymentMutationResult;

        if (resultCode === 'Refused' || !resultCode) {
          // TODO Adyen: refusalReasonCode -> translation should be mapped https://docs.adyen.com/development-resources/refusal-reasons/
          // "As a rule we advise merchants to be careful with exposing refusal reasons, as malicious shoppers can use it for card testing or other acts. In general, we advice merchants to only show generic refusal reasons."
          // Waiting for Barbara's decision what to show here.
          // Probably the best way is just "Try again" without showing any reasons
          actions.reject();

          return;
        }

        actions.resolve({
          resultCode,
          action,
          order,
          donationToken,
          paymentAttemptId: paymentAttemptUuid,
        });

        if (action?.url) {
          logger.info({ message: 'Redirecting to provider page', params: { userId: user?.id } });

          window.location.href = action.url;
        } else {
          logger.info({
            message: 'Payment method changed',
            params: { userId: user?.id },
          });
        }
      } catch (error) {
        logger.error({
          message: 'Submitting payment method change form',
          params: { userId: user?.id, error, errorMessage: error.message },
        });

        actions.reject();
      }
    },
    customOnPaymentCompleted: async (result, component) => {
      logger.info({ message: `User ${user?.id} updated payment method`, params: { userId: user?.id, result } });

      await handleTakeoverAccept();

      toast(phrases?.['payment-method-updated'] || 'Success', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const additionalProps = {
    sepaDescription,
    sepaName,
    creditCardDescription,
    creditCardName,
    submitText,
    successToastMessage,
    failedToastMessage,
    validationMessages,
  };

  const enabledCheckoutPaymentInputs = {
    de: [CHECKOUT_PAYMENT_INPUTS_DE.CARD, CHECKOUT_PAYMENT_INPUTS_DE.SEPA],
    nl: [CHECKOUT_PAYMENT_INPUTS_NL.CARD, CHECKOUT_PAYMENT_INPUTS_NL.SEPA],
  };

  return (
    <div className={styles.container}>
      {!!paymentSources ? (
        <div className={styles.changeAlreadyDefinedPaymentContainer}>
          <NotificationBar
            title={alreadyExistingPaymentMethodInfoBarTitle}
            content={alreadyExistingPaymentMethodInfoBarMessage}
            variant="alice"
            shadow="grounded"
          />
        </div>
      ) : (
        <>
          <CheckoutPaymentInputs
            configuration={configuration}
            setValidationError={setValidationError}
            validationError={validationError}
            enabledCheckoutPaymentInputs={enabledCheckoutPaymentInputs[country]}
            noDefaultPayment
            {...additionalProps}
          />
        </>
      )}
      <div className={styles.recurringConsentCheckbox}>
        <Checkbox controllerName="recurringPaymentMandate" labelText={recurringConsentText} />
      </div>
      {showConsent && (
        <div className={styles.generalConsentCheckbox}>
          <Checkbox
            controllerName="agreeTermsAndConditions"
            labelText={interpolateContent(termsAndConditionConsent, {
              termsAndConditionConsentDataAgreementLinkText: (
                <a
                  href={dataAgreementPath}
                  target="_blank"
                  className={styles.generalConsentCheckboxLink}
                  rel="noreferrer"
                >
                  {termsAndConditionConsentDataAgreementLinkText}
                </a>
              ),
              termsAndConditionConsentLinkTex: (
                <a
                  href={termsAndConditionsPath}
                  target="_blank"
                  className={styles.generalConsentCheckboxLink}
                  rel="noreferrer"
                >
                  {termsAndConditionConsentLinkTex}
                </a>
              ),
            })}
          />
        </div>
      )}
      <TakeoverReceiverConfirmationCheckbox text={confirmationCheckboxText} contentVariables={contentVariables} />
      <Button
        as="button"
        isFullWidth
        className={styles.takeoverProceedButton}
        variant={ButtonVariant.Icon}
        icon="none"
        onClick={() => {
          onSubmit();
        }}
        dataAttribute={{ 'data-test-id': 'takeover-proceed-button' }}
        // disabled={isSubmitting || methods.formState.isSubmitting || isSubmittingState}
        // isLoading={isSubmitting || methods.formState.isSubmitting || isSubmittingState}
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};
