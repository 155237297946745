import React from 'react';

import { Checkbox } from '@refactoredComponents/inputs/Checkbox/Checkbox';
import { TwoColumnText } from '@refactoredComponents/TwoColumnText/TwoColumnText';
import cn from 'classnames';
import { BillingType, OfferItem, OfferItemOneTimeRecurringPricing, OfferItemPricing } from 'types/offer.types';

import { Link } from '@components/Link/Link';
import Pricing from '@components/Pricing/Pricing';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './AdditionalServices.module.scss';

export interface IAdditionalItems {
  selectedMainProductPricing: OfferItemOneTimeRecurringPricing;
  additionalItems: OfferItem[];
  additionalServicesText: string;
  readMore: string;
  additionalServicesLink?: {
    component: string;
    content: { type: string; content: any[] };
    headingTitle: string;
    position: string;
  }[];
  additionalServicesSheetText: string;
  oneTime: string;
  perMonth: string;
  selectedAdditionalServicesPricings?: Record<string, OfferItemPricing>;
  selectAdditionalServicePricing?: (additionalItemId: string, pricing: OfferItemPricing) => void;
  isMandatory?: boolean;
}

export const AdditionalItems: React.FC<IAdditionalItems> = ({
  selectAdditionalServicePricing,
  selectedAdditionalServicesPricings,
  selectedMainProductPricing,
  additionalItems,
  additionalServicesText = 'Additional Services',
  readMore,
  additionalServicesLink,
  additionalServicesSheetText,
  perMonth,
  oneTime,
  isMandatory,
}) => {
  const { included: includedText } = usePhrases({ name: 'additionalServices' });

  const billingOptionType = {
    one_time: oneTime,
    recurring: perMonth,
  };

  const additionalItemsMatchingMainProduct = additionalItems.filter(additionalItem =>
    additionalItem.pricings.some(pricing => pricing.months === selectedMainProductPricing.months)
  );

  const handleChange = (additionalItemId: string, pricing: OfferItemPricing) => () => {
    selectAdditionalServicePricing(additionalItemId, pricing);
  };

  if (!additionalItemsMatchingMainProduct.length) {
    return null;
  }

  return (
    <div
      className={styles.additionalServicesWrapper}
      data-test-id={isMandatory ? 'mandatory-additional-services' : 'optional-additional-services'}
    >
      <div className={styles.additionalServices}>
        <span className={styles.additionalServicesText}>{additionalServicesText}</span>

        {/* @ts-ignore */}
        <Link
          {...additionalServicesLink?.[0]}
          className={styles.link}
          dataAttributes={{
            'data-analytics-read-more': additionalServicesSheetText,
            'data-analytics-modal-content': 'additional-services',
          }}
        >
          {readMore}
        </Link>
      </div>
      {additionalItemsMatchingMainProduct
        .filter(as => !as.isHiddenFromCustomer)
        .map(item => {
          const pricing = item.pricings.find(itemPricing => itemPricing.months === selectedMainProductPricing.months);
          const billingType = pricing?.billingType;

          const pricingDetails = {
            price: billingType === BillingType.OneTime ? pricing?.oneTimeAmount : pricing?.recurringAmount,
            isReferencePriceActive: pricing?.isReferenceAmountActive,
            referencePrice:
              billingType === BillingType.OneTime ? pricing?.referenceOneTimeAmount : pricing?.referenceRecurringAmount,
            postfix: billingOptionType[billingType],
            separator: billingType === BillingType.OneTime ? ' ' : '/',
            included:
              billingType === BillingType.OneTime ? pricing?.oneTimeAmount === 0 : pricing?.recurringAmount === 0,
            includedText: includedText,
          };

          return (
            <Checkbox
              className={isMandatory && styles.additionalServiceOption}
              key={item.id}
              name={item.contentSlug}
              label={
                <TwoColumnText
                  className={cn(styles.additionalServiceLabel, isMandatory && styles.a)}
                  leftColumn={
                    <span>
                      {item.content?.label || item.contentSlug}
                      {item.content.descriptionLink?.[0] && (
                        <>
                          {` - `}
                          <Link
                            {...item.content.descriptionLink?.[0]}
                            className={styles.labelLink}
                            dataAttributes={{
                              'data-analytics-modal-content': 'additional-services',
                            }}
                          >
                            {item.content.readMoreText}
                          </Link>
                        </>
                      )}
                    </span>
                  }
                  rightColumn={<Pricing {...pricingDetails} />}
                />
              }
              dataAttribute={{ 'data-test-id': item.contentSlug }}
              checked={isMandatory || selectedAdditionalServicesPricings[item.id] !== null}
              onChange={handleChange(item.id, pricing)}
              disabled={isMandatory}
            />
          );
        })}
    </div>
  );
};
