import { noSpecialCharactersRegex } from 'validation/regexps/no-special-characters';
import { postalCodeFilters } from 'validation/regexps/postal-code';
import * as Yup from 'yup';

export function getValidationSchema({ messages: { required, postalCodeNl, noSpecialCharacters } = {} }) {
  return {
    postalCode: Yup.string()
      .required(required)
      .matches(postalCodeFilters.nl, postalCodeNl),
    houseNumber: Yup.string()
      .matches(noSpecialCharactersRegex, noSpecialCharacters)
      .required(required),
    street: Yup.string().required(postalCodeNl),
    city: Yup.string().required(postalCodeNl),
  };
}

export function getDefaultValues() {
  return {};
}
