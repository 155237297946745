import React from 'react';

import cn from 'classnames';

import styles from './TwoColumnText.module.scss';

interface TwoColumnTextProps {
  rightColumn: React.ReactNode;
  leftColumn: React.ReactNode;
  className?: string;
}

export const TwoColumnText = ({ leftColumn, rightColumn, className }: TwoColumnTextProps) => {
  return (
    <div className={cn(styles.container, { [className]: className })}>
      <div className={styles.leftColumn}>{leftColumn}</div>
      <div className={styles.rightColumn}>{rightColumn}</div>
    </div>
  );
};
