import React from 'react';

import cn from 'classnames';

import { icons } from '../../../../base-components/EnergyIcons/EnergyIcons';

import InfoIcon from '@assets/icons/new/infoCircle.svg';
import DownloadableWrapper from '@components/DownloadableWrapper/DownloadableWrapper';
import { Link } from '@components/Link/Link';

import styles from './EnergyLabel.module.scss';

const EnergyLabel = ({
  variant,
  text = '',
  document = undefined,
  link,
  additionalClass = null,
  productCard = false,
}) => {
  const documentSrc =
    (document?.fieldtype === 'asset' && document.filename) || (typeof document === 'string' && document);

  return (
    <div
      className={cn(styles.container, {
        [styles.basicPosition]: !productCard,
        [styles.productCardPosition]: productCard,
      })}
    >
      <Link {...link} className={cn(styles.link, additionalClass)}>
        {icons[variant?.toLowerCase()] || variant}
      </Link>

      {documentSrc && (
        <DownloadableWrapper url={documentSrc}>
          <span className={styles.text}>
            {text}

            <InfoIcon className={styles.infoIcon} />
          </span>
        </DownloadableWrapper>
      )}
    </div>
  );
};

export default EnergyLabel;
