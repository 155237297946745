import { streetAndHouseNumber2AddressLine } from '../address-line';
import { required, sanitize } from './utils';

export class UserAddressBuilder {
  constructor({ firstName, lastName, phone, city, street, houseNumber, houseNumberAddon, country, postalCode }) {
    this.firstName = required(sanitize(firstName), 'FirstName');
    this.lastName = required(sanitize(lastName), 'LastName');
    this.phone = required(phone, 'Phone');
    this.city = required(sanitize(city), 'City');
    this.street = required(sanitize(street), 'Street');
    this.houseNumber = sanitize(houseNumber);
    this.houseNumberAddon = sanitize(houseNumberAddon);
    this.country = required(country, 'Country');
    this.postalCode = required(sanitize(postalCode), 'PostalCode');
  }
}
