import React from 'react';

import cn from 'classnames';

import { RichText } from '@components/RichText/RichText';
import { LevelHeadingCondition } from '@components/LevelHeadingCondition/LevelHeadingCondition';

import styles from './TitleWithText.module.scss';

export const TitleWithText = ({
  header,
  content,
  variant = 'Regular',
  background = 'BlueBright',
  textColor = 'White',
  center = false,
  noPaddings = false,
  className,
  isFirstLevelHeading,
}) => (
  <div
    className={cn(styles.container, styles[`themeBackground${background}`], className, {
      [styles.centerContentBlock]: center,
      [styles.paddingContent]: !noPaddings,
    })}
  >
    <LevelHeadingCondition
      isFirstLevelHeading={isFirstLevelHeading}
      className={cn(styles.maxWidth, styles[`header${variant}`], styles[`themeColor${textColor}`])}
      label={header}
    />

    {content && (
      <RichText
        document={content}
        className={cn(styles.maxWidth, styles[`content${variant}`], styles[`themeColor${textColor}`])}
      />
    )}
  </div>
);
