import * as Yup from 'yup';

export function getValidationSchema({ messages: { required = 'required' } = {} }) {
  return {
    agreedToMovingTerms: Yup.bool()
      .oneOf([true], required)
      .required(required),
  };
}

export function getDefaultValues() {
  return { agreedToMovingTerms: false };
}
