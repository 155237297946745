import React from 'react';

import { Image } from '@components/Image/Image';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { convert2slug } from '@utils/convert2slug';

import styles from './CheckoutOrder.module.scss';

export const CheckoutOrder = ({
  orderCardImage,
  applianceName,
  duration,
  price,
  condition,
  deliveryTime,
  editText,
  editTextOnClick,
}) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const termsTranslations = usePhrases({ name: 'terms', rawObject: true });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image alt="orderCardImage" image={orderCardImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.headerTitle} data-order="product-name">
            {applianceName}
          </span>{' '}
          <span className={styles.headerText}>{condition}</span>
        </div>

        <div className={styles.subHeader} data-order="order-info">
          {currencyFormatter({ price })} {unitsTranslations['per-month']?.value || 'p/m'} -{' '}
          {termsTranslations[convert2slug(duration)]?.value || duration}
        </div>

        <div className={styles.deliverySection}>
          {deliveryTime && (
            <>
              <div className={styles.greenDot} /> <div className={styles.deliveryTimeText}>{deliveryTime}</div>
            </>
          )}
        </div>
      </div>

      <div className={styles.editSection}>
        <div className={styles.editText} onClick={editTextOnClick} data-order="order-edit">
          {editText}
        </div>
      </div>
    </div>
  );
};
