import React, { useContext } from 'react';

import dayjs from 'dayjs';

import { ItemCardTop } from '@base-components/ItemCardTop/ItemCardTop';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useRouter } from 'next/router';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { IconTextBox } from '@base-components/IconTextBox/IconTextBox';
import { StepContext } from '@modules/UserPanel/Cancellation/page/CancellationPage/CancellationPage';
import { checkIsCountryWithdrawalPeriodDate } from '@modules/UserPanel/Cancellation/components/CancellationDetailsSubscriptionCard/CancellationDetailsSubscriptionCard';
import { SpacingsTemplate } from '@base-components/SpacingsTemplate/SpacingsTemplate';
import CalendarIcon from '@assets/icons/new/inputs/calendar.svg';
import { TextChipPriceBar } from '@base-components/TextChipPriceBar/TextChipPriceBar';
import { convert2slug } from '@utils/convert2slug';
import { usePhrases } from '@hooks/context/usePhrases';
import StatusLabel from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/components/StatusLabel/StatusLabel';
import { useGlobalContent } from '@hooks/useGlobalContent';
import { useLocale } from '@hooks/useLocale';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import LogoLoader from '@components/LogoLoader/LogoLoader';

export const CancelSubscriptionCardVariant = ({
  title,
  contractDuration,
  till,
  notificationBarNotActive,
  notificationBarBeforeContractEnds,
  notificationBarRegularMda,
  notificationBarRegularSda,
}) => {
  const router = useRouter();
  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });

  const { country, locale } = useLocale();
  const stepContext = useContext(StepContext);
  const conditionsTranslations = usePhrases({ name: 'conditions', rawObject: true });
  const termsPhrases = usePhrases({ name: 'terms' });
  const { data: globalContent } = useGlobalContent({
    locale,
  });
  const { currencyFormatter } = useCurrencyFormatter();

  if (!globalContent) {
    return <LogoLoader className={''} />;
  }

  const activationDate = subscription?.subscriptionActivationDate;
  const minCancellationDate = subscription?.minimumCancellationDate;
  const groupType = subscription?.plan?.category?.groupType;
  const status = subscription?.status;
  const pricing = subscription?.plan?.pricings[0]?.amount;

  const isCountryWithdrawalPeriodDate = checkIsCountryWithdrawalPeriodDate({
    activationDate,
    country,
    minCancellationDate,
  });

  const getNotificationBar = () => {
    if (status === 'future') {
      return notificationBarNotActive;
    }

    if (isCountryWithdrawalPeriodDate) {
      if (groupType === 'MDA') {
        return notificationBarRegularMda;
      }

      if (groupType === 'SDA') {
        return notificationBarRegularSda;
      }
    }
    return notificationBarBeforeContractEnds;
  };

  const content = globalContent?.['user-panel-subscription-card']?.global?.[0];

  return (
    <>
      <SpacingsTemplate spacingVariant="s16" marginVaraint="m16" bgVariant="white" isShadow isPadding>
        <ItemCardTop
          variant="gridContainerMyBm"
          vibText={subscription.articleNumber}
          offerNameText={subscription?.fields?.fullName}
          isSmallImgVariant
          image={subscription?.fields?.mainImage}
          label1Text={conditionsTranslations?.[subscription.applianceCondition]?.value || ''}
          label2Text={termsPhrases[convert2slug(subscription.contractTermPeriod)]}
        />
        <IconTextBox
          text={`${contractDuration} ${dayjs(activationDate).format('DD-MM-YYYY')} ${till} ${dayjs(
            minCancellationDate
          ).format('DD-MM-YYYY')}`}
          icon={<CalendarIcon />}
          backgroundColor="transparent"
        />
        <TextChipPriceBar
          text={'Status'}
          customLabel={
            // @ts-ignore
            <StatusLabel status={subscription.status} country={country} content={content} statusItems={[]} />
          }
          price={`${currencyFormatter({ price: pricing })}`}
        />
      </SpacingsTemplate>
      <SpacingsTemplate spacingVariant="s8" marginVaraint="m16" bgVariant="transparent">
        <NotificationBar
          {...getNotificationBar()[0]}
          contentVariables={{
            fee: stepContext.cancellationFee,
          }}
        />
      </SpacingsTemplate>
    </>
  );
};
