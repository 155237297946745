import { useContentContext } from '@context/content';
import { usePhrases } from '@hooks/context/usePhrases';
import { useRedirectToBackPath } from '@hooks/useRedirectToBackPath';
import { Paths } from '@utils/paths/paths';

import styles from './PageHeader.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const PageHeaderBackButton = () => {
    const backPagePhrases = usePhrases({ name: 'backpagelabels', rawObject: true });
    const { currentPageId, currentPageParams } = useContentContext();
    const redirectToBackPath = useRedirectToBackPath();

    const backButtonText =
        backPagePhrases && (backPagePhrases[currentPageId?.toLowerCase()]?.value || backPagePhrases.default.value);

    const displayBackButton = currentPageId !== Paths.Home && backButtonText;

    const handleClick = () => redirectToBackPath(currentPageId, currentPageParams);

    return displayBackButton ? (
        <div>
            <Button as="button" variant={ButtonVariant.Basic} onClick={handleClick} className={styles.backButton}>
                {backButtonText}
            </Button>
        </div>
    ) : null;
};
