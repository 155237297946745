/* eslint-disable import/no-cycle */
import React from 'react';

import { useContentContext } from '@context/content';

import DynamicComponent from '../DynamicComponent/DynamicComponent';

export const PaymentConfirmation = ({
  paymentFailedComponents,
  paymentSuccessComponents,
  paymentInProgressComponents,
}) => {
  const componentsByStatus = {
    expired: paymentFailedComponents,
    failed: paymentFailedComponents,
    canceled: paymentFailedComponents,
    paid: paymentSuccessComponents,
    open: paymentInProgressComponents,
    pending: paymentInProgressComponents,
    authorized: paymentInProgressComponents,
    default: paymentInProgressComponents,
  };

  const { currentPageParams } = useContentContext();

  return (
    <>
      {(componentsByStatus[currentPageParams.paymentStatus] || componentsByStatus.default).map(blok => (
        <DynamicComponent {...blok} key={blok._uid} />
      ))}
    </>
  );
};
