import React from 'react';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { RichText } from '@components/RichText/RichText';

import styles from './ContactPersonalDetails.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const ContactPersonalDetails = ({
  title = 'Personal Details',
  firstName,
  lastName,
  email,
  phone,
  isSubmitting = false,
  submitButtonText,
  consentsLabelRichText,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.inputsContainer}>
        <div className={styles.inputRow}>
          <div className={styles.singleInput}>
            <TextInput controllerName="firstName" labelText={firstName} />
          </div>
          <div className={styles.singleInput}>
            <TextInput controllerName="lastName" labelText={lastName} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.singleInput}>
            <TextInput controllerName="email" labelText={email} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.singleInput}>
            <TextInput controllerName="phone" labelText={phone} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.singleInput}>
            <Checkbox
              controllerName="consents"
              labelText={<RichText document={consentsLabelRichText} />}
              theme={Themes.White}
            />
          </div>
        </div>
      </div>
      <Button
        as="button"
        className={styles.button}
        variant={ButtonVariant.Basic}
        type="submit"
        isFullWidth
        isLoading={isSubmitting}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};
