import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { useCart } from '@hooks/cart';
import { useHttpService } from '@hooks/useHttpService';
import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { ClientHttpService } from '../../services/http/client-http';
import { Cart } from '../../types/cart.types';
import { Locale } from '../../types/common.types';
import { IdCheckResult, IdCheckResultStatus } from '../../types/user-risk-validation.types';
import { User } from '../../types/user.types';
import { useRedirect } from '../useRedirect';

import { sendIdCheckEvent } from '@services/gtm/page-initialization';

const useUserRiskValidationQueryId = 'useUserRiskValidationQueryId';

// TODO KUBA: check if all reasons are needed/add more if needed
const dataComparisonDeclineReasons = [
  'gender',
  'firstName',
  'lastName',
  'dateOfExpiry',
  'documentType',
  'issuingCountry',
  'documentNumbers',
];

const imageIntegrityDeclineReasons = [
  'imageQuality',
  'conclusiveDocumentQuality',
  'supportedDocument',
  'colourPicture',
];

const isDeclinedBy = (
  reasons: string[],
  availableErrors: typeof imageIntegrityDeclineReasons | typeof dataComparisonDeclineReasons
) => availableErrors.some(elem => reasons.includes(elem));

const handleIdCheckResult = async (
  idCheckResult: IdCheckResult,
  redirect: ReturnType<typeof useRedirect>,
  user: User,
  httpService: ClientHttpService,
  locale: Locale,
  cart: Cart,
  successfulCheckText: string
) => {
  const { status } = idCheckResult;
  const isDeclinedByWrongData =
    idCheckResult.reasons && isDeclinedBy(idCheckResult.reasons, dataComparisonDeclineReasons);
  const isDeclinedByImageQuality =
    idCheckResult.reasons && isDeclinedBy(idCheckResult.reasons, imageIntegrityDeclineReasons);

  await sendIdCheckEvent({
    idCheckResult,
    isDeclinedByWrongData,
    isDeclinedByImageQuality,
    option: {
      user: user ? 'loggedIn' : 'notLoggedIn',
    },
  });

  if (IdCheckResultStatus.Approved === status) {
    redirect({
      pageId: Paths.CheckoutDelivery,
      notificationTranslatedMessage: successfulCheckText,
      notificationType: toast.TYPE.SUCCESS,
    });

    return;
  }

  if (IdCheckResultStatus.Declined === status && isDeclinedByWrongData) {
    redirect({
      url: buildPath({
        pageId: Paths.CheckoutCustomerDetails,
        queryParams: { idCheckErrors: JSON.stringify({ fields: idCheckResult.reasons }) },
        locale,
      }),
    });

    return;
  }

  if (IdCheckResultStatus.Declined === status && isDeclinedByImageQuality) {
    const response = await httpService.post('/validate-user', {
      userId: user.id,
      items: cart?.items || [],
    });

    redirect({
      url: buildPath({
        pageId: Paths.IdConfirmation,
        queryParams: {
          token: response.data.token,
          workflow: response.data.workflowRunId,
          idCheckErrors: JSON.stringify({
            fields: idCheckResult.reasons,
          }),
        },
        locale,
      }),
    });

    return;
  }

  redirect({
    pageId: Paths.CheckoutOrderNotAccepted,
  });
};

export const useCheckoutUserRiskValidationResult = (
  isFormCompleted: boolean,
  redirect: ReturnType<typeof useRedirect>,
  user: User,
  successfulCheckText: string
) => {
  const httpService = useHttpService();
  const { locale } = useLocale();
  const { data: cart } = useCart();

  return useQuery(
    useUserRiskValidationQueryId,
    async () => {
      const response = await httpService.get('/validate-user/check-result');

      return response.data;
    },
    {
      onSuccess: data => {
        if (!data) {
          return;
        }
        handleIdCheckResult(data, redirect, user, httpService, locale, cart, successfulCheckText);
      },
      enabled: isFormCompleted,
      retry: 5,
      retryDelay: 5000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
