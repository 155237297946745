import React, { ReactNode } from 'react';

import { DataAttribute } from 'types/data-attributes';

import styles from './CheckboxInternals.module.scss';

interface CheckboxInternalsProps {
  name: string;
  label: ReactNode;
  dataAttribute?: DataAttribute;
  checked: boolean;
  disabled: boolean;
  onInfoClick?: () => void;
}

export const CheckboxInternals: React.FC<CheckboxInternalsProps> = ({
  name,
  label,
  dataAttribute,
  checked,
  disabled,
}) => {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        className={styles.input}
        checked={checked}
        {...dataAttribute}
        disabled={disabled}
      />
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
    </>
  );
};
