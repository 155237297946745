/* eslint-disable import/no-cycle */
import React, { useState, createContext, useEffect } from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { useLogger } from '@hooks/useLogger';

import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import PageLoader from '@components/PageLoader/PageLoader';
import PageSheet from '@components/PageSheet/PageSheet';
import { SubscriptionActionPageHeader } from '@components/SubscriptionActionPageHeader/SubscriptionActionPageHeader';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useLeavePrevention } from '@hooks/useLeavePrevention';
import { useLocale } from '@hooks/useLocale';
import { useUserPanelCancelSubscriptionMutation } from '@hooks/user-panel/useUserPanelCancelSubscriptionMutation';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CancellationPage.module.scss';

import { getCancellationFee } from '@helpers/fee';

dayjs.extend(minMax);


export const CancellationSteps = {
  cancellationDetails: 'cancellationDetails',
  cancellationPickUpDateStep: 'cancellationPickUpDateStep',
  cancellationSummary: 'cancellationSummary',
  cancellationConfirmation: 'cancellationConfirmation',
};

export const StepContext = createContext();

export const getDateByGroupType = groupType => {
  if (groupType === 'MDA') {
    return dayjs()
      .add(3, 'week')
      .toDate();
  }

  return dayjs()
    .add(2, 'week')
    .toDate();
};

export const CancellationPage = ({ story: { content }, pageContext, locale }) => {
  const { logger } = useLogger({ context: 'CancellationPage' });
  const userPanelCancelSubscriptionMutation = useUserPanelCancelSubscriptionMutation();
  const router = useRouter();
  const { data: subscription, isLoading } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });
  const { language } = useLocale();

  const defaultValues = {
    cancellationConfirmation: false,
    pickUpLater: 'false',
    selectedPickupDate: getDateByGroupType(subscription?.plan?.category?.groupType),
  };

  const formOptions = {
    resolver: null,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  const methods = useForm(formOptions);
  const [step, setStep] = useState(CancellationSteps.cancellationDetails);
  const [cancellationFee, setCancellationFee] = useState();
  const [selectedPickupDate, setSelectedPickupDate] = useState(defaultValues.selectedPickupDate);
  const [cancellationConfirmation, setCancellationConfirmation] = useState(defaultValues.cancellationConfirmation);

  const calculateFee = preferredCancellationDate => {
    const movingDate = subscription?.movingDate && dayjs(subscription.movingDate);
    const lastMovingDate = movingDate !== null ? dayjs.min(movingDate)?.toISOString() : null;
    const fee = getCancellationFee(subscription, lastMovingDate, preferredCancellationDate).total;

    setCancellationFee(fee);
  };

  const isSubscriptionTerminating = !isLoading && subscription?.status === 'terminating';

  useEffect(() => {
    if (subscription) {
      calculateFee();
    }
  }, [subscription]);

  if (isSubscriptionTerminating) {
    window.location.href = router.push(
      buildPath({
        pageId: Paths.UserPanelSubscriptions,
        locale,
      })
    );
  }

  useLeavePrevention({
    isUnsafeClose: true,
    isDisabled: step === CancellationSteps.cancellationConfirmation || isSubscriptionTerminating || !subscription,
  });

  if (!subscription || isLoading || isSubscriptionTerminating) {
    return <PageLoader />;
  }

  const onSubmitHandler = async data => {
    try {
      const cancelSubscriptionDto = {
        cancellationReason: 'other', // default cancellationReason, it will be handled by survey
        preferredCancellationDate: data.pickUpLater === 'false' ? null : dayjs(data.selectedPickupDate).toDate(),
        cancellationReminder: false,
        cancellationFee,
      };

      await userPanelCancelSubscriptionMutation.mutateAsync({
        id: pageContext.id,
        cancelSubscriptionDto,
      });

      setStep(CancellationSteps.cancellationConfirmation);
    } catch (e) {
      toast(e.data.errors[0].message, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const cancellationStep = content.body.find(blok => blok.step === CancellationSteps[step]);

  return (
    <div className={cn(styles.container, styles[step])}>
      <SubscriptionActionPageHeader />

      <div className={styles.formContent}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmitHandler, errors =>
              logger.error({ message: 'Error in onSubmit in CancellationPage', params: errors })
            )}
          >
            <StepContext.Provider
              value={{
                setStep,
                step,
                calculateFee,
                cancellationFee,
                setSelectedPickupDate,
                cancellationConfirmation,
                setCancellationConfirmation,
              }}
            >
              {cancellationStep.components?.map(component => {
                if (
                  [CancellationSteps.cancellationDetails, CancellationSteps.cancellationPickUpDateStep].includes(
                    step
                  ) &&
                  ['notificationBar'].includes(component.component) &&
                  (!cancellationFee || subscription.status === 'future')
                ) {
                  return undefined;
                }

                return (
                  <DynamicComponent
                    style={
                      component.component === 'iconButton'
                        ? {}
                        : {
                            marginBottom: '2.4rem',
                          }
                    }
                    {...component}
                    key={component._uid}
                    contentVariables={{
                      pickUpDate: dayjs(selectedPickupDate)
                        .locale(language)
                        .format('DD MMMM YYYY'),
                      fee: cancellationFee,
                    }}
                    layout="emptyLayout"
                    handleOnChange={params => {
                      if (params?.controllerName === 'cancellationConfirmation') {
                        setCancellationConfirmation(params?.fieldValue);
                      }
                    }}
                  />
                );
              })}
            </StepContext.Provider>
          </form>
        </FormProvider>
      </div>
      <PageSheet />
    </div>
  );
};
