import { useContentContext } from '@context/content';

export const useNotifications = name => {
  const { notifications } = useContentContext();

  return notifications
    ?.filter(p => p.full_slug.includes(`notifications/${name || ''}`))
    .reduce((acc, current) => {
      acc[current.slug] = current.content;

      return acc;
    }, {});
};
