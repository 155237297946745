import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';
import Loader from '@components/Loader/Loader';
import { useContentContext } from '@context/content';
import { usePaymentAttempt } from '@hooks/payment/usePaymentAttempt';
import { useLogger } from '@hooks/useLogger';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import styles from './VerifyPayment.module.scss';

export const VerifyPayment = ({ label, text, successToastMessage }) => {
  const { logger } = useLogger({ context: 'VerifyPayment' });
  const { transactionId } = useContentContext();
  const { data: paymentAttempt, isLoading: paymentAttemptIsLoading } = usePaymentAttempt(transactionId);
  const redirect = useRedirect();

  useEffect(() => {
    if (paymentAttempt) {
      logger.info({
        message: `User ${paymentAttempt?.user?.id} is trying to update payment method`,
        params: {
          userId: paymentAttempt?.user?.id,
          cartId: paymentAttempt?.cart?.id,
          paymentStatus: paymentAttempt?.status,
        },
      });

      if (paymentAttempt?.status === 'paid') {
        logger.info({
          message: `User ${paymentAttempt?.user?.id} is already changed the payment method`,
          params: {
            userId: paymentAttempt?.user?.id,
            cartId: paymentAttempt?.cart?.id,
            paymentAttemptId: paymentAttempt?.id,
            paymentStatus: paymentAttempt?.status,
          },
        });

        toast(successToastMessage, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });

        redirect({
          pageId: Paths.UserPanelPreferences,
        });
      }
    }

    if (
      (!paymentAttemptIsLoading && !paymentAttempt) ||
      ['failed', 'expired', 'canceled'].includes(paymentAttempt?.status)
    ) {
      toast('Something went wrong. Please try again.', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });

      redirect({ pageId: Paths.UserPanelChangePaymentMethod });
    }
  }, [paymentAttempt]);

  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
      <HeadingGroup label={label} text={text} />
    </div>
  );
};
