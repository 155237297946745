import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useUserNewPasswordMutation } from '@hooks/user/useUserNewPasswordMutation';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import styles from './ResetPassword.module.scss';
import { resetPasswordValidation } from './validation';
import { HeadingGroup } from '../../../components/HeadingGroup/HeadingGroup';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const ResetPassword = ({
  headerText,
  infoText,
  newPasswordText,
  confirmPasswordText,
  submitText,
  failMessageToast,
  validationMessages,
}) => {
  const userNewPasswordMutation = useUserNewPasswordMutation();
  const router = useRouter();
  const redirect = useRedirect();

  const formOptions = {
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(resetPasswordValidation(validationMessages)),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  const methods = useForm(formOptions);

  const onSubmitHandler = async data => {
    try {
      await userNewPasswordMutation.mutateAsync({
        password: data.newPassword,
        passwordRepeat: data.confirmPassword,
        token: router.query.token,
      });
      redirect({
        pageId: Paths.Login,
        notificationType: toast.TYPE.SUCCESS,
        notificationPhraseKey: 'password-changed',
      });
    } catch (error) {
      toast(failMessageToast, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.formContainer}>
        <HeadingGroup variant="default" alignLeft label={headerText} text={infoText} />
        <TextInput controllerName="newPassword" labelText={newPasswordText} password />
        <TextInput controllerName="confirmPassword" labelText={confirmPasswordText} password />
        <Button
          as="button"
          variant={ButtonVariant.Basic}
          theme={ButtonTheme.Secondary}
          type="submit"
          disabled={!methods.formState.isValid}
          isLoading={methods.formState.isSubmitting}
          className={styles.primaryButton}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
