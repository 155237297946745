import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useUserContext } from '@context/user';
import { JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'constants/localStorage';

export const useCreateReceiverUserMutation = () => {
  const httpService = useHttpService();
  const { setUseUserEnabled } = useUserContext();

  const [, setCookies] = useCookies();

  return useMutation(
    async createUserDto => {
      const response = await httpService.post('/takeover/create-user', createUserDto);

      if (response.status >= 400) {
        throw new Error(response.statusText);
      }

      return response.data;
    },
    {
      onSuccess: data => {
        // TODO: why is it disabled?
        // setUseUserEnabled(false);
        setCookies(JWT_STORAGE_KEY, data.authToken, { path: '/', sameSite: 'strict', secure: true });
        setCookies(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken, {
          path: '/',
          sameSite: 'strict',
          secure: true,
        });

        localStorage.setItem(JWT_STORAGE_KEY, data.authToken);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken);
      },
    }
  );
};
