import { CART_STORAGE_KEY } from 'constants/localStorage';

import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';

import { CART_STORAGE_KEY as COOKIE_CART_STORAGE_KEY } from '@hooks/cart/useCartId';
import { useHttpService } from '@hooks/useHttpService';

export const useCheckoutCreatePaymentOrderMutation = () => {
  const [, , removeCookie] = useCookies([COOKIE_CART_STORAGE_KEY]);

  const httpService = useHttpService();

  return useMutation(
    async (createPaymentOrderDto: {
      paymentMandateUuid: string;
      accountOwnerName: string;
      recurringPaymentMandate: boolean;
      agreeTermsAndConditions: boolean;
      redirectPath: string;
      userId: string;
      cartId: string;
      paymentType: any; // TODO Adyen: add type PaymentType;
      depositPaymentMethod: any; // TODO Adyen: add type DepositPaymentMethod;
      depositAmountInCents: number;
      termsAndConditionFile?: any;
      contractFile?: any;
      paymentGatewayId: string;
    }) => {
      const response = await httpService.post('/checkout/create-payment-order', createPaymentOrderDto);

      return response.data;
    },
    {
      onSuccess: () => {
        localStorage.removeItem(CART_STORAGE_KEY);
        removeCookie(COOKIE_CART_STORAGE_KEY, { path: '/', sameSite: 'strict', secure: true });
      },
    }
  );
};
