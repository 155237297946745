import * as Yup from 'yup';

import { passwordComplexityRegExp } from '../../../validation/regexps/password';

const passwordValidationSchema = ({ passwordTooShort, passwordMustContain, required }) => {
  return {
    oldPassword: Yup.string().required(required),
    newPassword: Yup.string()
      .min(5, passwordTooShort)
      .matches(new RegExp(passwordComplexityRegExp), passwordMustContain)
      .required(required),
  };
};

export const passwordValidation = validationMessages =>
  Yup.object().shape(passwordValidationSchema(validationMessages));
