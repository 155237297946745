import React from 'react';

import { sortPricingsByVariant } from '@helpers/sort-pricings-by-variant';
import { sendOnClickProductEvent } from '@services/gtm/product-category-page';
import { useLogger } from '@hooks/useLogger';
import cn from 'classnames';
import { groupBy } from 'lodash';
import Link from 'next/link';
import Router from 'next/router';
import { StoryData } from 'storyblok-js-client';
import { Country } from 'types/common.types';
import { Offer, OfferContractType, OfferItemOneTimeRecurringPricing } from 'types/offer.types';

import { Image } from '@components/Image/Image';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLocale } from '@hooks/useLocale';
import EnergyLabel from '@modules/PDP/components/EnergyLabel/EnergyLabel';
import OutOfStockForm from '@modules/PDP/components/OutOfStockForm/OutOfStockForm';
import { buildOfferPath } from '@utils/paths/build-paths';

import { FinancingOffer } from './components/FinancingOffer/FinancingOffer';
import { LabelRow } from './components/LabelRow/LabelRow';
import { ProductCardTable } from './components/ProductCardTable/ProductCardTable';
import { RentalOffer } from './components/RentalOffer/RentalOffer';
import { TitleContainer } from './components/TitleContainer/TitleContainer';
import { TopBar } from './components/TopBar/TopBar';
import styles from './ProductCard.module.scss';

const infoboxRangeValue = (groupedPricings, translatedConditions) => {
  const translate = Object.keys(translatedConditions)?.reduce((acc, currentKey) => {
    acc.push({
      key: currentKey,
      value: translatedConditions[currentKey],
    });

    return acc;
  }, []);

  return Object.keys(groupedPricings).reduce((acc, currentKey) => {
    const pricings = groupedPricings[currentKey];

    if (pricings.length === 0) {
      return acc;
    }

    const pricingWithMinimumAmount: OfferItemOneTimeRecurringPricing = pricings.reduce(
      (prevPricing: OfferItemOneTimeRecurringPricing, currPricing: OfferItemOneTimeRecurringPricing) => {
        if (!prevPricing) {
          return currPricing;
        }

        if (!currPricing) {
          return prevPricing;
        }

        return currPricing.recurringAmount < prevPricing.recurringAmount ? currPricing : prevPricing;
      }
    );

    acc.push({
      minValue: pricingWithMinimumAmount.recurringAmount,
      key: currentKey,
      condition: translate.find(item => item?.key === currentKey)?.value || currentKey,
      referenceAmount: pricingWithMinimumAmount.referenceRecurringAmount,
      isReferenceAmountActive: pricingWithMinimumAmount.isReferenceAmountActive,
    });

    return acc;
  }, []);
};

export const ProductCardOffer = ({
  offerData,
  plan: contentData,
  topBarColor,
  topBarTitle,
  index,
}: {
  offerData: Offer;
  plan: StoryData;
  buttonText: string;
  topBarColor: string;
  topBarTitle: string;
  index: number;
}) => {
  const { logger } = useLogger({ context: 'ProductCardOffer' });
  const { locale, country } = useLocale();
  const featurePhrases = Object.values(usePhrases({ name: 'features', rawObject: true })) as any;
  const translatedConditions = usePhrases({ name: 'conditions' });
  const financialProduct = offerData.financial;
  const mainOfferItems = offerData.offerItems.filter(offerItem => offerItem.type === 'main');
  const mainItem = mainOfferItems[0];
  const isDemandTestCategory = offerData.offerItems.some(offerItem => offerItem.isDemandTestCategory === true);

  if (!contentData) {
    logger.warn({ message: `Cannot find plan ${contentData.name}` });

    return null;
  }

  logger.info({ message: `Plan ${contentData.name}` });

  const groupedPricings = groupBy(sortPricingsByVariant(mainItem.pricings), 'variant');

  const url =
    buildOfferPath({
      locale,
      contentSlug: offerData.contentSlug,
      category: mainItem.categorySlug,
    }) || '#';

  const specialFeatureLabel = featurePhrases?.find(item => item?.key === 'specialFeature')?.label;

  const featuresToMap = contentData.content.featuresToDisplay || [];

  const features = featuresToMap.map(feature => {
    const featurePhrase = featurePhrases.find(item => item?.key === feature);
    const featureLink = featurePhrase?.link?.[0];

    return {
      key: feature,
      label: featurePhrase?.label,
      value: contentData.content?.features?.find(item => item?.key === feature)?.value || null,
      link: featureLink ? { ...featureLink, dataAttribute: { 'data-pcp-feature': featureLink.headingTitle } } : null,
      icon: featurePhrase?.icon?.filename ? featurePhrase.icon : null,
    };
  });

  const energyLabel = features.find(feature => feature.key === 'energyClass');

  const onClickGtmHandler = () => sendOnClickProductEvent(offerData, contentData?.content.name);

  return (
    <>
      <div className={styles.container} data-plan-type={mainItem.type}>
        {((!financialProduct && !mainItem.isAtLeastOneVariantAvailable) || (topBarTitle && topBarColor)) && (
          <TopBar
            className={styles.topBar}
            title={topBarTitle}
            color={topBarColor}
            available={
              mainItem.isAtLeastOneVariantAvailable ||
              ([OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(offerData.contractType) &&
                !!financialProduct)
            }
          />
        )}

        <div className={styles.paddingContainer}>
          {groupedPricings && translatedConditions && (
            <div className={styles.content}>
              <LabelRow
                machineCode={contentData.content?.subtitle}
                pricingsKeys={infoboxRangeValue(groupedPricings, translatedConditions)}
              />
            </div>
          )}

          <div className={styles.content}>
            <TitleContainer
              energyLabelDocument={contentData.content?.energyLabelDocument}
              title={contentData.content?.name}
              href={url}
              onClick={onClickGtmHandler}
            />
          </div>

          <div className={cn(styles.content, styles.imageContainer)}>
            <EnergyLabel variant={energyLabel?.value} link={energyLabel?.link} productCard />

            <Image
              image={contentData?.content?.fullImage?.filename}
              className={cn(styles.image, styles.imageClickable)}
              onClick={() => {
                onClickGtmHandler();
                Router.push(url);
              }}
              alt="energy label image"
              style={{ objectFit: 'contain', height: 'auto', width: '100vw' }}
              lazyLoading={index !== 0}
              width={360}
              height={260}
            />
          </div>

          <div className={styles.content}>
            <ProductCardTable specialFeatureLabel={specialFeatureLabel} features={features} />
          </div>

          <div className={styles.summaryContainer}>
            <Link href={url} onClick={onClickGtmHandler}>
              {groupedPricings && translatedConditions && (
                <>
                  {[OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(
                    offerData.contractType
                  ) &&
                    financialProduct &&
                    country === Country.DE && <FinancingOffer financialProduct={financialProduct} />}

                  {[OfferContractType.Rental, OfferContractType.RentalAndFinancing].includes(
                    offerData.contractType
                  ) && <RentalOffer mainItem={mainItem} />}
                </>
              )}
            </Link>
            <div className={styles.content} data-test-id="out-of-stock-form">
              {[OfferContractType.Rental, OfferContractType.RentalAndFinancing].includes(offerData.contractType) &&
                !mainItem.isAtLeastOneVariantAvailable &&
                !financialProduct && (
                  <OutOfStockForm
                    vib={mainItem.contentSlug}
                    url={url}
                    isDemandTestCategory={isDemandTestCategory}
                    className=""
                    showButton={false}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
