import React from 'react';

import { PaymentPlan } from 'types/frontend-financial-product.types';

import { OfferCardConditions } from '@modules/PDP/components/OfferCardConditions/OfferCardConditions';
import { OfferCardPricings } from '@modules/PDP/components/OfferCardPricings/OfferCardPricings';

import styles from './PaymentPlanFinanceBox.module.scss';
import { FinanceContent } from '../../FinanceContent';

export const PaymentPlanFinanceBox = ({
  readMoreText,
  content,
  financingOfferContent,
  financialProduct,
  durationsLink,
  conditionText,
  conditionsLink,
  currencyFormatter,
  groupedPricingsForFinancial,
  selectedMainProductPricing,
  setSelectedMainProductPricing,
  durationText,
  selectedPaymentPlan,
  financingLink,
  setSelectedPaymentPlan,
}) => {
  // TODO: BLUEMOVE-5992 to remove after release
  const finalFinancingOfferContent = Array.isArray(financingOfferContent)
    ? { ...financingOfferContent?.[0] }
    : financingOfferContent;

  return (
    <div className={styles.offerCards}>
      <OfferCardConditions
        readMoreText={readMoreText}
        conditionText={conditionText}
        conditionsLink={conditionsLink}
        currencyFormatter={currencyFormatter}
        groupedPricings={groupedPricingsForFinancial}
        selectedMainProductPricing={selectedMainProductPricing}
        setSelectedMainProductPricing={setSelectedMainProductPricing}
        setSelectedPaymentPlan={setSelectedPaymentPlan}
        paymentPlan={PaymentPlan.Finance}
      />

      <OfferCardPricings
        durationText={durationText}
        durationsLink={durationsLink}
        readMoreText={readMoreText}
        groupedPricings={groupedPricingsForFinancial}
        selectedMainProductPricing={selectedMainProductPricing}
        setSelectedMainProductPricing={setSelectedMainProductPricing}
        selectedPaymentPlan={selectedPaymentPlan}
        financingLink={financingLink}
      />

      <FinanceContent
        {...finalFinancingOfferContent}
        frontendFinancialProduct={financialProduct}
        selectedMainProductPricing={selectedMainProductPricing}
        content={content}
      />
    </div>
  );
};
