import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { usePhrases } from '@hooks/context/usePhrases';
import { useNlAddressValidationMutation } from '@hooks/useAddressValidation';
import { useUser } from '@hooks/user/useUser';
import { useUserPanelUpdateAddressMutation } from '@hooks/user-panel/useUserPanelUpdateAddressMutation';

import { existingCustomerValidationSchema } from './validation';
import { postalCodeFilters } from '../../../../../../validation/regexps/postal-code/index';
import styles from '../ChangeAddressForm.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

const fieldsNames = {
  postalCode: 'postalCode',
  street: 'street',
  city: 'city',
  houseNumber: 'houseNumber',
  houseNumberAddon: 'houseNumberAddon',
  phoneNumber: 'phoneNumber',
};

export const ChangeAddressFormNl = ({
  successToastMessage,
  changePhoneNumberText,
  changeAddressText,
  streetText,
  houseNumberText,
  cityText,
  submitText,
  postalCodeText,
  addonText,
  validationMessages,
}) => {
  const userChangeAddressAndPhoneMutation = useUserPanelUpdateAddressMutation();
  const nlAddressValidationMutation = useNlAddressValidationMutation();
  const { data: user } = useUser();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const [isAddressLoading, setAddressLoading] = useState(false);

  const formOptions = {
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(existingCustomerValidationSchema(validationMessages)),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    defaultValues: {
      phoneNumber: user.billingAddress.phone || '',
      postalCode: user.billingAddress.postalCode || '',
      street: user.billingAddress.street || '',
      city: user.billingAddress.city || '',
      houseNumber: user.billingAddress.houseNumber || '',
      houseNumberAddon: user.billingAddress.houseNumberAddon || '',
    },
  };
  const methods = useForm(formOptions);

  const checkAddress = useCallback(async () => {
    const postalCode = methods.getValues(fieldsNames.postalCode);
    const houseNumber = methods.getValues(fieldsNames.houseNumber);

    if (postalCode?.match(postalCodeFilters.nl) && houseNumber) {
      try {
        setAddressLoading(true);
        const address = await nlAddressValidationMutation.mutateAsync({
          postalCode: postalCode.toUpperCase().replace(/\s/g, ''),
          houseNumber: parseInt(houseNumber, 0),
        });

        if (address.city && address.street) {
          methods.setValue(fieldsNames.city, address.city, {
            shouldTouch: true,
            shouldValidate: true,
          });

          methods.setValue(fieldsNames.street, address.street, { shouldTouch: true, shouldValidate: true });

          methods.clearErrors(fieldsNames.postalCode);

          return;
        }

        methods.setError(fieldsNames.postalCode, {
          type: 'custom',
          message: validationMessages.postalCodeNlNotFound,
        });

        methods.setValue(fieldsNames.city, '', {
          shouldTouch: true,
          shouldValidate: true,
        });

        methods.setValue(fieldsNames.street, '', {
          shouldTouch: true,
          shouldValidate: true,
        });
      } catch (error) {
        methods.setError(fieldsNames.postalCode, {
          type: 'custom',
          message: validationMessages.postalCodeNlNotFound,
        });

        methods.setValue(fieldsNames.city, '', {
          shouldTouch: true,
          shouldValidate: true,
        });

        methods.setValue(fieldsNames.street, '', {
          shouldTouch: true,
          shouldValidate: true,
        });
      } finally {
        setAddressLoading(false);
      }
    }
  }, []);

  const onSubmitHandler = async data => {
    await checkAddress();

    try {
      const address = await nlAddressValidationMutation.mutateAsync({
        postalCode: data.postalCode.toUpperCase().replace(/\s/g, ''),
        houseNumber: parseInt(data.houseNumber, 0),
      });

      const isAddressCorrect = address.city === data.city && address.street === data.street;

      if (!isAddressCorrect) {
        throw Error('Incorrect address');
      }

      if (address) {
        await userChangeAddressAndPhoneMutation.mutateAsync({
          houseNumberAddon: data.houseNumberAddon,
          postalCode: data.postalCode,
          street: data.street,
          city: data.city,
          houseNumber: data.houseNumber,
          phone: data.phoneNumber,
        });
        toast(successToastMessage, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.container}>
        <div className={styles.formGroupWrapper}>
          <span className={styles.changeTextFont}>{changePhoneNumberText}</span>
          <TextInput controllerName={fieldsNames.phoneNumber} />
        </div>
        <div className={styles.formGroupWrapper}>
          <span className={styles.changeTextFont}>{changeAddressText}</span>
          <div className={styles.inputRow}>
            <TextInput
              controllerName={fieldsNames.postalCode}
              labelText={postalCodeText}
              onBlur={checkAddress}
              isLoading={isAddressLoading}
            />
            <TextInput controllerName={fieldsNames.houseNumber} labelText={houseNumberText} onBlur={checkAddress} />
            <TextInput controllerName={fieldsNames.houseNumberAddon} labelText={addonText} onBlur={checkAddress} />
          </div>
          <div className={styles.inputRow}>
            <TextInput controllerName={fieldsNames.street} labelText={streetText} disabled />
            <TextInput controllerName={fieldsNames.city} labelText={cityText} disabled />
          </div>
        </div>
        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Icon}
          icon="none"
          type="submit"
          isFullWidth
          isLoading={methods.formState.isSubmitting}
          disabled={!methods.formState.isValid}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
