/* eslint-disable import/no-cycle */
import React from 'react';

import SbEditable from 'storyblok-react';

import { NoComponent } from './components/NoComponent';
import { Components, disabledComponents, Layouts } from './configuration';

const DynamicComponent = blok => {
  const { layout, component: componentName, style } = blok;

  const blokComponent = Components[blok.component];
  const Component = blokComponent?.component;
  // Layout configuration from Storyblok
  // have a higher priority than from configuration
  const Layout = Layouts[layout] || blokComponent?.layout;

  if (typeof Component !== 'undefined') {
    return (
      <SbEditable content={blok}>
        <Layout style={style}>
          <Component {...blok} />
        </Layout>
      </SbEditable>
    );
  }

  if (!disabledComponents.includes(componentName)) {
    return <NoComponent componentName={componentName} />;
  }

  return null;
};

export default DynamicComponent;
