import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

import InvalidIcon from '@assets/icons/new/inputs/invalidInputIcon.svg';
import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';
import SearchIcon from '@assets/icons/new/inputs/searchIcon.svg';
import ValidIcon from '@assets/icons/new/inputs/validInputIcon.svg';
import Loader from '@components/Loader/Loader';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './TextInput.module.scss';

export const TextInput = ({
  controllerName,
  placeholder,
  labelText,
  hintText,
  password = false,
  search = false,
  mask,
  onBlur,
  disabled = false,
  lengthCounter = false,
  maxLength,
  dataAttribute,
  isLoading = false,
  customValidationCheckOnMount,
}) => {
  const inputsPhrases = usePhrases({ name: 'inputs', rawObject: true });
  const [hidePassword, setHidePassword] = useState(true);
  const {
    register,
    control,
    formState: { errors, isSubmitted },
    getValues,
  } = useFormContext();
  // if we pass value as defaultValue
  // we should pass defaultValue to InputMask component
  // otherwise it occurs errors
  const values = getValues();
  const fieldValue = values[controllerName];

  const error = get(errors, controllerName);
  const isError = error !== undefined;
  const isValid = isSubmitted && !error;

  useEffect(() => {
    if (customValidationCheckOnMount) {
      customValidationCheckOnMount();
    }
  }, []);

  return (
    <Controller
      render={({ field: { ref, ...field } }) => {
        return (
          <InputMask
            id={field.name}
            mask={mask}
            onChange={field.onChange}
            onBlur={field.onBlur}
            defaultValue={fieldValue}
            {...field}
          >
            {() => (
              <div className={cn(styles.textInputContainer)}>
                {labelText && <div className={styles.labelText}>{labelText}</div>}

                <div className={styles.inputContainer}>
                  <input
                    className={cn(styles.textInput, {
                      [styles.passwordInput]: password,
                      [styles.searchInput]: search,
                    })}
                    placeholder={placeholder}
                    type={password && hidePassword ? 'password' : 'text'}
                    {...register(controllerName)}
                    {...field}
                    onBlur={() => onBlur && onBlur()}
                    disabled={disabled}
                    maxLength={maxLength}
                    data-test-id={controllerName}
                    {...dataAttribute}
                  />
                  {!search && isValid && <ValidIcon className={styles.validationIcon} aria-hidden />}
                  {!search && isError && <InvalidIcon className={styles.validationIcon} aria-hidden />}
                  {search && <SearchIcon className={styles.searchIcon} aria-hidden />}
                  {isLoading && <Loader className={styles.loader} />}

                  {lengthCounter && maxLength && (
                    <div className={styles.lengthCounter}>
                      {field?.value?.length || 0}/{maxLength}
                    </div>
                  )}

                  {password && (
                    <button className={styles.hideButton} type="button" onClick={() => setHidePassword(!hidePassword)}>
                      {!hidePassword ? inputsPhrases?.hide?.value : inputsPhrases?.show?.value}
                    </button>
                  )}
                </div>

                {isError && (
                  <span className={styles.errorMessage}>
                    <RectangleIcon className={styles.rectangleIcon} />
                    {error.message}
                  </span>
                )}

                {hintText && <span className={styles.hintText}>{hintText}</span>}
              </div>
            )}
          </InputMask>
        );
      }}
      name={controllerName}
      {...dataAttribute}
      type="text"
      control={control}
    />
  );
};
