import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

import { AdditionalService } from 'types/additional-service.types';

export const ADDITIONAL_SERVICES_QUERY_ID = 'additionalServicesQueryId';

export const useSubscriptionAdditionalServices = (subscriptionId: string) => {
  const httpService = useHttpService();

  return useQuery(
    [ADDITIONAL_SERVICES_QUERY_ID, subscriptionId],
    async () => {
      const response = await httpService!.get<AdditionalService[]>(
        `/additional-service/subscription/${subscriptionId}`
      );

      return response.data!;
    },
    {
      enabled: !!httpService && !!subscriptionId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
