import React from 'react';

import ClockSvg from '@assets/icons/new/clock.svg';

import styles from './BlogpostCategory.module.scss';

import { BlogFilterLabel } from '@modules/Blog/BlogFilterLabel/BlogFilterLabel';
import { useBlogContext } from '@theme/BlogPostPage/BlogPostPage';

export const BlogpostCategory = ({ readingTime }) => {
  const { blogCategory } = useBlogContext();

  return (
    <div className={styles.blogpostCategoryAndReadingTime}>
      <BlogFilterLabel blogCategory={blogCategory} />
      <div className={styles.iconSpacing}>
        <ClockSvg />
      </div>
      <span className={styles.readingTime}>{readingTime}</span>
    </div>
  );
};
