import React from 'react';

import { RichText } from '@components/RichText/RichText';

import { ConditionalLink } from './ConditionalLink/ConditionalLink';
import { IconsRow } from './IconsRow/IconsRow';
import styles from './ProductCardTable.module.scss';

export const ProductCardTable = ({ specialFeatureLabel, features, description = '' }) => {
  const noIconsFeatures = features.filter(item => item?.key !== 'energyClass' && !item.icon);
  const specialFeature = {
    key: 'specialFeatures',
    header: specialFeatureLabel,
    icons: features.filter(item => item?.icon),
  };

  return (
    <div className={styles.container}>
      <div className={styles.textBox}>
        {description && <RichText document={description} className={styles.description} />}

        {noIconsFeatures.map((item, index) => {
          return (
            <div key={item?.label + index} className={styles.link}>
              <ConditionalLink link={item.link} additionalClass={styles.conditionalLink}>
                <span className={styles.feature}>
                  {item.label} {item.value}
                </span>
              </ConditionalLink>
              {index + 1 !== noIconsFeatures.length && <> | </>}
            </div>
          );
        })}
      </div>

      {specialFeature.icons.length > 0 && <IconsRow icons={specialFeature.icons} />}
    </div>
  );
};
