import React from 'react';

import styles from './BlogpostHeroImage.module.scss';
import { Image } from '../Image/Image';

export const BlogpostHeroImage = ({ desktopImage, mobileImage }) => {
  return (
    <div className={styles.wrapper}>
      <Image
        alt="blog post hero image"
        image={desktopImage}
        mobileImage={mobileImage}
        className={styles.blogpostHeroImage}
      />
    </div>
  );
};
