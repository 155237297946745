/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
import React from 'react';

import styles from './TakeoverSummaryCard.module.scss';

import { Image } from '@components/Image/Image';

export const TakeoverSummaryCard = ({ changeIcon, nameText, locationText, startDateText, data, userRole }) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Image image={changeIcon} mobileImage={changeIcon} className={styles.changeIcon} />
      </div>

      <div className={styles.addressContainer}>
        <div className={styles.shippingAddress}>
          <span className={styles.bold}>{nameText}</span>
          <span data-label={`takeover-${userRole}-name`}>
            {data.firstName} {data.lastName}
          </span>
          <span className={styles.email} data-label={`takeover-${userRole}-email`}>{data.email}</span>

          <div className={styles.separator} />

          <span className={styles.bold}>{locationText}</span>
          <span data-label={`takeover-${userRole}-street-house`}>
            {data.street} {data.houseNumber}
            {data.houseNumberAddon && `/${data.houseNumberAddon}`}
          </span>
          <span data-label={`takeover-${userRole}-postalcode-city`}>
            {data.postalCode} {data.city}
          </span>
          {data.takeoverDate && (
            <>
              <div className={styles.separator} />

              <span className={styles.bold}>{startDateText}</span>
              <span data-label={`takeover-${userRole}-date`}>{data.takeoverDate}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
