import React from 'react';

import { useCart } from '@hooks/cart';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from '../CheckoutOneTimePayment.module.scss';

export const CheckoutOneTimePaymentSummaryNl = ({ summaryDescription }) => {
  const { data: cart } = useCart();

  const { currencyFormatter } = useCurrencyFormatter();

  if (!cart) {
    return false;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.summaryDescriptionText}>{summaryDescription}</div>
        <div className={styles.valueText}>
          {currencyFormatter({
            price: cart.totalDepositPrice,
          })}
        </div>
      </div>
    </div>
  );
};
