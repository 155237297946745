import React from 'react';

import ReactImageZoom from 'react-image-zoom';

import imagePlaceholder from '@assets/images/image-placeholder.jpg';

export const getImageSource = image =>
  (image?.fieldtype === 'asset' && image.filename && `${image.filename}/m/filters:format(webp):quality(75)`) ||
  (typeof image === 'string' && image);

export enum ZoomPosition {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  ORIGINAL = 'original',
  RIGHT = 'right',
}

interface LensImageProps {
  image: string;
  zoomPosition: ZoomPosition;
  className: any;
  scale?: number;
  alt?: string;
}

const LensImage = ({ image, zoomPosition = ZoomPosition.RIGHT, scale = 1, className, alt }: LensImageProps) => {
  const isZoomPositionOriginal = zoomPosition === 'original';

  const imageSrc = getImageSource(image) || imagePlaceholder;

  return (
    <picture className={className}>
      <source media="(min-width: 768px)" srcSet={imageSrc} />

      <ReactImageZoom
        alt={alt}
        scale={scale}
        zoomWidth={450}
        img={imageSrc}
        zoomPosition={zoomPosition}
        offset={!isZoomPositionOriginal && { horizontal: 40 }}
        zoomStyle={
          !isZoomPositionOriginal && 'box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);border: 1px solid rgba(0, 0, 0, 0.1);'
        }
      />
    </picture>
  );
};

export default LensImage;
