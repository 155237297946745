import React from 'react';

import { useCheckoutTestData } from '@context/checkout-test-data';

import { Dropdown } from '../inputs/Dropdown/Dropdown';

export const idCheckResults = [
  { option: 'Omit Id Check', value: 'omit' },
  { option: 'Approved', value: 'approved' },
  { option: 'Declined', value: 'declined' },
  { option: 'Perform full workflow', value: 'full' },
];

export const TestIdCheck = () => {
  const { setTestingData } = useCheckoutTestData();

  return (
    <div style={{ border: '1px solid black', padding: '5px' }}>
      Choose if you want to go through full flow or just get result.
      <Dropdown
        controllerName="testIdCheckValue"
        labelText="Id check result"
        entries={idCheckResults}
        defaultValue={idCheckResults[0].value}
        onChange={value => setTestingData({ testIdCheckValue: value })}
      />
    </div>
  );
};
