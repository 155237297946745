import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  items: Yup.array(
    Yup.object().shape({
      dryerOnTop: Yup.boolean(),
      takeBackOldAppliance: Yup.boolean(),
    })
  ),
});

export const defaultValues = cartItemIds => {
  return {
    items: cartItemIds?.map(cartItemId => {
      return { id: cartItemId, dryerOnTop: false, takeBackOldAppliance: false };
    }),
  };
};
