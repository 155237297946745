import { CartItem } from 'types/cart.types';
import { OfferItemPricing } from 'types/offer.types';

export const isCartItemOnStock = (item: CartItem): boolean => {
  const isOfferIncluded = item?.offerItem;

  if (isOfferIncluded) {
    //TODO: OFFER
    return (item?.pricing as OfferItemPricing)?.isAvailable;
  }

  return (
    item?.plan?.isOnStock === 'available' ||
    (item?.plan?.isOnStock === 'automatic' &&
      item?.plan?.classifications?.find(c => c.classification?.toLowerCase() === item?.pricing?.variant)?.isOnStock)
  );
};
