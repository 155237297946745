import React from 'react';

import { RichText } from '@components/RichText/RichText';

import styles from '../CheckoutMonthlyPaymentForm.module.scss';

import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { CheckoutPaymentInputs } from '../../CheckoutPaymentInputs/CheckoutPaymentInputs';
import { CHECKOUT_PAYMENT_INPUTS_DE } from '../../CheckoutPaymentInputs/de/CheckoutPaymentInputsDe';
import { CHECKOUT_PAYMENT_INPUTS_NL } from '../../CheckoutPaymentInputs/nl/CheckoutPaymentInputsNl';
import { useLocale } from '@hooks/useLocale';

export const AdyenCheckoutMonthlyPaymentForm = props => {
  const { recurringConsentRichText, recurringConsentText } = props;
  const { country } = useLocale();

  const enabledCheckoutPaymentInputs = {
    de: [CHECKOUT_PAYMENT_INPUTS_DE.CARD],
    nl: [CHECKOUT_PAYMENT_INPUTS_NL.CARD, CHECKOUT_PAYMENT_INPUTS_NL.IDEAL],
  };

  return (
    <>
      <CheckoutPaymentInputs enabledCheckoutPaymentInputs={enabledCheckoutPaymentInputs[country]} {...props} />

      <div className={styles.recurringConsentCheckbox}>
        <Checkbox
          controllerName="recurringPaymentMandate"
          labelText={
            recurringConsentRichText ? (
              <RichText document={recurringConsentRichText} className={styles.recurringConsentRichText} />
            ) : (
              recurringConsentText
            )
          }
        />
      </div>
    </>
  );
};
