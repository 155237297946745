import React from 'react';

import cn from 'classnames';

import { useCart } from '@hooks/cart';
import { useCouponMessages } from '@hooks/cart/useCouponMessages';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { getCouponDiscountMessages } from '@utils/getCouponDiscountMessage';

import styles from './CheckoutMonthlyPriceSummary.module.scss';
import { CheckoutMonthlyPriceSummaryForOffer } from './CheckoutMonthlyPriceSummaryForOffer';

export const CheckoutMonthlyPriceSummary = ({ monthly, summaryDescription }) => {
  const { data: cart } = useCart();
  const { currencyFormatter } = useCurrencyFormatter();
  const { couponDiscountMessages } = useCouponMessages();
  const isOfferIncluded = cart?.items?.every(item => item.offerItem);

  if (isOfferIncluded) {
    return <CheckoutMonthlyPriceSummaryForOffer monthly={monthly} summaryDescription={summaryDescription} />;
  }

  if (!cart) {
    return false;
  }

  const { forDiscountedPeriodMessage, forAfterDiscountPeriodMessage } = getCouponDiscountMessages(
    cart.coupon,
    couponDiscountMessages
  );

  return (
    <div className={styles.container}>
      {cart.items?.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            <div className={styles.row}>
              <div className={styles.rowLeftColumn}>{item?.content?.name}</div>{' '}
              <div
                className={cn(styles.rowRightColumn, {
                  [styles.normalPriceAboveDiscount]: item.discountedPrice !== undefined,
                })}
              >
                {currencyFormatter({ price: item.pricing.amount })}{' '}
              </div>
            </div>

            {item.discountedPrice !== undefined && (
              <>
                <div className={styles.row}>
                  <div className={styles.rowLeftColumnDiscountMessage}>{forDiscountedPeriodMessage}</div>
                  <div className={cn(styles.rowRightColumn, styles.discountedPrice)}>
                    {currencyFormatter({ price: item.discountedPrice })}{' '}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.rowLeftColumnDiscountMessage}>{forAfterDiscountPeriodMessage}</div>
                  <div className={cn(styles.rowRightColumn)}>{currencyFormatter({ price: item.pricing.amount })} </div>
                </div>
              </>
            )}
            {index !== cart.items.length - 1 && <hr className={styles.rowLine} />}
          </React.Fragment>
        );
      })}

      <div className={styles.row}>
        <div className={styles.rowRightColumn}>{monthly}</div>{' '}
        <div
          className={cn(styles.rowRightColumn, {
            [styles.normalPriceAboveDiscount]: cart.discountedTotalMonthlyPrice !== undefined,
          })}
          data-total-normal-price={cart.totalMonthlyPrice}
        >
          {currencyFormatter({ price: cart.totalMonthlyPrice })}{' '}
        </div>
      </div>

      {cart.discountedTotalMonthlyPrice !== undefined && (
        <>
          <div className={styles.row}>
            <div className={styles.rowLeftColumnDiscountMessage}>{forDiscountedPeriodMessage}</div>
            <div
              className={cn(styles.discountedPrice, styles.rowRightColumn)}
              data-total-monthly-discount={cart.discountedTotalMonthlyPrice}
            >
              {currencyFormatter({ price: cart.discountedTotalMonthlyPrice })}{' '}
            </div>
          </div>

          {cart?.coupon?.durationType !== 'forever' && (
            <div className={styles.row}>
              <div className={styles.rowLeftColumnDiscountMessage}>{forAfterDiscountPeriodMessage}</div>
              <div className={cn(styles.rowRightColumn)} data-total-after-discount={cart.totalMonthlyPrice}>
                {currencyFormatter({ price: cart.totalMonthlyPrice })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
