import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Image } from '@components/Image/Image';

import styles from './MovingPlannerSummaryCard.module.scss';

export const MovingPlannerSummaryCard = ({ changeIcon, locationText, startDateText }) => {
  const formContext = useFormContext();

  const values = formContext?.getValues();

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <Image image={changeIcon} mobileImage={changeIcon} className={styles.changeIcon} />
      </div>

      <div className={styles.addressContainer}>
        <div className={styles.shippingAddress}>
          <span className={styles.bold}>{locationText}</span>
          <span>
            <span data-test-id="movingPlannerStreet">{values.street}</span>{' '}
            <span data-test-id="movingPlannerHouseNumber">{values.houseNumber}</span>
            <span data-test-id="movingPlannerAddon">{values.addon && `/${values.addon}`}</span>
          </span>
          <span>
            <span data-test-id="movingPlannerPostalCode">{values.postalCode}</span>{' '}
            <span data-test-id="movingPlannerCity">{values.city}</span>
          </span>
          {values.movingDate && (
            <>
              <div className={styles.separator} />

              <span className={styles.bold}>{startDateText}</span>
              <span data-test-id="movingPlannerDate">{values.movingDate}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
