import { Country } from './common.types';

export enum PricingVariant {
  Standard = 'standard',
  New = 'new',
  Refurbished = 'refurbished',
}

export enum TermUnit {
  Years = 'years',
  Months = 'months',
}

export interface Pricing {
  term: string;
  id: string;
  amount: number;
  months: number;
  termAmount: number;
  termUnit: TermUnit;
  plan: Plan;
  planId: string;
  variant: PricingVariant;
  deletedAt?: Date;
  isActive: boolean;
}
export interface PlanClassification {
  id: string;
  plan: Plan;
  classification: any; //TODO: add type
  isOnStock: boolean;
}

export enum StockStatus {
  Available = 'available',
  NotAvailable = 'not available',
  Automatic = 'automatic',
}

export enum PortfolioStatus {
  Future = 'future',
  Rejected = 'rejected',
  PhasingIn = 'phasing in',
  PhasedIn = 'phased in',
  PhasingOut = 'phasing out',
  PhasedOut = 'phased out',
}

export enum PlanClass {
  Premium = 'premium',
  Comfort = 'comfort',
  Basic = 'basic',
}

export interface Plan {
  id: string;
  isOnStock: StockStatus;
  isLive: boolean;
  bmId: string;
  isRefurbished?: boolean;
  depositAmount: number;
  fastDelivery: boolean;
  country: Country;
  class: PlanClass;
  category: any; //TODO: add type;
  categoryId?: string;
  pricings: Pricing[];
  product: any;
  productId?: string;
  pricebookEntry: string;
  variant: string;
  series?: string;
  internalName: string;
  classifications: PlanClassification[];
  portfolioStatus?: PortfolioStatus;
  endOfProductionDate?: Date;
}
