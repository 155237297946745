import React from 'react';

import * as dayjs from 'dayjs';

import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';
import { convert2slug } from '@utils/convert2slug';

import { OrderCardComponent } from '../../../../components/OrderCardComponent/OrderCardComponent';

const getDeliveryInfo = (country, type, tsb, deliveryPhrases) => {
  if (country === 'de' || type === 'SDA') {
    return {
      notificationBarContent: undefined,
      textContent: deliveryPhrases?.[type?.toLowerCase()]?.value,
    };
  }

  const content = tsb ? deliveryPhrases.deliverytsb.content : deliveryPhrases.deliverytsbcontact.content;

  return {
    notificationBarContent: content,
    textContent: undefined,
  };
};

export const CheckoutOrderCard = item => {
  const { country } = useLocale();
  const translatedConditions = usePhrases({ name: 'conditions' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const terms = usePhrases({ name: 'terms', rawObject: true });
  const general = usePhrases({ name: 'general' });
  const delivery = usePhrases({ name: 'deliverytime', rawObject: true });
  const { currencyFormatter } = useCurrencyFormatter();

  const priceFetch = item.price;
  const oneTimeFetch = item.plan.depositAmount;
  const classificationFetch = item.variant;
  const durationFetch = item.term;
  const image = item.content.fullImage.filename;
  const vib = item.content.subtitle;
  const productName = item.content.name;
  const tsb = item.order?.timeslotBookings.find(timeslot => timeslot.status === 'CONFIRMED');
  const tsbDate = tsb && dayjs(tsb.reservationStart).format('DD.MM');
  const tsbReservationStart = tsb && dayjs(tsb.reservationStart).format('HH:mm');
  const tsbReservationEnd = tsb && dayjs(tsb.reservationStop).format('HH:mm');
  const contentVariables = tsb && { date: tsbDate, time: `${tsbReservationStart} - ${tsbReservationEnd}` };
  const type = item.plan?.category?.groupType;
  const classification = translatedConditions[classificationFetch];
  const { notificationBarContent, textContent } = getDeliveryInfo(country, type, tsb, delivery);
  const duration = `${terms[convert2slug(durationFetch)]?.value}`;
  const price = `${currencyFormatter({ price: priceFetch })} / ${unitsTranslations['per-month-short'].value}`;
  const oneTimeFee = `+${currencyFormatter({ price: oneTimeFetch })} ${general.oneTimeFee}`;

  return (
    <OrderCardComponent
      image={image}
      vib={vib}
      classification={classification}
      productName={productName}
      content={notificationBarContent}
      contentVariables={contentVariables}
      deliveryTime={textContent}
      duration={duration}
      price={price}
      oneTimeFee={oneTimeFee}
      type={type}
    />
  );
};
