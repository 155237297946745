import React from 'react';
import styles from './TextChipPriceBar.module.scss';
import { Label } from '@components/Label/Label';

export const TextChipPriceBar = ({
  text,
  labelText,
  labelColor,
  customLabel,
  price,
}: {
  text: string;
  labelText?: string;
  labelColor?: '#17850F' | '#005DFF';
  customLabel?: React.ReactNode;
  price: string;
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div>{text}</div>
        {customLabel ? (
          customLabel
        ) : (
          <Label text={labelText} showDot={false} labelBackgroundColor={labelColor} fontColor="#FFF" />
        )}
      </div>
      <div className={styles.right}>{price}</div>
    </div>
  );
};
