import React from 'react';

import AddIcon from '@assets/icons/new/add.svg';
import RemoveIcon from '@assets/icons/new/remove.svg';
import { Image } from '@components/Image/Image';
import { Link } from '@components/Link/Link';
import LocalizationChangeDropdown, {
  LocalizationDropdownTheme,
} from '@components/LocalizationChangeDropdown/LocalizationChangeDropdown';
import { SimpleLayout } from '@components/SimpleLayout/SimpleLayout';
import { useLogger } from '@hooks/useLogger';
import { useMedia } from '@hooks/useMedia';

import styles from './index.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { DropdownPositions } from '@base-components/Dropdown/Dropdown';
import { scrollToTop } from 'helpers/scroll-to-top';

const FooterContent = ({ contactMethodTitle, contactMethods, links, backToTop, bottomLinks }) => {
  const [expandedGroups, setExpandedGroups] = React.useState([]);

  const { media } = useMedia();

  const toggleGroup = group => {
    setExpandedGroups(prevState =>
      prevState.includes(group) ? prevState.filter(g => g !== group) : [...prevState, group]
    );
  };

  return (
    <>
      <footer id="footer" className={styles.footer} style={{ background: '#252525', color: 'white' }}>
        <SimpleLayout className={styles.footerSimpleLayout}>
          <div className={styles.top}>
            <div className={styles.left}>
              <span className={styles.contactMethodTitle}>{contactMethodTitle}</span>
              <ul className={styles['info-links']}>
                {/* Change `linkv2` name to `link` in Storyblok */}
                {contactMethods.map(({ icon, title, subtitle, linkv2 }, i) => (
                  <li key={title + i} className={styles['item-container']}>
                    <Link label={title} {...linkv2?.[0]}>
                      <div className={styles.item}>
                        <div className={styles.icon}>
                          <Image style={{ objectFit: 'contain' }} image={icon} />
                        </div>
                        <div className={styles.body}>
                          <span className={styles.bodyTitle}>{title}</span>
                          <span className={styles.bodySubtitle}>{subtitle}</span>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.right}>
              {links.map((group, i) => (
                <div className={styles.column} key={group.title + i}>
                  <span
                    className={styles.heading}
                    onClick={() => toggleGroup(group.title)}
                    data-footer-description={group.title}
                  >
                    <span className={styles.groupTitleText}>{group.title}</span>
                    <span className={styles.expand}>
                      {expandedGroups.includes(group.title) ? <RemoveIcon /> : <AddIcon />}
                    </span>
                  </span>
                  <ul className={styles[`list${expandedGroups.includes(group.title) ? '--expanded' : ''}`]}>
                    {group.links.map(({ label, ...rest }, i) => (
                      <li key={label + i} className={styles.navigationLabel}>
                        <Link {...rest}>
                          {/*
                            TODO: Add also dataAtr, like we had before: <Link {...rest} dataAttributes={{ 'data-footer-description': label }} label={label} />
                            Error: Invalid <Link> with <a> child. Please remove <a> or use <Link legacyBehavior>.
                            Learn more: https://nextjs.org/docs/messages/invalid-new-link-with-extra-anchor <a>
                          */}
                          <span className={styles.item}>{label}</span>
                          {/* </a> */}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.backToTop}>
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              icon="pointerUp"
              className={styles.button}
              aria-label={backToTop}
              onClick={scrollToTop}
            />
            {backToTop}
          </div>
          <div className={styles.divider} />
          <div className={styles.bottom}>
            <ul className={styles.left}>
              {bottomLinks.map(({ label, ...rest }, i) => (
                <li key={label + i} className={styles.item}>
                  <Link {...rest}>
                    {/* Error: Invalid <Link> with <a> child. Please remove <a> or use <Link legacyBehavior>.
Learn more: https://nextjs.org/docs/messages/invalid-new-link-with-extra-anchor <a> */}
                    <span className={styles.item}>{label}</span>
                    {/* </a> */}
                  </Link>
                </li>
              ))}
            </ul>
            <div className={styles.right}>
              <LocalizationChangeDropdown
                dropdownPosition={{
                  horizontal: media.m ? DropdownPositions.Left : DropdownPositions.Right,
                  vertical: DropdownPositions.Top,
                }}
                theme={LocalizationDropdownTheme.dark}
              />
            </div>
          </div>
        </SimpleLayout>
      </footer>
    </>
  );
};

export const Footer = ({ components }) => {
  const { logger } = useLogger({ context: 'FooterComponent' });

  if (!components) {
    logger.error({ message: 'Missing components import in pageContext' });

    return;
  }

  const footerContent = components.footer.global;

  const [mobileFooter, desktopFooter] = footerContent;

  return (
    <>
      <div className={styles.mobileFooter}>
        <FooterContent {...mobileFooter} />
      </div>
      <div className={styles.desktopFooter}>
        <FooterContent {...desktopFooter} />
      </div>
    </>
  );
};
