// @ts-nocheck

import React from 'react';

import styles from './EnergyIcons.module.scss';

import ClassAIcon from '@assets/icons/new/energyLabels/a.svg';
import ClassAMIcon from '@assets/icons/new/energyLabels/am.svg';
import ClassAPIcon from '@assets/icons/new/energyLabels/ap.svg';
import ClassAPPIcon from '@assets/icons/new/energyLabels/app.svg';
import ClassAPPPIcon from '@assets/icons/new/energyLabels/appp.svg';
import ClassBIcon from '@assets/icons/new/energyLabels/b.svg';
import ClassCIcon from '@assets/icons/new/energyLabels/c.svg';
import ClassDIcon from '@assets/icons/new/energyLabels/d.svg';
import ClassEIcon from '@assets/icons/new/energyLabels/e.svg';
import ClassFIcon from '@assets/icons/new/energyLabels/f.svg';
import ClassGIcon from '@assets/icons/new/energyLabels/g.svg';

export const icons = {
  'a+++': <ClassAPPPIcon className={styles.icon} />,
  'a++': <ClassAPPIcon className={styles.icon} />,
  'a+': <ClassAPIcon className={styles.icon} />,
  'a-': <ClassAMIcon className={styles.icon} />,
  a: <ClassAIcon className={styles.icon} />,
  b: <ClassBIcon className={styles.icon} />,
  c: <ClassCIcon className={styles.icon} />,
  d: <ClassDIcon className={styles.icon} />,
  e: <ClassEIcon className={styles.icon} />,
  f: <ClassFIcon className={styles.icon} />,
  g: <ClassGIcon className={styles.icon} />,
};
