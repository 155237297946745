/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';

import * as dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from '@components/inputs/DatePicker/DatePicker';
import { Radio } from '@components/inputs/Radio/Radio';
import { TitleWithText } from '@components/TitleWithText/TitleWithText';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';

import styles from './CancellationPickUpDateForm.module.scss';
import { StepContext, getDateByGroupType } from '../../page/CancellationPage/CancellationPage';

export const CancellationPickUpDateForm = ({
  pickUpLaterText,
  differentCancelationDateText,
  differentCancelationDateDescriptionText,
  pickUpLaterRadioFalseOptionText,
  pickUpLaterRadioTrueOptionText,
  dataPickerErrorText,
}) => {
  const { control, getValues, setValue, setError, clearErrors } = useFormContext();
  const router = useRouter();
  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });
  const stepContext = useContext(StepContext);
  const pickUpLaterValue = getValues('pickUpLater');
  const selectedPickupDateValue = getValues('selectedPickupDate');

  const twoOrThreeWeeksLaterDate = getDateByGroupType(subscription?.plan?.category?.groupType);

  const sixYearsLaterDate = dayjs()
    .add(6, 'year')
    .toDate();

  useEffect(() => {
    if (getValues('pickUpLater') === 'false') {
      setValue('selectedPickupDate', twoOrThreeWeeksLaterDate);
      clearErrors('selectedPickupDate');

      stepContext.setSelectedPickupDate(twoOrThreeWeeksLaterDate);
    }
  }, [pickUpLaterValue]);

  useEffect(() => {
    if (!selectedPickupDateValue) {
      setError(
        'selectedPickupDate',
        {
          type: 'custom',
          message: dataPickerErrorText
            .replace(/{minDate}/, dayjs(twoOrThreeWeeksLaterDate).format('DD-MM-YYYY'))
            .replace(/{maxDate}/, dayjs(sixYearsLaterDate).format('DD-MM-YYYY')),
        },
        { shouldFocus: true }
      );
    }
  }, [selectedPickupDateValue]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Controller
          control={control}
          render={() => {
            return (
              <>
                <div className={styles.pickUpForm}>
                  <div className={styles.sectionContainer}>
                    <TitleWithText
                      header={pickUpLaterText}
                      background="Transparent"
                      textColor="BlackColor"
                      variant="Smaller"
                      noPaddings="true"
                    />

                    <div className={styles.radioContainer}>
                      <Radio
                        value="false"
                        controllerName="pickUpLater"
                        labelComponent={<div className={styles.radioLabel}>{pickUpLaterRadioFalseOptionText}</div>}
                      />

                      <Radio
                        value="true"
                        controllerName="pickUpLater"
                        labelComponent={<div className={styles.radioLabel}>{pickUpLaterRadioTrueOptionText}</div>}
                        isLastRadio
                      />
                    </div>

                    {pickUpLaterValue === 'true' && (
                      <div className={styles.sectionContainer}>
                        <TitleWithText
                          header={differentCancelationDateText}
                          content={differentCancelationDateDescriptionText}
                          background="Transparent"
                          textColor="BlackColor"
                          variant="Smaller"
                          noPaddings="true"
                        />

                        <div className={styles.datePickerContainer}>
                          <DatePicker
                            controllerName="selectedPickupDate"
                            onChange={value => {
                              stepContext.calculateFee(dayjs(value));
                              stepContext.setSelectedPickupDate(dayjs(value).toDate());
                            }}
                            minDate={twoOrThreeWeeksLaterDate}
                            maxDate={sixYearsLaterDate}
                            errorText={dataPickerErrorText}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          }}
          name="CancellationPickUpDateForm"
        />
      </div>
    </div>
  );
};
