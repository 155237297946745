import { forwardRef, ReactNode, useEffect } from 'react';

import cn from 'classnames';
import throttle from 'lodash/throttle';

import styles from './DropdownContent.module.scss';

interface DropdownContentProps {
  children: ReactNode;
  closeDropdown: () => void;
  isOpen: boolean;
  className: string;
}

const Content = ({ children, closeDropdown, isOpen, className }: DropdownContentProps, dropdownRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    const handleScroll = () => closeDropdown();

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', throttle(handleScroll, 100), { passive: true });

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [closeDropdown, dropdownRef]);

  return (
    <div
      className={cn(styles.dropdownContent, {
        [styles.hiddenDropdown]: !isOpen,
        [styles.visibleDropdown]: isOpen,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export const DropdownContent = forwardRef(Content);
