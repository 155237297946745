import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

const useUserActiveTakeoverQueryId = 'useUserActiveTakeoverQueryId';

export const useUserActiveTakeover = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useQuery(
    useUserActiveTakeoverQueryId,
    async () => {
      const { data } = await httpService.get('/takeover/user');

      return data;
    },
    {
      enabled: !!jwtToken,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
