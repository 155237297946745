import * as Yup from 'yup';

const emailValidationSchema = ({ email, required }) => {
  return {
    email: Yup.string()
      .email(email)
      .required(required),
  };
};

export const forgotPasswordValidation = validationMessages =>
  Yup.object().shape(emailValidationSchema(validationMessages));
