import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useUserPanelCancelSubscriptionMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ id, cancelSubscriptionDto }) => {
    await httpService.post(`/subscription/${id}/cancel`, { ...cancelSubscriptionDto });
  });
};
