import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { PaymentType } from '@helpers/payment';

export const usePaymentMutation = () => {
  const httpService = useHttpService();

  return useMutation(
    async (createPaymentDto: {
      userId: string;
      paymentMethod: PaymentType;
      riskData: any;
      amountValue: number;
      paymentAttemptUuid: string;
      paymentType?: any;
      redirectPath?: string;
      browserInfo?: any;
      cartId?: string;
    }) => {
      const { data } = await httpService.post('/payment-v2', createPaymentDto);

      return data;
    }
  );
};
