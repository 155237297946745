import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useCheckoutUserRiskValidationMutation } from '@hooks/checkout/useCheckoutUserRiskValidationMutation';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useCreateReceiverUserMutation } from '@hooks/user-panel/takeover/useCreateReceiverUserMutation';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import { CreateUserDtoBuilder } from '../../../../helpers/dtoBuilders/CreateUserBuilder';
import { RiskValidatorStatus } from '../../../../types/user-risk-validation.types';
import { useTakeoverForm } from '../page/TakeoverFormContext/TakeoverFormContext';

dayjs.extend(customParseFormat);

const updateInputsAfterLogin = (setValue, user) => {
  setValue('newUserSalutation', user.salutation, {
    shouldTouch: true,
    shouldValidate: true,
  });
  setValue('newUserFirstName', user.billingAddress?.firstName, {
    shouldTouch: true,
    shouldValidate: true,
  });
  setValue('newUserLastName', user.billingAddress?.lastName, {
    shouldTouch: true,
    shouldValidate: true,
  });
  setValue('newUserBirthdate', user.birthdate && dayjs(user.birthdate).format('DD-MM-YYYY'), {
    shouldTouch: true,
    shouldValidate: true,
  });
  setValue('newUserPhoneNumber', user.phone, {
    shouldTouch: true,
    shouldValidate: true,
  });
  setValue('newUserEmail', user.email, {
    shouldTouch: true,
    shouldValidate: true,
  });
};

export const useTakeoverCreateReceiver = activeTakeover => {
  const { logger } = useLogger({ context: 'useTakeoverCreateReceiver' });
  const [isSubmitting, setSubmitting] = useState(false);
  const { data: user, isLoading } = useUser();
  const { language } = useLocale();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const redirect = useRedirect();
  const createReceiverUserMutation = useCreateReceiverUserMutation();
  const validateUserMutation = useCheckoutUserRiskValidationMutation();
  const { setValue, getValues, trigger } = useFormContext();
  const { setNextStep, setPaymentStep, setIdCheckDetails } = useTakeoverForm();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.id !== activeTakeover.originalUser.id) {
      updateInputsAfterLogin(setValue, user);
    }
  }, [user, setValue]);

  const createAndCheckReceiverUser = async () => {
    setSubmitting(true);
    const formValues = getValues();
    const allFields = Object.keys(formValues);
    const fieldsWithoutPassword = allFields.filter(fieldName => fieldName !== 'newUserPassword');

    const fieldsToCheck = user ? fieldsWithoutPassword : allFields;

    const isStepValid = await trigger(fieldsToCheck);

    if (!isStepValid) {
      setSubmitting(false);

      return;
    }
    let signedUpUserId;
    const {
      newUserBirthdate,
      emailConsents,
      newUserSalutation,
      newUserPassword,
      newUserPhoneNumber,
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      customerType,
    } = formValues;
    try {
      const shippingAddress =
        activeTakeover.originalUser.shippingAddress?.city &&
        activeTakeover.originalUser.shippingAddress?.country &&
        activeTakeover.originalUser.shippingAddress?.street &&
        activeTakeover.originalUser.shippingAddress?.postalCode
          ? { ...activeTakeover.originalUser.shippingAddress }
          : { ...activeTakeover.originalUser.billingAddress };

      const createUserDto = new CreateUserDtoBuilder()
        .setFirstName(newUserFirstName)
        .setLastName(newUserLastName)
        .setBirthdate(newUserBirthdate)
        .setAcceptanceOfCommercialApproach(!!emailConsents)
        .setEmail(newUserEmail)
        .setPassword(newUserPassword)
        .setLanguage(language)
        .setPhone(newUserPhoneNumber)
        .setSalutation(newUserSalutation)
        .setCustomerType(customerType)
        .setShippingAddress({
          ...shippingAddress,
          firstName: newUserFirstName,
          lastName: newUserLastName,
          phone: newUserPhoneNumber,
        })
        .setBillingAddress({
          ...activeTakeover.originalUser.billingAddress,
          firstName: newUserFirstName,
          lastName: newUserLastName,
          phone: newUserPhoneNumber,
        })
        .validate();

      if (!user) {
        const singedUpResponse = await createReceiverUserMutation.mutateAsync(createUserDto);
        signedUpUserId = singedUpResponse.userId;
      }

      const testValues = formValues.testCreditCheckValue
        ? { testCreditCheckValue: formValues.testCreditCheckValue, testIdCheckValue: formValues.testIdCheckValue }
        : {};

      const riskValidationResult = await validateUserMutation.mutateAsync({
        userId: signedUpUserId || user.id,
        items: activeTakeover.contractTakeoverLinks.map(link => ({
          ...link.originalSubscription,
          pricing: {
            amount: link.originalSubscription.monthlyAmount,
          },
        })),
        ...testValues,
        variant: 'takeover',
      });

      const { status } = riskValidationResult.riskResult;

      logger.info({
        message: `User ${signedUpUserId || user.id} is registering`,
        params: {
          userId: signedUpUserId || user?.id,
          riskValidationResult,
        },
      });

      if (status === RiskValidatorStatus.RequireIdCheck) {
        setIdCheckDetails({ workflowRunId: riskValidationResult.workflowRunId, token: riskValidationResult.token });
        setNextStep();

        return;
      }
      if (status === RiskValidatorStatus.AllowedToContinue) {
        setPaymentStep();

        return;
      }
      redirect({ pageId: Paths.CheckoutOrderNotAccepted });
    } catch (error) {
      logger.error({
        message: `Takeover receiver form error for user ${user?.id}`,
        params: {
          userId: signedUpUserId || user?.id,
          language,
          email: newUserEmail,
        },
      });

      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return {
    createAndCheckReceiverUser,
    isUserCreated: !!user,
    isUserLoading: isLoading,
    isSubmitting,
  };
};
