import cn from 'classnames';

import styles from './ModalFooter.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const ModalFooter = ({ rejectButtonText, confirmButtonText, onReject, onConfirm, buttonVariant }) => {
  return (
    <div className={styles.footer}>
      <Button
        as="button"
        variant={buttonVariant || ButtonVariant.Icon}
        icon="none"
        theme={ButtonTheme.Tertiary}
        className={cn(styles.buttonWrapper, styles.rejectButton)}
        onClick={onReject}
        isFullWidth
      >
        {rejectButtonText}
      </Button>

      <Button
        as="button"
        variant={buttonVariant || ButtonVariant.Icon}
        icon="none"
        className={styles.buttonWrapper}
        onClick={onConfirm}
        isFullWidth
      >
        {confirmButtonText}
      </Button>
    </div>
  );
};
