import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const useUserRiskValidationQueryId = 'useUserRiskValidationQueryId';

export const useTakeoverUserRiskValidationResult = (isFormCompleted, handleResult) => {
  const httpService = useHttpService();

  return useQuery(
    useUserRiskValidationQueryId,
    async () => {
      const response = await httpService.get('/validate-user/check-result');

      return response.data;
    },
    {
      onSuccess: data => {
        if (!data) {
          return;
        }
        handleResult(data);
      },
      enabled: isFormCompleted,
      retry: 5,
      retryDelay: 5000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
