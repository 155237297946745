import React from 'react';

import * as dayjs from 'dayjs';

import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';

import styles from './MovingPlannerChargeNotification.module.scss';

import { getMovingFee } from '@helpers/fee';

export const MovingPlannerChargeNotification = ({ chargeNotification, subscriptions }) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const { language } = useLocale();

  const movingDates = subscriptions
    .map(subscription => subscription.movingDate && dayjs(subscription.movingDate))
    .filter(subscription => subscription != null);
  const lastMovingDate = movingDates.length ? dayjs.min(movingDates).toISOString() : null;

  const feeApplicable =
    lastMovingDate &&
    dayjs(lastMovingDate)
      .add(1, 'year')
      .isAfter();

  const fee = currencyFormatter({ price: getMovingFee() * 100 });

  const nextFreeMovingDate = dayjs(lastMovingDate)
    .locale(language)
    .add(1, 'year')
    .format('DD MMMM YYYY');

  if (!feeApplicable) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <NotificationBar variant="alice" content={chargeNotification} contentVariables={{ fee, nextFreeMovingDate }} />
    </div>
  );
};
