import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { PaymentType } from '@helpers/payment';

export const usePaymentDetailsMutation = () => {
  const httpService = useHttpService();

  return useMutation(async (createPaymentDetailsDto: any) => {
    const { data } = await httpService.post('/payment-v2/details', createPaymentDetailsDto);

    return data;
  });
};
