import React from 'react';

import styles from './ColumnRow.module.scss';
import DynamicComponent from '../DynamicComponent/DynamicComponent';

export const ColumnRow = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map(blok => (
        <div key={blok._uid}>
          <DynamicComponent {...blok} />
        </div>
      ))}
    </div>
  );
};
