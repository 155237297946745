const dayjs = require('dayjs');

const MOVING_FEE = 135;
const PICKUP_FEE = 75;
const CANCELLATION_FACTOR = 0.5;
const FREE_DAYS_DE = 14;
const FREE_DAYS_NL = 14;

const countryConfig = {
  de: {
    freeDays: FREE_DAYS_DE,
  },
  nl: {
    freeDays: FREE_DAYS_NL,
  },
};

const checkValueOfCancellationFee = value => {
  const floatValue = Number.parseFloat(value).toFixed(2);

  if (floatValue <= 0.0) {
    return 0;
  }

  return value;
};

const formatFeesValues = fees => {
  Object.keys(fees).forEach(fee => {
    fees[fee] = Number(Number.parseFloat(fees[fee]).toFixed(2));
  });

  return fees;
};

const calculateCancellationFee = ({ minimumCancellationDate, cancellationDate, amount, cancellationFactor = 1 }) => {
  return checkValueOfCancellationFee(
    (Math.floor(dayjs(minimumCancellationDate).diff(cancellationDate, 'months', true)) || 1) *
      amount *
      cancellationFactor
  );
};

const getDepositReturnableDate = startDate => {
  // Before 2018-04-28 we never return deposit
  if (dayjs(startDate).isBefore(dayjs('2018-04-28'))) {
    return dayjs(startDate);
  }

  // After 2020-06-25 we return deposit, because pickup fee is included to the price of monthly subscription, so if
  // somebody fulfill his contract he can cancel contract without any fine
  return dayjs(startDate).isBefore(dayjs('2020-06-25')) && dayjs(startDate).add(2, 'years');
};

module.exports = {
  FREE_DAYS_DE,
  FREE_DAYS_NL,
  countryConfig,
  getDepositReturnableDate,
  getMovingFee() {
    return MOVING_FEE;
  },
  getPickupFee() {
    return PICKUP_FEE;
  },
  // added 1 day in cancellationDate for the benefit of the user
  getCancellationFee(subscription, lastMovingDate, cancellationDate = dayjs().add(1, 'day')) {
    const opportunityStatus = subscription.status;
    const shippingCountry = subscription.address.country;
    const startDate = subscription.subscriptionActivationDate;
    const minimumCancellationDate = subscription.minimumCancellationDate;
    const contractTermPeriod = subscription.contractTermPeriod;
    const amount =
      (subscription.monthlyAmount ||
        subscription.plan?.pricings?.find(p => p.term === subscription.contractTermPeriod)?.amount) / 100;
    const country = shippingCountry.toLowerCase();
    const fees = {
      total: 0,
    };

    if (
      lastMovingDate &&
      dayjs(lastMovingDate)
        .add(6, 'month')
        .isAfter()
    ) {
      fees.moving = MOVING_FEE;
      fees.total += fees.moving;
    }

    const depositReturnableDate = startDate && getDepositReturnableDate(startDate);

    fees.pickUp = depositReturnableDate && depositReturnableDate.isAfter() ? PICKUP_FEE : 0;
    fees.total += fees.pickUp;

    if (
      cancellationDate.isBefore(dayjs(startDate).add(countryConfig[country].freeDays, 'days')) ||
      opportunityStatus !== 'active'
    ) {
      fees.cancellation = 0;
    } else if (['6 years'].includes(contractTermPeriod)) {
      fees.cancellation = calculateCancellationFee({
        minimumCancellationDate,
        cancellationDate,
        amount,
        cancellationFactor: CANCELLATION_FACTOR,
      });
    } else {
      fees.cancellation = calculateCancellationFee({
        minimumCancellationDate,
        cancellationDate,
        amount,
      });
    }

    fees.total += fees.cancellation;

    return formatFeesValues(fees);
  },
};
