/* eslint-disable import/no-cycle */
import React from 'react';

import { useContentContext } from '@context/content';

import DynamicComponent from '../DynamicComponent/DynamicComponent';

export const PaymentLinkInfo = ({ paymentLinkExpiredComponents, invoicePaidOrPendingComponents }) => {
  const {
    currentPageParams: { isDirectPaymentLinkExpired },
  } = useContentContext();

  return (
    <>
      {isDirectPaymentLinkExpired &&
        paymentLinkExpiredComponents.map(blok => <DynamicComponent {...blok} key={blok._uid} />)}
      {!isDirectPaymentLinkExpired &&
        invoicePaidOrPendingComponents.map(blok => <DynamicComponent {...blok} key={blok._uid} />)}
    </>
  );
};
