import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from './ContractSwitchSummaryPrice.module.scss';
import { useContractSwitchContext } from '../../page/ContractSwitchContext/ContractSwitchContext';

export const ContractSwitchSummaryPrice = ({ perMonthText, discountPriceText = 'Discounted price' }) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const { getValues } = useFormContext();
  const { availableOptions } = useContractSwitchContext();

  const option = availableOptions?.find(option => option.pricing.id === getValues('pricingId'));
  const isPriceDiscounted =
    option.newConditions?.monthlyAmount && option.newConditions?.monthlyAmount < option.pricing.amount;

  return (
    <div className={styles.container}>
      <hr className={styles.summaryLine} />

      <div className={styles.row}>
        <div className={styles.rowLeftColumn}>{perMonthText}</div>{' '}
        <div className={styles.rowRightColumn}>
          <span data-total-normal-price={option.pricing.amount}>
            {currencyFormatter({
              price: isPriceDiscounted ? option.newConditions?.monthlyAmount : option.pricing.amount,
            })}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};
