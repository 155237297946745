import React from 'react';

import { RichText } from '@components/RichText/RichText';
import { useContentContext } from '@context/content';
import { useFaqContext } from '@context/faq';
import { useQueryParam } from '@hooks/useQueryParam';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import { Category } from './components/Category/Category';
import { NoResults } from './components/NoResults/NoResults';
import { Question } from './components/Question/Question';
import { ResultItem } from './components/ResultItem/ResultItem';
import styles from './FaqSearchResults.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

const searchByTerm = (faqCategories, term) => {
  const termValue = term.toLowerCase();
  const faqGroupItems = faqCategories
    .map(faqCategory => faqCategory.content.faqArticles)
    .flat()
    .map(faqArticle => faqArticle.faqGroup)
    .flat()
    .map(faqGroup => faqGroup.items)
    .flat();

  const foundSearchItems = faqGroupItems.filter(faqGroupItem => {
    const answerValue = faqGroupItem?.answer?.content?.[0]?.content
      ?.map(content => content.text?.toLowerCase())
      .join('');
    const questionValue = faqGroupItem?.question?.toLowerCase();

    if (answerValue?.includes(termValue) || questionValue?.includes(termValue)) {
      return faqGroupItem;
    }

    return null;
  });

  return foundSearchItems;
};

export const FaqSearchResults = ({
  resultsFoundText,
  noResultsFoundText,
  noResultsFoundDescription,
  topicText,
  categoryText,
  backButtonText,
  backButtonOverviewText,
  readMoreText,
}) => {
  const { setSearchTerm } = useFaqContext();
  const redirect = useRedirect();
  const { faqCategories } = useContentContext();
  const searchParam = useQueryParam('search');
  const questionParam = useQueryParam('question');
  const categoryParam = useQueryParam('category');

  if (categoryParam) {
    return (
      <Category
        categoryParam={categoryParam}
        categoryText={categoryText}
        backButtonOverviewText={backButtonOverviewText}
      />
    );
  }

  if (questionParam) {
    return (
      <Question
        questionParam={questionParam}
        topicText={topicText}
        backButtonText={backButtonText}
        backButtonOverviewText={backButtonOverviewText}
      />
    );
  }

  if (searchParam) {
    const foundSearchItems = searchByTerm(faqCategories, searchParam);
    setSearchTerm(searchParam);

    return (
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button
            as="button"
            variant={ButtonVariant.Icon}
            icon="arrow-left"
            onClick={() => {
              redirect({ pageId: Paths.Faq });
            }}
          >
            {backButtonOverviewText}
          </Button>
        </div>

        {foundSearchItems?.length > 0 ? (
          <>
            <RichText
              contentVariables={{ searchResultsLength: foundSearchItems.length }}
              document={resultsFoundText}
              className={styles.articlesFound}
            />

            <div className={styles.resultsContainer}>
              {foundSearchItems.map(searchResult => (
                <ResultItem
                  key={searchResult._uid}
                  question={searchResult.question}
                  answer={searchResult.answer}
                  readMoreText={readMoreText}
                />
              ))}
            </div>
          </>
        ) : (
          <NoResults
            searchParams={searchParam}
            noResultsFoundText={noResultsFoundText}
            noResultsFoundDescription={noResultsFoundDescription}
          />
        )}
      </div>
    );
  }
};
