import { OfferItemPricing } from 'types/offer.types';

export const sortPricingsFromLowestPrice = (pricings: OfferItemPricing[]) => {
  return pricings.sort((firstPricing, secondPricing) => {
    if (firstPricing.billingType === 'one_time_recurring' && secondPricing.billingType === 'one_time_recurring') {
      return secondPricing.months - firstPricing.months;
    }

    if (firstPricing.billingType === 'recurring' && secondPricing.billingType === 'recurring') {
      return secondPricing.months - firstPricing.months;
    }

    if (firstPricing.billingType === 'one_time' && secondPricing.billingType === 'one_time') {
      return secondPricing.oneTimeAmount - firstPricing.oneTimeAmount;
    }

    return -1;
  });
};
