import * as Yup from 'yup';

import { emailFilter } from '../../../../validation/regexps/email/index';
import { passwordComplexityRegExp } from '../../../../validation/regexps/password';

export const validationSchema = ({ validationRequired, validationEmail, validationPassword }) =>
  Yup.object().shape({
    loginEmail: Yup.string()
      .required(validationRequired)
      .email(validationEmail)
      .max(70)
      .matches(emailFilter, validationEmail),

    loginPassword: Yup.string()
      .required(validationRequired)
      .min(5, validationPassword)
      .matches(passwordComplexityRegExp, validationPassword),
  });
