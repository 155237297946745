/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { useUser } from '@hooks/user/useUser';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';

import styles from './TakeoverProceedButton.module.scss';
import { TakeoverStepsNames, useTakeoverForm } from '../../../page/TakeoverFormContext/TakeoverFormContext';

import { Button, ButtonVariant } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';

const submittingStep = {
  provider: TakeoverStepsNames.takeoverCheck,
  receiver: TakeoverStepsNames.takeoverPayment,
};

const checkIfOnlyUserPasswordError = (user, errors) => {
  const fieldsWithError = Object.keys(errors);
  const isPasswordError = fieldsWithError.includes('newUserPassword');
  if (user && Object.values(errors).length === 1 && isPasswordError) {
    return false;
  }
  if (Object.values(errors).length > 0) {
    return true;
  }

  return false;
};

// TODO generic button should be used
export const TakeoverProceedButton = ({ label, activeTakeover }) => {
  const { logger } = useLogger({ context: 'TakeoverProceedButton' });
  const { setNextStep, step, type } = useTakeoverForm();
  const isSubmittingStep = step === submittingStep[type];
  const [isDisabled, setDisabled] = useState(false);

  const {
    control,
    trigger,
    formState: { errors, isSubmitting },
    getValues,
  } = useFormContext();
  const { data: user } = useUser();

  const logout = useUserLogoutMutation();

  useEffect(() => {
    if (type !== 'receiver') {
      return;
    }
    if (user && user.id === activeTakeover.originalUser.id) {
      const logoutUser = async () => {
        try {
          setDisabled(true);
          await logout.mutateAsync();
        } catch (error) {
          logger.info({ message: 'Failed to logout user' });
        } finally {
          setDisabled(false);
        }
      };
      logoutUser();
    }
  }, []);

  const onClick = async () => {
    const allFields = Object.keys(getValues());
    const fieldsWithoutPassword = allFields.filter(fieldName => fieldName !== 'newUserPassword');

    const fieldsToCheck = user ? fieldsWithoutPassword : allFields;

    const isStepValid = await trigger(fieldsToCheck);

    if (!isStepValid || isSubmittingStep) {
      return;
    }

    return setNextStep();
  };

  return (
    <Controller
      name="TakeoverProceedButton"
      control={control}
      render={() => {
        return (
          <Button
            as="button"
            isFullWidth
            className={styles.takeoverProceedButton}
            variant={ButtonVariant.Icon}
            icon="none"
            onClick={onClick}
            disabled={isSubmitting || checkIfOnlyUserPasswordError(user, errors) || isDisabled}
            type={isSubmittingStep ? 'submit' : 'button'}
            dataAttribute={{ 'data-test-id': 'takeover-proceed-button' }}
            isLoading={isSubmitting}
          >
            {label}
          </Button>
        );
      }}
    />
  );
};
