import React, { ReactNode, createContext, useEffect } from 'react';

import { TypeOptions, toast } from 'react-toastify';

import { SuccessIconWrapper } from '@components/views/Toast/SuccessIconWrapper';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLogger } from '@hooks/useLogger';

export const NotificationsContext = createContext(undefined);

export const NotificationContextProvider = ({
  children,
  queryNotification,
}: {
  children: ReactNode;
  queryNotification: string;
}) => {
  const { logger } = useLogger({ context: 'NotificationsContext' });
  const notificationsPhrases = usePhrases({ name: 'notifications', rawObject: true });
  const notificationMessage = notificationsPhrases?.[queryNotification]?.value;

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';

    if (!isBrowser || !localStorage) {
      return;
    }

    try {
      const localStorageNotifications = localStorage.getItem('notifications');
      const parsedLocalStorageNotifications = localStorageNotifications ? JSON.parse(localStorageNotifications) : [];
      const notifications: {
        message: string;
        type: TypeOptions;
      }[] =
        parsedLocalStorageNotifications.length > 0
          ? parsedLocalStorageNotifications
          : notificationMessage
          ? [{ message: notificationMessage, type: toast.TYPE.WARNING }]
          : [];
      notifications.forEach(n => {
        if (n.type === toast.TYPE.SUCCESS) {
          toast(<SuccessIconWrapper>{n.message}</SuccessIconWrapper>, {
            type: n.type,
            position: toast.POSITION.TOP_CENTER,
          });

          return;
        }
        toast(n.message, {
          type: n.type,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } catch (e) {
      logger.error({ message: 'Error during showing notifications', params: { errorMessage: e.message } });
    }
    localStorage.removeItem('notifications');
  }, [notificationMessage]);

  return <NotificationsContext.Provider value={undefined}>{children}</NotificationsContext.Provider>;
};
