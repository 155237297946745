import React from 'react';

import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { RichText } from '@components/RichText/RichText';
import { useContentContext } from '@context/content';

import styles from './CheckoutNotificationWrapper.module.scss';

export const CheckoutNotificationWrapper = ({
  imageIntegrityErrorsHeader,
  imageIntegrityImageQualityErrorText,
  imageIntegrityConclusiveDocumentQualityErrorText,
  imageIntegritySupportedDocumentErrorText,
  imageIntegrityColorPictureErrorText,
}: {
  imageIntegrityErrorsHeader: string;
  imageIntegrityImageQualityErrorText: StoryblokRichtext;
  imageIntegrityConclusiveDocumentQualityErrorText: StoryblokRichtext;
  imageIntegritySupportedDocumentErrorText: StoryblokRichtext;
  imageIntegrityColorPictureErrorText: StoryblokRichtext;
}) => {
  const { idCheckErrors }: { idCheckErrors: { fields: string[] } } = useContentContext();
  const errorsToMessageMap = {
    imageQuality: imageIntegrityImageQualityErrorText,
    conclusiveDocumentQuality: imageIntegrityConclusiveDocumentQualityErrorText,
    supportedDocument: imageIntegritySupportedDocumentErrorText,
    colourPicture: imageIntegrityColorPictureErrorText,
  };

  return (
    <>
      {idCheckErrors && (
        <div className={styles.notificationWrapper}>
          {idCheckErrors.fields.map(idCheckError => {
            return (
              !!errorsToMessageMap[idCheckError] && (
                <div key={idCheckError} className={styles.notificationBox}>
                  {/* @ts-ignore */}
                  <NotificationBar title={imageIntegrityErrorsHeader} variant={'error'}>
                    <RichText document={errorsToMessageMap[idCheckError]} />
                  </NotificationBar>
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};
