import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useSetNewPasswordMutation = () => {
  const httpService = useHttpService();

  return useMutation(async values => {
    const response = await httpService.post('/user/set-new-password', values);

    return response;
  });
};
