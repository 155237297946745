import { useRef } from 'react';

import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';

import { CreditCheckFailingReason, RiskValidatorDecision, RiskValidatorStatus } from 'types/user-risk-validation.types';
export interface ValidateUserDto {
  userId: string;
  items: unknown[];
  testCreditCheckValue?: string;
  testIdCheckValue?: string;
}

const TRIES_BEFORE_REDIRECT = 2;

export const useCheckoutUserRiskValidationMutation = () => {
  const { logger } = useLogger({ context: 'useCheckoutUserRiskValidationMutation' });
  const errorCounter = useRef(0);

  const httpService = useHttpService();

  return useMutation(async (validateUserDto: ValidateUserDto) => {
    const response = await httpService.post<RiskValidatorDecision>('/validate-user', validateUserDto);

    if (response.status >= 400) {
      logger.error({
        message: `There is a problem with checkout user risk validation for userId: ${validateUserDto.userId}`,
        params: { responseStatusText: response.statusText, userId: validateUserDto.userId },
      });

      throw new Error(response.statusText);
    }

    const riskResult = response.data;
    const isCreditCheckProviderError =
      riskResult.status === RiskValidatorStatus.DeniedToContinue &&
      riskResult.details.reason === CreditCheckFailingReason.ProviderFail;

    if (isCreditCheckProviderError) {
      logger.error({
        message: `There is a problem with credit check validation for userId: ${validateUserDto.userId}`,
        params: { responseStatusText: response.statusText, userId: validateUserDto.userId },
      });
      errorCounter.current += 1;

      return {
        riskResult,
        isProviderError: true,
        isWrongPersonalDataError: false,
        shouldShowFailedRequestPage: errorCounter.current >= TRIES_BEFORE_REDIRECT,
      };
    }

    const isCreditCheckWrongPersonalDataError =
      riskResult.status === RiskValidatorStatus.DeniedToContinue &&
      riskResult.details.reason === CreditCheckFailingReason.WrongPersonalData;

    if (isCreditCheckWrongPersonalDataError) {
      logger.warn({
        message: `There is a problem with credit check validation for userId: ${validateUserDto.userId}`,
        params: { responseStatusText: response.statusText, userId: validateUserDto.userId },
      });

      return {
        riskResult,
        isProviderError: false,
        shouldShowFailedRequestPage: false,
        isWrongPersonalDataError: true,
      };
    }

    return {
      riskResult,
      isProviderError: false,
      shouldShowFailedRequestPage: errorCounter.current >= TRIES_BEFORE_REDIRECT,
      isWrongPersonalDataError: false,
    };
  });
};
