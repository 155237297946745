import React from 'react';

import cn from 'classnames';

import { Image } from '@components/Image/Image';

import styles from '../BlogFilter.module.scss';

export const BlogFilterProductCategory = ({ filter, onClick, category }) => {
  return (
    <button
      className={cn(styles.sheetButton, {
        [styles.inactiveCategory]: filter !== category.uuid,
      })}
      type="button"
      onClick={onClick}
    >
      <Image className={styles.filterImage} image={category.content.image.filename} />{' '}
      <span className={styles.productCategoryText}>{category.content.text}</span>
    </button>
  );
};
