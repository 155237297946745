/* eslint-disable import/no-cycle */
import React from 'react';

import { toast } from 'react-toastify';

import Loader from '@components/Loader/Loader';
import { ConfirmModal } from '@components/modals/ConfirmModal/ConfirmModal';
import { useModal } from '@hooks/component/useModal';
import { useUserActiveTakeover } from '@hooks/subscriptions/useUserActiveTakeover';
import { useLogger } from '@hooks/useLogger';
import { useCancelTakeoverMutation } from '@hooks/user-panel/takeover/useCancelTakeoverMutation';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import styles from './TakeoverCancellationButton.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const TakeoverCancellationButton = ({
  buttonText,
  successMessage,
  errorMessage,
  areYouSureText,
  goBackText,
  cancelTakeoverText,
}) => {
  const { logger } = useLogger({ context: 'TakeoverCancellationButton' });
  const [isModalOpen, openModal, closeModal] = useModal();
  const { data: takeover, isLoading } = useUserActiveTakeover();
  const cancelTakeoverMutation = useCancelTakeoverMutation();
  const redirect = useRedirect();

  if (!takeover || isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const cancelTakeover = async () => {
    try {
      await cancelTakeoverMutation.mutateAsync({ token: takeover.token });

      logger.info({
        message: `User ${takeover?.newUser?.id} cancelled takeover`,
        params: {
          takeoverId: takeover.id,
          originalUserId: takeover.originalUser?.id,
          newUserId: takeover.newUser?.id,
        },
      });

      toast(successMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
      });

      redirect({ pageId: Paths.MyBluemovement });
    } catch (error) {
      logger.error({
        message: `There is a problem with takeover cancellation by user ${takeover?.newUser?.id}`,
        params: {
          takeoverId: takeover.id,
          originalUserId: takeover.originalUser?.id,
          newUserId: takeover.newUser?.id,
        },
      });

      toast(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR,
      });
    } finally {
      closeModal();
    }
  };

  if (!['provided', 'new'].includes(takeover.status)) {
    return null;
  }

  return (
    <>
      <Button
        variant={ButtonVariant.Icon}
        as="button"
        onClick={openModal}
        dataAttribute={{ 'data-test-id': 'takeover-cancel-button' }}
      >
        {buttonText}
      </Button>

      <ConfirmModal
        isOpen={isModalOpen}
        text={areYouSureText}
        footer={{
          rejectButtonText: goBackText,
          confirmButtonText: cancelTakeoverText,
          onReject: closeModal,
          onConfirm: cancelTakeover,
        }}
        closeModal={closeModal}
      />
    </>
  );
};
