import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import ClockIcon from '@assets/icons/new/clock.svg';
import CalendarIcon from '@assets/icons/new/inputs/calendar.svg';
import { Dropdown } from '@components/inputs/Dropdown/Dropdown';
import { Radio } from '@components/inputs/Radio/Radio';
import Loader from '@components/Loader/Loader';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import styles from './CheckoutTimeSlotBooking.module.scss';
import { CALL_ME_OPTION, sortByHours } from './helpers/helpers';
import { getCheckoutTimeSlotBookingValidationSchema } from './helpers/validation';
import { useTimeSlotBooking } from './hooks/useTimeSlotBooking';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';

export const timeslotBookingTestOptions = [
  { option: 'Real data', value: 'REAL' },
  { option: 'Mocked data', value: 'MOCKED' },
  { option: 'Empty array', value: 'EMPTY' },
];

const TimeslotsListHeader = ({ whatDayText }) => (
  <div className={styles.titleContainer}>
    <CalendarIcon />
    <span className={styles.title}>{whatDayText}</span>
  </div>
);


export const CheckoutTimeSlotBooking = ({
  howManyDaysShowAtFirst = 5,
  showMoreText = 'Show more',
  submitButtonText = 'Submit',
  whatDayText = 'What date would suit to you?',
  whatTimeText = 'What time would suit to you?',
  noneFitText = 'None fit me',
  callMeText = 'Please call me',
  chooseDateText = 'Please choose day to continue',
  sdaIncludedText = 'Oh you have sda included',
  emptyTimeSlotsText = 'Unfortunately we have no available slots to display we will contact you to arrange delivery',
  somethingWentWrong = 'Something went wrong, please select new date and timeslot',
  isRedirecting = false,
}) => {
  const { logger } = useLogger({ context: 'CheckoutTimeSlotBooking' });
  const [mode, setMode] = useState(timeslotBookingTestOptions[0].value);

  const {
    timeSlots,
    areTimeSlotsLoading,
    reserveTimeSlot,
    isSdaIncluded,
    areTimeSlotsEmpty,
    redirectToPaymentPage,
    isError,
  } = useTimeSlotBooking({ mode });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(getCheckoutTimeSlotBookingValidationSchema()),
  });

  const chosenDay = methods.getValues('timeSlotDay');
  const chosenHours = methods.getValues('timeSlotHours');
  const isCallMeOptionChosen = chosenDay === CALL_ME_OPTION;
  const isProperDayChosen = chosenDay && timeSlots && [chosenDay] in timeSlots;

  const [isExpanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(true);
  }, []);

  useEffect(() => {
    methods.setValue('timeSlotHours', '');
  }, [chosenDay]);

  useEffect(() => {
    setExpanded(false);
    methods.reset();
  }, [isError]);

  const handleTestCase = value => {
    setMode(value);
  };

  const errorInfoBar = useMemo(
    () =>
      isError ? (
        <div className={styles.notificationContainer}>
          <NotificationBar content={somethingWentWrong} variant="alice" />
        </div>
      ) : null,
    [isError]
  );

  const TestDropdown = () => {
    return (
      getRuntimeConfig('ENVIRONMENT_NAME') !== 'aws-prod' && (
        <div style={{ border: '1px solid black', padding: '1rem', margin: '0 0 5rem' }}>
          To force mock data (without request to Urbantz) just use the below button
          <Dropdown
            controllerName="testTimeslotBooking"
            labelText="TSB test data"
            defaultValue={mode}
            entries={timeslotBookingTestOptions}
            onChange={handleTestCase}
          />
        </div>
      )
    );
  };

  if (areTimeSlotsLoading) {
    return (
      <>
        <TimeslotsListHeader whatDayText={whatDayText} />

        <div className={styles.loaderContainer}>
          <Loader />
        </div>
        <Button as="button" className={styles.button} variant={ButtonVariant.Basic} type="submit" isFullWidth disabled>
          {submitButtonText}
        </Button>
      </>
    );
  }

  if (!areTimeSlotsLoading && areTimeSlotsEmpty) {
    return (
      <>
        <FormProvider {...methods}>
          {errorInfoBar}
          <TestDropdown />

          <div className={styles.titleContainer}>
            <CalendarIcon />
            <span className={styles.title}>{whatDayText}</span>
          </div>

          <div className={styles.emptyNotificationContainer}>
            <NotificationBar content={emptyTimeSlotsText} variant="gold" />
          </div>

          <Button as="button" variant={ButtonVariant.Basic} onClick={redirectToPaymentPage}>
            {submitButtonText}
          </Button>
        </FormProvider>
      </>
    );
  }

  const isThereMoreDaysThanPresented = Object.keys(timeSlots).length >= howManyDaysShowAtFirst;

  const timeSlotDaysFn = () => {
    const mappedTimeSlotsWithCallMeOption = [...Object.keys(timeSlots), CALL_ME_OPTION];

    return isExpanded
      ? mappedTimeSlotsWithCallMeOption
      : mappedTimeSlotsWithCallMeOption.slice(0, howManyDaysShowAtFirst);
  };

  const timeSlotDays = timeSlotDaysFn();

  return (
    <FormProvider {...methods}>
      {errorInfoBar}
      <TestDropdown />

      <form
        className={styles.container}
        onSubmit={methods.handleSubmit(
          async formData => {
            let carrier;
            if (formData.timeSlotDay != CALL_ME_OPTION) {
              carrier = timeSlots[formData.timeSlotDay].availableTimeSlots.filter(
                item => item.timeslotId === formData.timeSlotHours
              )[0].carrier;
            }

            await reserveTimeSlot({ ...formData, carrier });
          },
          errors => logger.error({ message: 'Error in submit form in CheckoutTimeSlotBooking', params: errors })
        )}
        id="timeslotBookingForm"
      >
        <TimeslotsListHeader whatDayText={whatDayText} />

        {timeSlotDays.map(day =>
          day !== CALL_ME_OPTION ? (
            <Radio
              key={day}
              controllerName="timeSlotDay"
              value={day}
              activeButtonClassName={styles.activeButton}
              labelComponent={
                <div className={styles.dayContainer}>
                  <span className={styles.weekDay}>{timeSlots[day].weekDay}</span>
                  <span className={styles.dayWithMonth}>{day}</span>
                </div>
              }
            />
          ) : (
            <Radio
              key={day}
              controllerName="timeSlotDay"
              value={CALL_ME_OPTION}
              activeButtonClassName={styles.activeButton}
              labelComponent={
                <div className={styles.dayContainer}>
                  <span className={styles.weekDay}>{noneFitText}</span>
                  <span className={styles.dayWithMonth}>{callMeText}</span>
                </div>
              }
            />
          )
        )}

        {isThereMoreDaysThanPresented && !isExpanded && (
          //TODO: BUTTON CHECK
          <Button
            as="button"
            variant={ButtonVariant.Icon}
            arrowPosition={isExpanded ? 'down' : 'down'}
            className={styles.readMore}
            toggleState={isExpanded}
            theme={ButtonTheme.RawOnLight}
            size="tiny"
            onClick={toggleExpanded}
          >
            {showMoreText}
          </Button>
        )}

        {isProperDayChosen && (
          <>
            <div className={styles.titleContainer}>
              <ClockIcon />
              <span className={styles.title}>{whatTimeText}</span>
            </div>

            {sortByHours(timeSlots[chosenDay].availableTimeSlots).map(day => (
              <Radio
                key={day.timeslotId}
                activeButtonClassName={styles.activeButton}
                controllerName="timeSlotHours"
                value={day.timeslotId}
                labelComponent={<span className={styles.hours}>{day.time}</span>}
              />
            ))}
          </>
        )}

        {!isProperDayChosen && !areTimeSlotsEmpty && !isCallMeOptionChosen && (
          <NotificationBar content={chooseDateText} variant="alice" className={styles.chooseDateContainer} />
        )}

        {!isCallMeOptionChosen && <div className={styles.separator} />}

        {isSdaIncluded && (
          <div className={styles.notificationContainer}>
            <NotificationBar content={sdaIncludedText} variant="alice" />
          </div>
        )}
        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Basic}
          type="submit"
          isFullWidth
          disabled={methods.formState.isSubmitting || (!isCallMeOptionChosen && !chosenHours) || isRedirecting}
          isLoading={methods.formState.isSubmitting || isRedirecting}
        >
          {submitButtonText}
        </Button>
      </form>
    </FormProvider>
  );
};
