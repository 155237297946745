import React, { useState, useRef } from 'react';

import cn from 'classnames';

import VectorIcon from '@assets/icons/new/vector.svg';
import { Image } from '@components/Image/Image';
import LensImage from '@components/LensImage/LensImage';
import { SwipeWrapper } from '@components/SwipeWrapper/SwipeWrapper';

import { FullNavigation } from './components/FullNavigation/FullNavigation';
import { FullScreen } from './components/FullScreen/FullScreen';
import styles from './ImageCarousel.module.scss';
import { ScrollNavigation } from '../ScrollNavigation/ScrollNavigation';
import { usePhrases } from '@hooks/context/usePhrases';
import { usePlanData } from '@modules/PDP/hooks/usePlanData';

export const ImageCarousel = ({ images }) => {
  const planData = usePlanData();
  const { close } = usePhrases({ name: 'general' });
  const imageRef = useRef();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const allImages = [planData?.offerContent?.content?.fullImage, ...images];
  const maxImageIndex = allImages.length - 1;

  const nextImage = () =>
    currentImageIndex < maxImageIndex ? setCurrentImageIndex(currentImageIndex + 1) : setCurrentImageIndex(0);

  const previousImage = () =>
    currentImageIndex > 0 ? setCurrentImageIndex(currentImageIndex - 1) : setCurrentImageIndex(maxImageIndex);

  const handleKeyDown = e => {
    if (e.key === 'ArrowLeft') {
      return previousImage();
    }

    if (e.key === 'ArrowRight') {
      return nextImage();
    }

    if (e.key === 'Escape') {
      return setFullScreen(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageCarousel} tabIndex={-1} onKeyDown={e => handleKeyDown(e)}>
        <div className={styles.topContainer}>
          <div className={styles.arrowsContainer}>
            <div className={styles.arrow} onClick={() => previousImage()}>
              <VectorIcon className={styles.vectorIconRotated} />
            </div>

            <div className={styles.arrow} onClick={() => nextImage()}>
              <VectorIcon className={styles.vectorIcon} />
            </div>
          </div>

          <SwipeWrapper onSwipeLeft={() => nextImage()} onSwipeRight={() => previousImage()}>
            <div className={styles.mainImage} onClick={() => setFullScreen(true)}>
              <LensImage alt="carousel image" image={allImages[currentImageIndex]} className={styles.image} />
            </div>
          </SwipeWrapper>
        </div>

        <ScrollNavigation currentImageIndex={currentImageIndex} imageRef={imageRef}>
          {allImages.map((image, imageIndex) => (
            <div key={imageIndex} ref={imageRef}>
              <Image
                image={image}
                alt="appliance"
                className={cn(styles.scrollNavigationImage, currentImageIndex === imageIndex && styles.currentImage)}
                onClick={() => setCurrentImageIndex(imageIndex)}
                lazyLoading={false}
              />
            </div>
          ))}
        </ScrollNavigation>

        <FullNavigation
          images={allImages}
          currentImageIndex={currentImageIndex}
          setFullScreen={value => setFullScreen(value)}
          setCurrentImageIndex={value => setCurrentImageIndex(value)}
        />

        {fullScreen && (
          <FullScreen
            images={allImages}
            closeButtonText={close}
            currentImageIndex={currentImageIndex}
            setFullScreen={value => setFullScreen(value)}
            setCurrentImageIndex={value => setCurrentImageIndex(value)}
            previousImage={previousImage}
            nextImage={nextImage}
          />
        )}
      </div>
    </div>
  );
};
