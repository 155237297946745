/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';
import { Story } from 'storyblok-js-client';

import { layoutSpacingModifierStyle } from '@components/DynamicComponent/configuration';
import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { AppContextProvider } from '@context/app';
import { prepareContentVariables } from '@utils/content-variables';

import style from './Page.module.scss';

import { Layout } from '@theme/Layout/Layout';
import ShoppingCartOffer from '@theme/ShoppingCart/ShoppingCartOffer';
import { Locale } from 'types/common.types';

const localModifications = [
  {
    if: 'uspBar',
    is: 'below',
    of: 'imageContentBlock',
    style: { marginTop: 0 },
  },
  {
    if: 'uspBar',
    is: 'above',
    of: 'imageContentBlock',
    style: { marginBottom: 0 },
  },
];

type PageProps = {
  story: Story['data']['story'];
  locale: Locale;
  withoutFooter?: boolean;
  withoutHeader?: boolean;
  pageContext: Record<string, any>;
};

export const Page = ({
  story: { content },
  locale,
  withoutFooter,
  withoutHeader,
  pageContext: {
    globalStories,
    allPlans = [],
    validationMessages,
    allOffers = [],
    components,
    shouldUseNewStorublok,
    ...pageContext
  },
}: PageProps) => {
  return (
    <AppContextProvider
      context={{
        contentVariables: prepareContentVariables({ allPlans, locale, allOffers }),
        locale,
        allPlans,
        allOffers,
        globalStories,
        ...pageContext,
      }}
    >
      <Layout
        locale={locale}
        globalStories={globalStories}
        storyblokContent={content}
        isPageWithoutFooter={withoutFooter}
        components={components}
        layoutHeader={
          !withoutHeader && (
            <>
              <PageHeader {...components?.header?.global?.[0]} />
              <ShoppingCartOffer {...globalStories.shoppingCart?.global?.[0]} />
            </>
          )
        }
        wrapperClassName={cn(style.theme, style[`themeBackground${content.background}`])}
      >
        {content.body
          ? content.body.map(blok => (
              <DynamicComponent
                {...blok}
                style={layoutSpacingModifierStyle({ blok, contentBody: content.body, localModifications })}
                layout={content.layout}
                key={blok._uid}
                validationMessages={validationMessages}
              />
            ))
          : null}
      </Layout>
    </AppContextProvider>
  );
};
