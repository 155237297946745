import React from 'react';
import styles from './DatasheetLinkEnergyLabel.module.scss';

export const DatasheetLinkEnergyLabel = ({ text, isLabel }: { text: string; isLabel: boolean }) => {
  return (
    <div className={styles.datasheetLinkEnergyLabel}>
      <div className={styles.datasheetLinkEnergyLabelText}>{text}</div>
      {isLabel && <div> icon placeholder!!!</div>}
    </div>
  );
};
