import styles from './ModalHeader.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const ModalHeader = ({ title, closeModal }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span className={styles.boldTitleText}>{title}</span>
      </div>
      <div className={styles.closeButtonWrapper}>
        <Button
          as="button"
          variant={ButtonVariant.Icon}
          icon="close"
          theme={ButtonTheme.RawOnLight}
          onClick={closeModal}
          className={styles.closeButton}
          size="tiny"
        />
      </div>
    </div>
  );
};
