import React from 'react';

import cn from 'classnames';

import { usePhrases } from '@hooks/context/usePhrases';

import styles from './TopBar.module.scss';

const topBarThemes = {
  brandYellow: styles.brandYellow,
  brandGreen: styles.brandGreen,
  brandPink: styles.brandPink,
  brandBlue: styles.brandBlue,
};

export const TopBar = ({ className, title, color, available = true }) => {
  const { outOfStock } = usePhrases({ name: 'general' });
  const barTitle = available ? title : outOfStock;
  const barColor = available ? color : 'brandBlue';

  return (
    <div className={cn(topBarThemes[barColor], className)}>
      <span className={styles.text}>{barTitle}</span>
    </div>
  );
};
