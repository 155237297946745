import React, { useEffect, useRef, useState } from 'react';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { CartItem } from 'types/cart.types';

import CheckmarkSolidCircleIcon from '@assets/icons/new/checkmarkSolidCircle.svg';
import LogoLoader from '@components/LogoLoader/LogoLoader';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useCartContext } from '@context/cart';
import { useCartId, useCouponMessages } from '@hooks/cart';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useIsCouponCodeRedeemed } from '@hooks/cart/useIsCouponCodeRedemeed';
import { useIsCouponExpiredMessage } from '@hooks/cart/useIsCouponExpiredMessage';
import { useOffers } from '@hooks/cart/useOffers';
import { useRemoveItemsWithOffer } from '@hooks/cart/useRemoveItemsWithOffer';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useNotCancelledSubscription } from '@hooks/useNotCancelledSubscriptions';
import { useRedirect } from '@hooks/useRedirect';
import PromoCode from '@modules/PromoCode/PromoCode';
import { getCouponDiscountMessages } from '@utils/getCouponDiscountMessage';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { Paths } from '@utils/paths/paths';

import CartItemOffer from './components/CartItem/CartItemOffer';
import { CartSummaryOffer } from './components/CartSummary/CartSummaryOffer';
import { RemoveOutOfStockItemsNotification } from './components/RemoveOutOfStockItemsNotification/RemoveOutOfStockItemsNotification';
import { aggregateOfferItems } from './helpers/aggregateCartItems';
import styles from './ShoppingCart.module.scss';
import ShoppingCartOfferWrapper from './ShoppingCartOfferWrapper';

const EmptyCart = ({ emptyCartText }) => (
  <div className={styles.emptyCart}>
    <span>{emptyCartText}</span>
  </div>
);

const Cart = ({
  emptyCartText = 'Cart is empty.',
  itemAddedText,
  infoBoxes = [],
  youPayMonthlyText,
  discountCodeText,
  copiedUrlText = 'Shipping cart link is copied!',
  totalText,
  monthlyText,
  checkText,
  continueToCheckoutText,
  showAllProductsText,
  twoOrMoreSameAppliancesText,
  addMoreThanOneNotificationText,
  autoApplicableCouponInfoMessage,
  explainDeliveryFee,
  scrollToTopCart,
}) => {
  const [shouldShowOutOfStockError, setShouldShowOutOfStockError] = useState(false);
  const cartId = useCartId();
  const { data: cart, refetch, isLoading } = useCartWithOffer();
  const { data: offersInTheCart, isLoading: areOffersLoading } = useOffers(
    cart?.items.map(i => i.offerContentSlug) || []
  );

  const removeItemsMutation = useRemoveItemsWithOffer();
  const { couponErrorMessages, couponDiscountMessages } = useCouponMessages();
  const { showItemAdded, isShareButtonTriggered, setIsShareButtonTriggered } = useCartContext();
  const redirect = useRedirect();
  const isCouponExpiredMessage = useIsCouponExpiredMessage();
  const isCouponCodeRedeemedMessage = useIsCouponCodeRedeemed();
  const { data: subscriptions, isLoading: isSubscriptionsLoading } = useNotCancelledSubscription();
  const { country, language } = useLocale();
  const { logger } = useLogger({ context: 'ShoppingCart' });

  const copyUrlToClipboard = async sharedUrl => {
    await navigator.clipboard.writeText(sharedUrl);
  };

  useEffect(() => {
    if (isShareButtonTriggered) {
      const sharedUrl = `${getRuntimeConfig('APP_URL')}/${country}-${language}/share-cart-offer?cartId=${cart.id}`;
      copyUrlToClipboard(sharedUrl);
      setIsShareButtonTriggered(false);
      toast(copiedUrlText, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
      });
    }
  }, [isShareButtonTriggered]);

  if (cartId && (isLoading || isSubscriptionsLoading || areOffersLoading)) {
    return (
      <div className={styles.loaderContainer}>
        <LogoLoader />
      </div>
    );
  }

  if (!cart || !cart?.items || cart?.items?.length === 0) {
    return <EmptyCart emptyCartText={emptyCartText} />;
  }

  const removeItems = async (items: CartItem[]) => {
    await refetch();

    return removeItemsMutation.mutate(items);
  };

  const removeAllOutOfStockItems = () => {
    const items = cart.items.filter(item => !item.isOnStock);

    return removeItemsMutation.mutate(items);
  };

  const continueToCheckout = () => {
    if (cart.hasOutOfStockItems) {
      setShouldShowOutOfStockError(true);
      scrollToTopCart();
      logger.info({
        message: `User with id ${cart?.user?.id} tried to continue to checkout with out of stock items in cart`,
        params: { userId: cart.user?.id, cartId: cart.id },
      });

      return;
    }
    redirect({ pageId: Paths.CheckoutCustomerDetails });
  };

  const { forDiscountedPeriodMessage } = getCouponDiscountMessages(cart.coupon, couponDiscountMessages);

  const aggregatedItemsForOffer = aggregateOfferItems(cart.items as Required<CartItem>[]);
  const notifications = [...infoBoxes];

  if (isCouponExpiredMessage || isCouponCodeRedeemedMessage) {
    notifications.push({
      key: 'coupon-expired',
      title: '',
      variant: 'error',
      notificationText: isCouponExpiredMessage || isCouponCodeRedeemedMessage,
    });
  }

  const hasAtLeastOneMDA = cart.items.filter(i => i.offerItem.groupType === 'MDA').length === 1;
  if (hasAtLeastOneMDA && !cart.coupon && addMoreThanOneNotificationText && country === 'nl') {
    notifications.push({
      key: 'add-more-than-one',
      title: '',
      variant: 'alice',
      notificationText: addMoreThanOneNotificationText,
    });
  }

  if (
    cart.items.filter(item => item.offerItem.type === 'main').length === 1 &&
    subscriptions.length === 0 &&
    !cart.coupon &&
    addMoreThanOneNotificationText &&
    country === 'de'
  ) {
    notifications.push({
      key: 'add-more-than-one',
      title: '',
      variant: 'alice',
      notificationText: addMoreThanOneNotificationText,
    });
  }

  if (!isCouponExpiredMessage && cart.coupon && !notifications.some(n => n.key === cart.coupon.code)) {
    notifications.push({
      key: cart.coupon.code,
      title: '',
      variant: 'alice',
      notificationText: autoApplicableCouponInfoMessage,
    });
  }

  // TODO AS refactor if needed - hotfix for release
  if (cart.items.filter(item => item.offerItem.type === 'main').length > 1 && explainDeliveryFee) {
    notifications.push({
      key: 'explain-delivery-fee',
      title: '',
      variant: 'alice',
      notificationText: explainDeliveryFee,
    });
  }

  return (
    <>
      {showItemAdded && (
        <div className={styles.header}>
          <span className={styles.titleIcon}>
            <CheckmarkSolidCircleIcon />
          </span>

          <span className={styles.title} data-item-added-text={itemAddedText || ''}>
            {itemAddedText || ''}
          </span>
        </div>
      )}

      {shouldShowOutOfStockError && cart?.hasOutOfStockItems && (
        <RemoveOutOfStockItemsNotification ctaAction={removeAllOutOfStockItems} />
      )}

      <div className={styles.cartItems}>
        {aggregatedItemsForOffer.map(item => {
          const offerItems =
            offersInTheCart?.find(offer => offer.contentSlug === item.mainItem.offerContentSlug)?.offerItems || [];
          const possibleOfferItems = offerItems.filter(offerItem =>
            offerItem.pricings.some(offerItemPricing => offerItemPricing.months === item.mainItem.pricing.months)
          );

          const cartItemOfferProps = {
            aggregatedCartItem: item,
            couponDiscountMessage: forDiscountedPeriodMessage,
            twoOrMoreSameAppliancesText,
            youPayMonthlyText,
            isCouponExpiredMessage,
            isCouponCodeRedeemedMessage,
            possibleOfferItems,
            removeItems,
          };

          return <CartItemOffer key={item.mainItem?.setId} {...cartItemOfferProps} />;
        })}
      </div>

      <PromoCode text={discountCodeText} checkText={checkText} couponErrorMessages={couponErrorMessages} className="" />

      <CartSummaryOffer
        totalText={totalText}
        monthlyText={monthlyText}
        couponDiscountMessage={forDiscountedPeriodMessage}
      />

      <div className={styles.infoBoxContainer}>
        {notifications.map(({ notificationText, title, variant }) => (
          <NotificationBar
            key={notificationText}
            title={title}
            variant={variant}
            content={notificationText}
            contentVariables={cart.coupon}
          />
        ))}
      </div>

      <Button
        variant={ButtonVariant.Basic}
        disabled={isCouponExpiredMessage || isCouponCodeRedeemedMessage || !cart.numberOfAvailableItems}
        as="button"
        className={styles.checkoutButton}
        onClick={continueToCheckout}
      >
        {continueToCheckoutText}
      </Button>

      <Button
        as="button"
        variant={ButtonVariant.Basic}
        theme={ButtonTheme.White}
        isElevated
        className={styles.showAllProductsButton}
        onClick={() => redirect({ pageId: Paths.ProductsOverview })}
      >
        {showAllProductsText}
      </Button>
    </>
  );
};

const ShoppingCartOffer = props => {
  const { isCartOpen, toggleCart, setShowItemAdded, setIsShareButtonTriggered } = useCartContext();
  const cartRef = useRef<HTMLDivElement>();
  const { data: cart, isLoading, isIdle } = useCartWithOffer();
  const router = useRouter();

  useEffect(() => {
    if (router.query.openCart) {
      toggleCart();
    }
  }, []);

  const onClose = () => {
    setShowItemAdded(false);
    toggleCart();
  };

  const handleShare = () => {
    setIsShareButtonTriggered(true);
  };

  if (!isCartOpen) {
    return null;
  }

  const scrollToTopCart = () => {
    if (cartRef.current) {
      cartRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <ShoppingCartOfferWrapper cartRef={cartRef} onClose={onClose}>
      <>
        <div className={styles.closeButtonContainer}>
          {!(!cart || isLoading || isIdle || !cart.items || cart.items?.length === 0) && (
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              theme={ButtonTheme.RawOnLight}
              icon="share"
              className={styles.shareButton}
              onClick={handleShare}
            />
          )}

          <Button
            as="button"
            variant={ButtonVariant.Icon}
            icon="close"
            theme={ButtonTheme.RawOnLight}
            onClick={onClose}
            className={styles.closeButton}
          />
        </div>
        <Cart {...props} scrollToTopCart={scrollToTopCart} />
      </>
    </ShoppingCartOfferWrapper>
  );
};

export default ShoppingCartOffer;
