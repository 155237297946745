import React from 'react';

import TrashIcon from '@assets/icons/new/trash.svg';

import styles from './ItemCardTop.module.scss';
import { Image } from '../../components/Image/Image';
import { Label } from '../../components/Label/Label';
import { DatasheetLinkEnergyLabel } from '../DatasheetLinkEnergyLabel/DatasheetLinkEnergyLabel';
import { SecondaryTitle } from '../SecondaryTitle/SecondaryTitle';
import { Title } from '../Title/Title';

const variants = {
  myBm: 'gridContainerMyBm',
  checkout: 'gridContainerCheckout',
  pdp: 'gridContainerPdp',
} as const;

export const ItemCardTop = ({
  variant = 'gridContainerMyBm',
  isSmallImgVariant = false,
  vibText,
  // TODO: refactor it to array with labels
  label1Text,
  label1WithDot = false,
  label1Background = '#AAD6FF',
  label2Text,
  label2WithDot = false,
  label2Background = '#AAD6FF',
  label3Text,
  label3WithDot = false,
  label3Background = '#AAD6FF',
  offerNameText,
  isResponsive = false,
  isDatasheetLinkEnergyLabel = true,
  datasheetLinkEnergyLabelText,
  onDelete,
  children,
  image,
}: {
  variant: typeof variants[keyof typeof variants];
  isSmallImgVariant?: boolean;
  vibText: string;
  label1Text?: string;
  label1WithDot?: boolean;
  label1Background?: string;
  label2Text?: string;
  label2WithDot?: boolean;
  label2Background?: string;
  label3Text?: string;
  label3WithDot?: boolean;
  label3Background?: string;
  offerNameText: string;
  isResponsive?: boolean;
  isDatasheetLinkEnergyLabel?: boolean;
  datasheetLinkEnergyLabelText?: string;
  onDelete?: () => any;
  children?: React.ReactNode;
  image: any;
}) => {
  return (
    <div>
      <div className={styles[variant]}>
        <div className={styles.img}>
          <Image
            alt={`${vibText}-image`}
            className={styles.imageContainer}
            image={image}
            mobileImage={image}
            onClick={() => {}}
          />
        </div>
        <div className={styles.subtitle}>
          <SecondaryTitle text={vibText} />
        </div>
        <div className={styles.title}>
          <Title text={offerNameText} isResponsive={isResponsive} />
        </div>
        {onDelete && (
          <div className={styles.bin}>
            <button onClick={onDelete}>
              <TrashIcon />
            </button>
          </div>
        )}
        {(label1Text || label2Text || label3Text) && (
          <div className={styles.label}>
            {label1Text && (
              <Label
                text={label1Text}
                showDot={label1WithDot}
                labelBackgroundColor={label1Background}
                className={styles.chipText}
              />
            )}
            {label2Text && (
              <Label
                text={label2Text}
                showDot={label2WithDot}
                labelBackgroundColor={label2Background}
                className={styles.chipText}
              />
            )}
            {label3Text && (
              <Label
                text={label3Text}
                showDot={label3WithDot}
                labelBackgroundColor={label3Background}
                className={styles.chipText}
              />
            )}
          </div>
        )}
        {!isSmallImgVariant && (
          <div className={styles.text}>
            <DatasheetLinkEnergyLabel text={datasheetLinkEnergyLabelText} isLabel={isDatasheetLinkEnergyLabel} />
          </div>
        )}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
