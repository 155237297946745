/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';
import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import Loader from '@components/Loader/Loader';
import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

import styles from './MovingPlannerSubscriptions.module.scss';

export const MovingPlannerSubscriptions = ({ calendarIcon, selectForText, subscriptions }) => {
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  if (!subscriptions) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const values = getValues();
  const filteredSubscriptions = subscriptions.filter(subscription => subscription.status === 'active');

  const error = errors?.items;

  return (
    <div className={styles.container}>
      <Controller
        name="movingPlannerSubscriptions"
        control={control}
        render={() => {
          return (
            <div>
              {filteredSubscriptions.map((subscription, index) => (
                <div
                  key={subscription.id}
                  className={cn(styles.subscriptionCardContainer, {
                    [styles.selected]: values.items?.[index]?.subscriptionToMove,
                  })}
                >
                  <SubscriptionCard
                    calendarIcon={calendarIcon}
                    subscription={subscription}
                    noViewButton
                    additionalServices={subscription.additionalServices}
                  >
                    <div className={styles.separator} />

                    <Checkbox
                      labelText={selectForText}
                      controllerName={`items.${index}.subscriptionToMove`}
                      theme={Themes.White}
                      onClick={() => {
                        trigger('items');
                      }}
                    />
                  </SubscriptionCard>
                </div>
              ))}
              {error && (
                <span className={styles.errorMessage}>
                  <RectangleIcon className={styles.rectangleIcon} />
                  {error.message}
                </span>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
