import React from 'react';

import { CartContextProvider } from './cart';
import { ContentContextProvider } from './content';
import { CookieContextProvider } from './cookies';
import { FaqContextProvider } from './faq';
import { GTMContextProvider } from './gtm';
import { NotificationContextProvider } from './notifications';
import { SheetContextProvider } from './sheet';
import { UserContextProvider } from './user';

export const AppContextProvider = ({ children, context }) => {
  return (
    <ContentContextProvider context={context}>
      <FaqContextProvider>
        <UserContextProvider>
          <CookieContextProvider>
            <GTMContextProvider>
              <CartContextProvider>
                <SheetContextProvider>
                  <NotificationContextProvider queryNotification={context.notification}>
                    {children}
                  </NotificationContextProvider>
                </SheetContextProvider>
              </CartContextProvider>
            </GTMContextProvider>
          </CookieContextProvider>
        </UserContextProvider>
      </FaqContextProvider>
    </ContentContextProvider>
  );
};
