import * as Yup from 'yup';

export function getValidationSchema({ messages: { required } }) {
  return {
    agreedToTakeoverTerms: Yup.bool()
      .oneOf([true], required)
      .required(required),
  };
}

export function getDefaultValues() {
  return { agreedToTakeoverTerms: false };
}
