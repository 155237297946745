import { Modal } from '@components/modals/Modal/Modal';

import { ButtonVariant } from '@base-components/Button';

interface ConfirmModalProps {
  isOpen: boolean;
  text: string;
  footer: {
    rejectButtonText: string;
    confirmButtonText: string;
    buttonVariant: ButtonVariant;
    onReject: () => void;
    onConfirm: () => void;
  };
  closeModal: () => void;
}

export const ConfirmModal = ({ isOpen, text, footer, closeModal }: ConfirmModalProps) => (
  <Modal footer={footer} isOpen={isOpen} closeModal={closeModal}>
    {text}
  </Modal>
);
