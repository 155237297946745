import * as Yup from 'yup';

export function getCheckoutPaymentFormValidationSchema(messages, paymentSources) {
  const baseShape = {
    recurringPaymentMandate: Yup.bool()
      .oneOf([true], messages?.validationRequired)
      .required(messages?.validationRequired),
  };

  if (!paymentSources) {
    baseShape.monthlyPaymentMethod = Yup.string().required(messages?.validationRequired);
  }

  return Yup.object().shape(baseShape);
}
