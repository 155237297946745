import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const usePaymentAttemptMutation = () => {
  const httpService = useHttpService();

  return useMutation(async (createPaymentAttemptDto: any) => {
    const { data } = await httpService.post('/payment-v2/attempt', createPaymentAttemptDto);

    return data;
  });
};
