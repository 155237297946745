import React, { ReactNode } from 'react';

import styles from './SuccessIconWrapper.module.scss';
interface SuccessIconWrapperProps {
  children: ReactNode;
}

export const SuccessIconWrapper: React.FC<SuccessIconWrapperProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <svg className={styles.svg} viewBox="0 0 24 24" width="24px" height="24px" fill="#07bc0c">
        <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
      </svg>
      {children}
    </div>
  );
};
