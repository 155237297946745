/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';

import styles from './Column.module.scss';
import DynamicComponent from '../DynamicComponent/DynamicComponent';

const NoOfColumnsStyle = {
  '2': styles.twoColumns,
  '3': styles.threeColumns,
};

const BehaviourStyle = {
  stacked: styles.stackingLayout,
  scroll: styles.mobileScrollLayout,
};

const Items = ({ items, noOfColumns, stickyColumn }) =>
  items.map((blok, index) => (
    <div
      key={blok._uid}
      className={cn(
        {
          [styles.stickyColumn]: index === 0 && stickyColumn,
        },
        styles.item,
        NoOfColumnsStyle[noOfColumns] || NoOfColumnsStyle['3']
      )}
    >
      <DynamicComponent {...blok} />
    </div>
  ));

export const Column = ({ items, mobileBehavior, noOfColumns, outerMargin, stickyColumn }) => {
  return (
    <div className={cn(styles.container)}>
      <div
        className={cn(
          BehaviourStyle[mobileBehavior] || BehaviourStyle.stacked,
          {
            [styles.outerMargin]: outerMargin,
            [styles.regularMargin]: !outerMargin,
          }
        )}
      >
        <Items items={items} stickyColumn={stickyColumn} noOfColumns={noOfColumns} />
      </div>
    </div>
  );
};
