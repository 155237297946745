import React, { ReactNode } from 'react';

import { LabelWithInfoButton } from '@refactoredComponents/inputs/LabelWithInfoButton/LabelWithInfoButton';
import cn from 'classnames';
import { DataAttribute } from 'types/data-attributes';

import styles from './RadioButton.module.scss';

export interface IRadioButton {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  dataAttribute?: DataAttribute;
  children?: ReactNode;
  onChange: () => void;
  onInfoClick?: () => void;
}

export const RadioButton = ({
  id,
  name,
  label,
  checked,
  children,
  dataAttribute,
  onChange,
  onInfoClick,
}: IRadioButton) => {
  return (
    <label key={id} className={cn(styles.label, { [styles.selectedOption]: checked })}>
      <div className={styles.wrapper} {...dataAttribute}>
        <div>
          <input type="radio" name={name} id={id} checked={checked} onChange={onChange} />
          <LabelWithInfoButton label={label} onInfoClick={onInfoClick} />
        </div>
        {children}
      </div>
    </label>
  );
};
