import { useCart, useCouponMessages } from '@hooks/cart';

export const useIsCouponCodeRedeemed = () => {
  const { data: cart } = useCart();
  const { couponErrorMessages } = useCouponMessages();

  if (cart?.isCouponCodeRedeemed) {
    return couponErrorMessages?.couponCodeAlreadyRedeemed;
  }

  return undefined;
};
