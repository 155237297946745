import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as weekday from 'dayjs/plugin/weekday';
import isUndefined from 'lodash/isUndefined';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);

export function getValidationSchema({ messages: { required, dateFormat, in3weeksMin, in3MonthsMax, onlyWeekday } }) {
  return {
    movingDate: Yup.string()
      .required(required)
      .test('validDate', dateFormat, val => {
        return !isUndefined(val) && dayjs(val, 'YYYY-MM-DD', true).isValid();
      })
      .test('in3weeksMin', in3weeksMin || 'in3weeksMin', val => {
        return !isUndefined(val) && dayjs(val).isSameOrAfter(dayjs().add(3, 'weeks'), 'day');
      })
      .test('in3MonthsMax', in3MonthsMax || 'in3MonthsMax', val => {
        return !isUndefined(val) && dayjs(val).isSameOrBefore(dayjs().add(3, 'months'), 'day');
      })
      // Sunday = 0, Saturday = 6
      .test('onlyWeekday', onlyWeekday || 'onlyWeekday', val => {
        return !isUndefined(val) && ![0, 6].includes(dayjs(val).weekday());
      }),
  };
}

export function getDefaultValues() {
  return { movingDate: '' };
}
