import { ReactNode } from 'react';

import { motion } from 'motion/react';

export enum Slide {
  Left = 'left',
  Right = 'right',
}

interface AnimatedListElementProps {
  children: ReactNode;
  className?: string;
  slideFrom?: Slide;
}

export const AnimatedListElement = ({ children, className, slideFrom = Slide.Left }: AnimatedListElementProps) => (
  <motion.li
    initial={{ transform: slideFrom === Slide.Right ? 'translateX(500px)' : 'translateX(-500px)' }}
    animate={{ transform: 'translateX(0px)' }}
    transition={{ type: 'tween' }}
    exit={{ opacity: 0 }}
    className={className}
  >
    {children}
  </motion.li>
);
