import React from 'react';

import { useFormContext } from 'react-hook-form';

import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

import styles from './TakeoverSelectedSubscriptions.module.scss';

export const TakeoverSelectedSubscriptions = ({
  subscriptions = [],
  showSummary = false,
  calendarIcon,
  showItemPriceList,
}) => {
  const formContext = useFormContext();
  const { currencyFormatter } = useCurrencyFormatter();
  const { youPayMonthly } = usePhrases({ name: 'general' });

  let selectedSubscriptions = subscriptions;
  if (formContext) {
    const values = formContext?.getValues();
    selectedSubscriptions = subscriptions.filter(subscription =>
      values.items.find(item => item.subscriptionToTakeover && item.id === subscription.id)
    );
  }

  const pricePerMonth = subscriptions.reduce((acc, subscription) => acc + subscription.monthlyAmount, 0);

  return (
    <>
      <div className={styles.container}>
        {selectedSubscriptions.map(subscription => (
          <div key={subscription.id}>
            <SubscriptionCard
              subscription={subscription}
              noViewButton
              calendarIcon={calendarIcon}
              additionalServices={subscription.additionalServices}
              showItemPriceList={showItemPriceList}
            />
          </div>
        ))}
      </div>
      {showSummary && (
        <div className={styles.monthlyFeeContainer}>
          <span className={styles.monthlyFeeContainerText}>{youPayMonthly}</span>

          <span className={styles.monthlyFeeContainerValue} data-monthly-price={pricePerMonth}>
            {currencyFormatter({ price: pricePerMonth }) || '-'}
          </span>
        </div>
      )}
    </>
  );
};
