import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import ProfileIcon from '@assets/icons/new/profile.svg';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { RichText } from '@components/RichText/RichText';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useUserLoginMutation } from '@hooks/user/useUserLoginMutation';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { useRedirect } from '@hooks/useRedirect';
import { interpolateContent } from '@utils/interpolate-content';

import styles from './TakeoverLoginForm.module.scss';
import { validationSchema } from './validation';

import { Button, ButtonVariant } from '@base-components/Button';

export const TakeoverLoginForm = ({
  emailLabelText,
  passwordLabelText,
  loginButtonText,
  logoutButtonText,
  redirectPageId = 'TheSamePage',
  loggedInTitleText,
  alreadyLoginText,
  haveAnAccountTitleText,
  haveAnAccountDescriptionText,
  forbiddenProviderLoginText,
  activeTakeover,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'TakeoverLoginForm' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { data: user } = useUser();
  const userLoginMutation = useUserLoginMutation();
  const userLogoutMutation = useUserLogoutMutation();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const formOptions = {
    resolver: yupResolver(validationSchema(validationMessages)),
  };
  const contextMethods = useFormContext();
  const methods = useForm(formOptions);
  const redirect = useRedirect();

  const logoutUser = async () => {
    try {
      logger.info({
        message: `User is trying to logout`,
        params: { userId: user.id },
      });

      await userLogoutMutation.mutateAsync();

      if (redirectPageId === 'TheSamePage' && contextMethods) {
        contextMethods.reset();
        methods.reset();

        return;
      }
      // TODO: Hack here, for clearing register form inputs we should use useform/reset method
      redirect({ pageId: redirectPageId });
    } catch (error) {
      logger.error({
        message: 'There is a problem with logout',
        params: { userId: user.id, errorMessage: error.message },
      });

      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const loginUser = async ({ email, password }) => {
    try {
      logger.info({
        message: `User ${email} is trying to login`,
        params: { userEmail: email },
      });

      await userLoginMutation.mutateAsync({ username: email, password });
    } catch (error) {
      logger.error({
        message: `There is a problem with login ${email}`,
        params: { userEmail: email, errorMessage: error.message },
      });

      if (error.status === 500) {
        toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });
      }

      const message = error.data.errors[0].message;

      if (message === 'invalidCredentials') {
        setIsError(true);
      }

      setIsSubmitting(false);
    }
  };

  const onSubmit = async () => {
    const { loginEmail, loginPassword } = methods.getValues();

    if (loginEmail === activeTakeover.originalUser.email) {
      methods.setError('loginEmail', {
        type: 'custom',
        message:
          interpolateContent(forbiddenProviderLoginText, {
            providerFirstName: activeTakeover.originalUser.firstName,
          }) || 'You can not login to provider account',
      });

      return;
    }

    try {
      setIsSubmitting(true);
      setIsError(false);

      await loginUser({ email: loginEmail, password: loginPassword });
      setShowLogin(false);
    } catch (e) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (user?.id === activeTakeover.originalUser.id) {
      logoutUser();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardContentContainer}>
          <div className={styles.cartTop}>
            <div className={styles.cardIcon}>
              <ProfileIcon />
            </div>
            <div className={styles.cardTitle}>
              {user && (
                <>
                  <span className={styles.cardTitleText}>{loggedInTitleText}</span>
                  <div className={styles.alreadyLoginTextContainer}>
                    <RichText className={styles.alreadyLoginText} document={alreadyLoginText} contentVariables={user} />
                    <span className={styles.logoutText} onClick={logoutUser}>
                      {logoutButtonText}
                    </span>
                  </div>
                </>
              )}

              {!user && (
                <>
                  <span className={styles.cardTitleText}>{haveAnAccountTitleText}</span>
                  <div
                    className={styles.cardDescriptionText}
                    onClick={() => setShowLogin(!showLogin)}
                    data-checkout="user-login"
                  >
                    <RichText document={haveAnAccountDescriptionText} />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.cardContent}>
            {!user && (showLogin || isError) && (
              <FormProvider {...methods}>
                <form
                  onSubmit={contextMethods.handleSubmit(onSubmit)}
                  className={styles.loginForm}
                  id="takeoverLoginForm"
                >
                  <TextInput
                    controllerName="loginEmail"
                    labelText={emailLabelText}
                    dataAttribute={{ 'data-test-id': 'email-input' }}
                  />
                  <TextInput
                    controllerName="loginPassword"
                    labelText={passwordLabelText}
                    password
                    dataAttribute={{ 'data-test-id': 'password-input' }}
                  />
                  {isError && (
                    <NotificationBar
                      variant="error"
                      shadow="grounded"
                      content={errorPhrases?.['invalid-credentials']?.value || 'Invalid credentials'}
                    />
                  )}
                  <Button
                    as="button"
                    className={styles.button}
                    variant={ButtonVariant.Icon}
                    icon="none"
                    onClick={onSubmit}
                    type="button"
                    isFullWidth
                    isLoading={isSubmitting}
                  >
                    {loginButtonText}
                  </Button>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
