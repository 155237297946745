import React from 'react';

import { InformationTable } from '@components/InformationTable/InformationTable';
import { usePhrases } from '@hooks/context/usePhrases';
import { useSectionsWithPhraseHeaders } from '@hooks/useSectionsWithPhraseHeaders';

import { usePlanData } from '../../hooks/usePlanData';
import { convertPlanToInformationItem } from '../../utils/convertPlanToInformationItem';
import EnergyLabel from '../EnergyLabel/EnergyLabel';

export const PlanInformationTable = ({ showMoreButtonText, showLessButtonText, energyLabel, sections }) => {
  const { planContent, offerContent } = usePlanData();
  const featurePhrases = usePhrases({ name: 'features', rawObject: true });
  const contentEntity = offerContent || planContent;

  const renderers = {
    energyClass: value => (
      <EnergyLabel
        variant={value}
        text={energyLabel}
        document={contentEntity.content.energyLabelDocument}
        link={featurePhrases?.energyClass?.link?.[0]}
      />
    ),
  };

  const items = [convertPlanToInformationItem({ planContent: contentEntity })];

  return (
    <InformationTable
      showMoreButtonText={showMoreButtonText}
      showLessButtonText={showLessButtonText}
      sections={useSectionsWithPhraseHeaders({ sections })}
      items={items}
      renderers={renderers}
    />
  );
};
