import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import styles from './ScrollNavigation.module.scss';

export const ScrollNavigation = ({
  children,
  className,
  currentImageIndex,
  imageRef,
  forceVisibility = false,
  hideScroll = false,
  isMobile,
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    if (isMobile) {
      return;
    }
    const imageElement = imageRef.current;
    const scroll = scrollRef.current;

    if (imageElement) {
      const itemElementWidth = imageElement.offsetWidth;
      const currentScrollWidth = currentImageIndex * itemElementWidth;

      scroll.scrollLeft = currentScrollWidth - itemElementWidth;
    }
  }, [currentImageIndex, isMobile]);

  return (
    <div
      className={cn(styles.scrollNavigation, className, {
        [styles.mobile]: !forceVisibility,
        [styles.scroll]: !hideScroll,
        [styles.hideScroll]: hideScroll,
      })}
      ref={scrollRef}
    >
      {children}
    </div>
  );
};
