import { useLocale } from '@hooks/useLocale';
import { buildCategoryPath, getHrefFromStoryblok } from '@utils/paths/build-paths';

import { LinkSeparator } from './LinkSeparator';
import styles from './MenuItem.module.scss';
import { CategoryLinkType, InternalLinkType, LinkComponent, LinkSeparatorType } from '../../../types';
import { AnimatedListElement, Slide } from '../AnimatedListElement';

import { Button, ButtonTheme, ButtonVariant } from '@base-components/Button';

type InternalLinkWithoutUId = Omit<InternalLinkType, '_uid'> & { slideFrom: Slide };
type CategoryLinkWithoutUId = Omit<CategoryLinkType, '_uid'> & { slideFrom: Slide };
type LinkSeparatorWithoutUId = Omit<LinkSeparatorType, '_uid'> & { slideFrom: Slide };
type MenuItemType = InternalLinkWithoutUId | CategoryLinkWithoutUId | LinkSeparatorWithoutUId;

export const MenuItem = (menuItem: MenuItemType) => {
  const { locale } = useLocale();

  const renderDynamicComponent = () => {
    const isLinkSeparator = (item: MenuItemType): item is LinkSeparatorWithoutUId => {
      return item.component === 'linkSeparator';
    };

    const isCategoryLink = (item: MenuItemType): item is CategoryLinkWithoutUId => {
      return item.component === LinkComponent.CategoryLink;
    };

    if (isLinkSeparator(menuItem)) {
      return <LinkSeparator />;
    }

    return (
      <AnimatedListElement slideFrom={menuItem.slideFrom}>
        {isCategoryLink(menuItem) ? (
          <CategoryLink locale={locale} label={menuItem.label} icon={menuItem.icon} category={menuItem.category} />
        ) : (
          <InternalLink
            locale={locale}
            link={menuItem.link}
            pageId={menuItem.pageId}
            icon={menuItem.icon}
            label={menuItem.label}
          />
        )}
      </AnimatedListElement>
    );
  };

  return renderDynamicComponent();
};

type InternalLinkProps = {
  locale: string;
} & Omit<InternalLinkType, '_uid' | 'component'>;

const InternalLink = ({ locale, link, pageId, icon, label }: InternalLinkProps) => {
  const href = getHrefFromStoryblok({ locale, link, pageId });

  return (
    <Button
      variant={ButtonVariant.Icon}
      as="anchor"
      theme={ButtonTheme.White}
      arrowPosition="none"
      icon={icon}
      href={href}
      onClick={e => {
        e.preventDefault();
        window.location.href = href;
      }}
      dataAttribute={{ 'data-test-id': pageId }}
      className={styles.mobileLinkButton}
      isFullWidth
    >
      {label}
    </Button>
  );
};

const CategoryLink = ({ locale, label, icon, category }) => {
  const href = buildCategoryPath({ locale, category });

  return (
    <Button
      variant={ButtonVariant.Icon}
      as="anchor"
      theme={ButtonTheme.White}
      arrowPosition="none"
      icon={icon}
      href={href}
      onClick={e => {
        e.preventDefault();
        window.location.href = href;
      }}
      dataAttribute={{
        'data-topnav-description': label,
        'link-category': category,
      }}
      className={styles.mobileLinkButton}
      isFullWidth
    >
      {label}
    </Button>
  );
};
