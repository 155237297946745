import React, { useRef } from 'react';

import cn from 'classnames';

import { Image } from '@components/Image/Image';
import { Link } from '@components/Link/Link';

import styles from './ContactCard.module.scss';

interface ContactCardProps {
  title: string;
  subtitle: string;
  content: string;
  underlineContent: boolean;
  desktopTitle: string;
  desktopContent: string;
  bigTitle: boolean;
  icon: any;
  link: any;
}

export const ContactCard = ({
  title = '',
  subtitle = '',
  content = '',
  underlineContent = false,
  desktopTitle = '',
  desktopContent = '',
  bigTitle = false,
  icon,
  link,
}: ContactCardProps) => {
  const ref = useRef();

  return (
    <Link {...link?.[0]}>
      <div className={styles.container}>
        <div className={styles.cardIcon}>
          <Image image={icon} imgRef={ref} mobileImage="" alt="" className="" onClick={() => {}} />
        </div>
        <div className={styles.content}>
          <p className={cn(styles.title, { [styles.visibleOnMobile]: desktopTitle }, { [styles.bigTitle]: bigTitle })}>
            {title}
          </p>
          {desktopTitle && <p className={cn(styles.title, styles.visibleOnDesktop)}>{desktopTitle}</p>}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          <p
            className={cn(
              styles.smallText,
              { [styles.underline]: underlineContent },
              { [styles.visibleOnMobile]: desktopContent }
            )}
          >
            {content}
          </p>
          {desktopContent && (
            <p className={cn(styles.smallText, { [styles.underline]: underlineContent }, styles.visibleOnDesktop)}>
              {desktopContent}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};
