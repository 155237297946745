import React from 'react';

import { useSheetContext } from '@context/sheet';
import { useBlogContext } from '@theme/BlogPostPage/BlogPostPage';

import { BlogFilterButtonAllCategories } from './BlogFilterButtonAllCategories';
import { BlogFilterButtonBlogCategory } from './BlogFilterButtonBlogCategory';
import { BlogFilterProductCategory } from './BlogFilterProductCategory';
import styles from '../BlogFilter.module.scss';

export const SheetFilters = ({ filterByBlogCategoryText, allCategoryFilterLabelText, filterByProductCategoryText }) => {
  const { filter, setFilter, resetFilter, blogCategories, productCategories } = useBlogContext();
  const sheet = useSheetContext();
  const setIsOpen = sheet.sheetContext.setIsOpen;

  const onClickReset = () => {
    setIsOpen(false);
    resetFilter();
  };

  const onClickCategory = uuid => {
    setIsOpen(false);
    setFilter(uuid);
  };

  return (
    <div className={styles.filterRowWrapper}>
      <div className={styles.subTitleFilterRowText}>{filterByBlogCategoryText}</div>
      <BlogFilterButtonAllCategories
        isSheet
        filter={filter}
        onClick={onClickReset}
        allCategoryFilterLabelText={allCategoryFilterLabelText}
      />
      {blogCategories.map(category => (
        <BlogFilterButtonBlogCategory
          key={category.uuid}
          isSheet
          filter={filter}
          onClick={() => onClickCategory(category.uuid)}
          category={category}
        />
      ))}
      <div className={styles.subTitleFilterRowText}>{filterByProductCategoryText}</div>
      {productCategories.map(category => (
        <BlogFilterProductCategory
          key={category.uuid}
          filter={filter}
          onClick={() => onClickCategory(category.uuid)}
          category={category}
        />
      ))}
    </div>
  );
};
