import React from 'react';

import cn from 'classnames';

import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useCouponMessages } from '@hooks/cart/useCouponMessages';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { getCouponDiscountMessages } from '@utils/getCouponDiscountMessage';

import styles from './CheckoutMonthlyPriceSummary.module.scss';
import { CheckoutOneTimePaymentSummary } from '../CheckoutOneTimePaymentSummary/CheckoutOneTimePaymentSummary';

export const CheckoutMonthlyPriceSummaryForOffer = ({ monthly, summaryDescription }) => {
  const { data: cart } = useCartWithOffer();
  const { currencyFormatter } = useCurrencyFormatter();
  const { couponDiscountMessages } = useCouponMessages();

  if (!cart) {
    return false;
  }

  const { forDiscountedPeriodMessage, forAfterDiscountPeriodMessage } = getCouponDiscountMessages(
    cart.coupon,
    couponDiscountMessages
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.rowRightColumn}>{monthly}</div>{' '}
        <div
          className={cn(styles.rowRightColumn, {
            [styles.normalPriceAboveDiscount]: cart.discountedTotalMonthlyPrice !== undefined,
          })}
          data-total-normal-price={cart.totalMonthlyPrice}
        >
          {currencyFormatter({ price: cart.totalMonthlyPrice })}{' '}
        </div>
      </div>

      {cart.discountedTotalMonthlyPrice !== undefined && (
        <>
          <div className={styles.row}>
            <div className={styles.rowLeftColumnDiscountMessage}>{forDiscountedPeriodMessage}</div>
            <div
              className={cn(styles.discountedPrice, styles.rowRightColumn)}
              data-total-monthly-discount={cart.discountedTotalMonthlyPrice}
            >
              {currencyFormatter({ price: cart.discountedTotalMonthlyPrice })}{' '}
            </div>
          </div>

          {(cart?.coupon as any).durationType !== 'forever' && (
            <div className={styles.row}>
              <div className={styles.rowLeftColumnDiscountMessage}>{forAfterDiscountPeriodMessage}</div>
              <div className={cn(styles.rowRightColumn)} data-total-after-discount={cart.totalMonthlyPrice}>
                {currencyFormatter({ price: cart.totalMonthlyPrice })}
              </div>
            </div>
          )}
        </>
      )}

      <CheckoutOneTimePaymentSummary summaryDescription={summaryDescription} />
    </div>
  );
};
