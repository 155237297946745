/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Image } from '@components/Image/Image';

import { ContactCard } from '../ContactCard/ContactCard';
import styles from './ContactImage.module.scss';

export const ContactImage = ({ cards = [], headerTitle = '', headerContent = '', image }) => {
  return (
    <div>
      <div className={styles.imageContainer}>
        <Image image={image} className={styles.image} />
        <div className={styles.headerWrapper}>
          <h1 className={styles.headerTitle}>{headerTitle}</h1>
          <p className={styles.headerContent}>{headerContent}</p>
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {cards.map(blok => (
          <ContactCard key={blok._uid} {...blok} />
        ))}
      </div>
    </div>
  );
};
