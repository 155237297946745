import { noSpecialCharactersRegex } from '../../../../../../validation/regexps/no-special-characters';
import { phoneFilters } from '../../../../../../validation/regexps/phone';
import { postalCodeFilters } from '../../../../../../validation/regexps/postal-code';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as Yup from 'yup';

dayjs.extend(customParseFormat);

const customerValidationSchema = ({
  required,
  maxLength,
  phoneNumber,
  houseNumber,
  postalCodeDe,
  noSpecialCharacters,
}) => {
  return {
    phoneNumber: Yup.string()
      .required(required)
      .matches(phoneFilters.de, phoneNumber),

    street: Yup.string()
      .required(required)
      .max(140, maxLength),

    houseNumber: Yup.string()
      .matches(noSpecialCharactersRegex, noSpecialCharacters)
      .required(required)
      .max(100, maxLength)
      .typeError(houseNumber),

    postalCode: Yup.string()
      .required(required)
      .matches(postalCodeFilters.de, postalCodeDe),

    city: Yup.string()
      .required(required)
      .max(40, maxLength),
  };
};

export const existingCustomerValidationSchema = validationMessages =>
  Yup.object().shape(customerValidationSchema(validationMessages));
