import { interpolateContent } from '@utils/interpolate-content';

export const getCouponDiscountMessages = (coupon, messages) => {
  if (!coupon) {
    return {
      forDiscountedPeriodMessage: '',
      forAfterDiscountPeriodMessage: '',
    };
  }

  if (coupon.durationType === 'limited_period') {
    return {
      forDiscountedPeriodMessage: interpolateContent(messages.limitedPeriod, { period: coupon.period }),
      forAfterDiscountPeriodMessage: interpolateContent(messages.afterLimitedPeriod, { period: coupon.period }),
    };
  }

  if (coupon.durationType === 'one_time') {
    return {
      forDiscountedPeriodMessage: messages.oneTime,
      forAfterDiscountPeriodMessage: messages.afterOneTime,
    };
  }

  if (coupon.durationType === 'forever') {
    return {
      forDiscountedPeriodMessage: messages.forever,
    };
  }
};
