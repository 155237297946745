import React, { useCallback } from 'react';

import * as dayjs from 'dayjs';

import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useRedirect } from '@hooks/useRedirect';
import { useTimer } from '@hooks/useTimer';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutTimeslotBookingTimer.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const CheckoutTimeslotBookingTimer = ({ content, timesUpContent, timeslot, chooseTimeslotAgainButtonText }) => {
  const { data: cartWitOffer } = useCartWithOffer();
  const cartItems = cartWitOffer?.items || [];
  const areOnlySda = cartItems.every(item => item?.offerItem?.groupType === 'SDA');
  const { timer, isTimeEnd } = useTimer({
    expectedDate: timeslot?.expirationDate,
  });
  const redirect = useRedirect();
  const redirectToDeliveryPage = useCallback(() => {
    redirect({ pageId: Paths.CheckoutDelivery });
  }, []);

  if (!timeslot || areOnlySda) {
    return <></>;
  }

  const isTimeslotExpired = dayjs(timeslot.expirationDate).subtract(dayjs()) < 0;

  const { date, hoursRange } = timeslot;

  return (
    <div className={styles.container}>
      <NotificationBar
        className={{ [styles.notificationBar]: isTimeEnd || isTimeslotExpired }}
        content={isTimeEnd || isTimeslotExpired ? timesUpContent : content}
        contentVariables={{ timer, date, hoursRange }}
        variant="clockYellow"
      />
      {(isTimeEnd || isTimeslotExpired) && (
        <Button
          as="button"
          variant={ButtonVariant.Basic}
          onClick={redirectToDeliveryPage}
          className={styles.button}
          theme={ButtonTheme.RawOnLight}
        >
          {chooseTimeslotAgainButtonText}
        </Button>
      )}
    </div>
  );
};
