import { useState } from 'react';

import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from './useJwt';

const useUserLastOrderId = 'useUserLastOrder';

export const useUserLastOrder = () => {
  const jwtToken = useJwt();
  const httpService = useHttpService();
  const [isOrderFetched, setOrderFetched] = useState(false);

  return useQuery(
    [useUserLastOrderId],
    async () => {
      if (!jwtToken) {
        return;
      }

      const { data } = await httpService.get('/user/last-order');

      return data;
    },
    {
      enabled: !isOrderFetched,
      onSuccess: () => setOrderFetched(true),
      retry: 2,
      retryDelay: 1500,
    }
  );
};
