import React from 'react';

import cn from 'classnames';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

import styles from './BlogpostWrapper.module.scss';

const Items = ({ items }) =>
  items.map(blok => {
    const responsiveStyle = blok.wide ? styles.wideColumn : styles.responsiveColumns;

    return (
      <div key={blok._uid} className={cn(styles.item, responsiveStyle)}>
        <DynamicComponent {...blok} />
      </div>
    );
  });

export const BlogpostWrapper = ({ items }) => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.regularMargin, styles.stackingLayout)}>
        <Items items={items} />
      </div>
    </div>
  );
};
