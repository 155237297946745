import React from 'react';

import cn from 'classnames';

import styles from './Table.module.scss';

export const Table = ({ table: { thead, tbody }, theme = 'BrandPink', isNarrow = false }) => {
  return (
    <div className={cn(styles.container, styles[`tableScroll${theme}`], { [styles.narrow]: isNarrow })}>
      <table className={styles.table}>
        <thead>
          <tr>
            {thead.map(({ _uid, value }) => (
              <th key={_uid} className={styles.tableHeader}>
                {value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.map(({ _uid, body }) => (
            <tr key={_uid}>
              {body.map(({ _uid: unique, value }) => (
                <td key={unique} className={styles.tableCell}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
