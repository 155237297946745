import React from 'react';

import cn from 'classnames';

import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from './CartSummary.module.scss';

export const CartSummaryOffer = ({ totalText, monthlyText, couponDiscountMessage }) => {
  const { data: cart } = useCartWithOffer();
  const { oneTimeFee } = usePhrases({ name: 'general' });
  const { currencyFormatter } = useCurrencyFormatter();

  const monthlyPromotions = [];
  const oneTimePromotions = [];

  if (
    cart.coupon &&
    cart.discountedTotalMonthlyPrice >= 0 &&
    !cart.isCouponAfterValidTime &&
    !cart.isCouponCodeRedeemed
  ) {
    const { code, period } = cart.coupon;

    monthlyPromotions.push({
      code,
      period,
      amount: cart.discountedTotalMonthlyPrice,
      text: couponDiscountMessage,
    });
  }

  return (
    <div className={styles.paymentSummary}>
      <div className={styles.paymentSummaryTotal}>{totalText}</div>

      <div className={styles.paymentSummaryItem}>
        <span className={styles.itemText}>{oneTimeFee}</span>

        <span
          className={cn({
            [styles.stroke]: oneTimePromotions?.length > 0,
          })}
        >
          <span className={styles.text} data-total-deposit-value={cart.totalDepositPrice}>{`${currencyFormatter({
            price: cart.totalDepositPrice,
          })}`}</span>
        </span>
      </div>

      {oneTimePromotions?.map(promo => (
        <div className={cn(styles.paymentSummaryItem, styles.activePromotion)} key={promo.code}>
          <span className={styles.text}>{promo.text}</span>
          <span className={styles.text} data-promo-value={promo.newPrice}>{`${currencyFormatter({
            price: promo.newPrice,
          })}`}</span>
        </div>
      ))}

      <div className={styles.paymentSummaryItem}>
        <span className={styles.text}>{monthlyText}</span>
        <span className={styles.text}>
          <span className={styles.text} data-total-monthly-value={cart.totalMonthlyPrice}>{`${currencyFormatter({
            price: cart.totalMonthlyPrice,
          })}`}</span>
        </span>
      </div>

      {monthlyPromotions?.map(monthlyPromo => (
        <div className={cn(styles.paymentSummaryItem, styles.activePromotionSummaryItem)} key={monthlyPromo.code}>
          <span>
            <span className={cn(styles.text, styles.activePromotionText)}>{monthlyPromo.text}</span>
          </span>

          <span
            className={cn(styles.text, styles.activePromotionText)}
            data-total-discounted-price={cart.discountedTotalMonthlyPrice || cart.totalMonthlyPrice}
          >{`${currencyFormatter({
            price:
              cart.discountedTotalMonthlyPrice || cart.discountedTotalMonthlyPrice === 0
                ? cart.discountedTotalMonthlyPrice
                : cart.totalMonthlyPrice,
          })}`}</span>
        </div>
      ))}
    </div>
  );
};
