import * as util from 'util';

import * as Yup from 'yup';

import { phoneFilters } from 'validation/regexps/phone';

export function getValidationSchema({ messages: { phoneNumber, required, maxLength } }) {
  return {
    phoneNumber: Yup.string()
      .max(40, util.format(maxLength, 20))
      .required(required)
      .matches(phoneFilters.nl, phoneNumber),
  };
}

export function getDefaultValues() {
  return { phoneNumber: '' };
}
