import React from 'react';

import Script from 'next/script';

const htmlTagsRegex = /<.*?>(.*?)<\/.*?>/gs;

export const Snippet = ({ snippet, strategy, label }) => {
  const matches = [
    ...(snippet || '')
      .replace(/<!--(.*?)-->/g, '')
      .trim()
      .matchAll(htmlTagsRegex),
  ];
  const scriptMatch = matches.find(m => m[0].startsWith('<script'));
  const notScriptMatches = matches.filter(m => !m[0].startsWith('<script'));

  return (
    <>
      {scriptMatch && (
        <Script id="snippet-script" type="text/javascript" strategy={strategy || 'lazyOnload'}>
          {scriptMatch[1]}
        </Script>
      )}
      <div id={label} dangerouslySetInnerHTML={{ __html: notScriptMatches.map(m => m[0]).join() }} />
    </>
  );
};
