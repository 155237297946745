import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useCheckoutEditUserPersonalDataMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ updateUserDto }) => {
    const response = await httpService.patch(`/checkout/edit-details/${updateUserDto.id}`, { ...updateUserDto });

    return response.data;
  });
};
