import React from 'react';

import Pricing from '@components/Pricing/Pricing';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './ItemPriceList.module.scss';

import { AdditionalService } from 'types/additional-service.types';

type Props = {
  basicCostPhrase: string;
  postfixPhrase: string;
  subscriptionMonthlyAmount: number;
  recurringAdditionalServices: (AdditionalService & { label: string })[];
};

const ItemPriceList = ({
  basicCostPhrase,
  subscriptionMonthlyAmount,
  recurringAdditionalServices,
  postfixPhrase,
}: Props) => {
  const { included: includedText } = usePhrases({ name: 'additionalServices' });

  return (
    <div>
      <div className={styles.line}>
        <span>{basicCostPhrase}</span>
        <span>
          <Pricing price={subscriptionMonthlyAmount} postfix={postfixPhrase} isReferencePriceActive={false} />
        </span>
      </div>
      {recurringAdditionalServices &&
        recurringAdditionalServices
          .filter(as => !as.isHiddenFromCustomer)
          .map(as => {
            const isOneTimeAmountEqual0 = as?.oneTimeAmount === 0;

            return (
              <>
                <div key={as.id} className={styles.line}>
                  <span>{as?.label || as?.stockName}</span>
                  <span>
                    <Pricing
                      price={as.monthlyAmount}
                      postfix={postfixPhrase}
                      included={isOneTimeAmountEqual0}
                      includedText={includedText}
                      isReferencePriceActive={false}
                    />
                  </span>
                </div>
              </>
            );
          })}
    </div>
  );
};

export default ItemPriceList;
