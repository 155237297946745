import { CreateCartItemDto } from '@hooks/cart/useAddToCartWithOfferMutation';

import { OfferItemPricing } from 'types/offer.types';

export const convertSelectedOfferItemsToCartDto = (
  selectedOfferItems: Record<string, OfferItemPricing>
): CreateCartItemDto[] => {
  return Object.entries(selectedOfferItems)
    .map(([offerItemId, pricing]) => ({
      offerItemId,
      pricingId: pricing?.id,
    }))
    .filter(item => item?.pricingId);
};
