import { usePhrases } from '@hooks/context/usePhrases';

export type FilteringTranslations = {
  featurePhrases: Record<
    string,
    {
      key: string;
      label: string;
      icon: { filename: string };
    }
  >;
  filterPhrases: Record<string, string>;
  conditionsPhrases: Record<string, string>;
  contractTypePhrases: Record<string, string>;
  subCategoryPhrases: Record<string, string>;
  installationTypePhrases: Record<string, string>;
};

export const useFilteringTranslations = (): FilteringTranslations => {
  const filterPhrases = usePhrases({ name: 'filtering' });
  const conditionsPhrases = usePhrases({ name: 'conditions' });
  const contractTypePhrases = usePhrases({ name: 'offer-types' });
  const featurePhrases = usePhrases({ name: 'features', rawObject: true });
  const subCategoryPhrases = usePhrases({ name: 'sub-category' });
  const installationTypePhrases = usePhrases({ name: 'installation-type' });

  return {
    featurePhrases,
    filterPhrases,
    conditionsPhrases,
    contractTypePhrases,
    subCategoryPhrases,
    installationTypePhrases,
  };
};
