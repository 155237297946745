import React, { useState } from 'react';

import { Table } from './components/Table/Table';
import styles from './InformationTable.module.scss';

export const InformationTable = ({ showMoreButtonText, showLessButtonText, sections, items, renderers = {} }) => {
  const [showMore, setShowMore] = useState(false);
  const [firstSection, ...restSections] = sections;

  const showMoreText = showMore ? showLessButtonText : showMoreButtonText;

  return (
    <div className={styles.container}>
      {firstSection && <Table {...firstSection} items={items} renderers={renderers} />}
      {showMore &&
        restSections?.map(section => (
          <Table key={section.title || section._uid} {...section} items={items} renderers={renderers} />
        ))}

      <div
        data-table-toggle={showMoreText}
        className={styles.showButton}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMoreText}
      </div>
    </div>
  );
};
