import React, { useEffect } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

import DropdownIcon from '@assets/icons/new/inputs/dropdownArrow.svg';
import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';

import styles from './Dropdown.module.scss';

export const Dropdown = ({ controllerName, labelText, entries, onChange, defaultValue, disabled, dataAttribute }) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, controllerName);
  const isError = error !== undefined;
  const isValid = !error;

  useEffect(() => {
    if (defaultValue) {
      setValue(controllerName, defaultValue);
    }
  }, [defaultValue, controllerName]);

  return (
    <Controller
      render={({ field: { ref, ...field } }) => {
        return (
          <div
            className={cn(styles.dropdownContainer, {
              [styles.invalid]: isError,
              [styles.valid]: isValid,
            })}
          >
            <div className={styles.labelText}>{labelText}</div>

            <div>
              <select
                className={styles.dropdown}
                {...register(controllerName)}
                {...field}
                data-test-id={controllerName}
                {...dataAttribute}
                disabled={disabled}
                defaultValue={defaultValue}
                onChange={event => {
                  setValue(controllerName, event.target.value);

                  if (onChange) {
                    onChange(event.target.value);
                  }
                }}
              >
                <option value="">--</option>
                {entries.map(({ option, value }) => (
                  <option key={`dropdown-${value}`} value={value}>
                    {option}
                  </option>
                ))}
              </select>
              <DropdownIcon className={styles.dropdownIcon} />
            </div>

            {isError && (
              <div className={styles.errorMessage}>
                {error?.message && (
                  <>
                    <RectangleIcon className={styles.rectangleIcon} />
                    <span>{error.message}</span>
                  </>
                )}
              </div>
            )}
          </div>
        );
      }}
      name={controllerName}
      type="text"
      control={control}
    />
  );
};
