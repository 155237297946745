export const sortPricingsByVariant = pricings =>
  pricings?.sort((a, b) => {
    if (a.variant < b.variant) {
      return -1;
    }
    if (a.variant > b.variant) {
      return 1;
    }

    return 0;
  });
