import { MouseEvent, ReactNode, useEffect } from 'react';

import cn from 'classnames';

import styles from './ModalContainer.module.scss';
import { ModalFooter } from './ModalFooter/ModalFooter';
import { ModalHeader } from './ModalHeader/ModalHeader';

import { ButtonVariant } from '@base-components/Button';

export type ModalContainerProps = {
  closeModal: () => void;
  size?: 'small' | 'large';
  title?: string;
  children?: ReactNode;
  footer?:
    | {
        type?: 'standard';
        rejectButtonText: string;
        confirmButtonText: string;
        buttonVariant: ButtonVariant;
        onReject: () => void;
        onConfirm: () => void;
      }
    | {
        type?: 'custom';
        component: ReactNode;
      };
};

export const ModalContainer = ({ size = 'small', title, children, footer, closeModal }: ModalContainerProps) => {
  const handleOverlayClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    closeModal();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.overlay} onClick={handleOverlayClick} />
      <div className={cn(styles.wrapper, styles.fadeIn, { [styles.largeModal]: size === 'large' })}>
        <div className={styles.body}>
          <ModalHeader title={title} closeModal={closeModal} />
          {children && <div className={cn(styles.content, { [styles.withFooter]: !!footer })}>{children}</div>}
          {footer?.type === 'custom'
            ? footer.component
            : footer && (
                <ModalFooter
                  rejectButtonText={footer.rejectButtonText}
                  confirmButtonText={footer.confirmButtonText}
                  onReject={footer.onReject}
                  onConfirm={footer.onConfirm}
                  buttonVariant={footer.buttonVariant}
                />
              )}
        </div>
      </div>
    </div>
  );
};
