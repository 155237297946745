import React from 'react';

import cn from 'classnames';
import { toast } from 'react-toastify';

import Logout from '@assets/icons/menu/logout.svg';
import BoschHeaderLogo from '@assets/icons/new/logo/boschLogo.svg';
import { useContentContext } from '@context/content';
import { usePhrases } from '@hooks/context/usePhrases';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { useRedirect } from '@hooks/useRedirect';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './SubscriptionActionPageHeader.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const SubscriptionActionPageHeader = () => {
  const { country, language, locale } = useLocale();
  const backPagePhrases = usePhrases({ name: 'backpagelabels', rawObject: true });
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const generalPhrases = usePhrases({ name: 'general', rawObject: true });
  const { currentPageId } = useContentContext();
  const { data: user } = useUser();
  const userLogoutMutation = useUserLogoutMutation();
  const redirect = useRedirect();

  const backButtonText =
    backPagePhrases && (backPagePhrases[currentPageId?.toLowerCase()]?.value || backPagePhrases.default.value);

  const logoutUser = async () => {
    try {
      await userLogoutMutation.mutateAsync();
      redirect({ pageId: Paths.Home });
    } catch (e) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className={cn(styles.container)}>
      <div className={styles.mobileBackButton}>
        <Button
          as="button"
          variant={ButtonVariant.Icon}
          icon="arrow-left"
          className={styles.mobileBackButton}
          onClick={e => {
            e.preventDefault();

            window.location.href = buildPath({
              pageId: Paths.UserPanelSubscriptions,
              locale,
            });
          }}
        ></Button>
      </div>

      <div className={styles.headerContainer}>
        <a href={`/${country}-${language}`} className={styles.link}>
          <BoschHeaderLogo className={styles.logo} width="267" height="61" />

          <div className={styles.backButton}>
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              icon="arrow-left"
              className={styles.mobileBackButton}
              onClick={e => {
                e.preventDefault();

                window.location.href = buildPath({
                  pageId: Paths.UserPanelSubscriptions,
                  locale,
                });
              }}
            >
              {backButtonText}
            </Button>
          </div>
        </a>
        {user && (
          <span className={styles.logout} onClick={logoutUser}>
            <span className={styles.desktopText}>{generalPhrases?.logout?.value || 'Logout'}</span>
            <Logout />
          </span>
        )}
      </div>
    </div>
  );
};
