import React, { useState, useEffect } from 'react';

import { useMedia } from '@hooks/useMedia';

import { Block } from './components/Block/Block';
import styles from './UspBar.module.scss';

export const UspBar = ({ blocks, mobileIntervalMs = 1000 }) => {
  const { isMobile } = useMedia();
  const [blockCounter, setBlockCounter] = useState(0);

  useEffect(() => {
    if (isMobile) {
      const timeout = setTimeout(() => {
        if (blockCounter < blocks.length - 1) {
          setBlockCounter(blockCounter + 1);
        }

        if (blockCounter === blocks.length - 1) {
          setBlockCounter(0);
        }
      }, mobileIntervalMs);

      return () => clearTimeout(timeout);
    }
  }, [blockCounter, isMobile]);

  if (isMobile) {
    return (
      <div className={styles.container}>
        <Block animated text={blocks[blockCounter].text} icon={blocks[blockCounter].icon} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.blocks}>
        {blocks.map(block => (
          <Block key={block.text} text={block.text} icon={block.icon} />
        ))}
      </div>
    </div>
  );
};
