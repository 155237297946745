import React, { useState } from 'react';

import cn from 'classnames';

import { usePhrases } from '@hooks/context/usePhrases';

import styles from './IconsRow.module.scss';
import { ConditionalLink } from '../ConditionalLink/ConditionalLink';

export const IconsRow = ({ icons }) => {
  const slicedIcons = icons.slice(0, 2);
  const numberOfIcons = icons.length - slicedIcons.length;
  const [showAllIcons, setShowAllIcons] = useState(false);
  const { close } = usePhrases({ name: 'general' });

  const iconHandler = () => {
    setShowAllIcons(!showAllIcons);
  };

  return (
    <div className={styles.row}>
      <div className={cn(styles.cell, styles.value)}>
        <div className={styles.icons}>
          {(showAllIcons ? icons : slicedIcons).map((iconRow, index) => (
            <ConditionalLink key={`${index}-${iconRow?.link}`} link={iconRow?.link} additionalClass={styles.link}>
              <div className={cn(styles.iconContainer, styles.iconBasicColor)}>
                <span className={styles.iconLabel}>{iconRow.label}</span>
              </div>
            </ConditionalLink>
          ))}
          {icons.length > 2 && (
            <div
              onClick={iconHandler}
              className={cn(styles.iconContainer, {
                [styles.iconBasicColor]: !showAllIcons,
                [styles.iconCloseColor]: showAllIcons,
              })}
            >
              <span className={styles.iconLabel}>{showAllIcons ? <p>x {close}</p> : <p>... ({numberOfIcons})</p>}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
