import React from 'react';

import { useLocale } from '@hooks/useLocale';

import { CheckoutPaymentInputsDe } from './de/CheckoutPaymentInputsDe';
import { CheckoutPaymentInputsNl } from './nl/CheckoutPaymentInputsNl';
import { Card, Klarna, Redirect, SepaDirectDebit } from '@adyen/adyen-web';

const radioInputs = {
  de: CheckoutPaymentInputsDe,
  nl: CheckoutPaymentInputsNl,
};

const scrollIntoElementId = elementId => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const remountComponent = component => {
  if (component instanceof SepaDirectDebit) {
    component.mount('#sepa-container');
    scrollIntoElementId('checkoutPaymentForm');
  }

  if (component instanceof Card) {
    component.mount('#card-container');
    scrollIntoElementId('checkoutPaymentForm');
  }

  if (component instanceof Redirect) {
    component.mount('#ideal-container');
    scrollIntoElementId('checkoutPaymentForm');
  }

  if (component instanceof Klarna) {
    component.mount('#klarna_paynow-container');
    scrollIntoElementId('checkoutPaymentForm');
  }
};

export const getPaymentMethodByComponent = component => {
  if (component instanceof SepaDirectDebit) {
    return 'sepadirectdebit';
  }

  if (component instanceof Card) {
    return 'creditCard';
  }

  if (component instanceof Redirect) {
    return 'ideal';
  }

  if (component instanceof Klarna) {
    return 'klarna_paynow';
  }
};

export const CheckoutPaymentInputs = props => {
  const { country } = useLocale();
  const CountryInputs = radioInputs[country];

  return <CountryInputs {...props} />;
};
