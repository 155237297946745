import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isUndefined from 'lodash/isUndefined';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export function getValidationSchema({
  newestSubscriptionDate,
  messages: {
    required = 'required',
    dateFormat = 'dateFormat',
    noPast = 'noPast',
    max6months = 'max6months',
    min3MonthsAfterActivation = 'min3MonthsAfterActivation',
  } = {},
}) {
  return {
    takeoverDate: Yup.string()
      .required(required)
      .test('validDate', dateFormat, val => {
        return !isUndefined(val) && dayjs(val, 'YYYY-MM-DD', true).isValid();
      })
      .test('isFirstDayOfMonth', dateFormat, val => {
        return !isUndefined(val) && dayjs(val).date() === 1;
      })
      .test('noPast', noPast || 'noPast', val => {
        return !isUndefined(val) && dayjs(val).isAfter(dayjs(), 'day');
      })
      .test('max6months', max6months || 'max6months', val => {
        return !isUndefined(val) && dayjs(val).isSameOrBefore(dayjs().add(6, 'months'), 'day');
      })
      .test('min3MonthsAfterActivation', min3MonthsAfterActivation || 'min3Months', val => {
        return !isUndefined(val) && dayjs(val).isSameOrAfter(dayjs(newestSubscriptionDate).add(3, 'months'), 'day');
      }),
  };
}

export function getDefaultValues() {
  return { takeoverDate: '' };
}
