/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React from 'react';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import { useMedia } from '@hooks/useMedia';

import styles from './ContractSwitchConfirmation.module.scss';

export const ContractSwitchConfirmation = ({ background, components }) => {
  const [backgroundImage] = background;
  const { isMobile } = useMedia();

  const desktopImage = backgroundImage.image && backgroundImage.image.filename;
  const mobileImage = backgroundImage.mobileImage && backgroundImage.mobileImage.filename;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {components?.map(component => (
          <DynamicComponent {...component} key={component._uid} layout="emptyLayout" style={{ color: '#FFF' }} />
        ))}
      </div>

      <div
        className={styles.backgroundContainer}
        style={{ backgroundImage: isMobile && mobileImage ? `url(${mobileImage})` : `url(${desktopImage})` }}
      />
    </div>
  );
};
