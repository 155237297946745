import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';

const ConditionOrder = {
  refurbished: 1,
  new: 2,
  standard: 3,
};

const filteringOptions: FilteringOptions = (offersData, { conditionsPhrases }) => {
  return offersData
    .reduce((accumulatedConditionsFilters, currentOffer) => {
      const mainOfferItem = currentOffer.offerData.offerItems.find(item => item.type === 'main');
      mainOfferItem.pricings?.map(pricing => {
        if (!accumulatedConditionsFilters.includes(pricing.variant)) {
          const index = accumulatedConditionsFilters.findIndex(
            accumulatedCondition => ConditionOrder[accumulatedCondition] > ConditionOrder[pricing.variant]
          );

          if (index === -1) {
            accumulatedConditionsFilters.push(pricing.variant);
          } else {
            accumulatedConditionsFilters.splice(index, 0, pricing.variant);
          }
        }
      });

      return accumulatedConditionsFilters;
    }, [])
    .map(condition => conditionsPhrases[condition]);
};

const filterCondition: ValidationFunction = (selectedFilters, offer, translations) =>
  selectedFilters.some(condition => {
    return offer.offerData.offerItems.some(item => {
      return item.pricings.some(pricing => translations.conditionsPhrases[pricing.variant] === condition);
    });
  });

export const conditionFilter = filterBuilder(filteringOptions, filterCondition);
