import React from 'react';

import cn from 'classnames';

import { BlogFilterLabel } from '../../BlogFilterLabel/BlogFilterLabel';
import styles from '../BlogFilter.module.scss';

export const BlogFilterButtonBlogCategory = ({ isSheet, filter, onClick, category }) => {
  return (
    <button
      className={cn({
        [styles.sheetButton]: isSheet,
        [styles.labelButton]: !isSheet,
        [styles.inactiveCategory]: filter !== category.uuid,
      })}
      type="button"
      onClick={onClick}
    >
      <BlogFilterLabel blogCategory={category.uuid} />
    </button>
  );
};
