import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useContentContext } from '@context/content';
import { usePhrases } from '@hooks/context/usePhrases';
import { useUserResetPasswordMutation } from '@hooks/user/useUserResetPasswordMutation';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import styles from './ForgotPassword.module.scss';
import { forgotPasswordValidation } from './validation';
import { HeadingGroup } from '../../../components/HeadingGroup/HeadingGroup';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const ForgotPassword = ({
  headerText,
  infoText,
  emailText,
  submitText,
  failMessageToast,
  validationMessages,
}) => {
  const userResetPasswordMutation = useUserResetPasswordMutation();
  const redirect = useRedirect();
  const { notification } = useContentContext();
  const phrases = usePhrases({ name: 'errors' });

  useEffect(() => {
    if (notification) {
      toast(phrases?.[notification] || notification, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, []);

  const formOptions = {
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordValidation(validationMessages)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  const methods = useForm(formOptions);

  const onSubmitHandler = async data => {
    try {
      await userResetPasswordMutation.mutateAsync({ email: data.email });
      redirect({
        pageId: Paths.Login,
        notificationType: toast.TYPE.SUCCESS,
        notificationPhraseKey: 'link-to-reset-password-sent',
      });
    } catch (error) {
      toast(failMessageToast, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.formContainer}>
        <HeadingGroup variant="default" alignLeft label={headerText} text={infoText} />
        <TextInput controllerName="email" labelText={emailText} />
        <Button
          as="button"
          variant={ButtonVariant.Basic}
          theme={ButtonTheme.Secondary}
          type="submit"
          disabled={!methods.formState.isValid}
          isLoading={methods.formState.isSubmitting}
          fullWidth
          className={styles.primaryButton}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
