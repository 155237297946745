import React from 'react';

import styles from './CheckoutHeader.module.scss';

export const CheckoutHeader = ({ text, withLine, type = 'Black' }) => {
  return (
    <div className={styles.container}>
      <div className={styles[`headerContainer${type}`]}>
        <div className={styles[`text${type}`]}>{text}</div>
        {withLine && <div className={styles.line} />}
      </div>
    </div>
  );
};
