import React, { useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';
import Loader from '@components/Loader/Loader';
import { useContentContext } from '@context/content';
import { usePaymentAttempt } from '@hooks/payment/usePaymentAttempt';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useRedirect } from '@hooks/useRedirect';
import { CheckoutOneTimePaymentInputs } from '@modules/Checkout/components/CheckoutOneTimePayment/CheckoutOneTimePaymentInputs';
import { Paths } from '@utils/paths/paths';
import { useContent } from '@hooks/useContent';

import styles from './CheckoutVerifyPayment.module.scss';
import { getCheckoutFailedPaymentFormValidationSchema, getDefaultValues } from './validation';

import { sendPaymentEvent } from '@services/gtm/page-initialization';
import { AdyenContext } from '@context/adyen';

export const CheckoutVerifyPayment = ({
  label,
  text,
  failedLabel,
  failedText,
  validationRequired,
  failedSubmitButtonText,
  summaryDescription,
  ...paymentControllerProps
}) => {
  const { logger } = useLogger({ context: 'CheckoutVerifyPayment' });
  const { country, language } = useLocale();
  const { data: plans, isLoading: plansIsLoading } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  const { currencyFormatter } = useCurrencyFormatter();
  const { transactionId } = useContentContext();
  const { data: paymentAttempt, isLoading: paymentAttemptIsLoading } = usePaymentAttempt(transactionId);
  const { data: user, isLoading, isIdle } = useUser();
  const redirect = useRedirect();
  const adyenContext = useContext(AdyenContext);

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(getCheckoutFailedPaymentFormValidationSchema({ validationRequired })),
  });

  useEffect(() => {
    const isGtmEventSend = sessionStorage.getItem('isGtmEventSend');

    if (paymentAttempt && !isGtmEventSend && plans && !plansIsLoading) {
      logger.info({
        message: `User ${paymentAttempt?.user?.id} is trying to process payment`,
        params: {
          userId: paymentAttempt?.user?.id,
          cartId: paymentAttempt?.cart?.id,
          paymentStatus: paymentAttempt?.status,
        },
      });

      methods.setValue('oneTimePaymentMethod', paymentAttempt?.params?.depositPaymentMethod);

      sendPaymentEvent({
        cart: paymentAttempt.cart,
        country,
        payment: paymentAttempt,
        plans,
      }).then(() => {
        sessionStorage.setItem('isGtmEventSend', true);

        if (paymentAttempt?.status === 'paid') {
          logger.info({
            message: `User ${paymentAttempt?.user?.id} is already paid`,
            params: {
              userId: paymentAttempt?.user?.id,
              cartId: paymentAttempt?.cart?.id,
              paymentAttemptId: paymentAttempt?.id,
              paymentStatus: paymentAttempt?.status,
            },
          });

          sessionStorage.removeItem('isGtmEventSend');

          redirect({
            pageId: Paths.CheckoutOrderConfirmation,
          });
        } else {
          // TODO: check it! existing isGtmEventSend item cause verify page stuck
          sessionStorage.removeItem('isGtmEventSend');
        }
      });
    }
  }, [paymentAttempt, plans, plansIsLoading]);

  if (
    (!paymentAttemptIsLoading && !paymentAttempt) ||
    ['failed', 'expired', 'canceled'].includes(paymentAttempt?.status)
  ) {
    if (!isLoading && !isIdle && !user) {
      sessionStorage.removeItem('isGtmEventSend');
      redirect({ pageId: Paths.ProductsOverview });
    }

    const onSubmit = async () => {
      await adyenContext?.attachedPaymentMethod?.submit();
    };

    return (
      <div className={styles.container}>
        <HeadingGroup label={failedLabel} text={failedText} />
        <div className={styles.form}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} id="checkoutFailedPaymentForm">
              <div className={styles.summaryDescription}>
                {summaryDescription} {currencyFormatter({ price: paymentAttempt?.amount })}
              </div>

              <CheckoutOneTimePaymentInputs
                submitButtonText={failedSubmitButtonText}
                showConsent={false}
                paymentAttempt={paymentAttempt}
                {...paymentControllerProps}
              />
            </form>
          </FormProvider>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
      <HeadingGroup label={label} text={text} />
    </div>
  );
};
