import React from 'react';

import styles from './TextMap.module.scss';

export const TextMap = ({ items, keysToMap }) => {
  return (
    <div className={styles.container}>
      {keysToMap?.map(key => {
        const text = items?.find(item => item.key === key)?.text;

        return (
          <span className={styles.text} key={key}>
            {text}
          </span>
        );
      })}
    </div>
  );
};
