import React from 'react';

import Pricing from '@components/Pricing/Pricing';

import styles from './InfoBox.module.scss';

export const InfoBox = ({ from = '', monthUnit = 'm', pricingRanges }) => {
  return (
    <div className={styles.container}>
      {pricingRanges.map(item => {
        return (
          <div className={styles.content} key={item.key}>
            <div>{item.condition}</div>
            <Pricing
              price={item.minValue}
              referencePrice={item.referenceAmount}
              isReferencePriceActive={item.isReferenceAmountActive}
              from={from}
              postfix={monthUnit}
            />
          </div>
        );
      })}
    </div>
  );
};
