import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

const useUserContractSwitchesQueryId = 'useUserContractSwitchesQueryId';

export const useUserContractSwitches = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useQuery(
    useUserContractSwitchesQueryId,
    async () => {
      const { data } = await httpService.get('/subscription/switch/user');

      return data;
    },
    {
      enabled: !!jwtToken,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
