import { useMutation } from 'react-query';
import { useHttpService } from '@hooks/useHttpService';

export const useUserResetPasswordMutation = () => {
  const httpService = useHttpService();

  return useMutation(async resetPasswordDto => {
    await httpService.post('/user/reset-password', resetPasswordDto);

    return resetPasswordDto.email;
  });
};
