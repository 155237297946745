/* eslint-disable import/no-cycle */
import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useUpdateCartItemsWithOffer } from '@hooks/cart/useUpdateCartItemsWithOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCondition } from '@hooks/useCondition';
import { useContent } from '@hooks/useContent';
import { useDeliveryTime } from '@hooks/useDeliveryTime';
import { useLocale } from '@hooks/useLocale';
import { useRedirect } from '@hooks/useRedirect';
import { CheckoutCard } from '@modules/Checkout/components/CheckoutCard/CheckoutCard';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutCardsForm.module.scss';
import { validationSchema, defaultValues } from './validation';

import { Button, ButtonVariant } from '@base-components/Button';

export const CheckoutCardsFormForOffer = ({
  dryerOnTop,
  dryerOnTopExternalText,
  takeBackOldAppliance,
  takeBackOldApplianceExternalText,
  continueButtonText,
  isRedirecting,
}) => {
  const { locale } = useLocale();
  const { data: cart } = useCartWithOffer();
  const redirect = useRedirect();
  const { data: categories } = useContent({ locale, name: 'categories/' });
  const { getCondition } = useCondition();
  const { getDeliveryTime } = useDeliveryTime();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const cartItemIds = cart?.items?.map(cartItem => cartItem?.id);
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues(cartItemIds),
  };
  const methods = useForm(formOptions);
  const updateCartItems = useUpdateCartItemsWithOffer();

  const onSubmit = async data => {
    try {
      const itemsWithControls = data?.items?.map(item => ({
        id: item.id,
        dryerOnTop: item.dryerOnTop,
        takeBackOldAppliance: item.takeBackOldAppliance,
      }));

      await updateCartItems.mutateAsync({
        items: itemsWithControls,
      });

      redirect({ pageId: Paths.CheckoutCustomerDetails });
    } catch (e) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }} id="checkoutCardsForm">
          {cart?.items?.map((cartItem, index) => {
            const categorySlug = cartItem?.offerItem?.categorySlug;
            const isDryer = categorySlug === 'dryer';
            const hexBackgroundColorColor = categories?.[categorySlug]?.colorHex;
            const condition = getCondition(cartItem);
            const deliveryTime = getDeliveryTime(cartItem);
            const isSdaAppliance = cartItem?.offerItem?.groupType.toLowerCase() === 'sda';

            return (
              <div className={styles.container} key={`checkoutCard-${cartItem?.id}`}>
                <CheckoutCard
                  backgroundColor={hexBackgroundColorColor}
                  checkoutCardImage={cartItem?.content?.mainImage}
                  checkoutCardMobileImage={cartItem?.content?.mobileImage}
                  applianceName={cartItem?.content?.name || cartItem?.plan?.bmId}
                  description={cartItem?.content?.description}
                  duration={cartItem?.pricing?.term}
                  condition={condition}
                  deliveryTime={deliveryTime}
                  dryerOnTop={isDryer && dryerOnTop}
                  dryerOnTopExternalText={dryerOnTopExternalText}
                  takeBackOldAppliance={!isSdaAppliance && takeBackOldAppliance}
                  takeBackOldApplianceExternalText={takeBackOldApplianceExternalText}
                  currentIndex={index}
                  categorySlug={categorySlug}
                />
              </div>
            );
          })}

          {/* @ts-ignore */}
          <Button
            as="button"
            variant={ButtonVariant.Basic}
            type="submit"
            isLoading={methods.formState.isSubmitting || isRedirecting}
            className={styles.primaryButton}
          >
            {continueButtonText}
          </Button>
        </form>
      </FormProvider>
    </>
  );
};
