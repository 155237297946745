/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';

import { RichText } from '@components/RichText/RichText';
import { useContentContext } from '@context/content';
import { useFaqContext } from '@context/faq';
import { useRedirect } from '@hooks/useRedirect';
import { Paths } from '@utils/paths/paths';

import styles from './Question.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

const findFaqGroupItemByQuestion = (faqCategories, question) => {
  const faqGroupItems = faqCategories
    .map(faqCategory => faqCategory.content.faqArticles)
    .flat()
    .map(faqArticle => faqArticle.faqGroup)
    .flat()
    .map(faqGroup => faqGroup.items)
    .flat();

  const foundFaqGroupItem = faqGroupItems.find(
    faqGroupItem => faqGroupItem.question.toLowerCase() === question.toLowerCase()
  );

  return foundFaqGroupItem;
};

export const Question = ({ questionParam, topicText, backButtonText, backButtonOverviewText }) => {
  const redirect = useRedirect();
  const { searchTerm, setSearchTerm } = useFaqContext();
  const { faqCategories } = useContentContext();
  const faqGroupItem = findFaqGroupItemByQuestion(faqCategories, questionParam);

  useEffect(() => {
    return () => {
      if (searchTerm) {
        setSearchTerm(null);
      }
    };
  }, []);

  if (!faqGroupItem) {
    // TODO: Should redirect to 404 page (create it!)
    return (
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button
            as="button"
            variant={ButtonVariant.Icon}
            icon="arrow-left"
            onClick={() => {
              window.history.back();
            }}
          >
            {backButtonText}
          </Button>
        </div>

        <div className={styles.notFound}>Not found</div>
      </div>
    );
  }

  const { answer, question } = faqGroupItem;

  return (
    <div className={styles.container}>
      <div className={styles.topic}>{topicText}</div>

      <div className={styles.buttonContainer}>
        <Button
          as="button"
          variant={ButtonVariant.Icon}
          icon="arrow-left"
          onClick={() => {
            if (searchTerm) {
              window.history.back();
            } else {
              redirect({ pageId: Paths.Faq });
            }
          }}
        >
          {searchTerm ? backButtonText : backButtonOverviewText}
        </Button>
      </div>

      <div className={styles.question}>{question}</div>
      <RichText className={styles.answer} document={answer} />
    </div>
  );
};
