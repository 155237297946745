/* eslint-disable import/no-cycle */
// docs/application_procesess/BUNDLE_SPLITTING.md

import { Blogpost } from '@components/Blogpost/Blogpost';
import { BlogpostCategory } from '@components/BlogpostCategory/BlogpostCategory';
import { BlogpostColumn } from '@components/BlogpostColumn/BlogpostColumn';
import { BlogpostHeroImage } from '@components/BlogpostHeroImage/BlogpostHeroImage';
import { BlogpostTightContent } from '@components/BlogpostTightContent/BlogpostTightContent';
import { Carousel } from '@components/Carousel/Carousel';
import { CategoryCard } from '@components/CategoryCard/CategoryCard';
import { Column } from '@components/Column/Column';
import { ColumnRow } from '@components/ColumnRow/ColumnRow';
import { CompactLayout } from '@components/CompactLayout/CompactLayout';
import { CompareHeader } from '@components/CompareHeader/CompareHeader';
import { CookiebotScript } from '@components/Cookiebot/Cookiebot';
import { EmptyLayout } from '@components/EmptyLayout/EmptyLayout';
import { FaqGroup } from '@components/FaqGroup/FaqGroup';
import { FaqGroupItem } from '@components/FaqGroupItem/FaqGroupItem';
import { FloatingActionBar } from '@components/FloatingActionBar/FloatingActionBar';
import { FullWidthLayout } from '@components/FullWidthLayout/FullWidthLayout';
import { GeneralInfo } from '@components/GeneralInfo/GeneralInfo';
import { Header } from '@components/Header/Header';
import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';
import { Hero } from '@components/Hero/Hero';
import { Image } from '@components/Image/Image';
import { ImageCarousel } from '@components/ImageCarousel/ImageCarousel';
import { ImageContentBlock } from '@components/ImageContentBlock/ImageContentBlock';
import { InformationCard } from '@components/InformationCard/InformationCard';
import { InformationTable } from '@components/InformationTable/InformationTable';
import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { Label } from '@components/Label/Label';
import { TextWithLink } from '@components/Link/TextWithLink';
import { Loadbee } from '@components/Loadbee/Loadbee';
import { NewSimpleLayout } from '@components/NewSimpleLayout/NewSimpleLayout';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { NotificationBar2PlusItems } from '@components/NotificationBar2PlusItems/NotificationBar2PlusItems';
import { OverflowLayout } from '@components/OverflowLayout/OverflowLayout';
import { PageHeader } from '@components/PageHeader/PageHeader';
import { PaymentConfirmation } from '@components/PaymentConfirmation/PaymentConfirmation';
import { PaymentOptions } from '@components/PaymentOptions/PaymentOptions';
import { ProductCard } from '@components/ProductCard/ProductCard';
import { ProductCardOffer } from '@components/ProductCard/ProductCardForOffer';
import { ProductCardColumn } from '@components/ProductCardColumn/ProductCardColumn';
import { QualtricsContainer } from '@components/QualtricsContainer/QualtricsContainer';
import { SimpleLayout } from '@components/SimpleLayout/SimpleLayout';
import { Snippet } from '@components/Snippet/Snippet';
import { StoryblokButton } from '@components/StoryblokButton/StoryblokButton';
import { Table } from '@components/Table/Table';
import { TeamCard } from '@components/TeamCard/TeamCard';
import { TextMap } from '@components/TextMap/TextMap';
import { TitleBlock } from '@components/TitleBlock/TitleBlock';
import { TitleWithText } from '@components/TitleWithText/TitleWithText';
import { TrustPilot } from '@components/TrustPilot/TrustPilot';
import { UspBar } from '@components/UspBar/UspBar';
import { VideoPlayer } from '@components/VideoPlayer/VideoPlayer';
import { FaqSearch } from '@modules//Faq/FaqSearch/FaqSearch';
import { FaqSearchResults } from '@modules//Faq/FaqSearchResults/FaqSearchResults';
import { ForgotPassword } from '@modules/Auth/ForgotPassword/ForgotPassword';
import { Login } from '@modules/Auth/Login/Login';
import { ResetPassword } from '@modules/Auth/ResetPassword/ResetPassword';
import { BlogFilter } from '@modules/Blog/BlogFilter/BlogFilter';
import { CheckoutAccountCard } from '@modules/Checkout/components/CheckoutAccountCard/CheckoutAccountCard';
import { CheckoutAddressDetails } from '@modules/Checkout/components/CheckoutAddressDetails/CheckoutAddressDetails';
import { CheckoutBlockedOrderInfo } from '@modules/Checkout/components/CheckoutBlockedOrderInfo/CheckoutBlockedOrderInfo';
import { CheckoutCard } from '@modules/Checkout/components/CheckoutCard/CheckoutCard';
import { CheckoutCardsForm } from '@modules/Checkout/components/CheckoutCardsForm/CheckoutCardsForm';
import { CheckoutCartItemsCarousel } from '@modules/Checkout/components/CheckoutCartItemsCarousel/CheckoutCartItemsCarousel';
import { CheckoutContactMethods } from '@modules/Checkout/components/CheckoutContactMethods/CheckoutContactMethods';
import { CheckoutContentSheet } from '@modules/Checkout/components/CheckoutContentSheet/CheckoutContentSheet';
import { CheckoutCouponForm } from '@modules/Checkout/components/CheckoutCouponForm/CheckoutCouponForm';
import { CheckoutCreditCheck } from '@modules/Checkout/components/CheckoutCreditCheck/CheckoutCreditCheck';
import { CheckoutHeader } from '@modules/Checkout/components/CheckoutHeader/CheckoutHeader';
import { CheckoutIdCheckForm } from '@modules/Checkout/components/CheckoutIdCheckForm/CheckoutIdCheckForm';
import { CheckoutLoginForm } from '@modules/Checkout/components/CheckoutLoginForm/CheckoutLoginForm';
import { CheckoutMonthlyPaymentForm } from '@modules/Checkout/components/CheckoutMonthlyPaymentForm/CheckoutMonthlyPaymentForm';
import { CheckoutMonthlyPriceSummary } from '@modules/Checkout/components/CheckoutMonthlyPriceSummary/CheckoutMonthlyPriceSummary';
import { CheckoutNotificationWrapper } from '@modules/Checkout/components/CheckoutNotificationWrapper/CheckoutNotificationWrapper';
import { CheckoutOneTimePaymentInputs } from '@modules/Checkout/components/CheckoutOneTimePayment/CheckoutOneTimePaymentInputs';
import { CheckoutOneTimePaymentSummary } from '@modules/Checkout/components/CheckoutOneTimePaymentSummary/CheckoutOneTimePaymentSummary';
import { CheckoutOrder } from '@modules/Checkout/components/CheckoutOrder/CheckoutOrder';
import { CheckoutOrderCard } from '@modules/Checkout/components/CheckoutOrderCard/CheckoutOrderCard';
import { CheckoutOrderCardCarousel } from '@modules/Checkout/components/CheckoutOrderCardCarousel/CheckoutOrderCardCarousel';
import { CheckoutOrders } from '@modules/Checkout/components/CheckoutOrders/CheckoutOrders';
import { CheckoutRegisterForm } from '@modules/Checkout/components/CheckoutRegisterForm/CheckoutRegisterForm';
import { CheckoutScoreInput } from '@modules/Checkout/components/CheckoutScoreInput/CheckoutScoreInput';
import { CheckoutSteps } from '@modules/Checkout/components/CheckoutSteps/CheckoutSteps';
import { CheckoutTextWithLink } from '@modules/Checkout/components/CheckoutTextWithLink/CheckoutTextWithLink';
import { CheckoutTimeSlotBooking } from '@modules/Checkout/components/CheckoutTimeSlotBooking/CheckoutTimeSlotBooking';
import { CheckoutTimeslotBookingTimer } from '@modules/Checkout/components/CheckoutTimeslotBookingTimer/CheckoutTimeslotBookingTimer';
import { CheckoutVerifyPayment } from '@modules/Checkout/components/CheckoutVerifyPayment/CheckoutVerifyPayment';
import { ContactCard } from '@modules/Contact/ContactCard/ContactCard';
import { ContactImage } from '@modules/Contact/ContactImage/ContactImage';
import { ContactIssueInput } from '@modules/Contact/ContactIssueInput/ContactIssueInput';
import { ContactPageContainer } from '@modules/Contact/ContactPageContainer/ContactPageContainer';
import { ContactPersonalDetails } from '@modules/Contact/ContactPersonalDetails/ContactPersonalDetails';
import { ContactQuestionTypes } from '@modules/Contact/ContactQuestionTypes/ContactQuestionTypes';
import { FaqArticle } from '@modules/Faq/FaqArticle/FaqArticle';
import { FaqOverview } from '@modules/Faq/FaqOverview/FaqOverview';
import { CategoriesOverview } from '@modules/PCP/components/CategoriesOverview/CategoriesOverview';
import { CategoryCompareHeader } from '@modules/PCP/components/CategoryCompareHeader/CategoryCompareHeader';
import { CategoryComparing } from '@modules/PCP/components/CategoryComparing/CategoryComparing';
import { OfferCard } from '@modules/PDP/components/OfferCard/OfferCard';
import { OfferCardHeader } from '@modules/PDP/components/OfferCardHeader/OfferCardHeader';
import { PlanInformationTable } from '@modules/PDP/components/PlanInformationTable/PlanInformationTable';
import { SubscriptionCardHeader } from '@modules/PDP/components/SubscriptionCardHeader/SubscriptionCardHeader';
import { CancellationDetailsSubscriptionCard } from '@modules/UserPanel/Cancellation/components/CancellationDetailsSubscriptionCard/CancellationDetailsSubscriptionCard';
import { CancellationPickUpDateForm } from '@modules/UserPanel/Cancellation/components/CancellationPickUpDateForm/CancellationPickUpDateForm';
import { CancellationProceedButton } from '@modules/UserPanel/Cancellation/components/CancellationProceedButton/CancellationProceedButton';
import { CancellationSetReminderButton } from '@modules/UserPanel/Cancellation/components/CancellationSetReminderButton/CancellationSetReminderButton';
import { CancellationSubscriptionCard } from '@modules/UserPanel/Cancellation/components/CancellationSubscriptionCard/CancellationSubscriptionCard';
import { CancellationSummaryText } from '@modules/UserPanel/Cancellation/components/CancellationSummaryText/CancellationSummaryText';
import { ChangeAddressForm } from '@modules/UserPanel/ChangeAddress/components/ChangeAddressForm/ChangeAddressForm';
import { ChangeMarketingOptin } from '@modules/UserPanel/ChangeMarketingOptin/ChangeMarketingOptin';
import { ChangePasswordForm } from '@modules/UserPanel/ChangePassword/ChangePasswordForm';
import { ChangePaymentMethodForm } from '@modules/UserPanel/ChangePaymentMethodForm/ChangePaymentMethodForm';
import { InvoiceList } from '@modules/UserPanel/Invoices/components/InvoiceList/InvoiceList';
import { MovingPlannerChargeNotification } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerChargeNotification/MovingPlannerChargeNotification';
import { MovingPlannerConfirmation } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerConfirmation/MovingPlannerConfirmation';
import { MovingPlannerConfirmationCheckbox } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerConfirmationCheckbox/MovingPlannerConfirmationCheckbox';
import { MovingPlannerMovingDate } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerMovingDate/MovingPlannerMovingDate';
import { MovingPlannerNewAddress } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerNewAddress/MovingPlannerNewAddress';
import { MovingPlannerPhoneNumber } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerPhoneNumber/MovingPlannerPhoneNumber';
import { MovingPlannerProceedButton } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerProceedButton/MovingPlannerProceedButton';
import { MovingPlannerSelectedSubscriptions } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerSelectedSubscriptions/MovingPlannerSelectedSubscriptions';
import { MovingPlannerSubscriptions } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerSubscriptions/MovingPlannerSubscriptions';
import { MovingPlannerSummaryCard } from '@modules/UserPanel/MovingPlanner/components/MovingPlannerSummaryCard/MovingPlannerSummaryCard';
import { AccountCard } from '@modules/UserPanel/Subscriptions/components/AccountCard/AccountCard';
import { PaymentCard } from '@modules/UserPanel/Subscriptions/components/PaymentCard/PaymentCard';
import { UserPanelSubscriptionDetails } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/UserPanelSubscriptionDetails';
import { SubscriptionCardWrapper } from '@modules/UserPanel/Subscriptions/components/SubscriptionCardWrapper/SubscriptionCardWrapper';
import { SubscriptionList } from '@modules/UserPanel/Subscriptions/components/SubscriptionList/SubscriptionList';
import { ContractSwitchBenefits } from '@modules/UserPanel/Switch/components/ContractSwitchBenefits/ContractSwitchBenefits';
import { ContractSwitchConfirmation } from '@modules/UserPanel/Switch/components/ContractSwitchConfirmation/ContractSwitchConfirmation';
import { ContractSwitchDuration } from '@modules/UserPanel/Switch/components/ContractSwitchDuration/ContractSwitchDuration';
import { ContractSwitchProceedButton } from '@modules/UserPanel/Switch/components/ContractSwitchProceesButton/ContractSwitchProceedButton';
import { ContractSwitchSubscriptionCard } from '@modules/UserPanel/Switch/components/ContractSwitchSubscriptionCard/ContractSwitchSubscriptionCard';
import { ContractSwitchSummaryForm } from '@modules/UserPanel/Switch/components/ContractSwitchSummaryForm/ContractSwitchSummaryForm';
import { ContractSwitchSummaryPrice } from '@modules/UserPanel/Switch/components/ContractSwitchSummaryPrice/ContractSwitchSummaryPrice';
import { TakeoverProviderConfirmationCheckbox } from '@modules/UserPanel/Takeover/components/provider/TakeoverProviderConfirmationCheckbox/TakeoverProviderConfirmationCheckbox';
import { TakeoverProviderSummaryCard } from '@modules/UserPanel/Takeover/components/provider/TakeoverProviderSummaryCard/TakeoverProviderSummaryCard';
import { TakeoverSubscriptions } from '@modules/UserPanel/Takeover/components/provider/TakeoverSubscriptions/TakeoverSubscriptions';
import { TakeoverTransferDate } from '@modules/UserPanel/Takeover/components/provider/TakeoverTransferDate/TakeoverTransferDate';
import { TakeoverTransferReceiverDataForm } from '@modules/UserPanel/Takeover/components/provider/TakeoverTransferReceiverDataForm/TakeoverTransferReceiverDataForm';
import { TakeoverIdCheckForm } from '@modules/UserPanel/Takeover/components/receiver/TakeoverIdCheckForm/TakeoverIdCheckForm';
import { TakeoverPaymentForm } from '@modules/UserPanel/Takeover/components/receiver/TakeoverPaymentForm/TakeoverPaymentForm';
import { TakeoverReceiverSummaryCard } from '@modules/UserPanel/Takeover/components/receiver/TakeoverReceiverSummaryCard/TakeoverReceiverSummaryCard';
import { TakeoverTransferReceiverAcceptingForm } from '@modules/UserPanel/Takeover/components/receiver/TakeoverTransferReceiverAcceptingForm/TakeoverTransferReceiverAcceptingForm';
import { CurrentAddress } from '@modules/UserPanel/Takeover/components/shared/CurrentAddress/CurrentAddress';
import { TakeoverCancellationButton } from '@modules/UserPanel/Takeover/components/shared/TakeoverCancellationButton/TakeoverCancellationButton';
import { TakeoverConfirmation } from '@modules/UserPanel/Takeover/components/shared/TakeoverConfirmation/TakeoverConfirmation';
import { TakeoverLoginForm } from '@modules/UserPanel/Takeover/components/shared/TakeoverLoginForm/TakeoverLoginForm';
import { TakeoverProceedButton } from '@modules/UserPanel/Takeover/components/shared/TakeoverProceedButton/TakeoverProceedButton';
import { TakeoverSelectedSubscriptions } from '@modules/UserPanel/Takeover/components/shared/TakeoverSelectedSubscriptions/TakeoverSelectedSubscriptions';
import { NewMandateForm } from '@components/NewMandateForm/NewMandateForm';

import { Anchor } from '../Anchor/Anchor';
import { BlogpostWrapper } from '../BlogpostWrapper/BlogpostWrapper';
import { DeprecatedIconButton } from '../Button/IconButton/IconButton';
import { GlobalReference } from '../GlobalReference';
import { Radio } from '../inputs/Radio/Radio';
import { PaymentLinkInfo } from '../PaymentLinkInfo/PaymentLinkInfo';
import { RefBlock } from '../RefBlock/RefBlock';

import { CardsWrapper } from '@base-components/CardsWrapper/CardsWrapper';
import { CancelSubscriptionCardVariant } from '@variants/CancelSubscriptionCardVariant/CancelSubscriptionCardVariant';
import { VerifyPayment } from '@modules/Checkout/components/CheckoutPaymentForm/VerifyPayment';

export const disabledComponents = ['checkoutSideBox', 'snippet', 'checkoutStepper'];

export const Layouts = {
  emptyLayout: EmptyLayout,
  compactLayout: CompactLayout,
  simpleLayout: SimpleLayout, // deprecated, please don't use in new components, especially when new <Page /> is created, while wroking on pages, this layout can be removed from components and below can be added
  newSimpleLayout: NewSimpleLayout, // use it if <Page /> is allowing it (don't have own margins and width)
  overflowLayout: OverflowLayout,
  fullWidthLayout: FullWidthLayout,
};

const GenericComponents = {
  globalReference: { component: GlobalReference, layout: Layouts.emptyLayout },
  headingGroup: { component: HeadingGroup, layout: Layouts.simpleLayout },
  column: { component: Column, layout: Layouts.emptyLayout },
  columnRow: { component: ColumnRow, layout: Layouts.emptyLayout },
  informationCard: { component: InformationCard, layout: Layouts.emptyLayout },
  uspBar: { component: UspBar, layout: Layouts.simpleLayout },
  label: { component: Label, layout: Layouts.simpleLayout },
  button: { component: StoryblokButton, layout: Layouts.emptyLayout },
  compareHeader: { component: CompareHeader, layout: Layouts.simpleLayout },
  faqArticle: { component: FaqArticle, layout: Layouts.simpleLayout },
  faqGroup: { component: FaqGroup, layout: Layouts.simpleLayout },
  faqGroupItem: { component: FaqGroupItem, layout: Layouts.simpleLayout },
  floatingActionBar: { component: FloatingActionBar, layout: Layouts.simpleLayout },
  imageCarousel: { component: ImageCarousel, layout: Layouts.emptyLayout },
  imageContentBlock: { component: ImageContentBlock, layout: Layouts.simpleLayout },
  informationTable: { component: InformationTable, layout: Layouts.simpleLayout },
  notificationBar: { component: NotificationBar, layout: Layouts.simpleLayout },
  notificationBar2PlusItems: { component: NotificationBar2PlusItems, layout: Layouts.emptyLayout },
  titleBlock: { component: TitleBlock, layout: Layouts.simpleLayout },
  pageHeader: { component: PageHeader, layout: Layouts.simpleLayout },
  header: { component: Header, layout: Layouts.simpleLayout },
  trustPilot: { component: TrustPilot, layout: Layouts.simpleLayout },
  loadbee: { component: Loadbee, layout: Layouts.emptyLayout },
  checkbox: { component: Checkbox, layout: Layouts.emptyLayout },
  checkoutCard: { component: CheckoutCard, layout: Layouts.simpleLayout },
  categoryCard: { component: CategoryCard, layout: Layouts.emptyLayout },
  paymentOptions: { component: PaymentOptions, layout: Layouts.simpleLayout },
  iconButton: { component: DeprecatedIconButton, layout: Layouts.emptyLayout },
  anchor: { component: Anchor, layout: Layouts.emptyLayout },
  hero: { component: Hero, layout: Layouts.emptyLayout },
  snippet: { component: Snippet, layout: Layouts.emptyLayout },
  titleWithText: { component: TitleWithText, layout: Layouts.emptyLayout },
  table: { component: Table, layout: Layouts.emptyLayout },
  radio: { component: Radio, layout: Layouts.emptyLayout },
  image: { component: Image, layout: Layouts.emptyLayout },
  refBlock: { component: RefBlock, layout: Layouts.simpleLayout },
  generalInfo: { component: GeneralInfo, layout: Layouts.simpleLayout },
  videoContainer: { component: VideoPlayer, layout: Layouts.emptyLayout },
  textMap: { component: TextMap, layout: Layouts.emptyLayout },
  textWithLink: { component: TextWithLink, layout: Layouts.emptyLayout },
};

const BlogpostComponents = {
  blogpostHeroImage: { component: BlogpostHeroImage, layout: Layouts.emptyLayout },
  blogpostCategory: { component: BlogpostCategory, layout: Layouts.emptyLayout },
  blogFilter: { component: BlogFilter, layout: Layouts.emptyLayout },
  blogpost: { component: Blogpost, layout: Layouts.emptyLayout },
  blogpostColumn: { component: BlogpostColumn, layout: Layouts.emptyLayout },
  blogpostWrapper: { component: BlogpostWrapper, layout: Layouts.emptyLayout },
  blogpostTightContent: { component: BlogpostTightContent, layout: Layouts.emptyLayout },
};

const CheckoutComponents = {
  checkoutHeader: { component: CheckoutHeader, layout: Layouts.compactLayout },
  checkoutOrder: { component: CheckoutOrder, layout: Layouts.compactLayout },
  checkoutTextWithLink: { component: CheckoutTextWithLink, layout: Layouts.compactLayout },
  checkoutSteps: { component: CheckoutSteps, layout: Layouts.compactLayout },
  checkoutOrders: { component: CheckoutOrders, layout: Layouts.compactLayout },
  checkoutCardsForm: { component: CheckoutCardsForm, layout: Layouts.compactLayout },
  checkoutRegisterForm: { component: CheckoutRegisterForm, layout: Layouts.compactLayout },
  checkoutMonthlyPriceSummary: { component: CheckoutMonthlyPriceSummary, layout: Layouts.compactLayout },
  checkoutOneTimePaymentSummary: { component: CheckoutOneTimePaymentSummary, layout: Layouts.compactLayout },
  checkoutMonthlyPaymentForm: { component: CheckoutMonthlyPaymentForm, layout: Layouts.compactLayout },
  checkoutOneTimePayment: { component: CheckoutOneTimePaymentInputs, layout: Layouts.compactLayout },
  checkoutVerifyPayment: { component: CheckoutVerifyPayment, layout: Layouts.compactLayout },
  checkoutLoginForm: { component: CheckoutLoginForm, layout: Layouts.compactLayout },
  checkoutAddressDetails: { component: CheckoutAddressDetails, layout: Layouts.compactLayout },
  checkoutCouponForm: { component: CheckoutCouponForm, layout: Layouts.compactLayout },
  checkoutContentSheet: { component: CheckoutContentSheet, layout: Layouts.compactLayout },
  checkoutScoreInput: { component: CheckoutScoreInput, layout: Layouts.compactLayout },
  checkoutContactMethods: { component: CheckoutContactMethods, layout: Layouts.compactLayout },
  checkoutIdCheckForm: { component: CheckoutIdCheckForm, layout: Layouts.compactLayout },
  checkoutTimeslotBookingTimer: { component: CheckoutTimeslotBookingTimer, layout: Layouts.compactLayout },
  checkoutOrderCard: { component: CheckoutOrderCard, layout: Layouts.emptyLayout },
  checkoutOrderCardCarousel: { component: CheckoutOrderCardCarousel, layout: Layouts.emptyLayout },
  checkoutBlockedOrderInfo: { component: CheckoutBlockedOrderInfo, layout: Layouts.emptyLayout },
  checkoutTimeSlotBooking: { component: CheckoutTimeSlotBooking, layout: Layouts.compactLayout },
  checkoutAccountCard: { component: CheckoutAccountCard, layout: Layouts.emptyLayout },
  checkoutCartItemsCarousel: { component: CheckoutCartItemsCarousel, layout: Layouts.emptyLayout },
  checkoutCreditCheck: { component: CheckoutCreditCheck, layout: Layouts.emptyLayout },
  checkoutNotificationWrapper: { component: CheckoutNotificationWrapper, layout: Layouts.emptyLayout },
};

const FaqComponents = {
  faqSearch: { component: FaqSearch, layout: Layouts.emptyLayout },
  faqSearchResults: { component: FaqSearchResults, layout: Layouts.simpleLayout },
  faqOverview: { component: FaqOverview, layout: Layouts.simpleLayout },
};

const ContactComponents = {
  contactImage: { component: ContactImage, layout: Layouts.emptyLayout },
  contactPageContainer: { component: ContactPageContainer, layout: Layouts.emptyLayout },
  contactQuestionTypes: { component: ContactQuestionTypes, layout: Layouts.emptyLayout },
  contactIssueInput: { component: ContactIssueInput, layout: Layouts.emptyLayout },
  contactPersonalDetails: { component: ContactPersonalDetails, layout: Layouts.emptyLayout },
  contactCard: { component: ContactCard, layout: Layouts.emptyLayout },
};

const OtherComponents = {
  categoriesOverview: { component: CategoriesOverview, layout: Layouts.emptyLayout },
  offerCard: { component: OfferCard, layout: Layouts.emptyLayout },
  subscriptionCardHeader: { component: SubscriptionCardHeader, layout: Layouts.emptyLayout }, // TODO: BLUEMOVE-5992 remove after release
  offerCardHeader: { component: OfferCardHeader, layout: Layouts.emptyLayout },
  planInformationTable: { component: PlanInformationTable, layout: Layouts.simpleLayout },
  categoryComparing: { component: CategoryComparing, layout: Layouts.simpleLayout },
  productCardColumn: { component: ProductCardColumn, layout: Layouts.simpleLayout },
  productCard: { component: ProductCard, layout: Layouts.emptyLayout },
  productCardOffer: { component: ProductCardOffer, layout: Layouts.emptyLayout },
  categoryCompareHeader: { component: CategoryCompareHeader, layout: Layouts.emptyLayout },
  qualtricsContainer: { component: QualtricsContainer, layout: Layouts.emptyLayout },
  carousel: { component: Carousel, layout: Layouts.emptyLayout },
  teamCard: { component: TeamCard, layout: Layouts.emptyLayout },
  currentAddress: { component: CurrentAddress, layout: Layouts.emptyLayout },
  paymentConfirmation: { component: PaymentConfirmation, layout: Layouts.emptyLayout },
  paymentLinkInfo: { component: PaymentLinkInfo, layout: Layouts.emptyLayout },
  login: { component: Login, layout: Layouts.simpleLayout },
  forgotPassword: { component: ForgotPassword, layout: Layouts.simpleLayout },
  resetPassword: { component: ResetPassword, layout: Layouts.simpleLayout },
  cookiebotScript: { component: CookiebotScript, layout: Layouts.simpleLayout },
  newMandateForm: { component: NewMandateForm, layout: Layouts.newSimpleLayout },
  verifyPayment: { component: VerifyPayment, layout: Layouts.compactLayout },
};

const UserPanelCancellationComponents = {
  cancellationPickUpDateForm: { component: CancellationPickUpDateForm, layout: Layouts.emptyLayout },
  cancellationSubscriptionCard: { component: CancellationSubscriptionCard, layout: Layouts.emptyLayout },
  cancellationProceedButton: { component: CancellationProceedButton, layout: Layouts.emptyLayout },
  cancellationDetailsSubscriptionCard: { component: CancellationDetailsSubscriptionCard, layout: Layouts.emptyLayout },
  cancellationSetReminderButton: { component: CancellationSetReminderButton, layout: Layouts.emptyLayout },
  cancellationSummaryText: { component: CancellationSummaryText, layout: Layouts.emptyLayout },
};

const UserPanelInvoicesComponents = {
  invoiceList: { component: InvoiceList, layout: Layouts.simpleLayout },
};

const UserPanelSubscriptionsComponents = {
  accountCard: { component: AccountCard, layout: Layouts.emptyLayout }, // TODO: probably to rename with prefix UserPanel...
  paymentCard: { component: PaymentCard, layout: Layouts.emptyLayout },
  userPanelSubscriptionCard: { component: UserPanelSubscriptionDetails, layout: Layouts.emptyLayout },
  subscriptionList: { component: SubscriptionList, layout: Layouts.emptyLayout },
  userPanelChangeMarketingOptin: { component: ChangeMarketingOptin, layout: Layouts.emptyLayout },
  userPanelChangeAddressForm: { component: ChangeAddressForm, layout: Layouts.emptyLayout },
  userPanelChangePasswordForm: { component: ChangePasswordForm, layout: Layouts.emptyLayout },
  userPanelChangePaymentMethodForm: { component: ChangePaymentMethodForm, layout: Layouts.emptyLayout },
  userPanelSubscriptionCardWrapper: { component: SubscriptionCardWrapper, layout: Layouts.emptyLayout },
};

const UserPanelContractSwitchComponents = {
  contractSwitchDuration: { component: ContractSwitchDuration, layout: Layouts.compactLayout },
  contractSwitchProceedButton: { component: ContractSwitchProceedButton, layout: Layouts.compactLayout },
  contractSwitchSummaryForm: { component: ContractSwitchSummaryForm, layout: Layouts.compactLayout },
  contractSwitchSummaryPrice: { component: ContractSwitchSummaryPrice, layout: Layouts.compactLayout },
  contractSwitchSubscriptionCard: { component: ContractSwitchSubscriptionCard, layout: Layouts.compactLayout },
  contractSwitchBenefits: { component: ContractSwitchBenefits, layout: Layouts.compactLayout },
  contractSwitchConfirmation: { component: ContractSwitchConfirmation, layout: Layouts.emptyLayout },
};

const UserPanelTakeoverComponents = {
  takeoverProceedButton: { component: TakeoverProceedButton, layout: Layouts.emptyLayout },
  takeoverSubscriptions: { component: TakeoverSubscriptions, layout: Layouts.emptyLayout },
  takeoverTransferDate: { component: TakeoverTransferDate, layout: Layouts.emptyLayout },
  takeoverTransferReceiverDataForm: { component: TakeoverTransferReceiverDataForm, layout: Layouts.emptyLayout },
  takeoverTransferReceiverAcceptingForm: {
    component: TakeoverTransferReceiverAcceptingForm,
    layout: Layouts.emptyLayout,
  },
  takeoverPaymentForm: {
    component: TakeoverPaymentForm,
    layout: Layouts.emptyLayout,
  },
  takeoverSelectedSubscriptions: { component: TakeoverSelectedSubscriptions, layout: Layouts.emptyLayout },
  takeoverReceiverSummaryCard: { component: TakeoverReceiverSummaryCard, layout: Layouts.emptyLayout },
  takeoverProviderSummaryCard: { component: TakeoverProviderSummaryCard, layout: Layouts.emptyLayout },
  takeoverProviderConfirmationCheckbox: {
    component: TakeoverProviderConfirmationCheckbox,
    layout: Layouts.emptyLayout,
  },
  takeoverConfirmation: { component: TakeoverConfirmation, layout: Layouts.emptyLayout },
  takeoverLoginForm: { component: TakeoverLoginForm, layout: Layouts.emptyLayout },
  takeoverCancellationButton: { component: TakeoverCancellationButton, layout: Layouts.emptyLayout },
  takeoverIdCheckForm: { component: TakeoverIdCheckForm, layout: Layouts.emptyLayout },
};

const UserPanelMovingPlannerComponents = {
  movingPlannerSubscriptions: { component: MovingPlannerSubscriptions, layout: Layouts.emptyLayout },
  movingPlannerProceedButton: { component: MovingPlannerProceedButton, layout: Layouts.emptyLayout },
  movingPlannerMovingDate: { component: MovingPlannerMovingDate, layout: Layouts.emptyLayout },
  movingPlannerPhoneNumber: { component: MovingPlannerPhoneNumber, layout: Layouts.emptyLayout },
  movingPlannerNewAddress: { component: MovingPlannerNewAddress, layout: Layouts.emptyLayout },
  movingPlannerSelectedSubscriptions: { component: MovingPlannerSelectedSubscriptions, layout: Layouts.emptyLayout },
  movingPlannerSummaryCard: { component: MovingPlannerSummaryCard, layout: Layouts.emptyLayout },
  movingPlannerChargeNotification: { component: MovingPlannerChargeNotification, layout: Layouts.emptyLayout },
  movingPlannerConfirmationCheckbox: { component: MovingPlannerConfirmationCheckbox, layout: Layouts.emptyLayout },
  movingPlannerConfirmation: { component: MovingPlannerConfirmation, layout: Layouts.emptyLayout },
};

const AggregatedComponents = {
  cardsWrapper: { component: CardsWrapper, layout: Layouts.emptyLayout },
  cancelSubscriptionCardVariant: { component: CancelSubscriptionCardVariant, layout: Layouts.emptyLayout },
};

export const layoutModifier = ({ component, defaultLayout, modifications = {} }) => {
  return modifications[component] || defaultLayout;
};

export const layoutSpacingModifierStyle = ({ blok, contentBody, localModifications = [] }) => {
  return localModifications.reduce((result, modification) => {
    const componentIndex = contentBody.findIndex(b => b.component === modification.if);
    if (componentIndex === -1 || blok.component !== modification.if) {
      return result;
    }

    if (!modification.is || !modification.of) {
      return modification.style;
    }

    const position = modification.is === 'above' ? componentIndex + 1 : componentIndex - 1;

    if (contentBody[position]?.component === modification.of) {
      return modification.style;
    }

    return result;
  }, {});
};

export const Components = {
  ...GenericComponents,
  ...BlogpostComponents,
  ...CheckoutComponents,
  ...FaqComponents,
  ...ContactComponents,
  ...OtherComponents,
  ...UserPanelCancellationComponents,
  ...UserPanelInvoicesComponents,
  ...UserPanelSubscriptionsComponents,
  ...UserPanelTakeoverComponents,
  ...UserPanelMovingPlannerComponents,
  ...UserPanelContractSwitchComponents,
  ...AggregatedComponents,
};
