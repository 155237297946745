import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

const useUserInvoicesQueryId = 'useUserInvoicesQueryId';

export const useUserInvoices = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useQuery(
    useUserInvoicesQueryId,
    async () => {
      const { data } = await httpService.get('/invoice/user');

      return data;
    },
    { enabled: !!jwtToken, refetchOnMount: false, refetchOnWindowFocus: false, retry: 0 }
  );
};
