import React from 'react';

import styles from './FloatingActionBar.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const FloatingActionBar = ({
  buttonText,
  onClickButton,
  applianceNameText,
  subscriptionDurationText,
  pricePerMonthText,
  deliveryFeeText,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.floatingActionBar}>
        <div className={styles.leftSection}>
          <div className={styles.textTop}>{applianceNameText}</div>
          <div className={styles.textBottom}>{subscriptionDurationText}</div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.textContainer}>
            <div className={styles.textTop}>{pricePerMonthText}</div>
            <div className={styles.textBottom}>{deliveryFeeText}</div>
          </div>

          <div className={styles.buttonContainer}>
            <Button as="button" variant={ButtonVariant.Icon} icon="none" onClick={onClickButton}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
