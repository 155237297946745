import React from 'react';

import { Button, ButtonVariant } from '@base-components/Button';
import { Controller } from 'react-hook-form';

import { SwitchStepsNames, useContractSwitchContext } from '../../page/ContractSwitchContext/ContractSwitchContext';

export const ContractSwitchProceedButton = ({ label }) => {
  const { setNextStep, handleSubmit, step } = useContractSwitchContext();

  const stepToAction = {
    [SwitchStepsNames.select]: setNextStep,
    [SwitchStepsNames.check]: handleSubmit,
  };

  return (
    <Controller
      name="contractSwitchProceedButton"
      render={() => {
        return (
          <Button
            as="button"
            isFullWidth
            variant={ButtonVariant.Basic}
            onClick={() => stepToAction[step]()}
            dataAttribute={{ 'data-test-id': 'contract-switch-proceed-button' }}
          >
            {label}
          </Button>
        );
      }}
    />
  );
};
