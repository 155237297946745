import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

import { useLocale } from '@hooks/useLocale';
import { getHrefFromStoryblok } from '@utils/paths/build-paths';

import styles from './MenuItem.module.scss';

interface MenuItemProps {
  label: string;
  link: string;
  pageId: string;
}

export const MenuItem = ({ link, pageId, label }: MenuItemProps) => {
  const { locale } = useLocale();

  return (
    <div>
      <Button
        variant={ButtonVariant.Basic}
        as="anchor"
        theme={ButtonTheme.White}
        href={getHrefFromStoryblok({ locale, link, pageId })}
        dataAttribute={{ 'data-test-id': pageId }}
        className={styles.link}
      >
        {label}
      </Button>
    </div>
  );
};
