import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

import { userQueryId } from './useUser';
import { CART_QUERY_ID } from '../cart/useCart';

import { JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'constants/localStorage';

export const useUserLoginMutation = () => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const [, setCookie] = useCookies([JWT_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY]);

  return useMutation(
    async ({ username, password }) => {
      const response = await httpService.post('/auth/login', { username, password });

      return response.data;
    },
    {
      onSuccess: data => {
        setCookie(JWT_STORAGE_KEY, data.authToken, { path: '/', sameSite: 'strict', secure: true });
        setCookie(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken, {
          path: '/',
          sameSite: 'strict',
          secure: true,
        });
        localStorage.setItem(JWT_STORAGE_KEY, data.authToken);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, data.refreshToken);
        queryClient.refetchQueries([userQueryId]);
        queryClient.invalidateQueries({ queryKey: [CART_QUERY_ID] });
      },
    }
  );
};
