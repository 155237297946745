import React from 'react';

import { useRouter } from 'next/router';

import Loader from '@components/Loader/Loader';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

import styles from './SubscriptionCardWrapper.module.scss';

export const SubscriptionCardWrapper = ({ showItemPriceList }) => {
  const router = useRouter();
  const { data: subscription, isLoading } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });

  if (!subscription || isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <SubscriptionCard
        subscription={subscription}
        noViewButton
        showItemPriceList={showItemPriceList}
        additionalServices={subscription?.additionalServices}
      />
    </div>
  );
};
