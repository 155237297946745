import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';

const filteringOptions: FilteringOptions = (offersData, { installationTypePhrases }) =>
  offersData
    .reduce((accumulatedFilters, currentOffer) => {
      const installationType = currentOffer.storyblokContent?.plan?.content?.installationType;

      if (installationType?.length && !accumulatedFilters.includes(installationTypePhrases[installationType])) {
        accumulatedFilters.push(installationTypePhrases[installationType]);
      }

      return accumulatedFilters;
    }, [])
    .sort((a, b) => a.localeCompare(b));

const filterCondition: ValidationFunction = (selectedFilters, offer, { installationTypePhrases }) =>
  selectedFilters.some(filter => {
    const originalName = Object.keys(installationTypePhrases).find(
      phrase => installationTypePhrases[phrase] === filter
    );

    return offer.storyblokContent?.plan?.content?.installationType?.includes(originalName);
  });

export const installationTypeFilter = filterBuilder(filteringOptions, filterCondition);
