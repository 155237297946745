import React from 'react';

import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';

import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';
import { Image } from '@components/Image/Image';

import styles from './Hero.module.scss';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';

export const Hero = ({
  label,
  text,
  image,
  stickerText,
  theme,
  backgroundImg,
  video,
  imageLeft = false,
  stickerPosition = 'right',
  isFirstLevelHeading,
}) => {
  return (
    <div
      style={{ backgroundImage: backgroundImg?.filename && `url(${backgroundImg.filename})` }}
      className={!backgroundImg?.filename && styles[`contentContainerBackground${upperFirst(theme)}`]}
    >
      <div className={cn(styles.container, { [styles.imageLeft]: imageLeft })}>
        <div className={styles.content}>
          <HeadingGroup label={label} text={text} isFirstLevelHeading={isFirstLevelHeading} alignLeft />
        </div>
        <div className={styles.contentImage}>
          {video && <VideoPlayer video={video[0]} />}
          {image?.filename && <Image alt="hero image" image={image} className={styles.image} lazyLoading={false} />}
          {stickerText && <div className={cn(styles.sticker, styles[stickerPosition])}>{stickerText}</div>}
        </div>
      </div>
    </div>
  );
};
