/* eslint-disable import/no-cycle */
import React, { useState, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { Dropdown } from '@components/inputs/Dropdown/Dropdown';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { RichText } from '@components/RichText/RichText';
import { TestCreditCheck } from '@components/TestCreditCheck/TestCreditCheck';
import { TestIdCheck } from '@components/TestIdCheck/TestIdCheck';
import { useDeAddressValidationMutation } from '@hooks/useAddressValidation';
import { CheckoutCheckErrors } from '@modules/Checkout/components/CheckoutCheckErrors/CheckoutCheckErrors';
import { CheckoutEmailInput } from '@modules/Checkout/components/CheckoutEmailInput/CheckoutEmailInput';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import { existingCustomerValidationSchema, newCustomerValidationSchema, getDefaultValues } from './validation';
import styles from '../CheckoutRegisterForm.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';
import { isTestingEnvironment } from '@helpers/isTestingEnvironment';

export const CheckoutRegisterFormDe = ({
  user,
  cart,
  onSubmit,
  formHeaderText,
  salutationLabelText,
  firstNameLabelText,
  lastNameLabelText,
  birthdateLabelText,
  birthdatePlaceholder,
  phoneNumberOptionalLabelText,
  phoneNumberLabelText,
  phoneNumberPlaceholder,
  phoneNumberHintText,
  streetLabelText,
  houseNumberLabelText,
  postalCodeLabelText,
  postalCodePlaceholder,
  cityLabelText,
  didYouMeanText,
  applyChangesText,
  differentShippingAddressLabelText,
  deliveryInfoLabelText,
  deliveryInfoNoteLabelText,
  bluemovementAccountHeaderText,
  bluemovementAccountHeaderDescriptionText,
  emailLabelText,
  passwordLabelText,
  passwordHintText,
  emailConsentsLabelRichText,
  submitButtonText,
  notificationBar,
  salutationEntries,
  importantInfoTitle,
  importantInfoText,
  errorCreditCheckTitle,
  errorIdCheckTitle,
  isRedirecting,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'CheckoutRegisterFormDe' });
  const [addressSuggestion, setAddressSuggestion] = useState({});
  const [differentShippingAddressSuggestion, setDifferentShippingAddressSuggestion] = useState({});
  const defaultValues = getDefaultValues(user, cart);
  const formOptions = {
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      user ? existingCustomerValidationSchema(validationMessages) : newCustomerValidationSchema(validationMessages)
    ),
    defaultValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };
  const methods = useForm(formOptions);

  const deAddressValidationMutation = useDeAddressValidationMutation();

  const checkAddressDebounce = useCallback(
    debounce(async ({ differentShippingAddress }) => {
      const fieldsNames = {
        postalCode: differentShippingAddress ? 'differentShippingAddress.postalCode' : 'postalCode',
        street: differentShippingAddress ? 'differentShippingAddress.street' : 'street',
        city: differentShippingAddress ? 'differentShippingAddress.city' : 'city',
        houseNumber: differentShippingAddress ? 'differentShippingAddress.houseNumber' : 'houseNumber',
      };

      const postalCode = methods.getValues(fieldsNames.postalCode);
      const street = methods.getValues(fieldsNames.street);
      const city = methods.getValues(fieldsNames.city);
      const houseNumber = methods.getValues(fieldsNames.houseNumber);

      if (postalCode !== '' || street !== '' || city !== '' || houseNumber !== '') {
        try {
          const address = await deAddressValidationMutation.mutateAsync({
            postalCode,
            street,
            city,
            houseNumber,
          });

          if (address) {
            if (differentShippingAddress) {
              setDifferentShippingAddressSuggestion(address);
            } else {
              setAddressSuggestion(address);
            }
          }
        } catch (error) {
          logger.error({ message: 'Error in checkAddressDebounce() in CheckoutRegisterFormDe', params: error });
        }
      }
    }, 1500),
    []
  );

  const checkAddress = () => {
    checkAddressDebounce({ differentShippingAddress: false });
  };

  const checkDifferentShippingAddress = () => {
    checkAddressDebounce({ differentShippingAddress: true });
  };

  const setAddressValues = () => {
    methods.setValue('street', addressSuggestion.street, { shouldTouch: true });
    methods.setValue('city', addressSuggestion.city, { shouldTouch: true });
    methods.setValue('houseNumber', addressSuggestion.houseNumber, { shouldTouch: true });
    methods.setValue('postalCode', addressSuggestion.postalCode, { shouldTouch: true });
  };

  const setDifferentShippingAddressValues = () => {
    methods.setValue('differentShippingAddress.street', differentShippingAddressSuggestion.street, {
      shouldTouch: true,
    });
    methods.setValue('differentShippingAddress.city', differentShippingAddressSuggestion.city, { shouldTouch: true });
    methods.setValue('differentShippingAddress.houseNumber', differentShippingAddressSuggestion.houseNumber, {
      shouldTouch: true,
    });
    methods.setValue('differentShippingAddress.postalCode', differentShippingAddressSuggestion.postalCode, {
      shouldTouch: true,
    });
  };

  const hasUserAnySubscriptions = user?.subscriptions?.length > 0;

  // TODO: ¯\_(ツ)_/¯
  // for some reason handleSubmit doesn't see the email error triggered by setError method
  // so we need to disable the submit button based on the form state error
  const isEmailError = methods.formState.errors?.email;
  // Object keys come from credit check provider

  const CreditCheckErrorLabelText = {
    CONSUMER_ADDR_STREET: streetLabelText,
    CONSUMER_ADDR_STREET_NO: houseNumberLabelText,
  };

  // Object keys come from credit check provider
  const IdCheckErrorLabelText = {
    gender: salutationLabelText,
    dateOfBirth: birthdateLabelText,
    firstName: firstNameLabelText,
    lastName: lastNameLabelText,
  };

  return (
    <div className={styles.container}>
      <div className={styles.checkoutRegisterForm}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit, errors => {
              logger.error({ message: 'Error in handleSubmit() in CheckoutRegisterFormDe', parms: errors });
            })}
            id="checkoutRegisterFormDe"
          >
            <div className={styles.column}>
              <span className={styles.columnText}>{formHeaderText}</span>
            </div>

            <CheckoutCheckErrors
              importantInfoText={importantInfoText}
              importantInfoTitle={importantInfoTitle}
              errorCreditCheckTitle={errorCreditCheckTitle}
              errorIdCheckTitle={errorIdCheckTitle}
              CreditCheckErrorLabelText={CreditCheckErrorLabelText}
              IdCheckErrorLabelText={IdCheckErrorLabelText}
            />

            <div className={styles.column}>
              <Dropdown
                controllerName="salutation"
                labelText={salutationLabelText}
                entries={salutationEntries}
                disabled={hasUserAnySubscriptions}
              />
              <TextInput controllerName="firstName" labelText={firstNameLabelText} disabled={hasUserAnySubscriptions} />
              <TextInput controllerName="lastName" labelText={lastNameLabelText} disabled={hasUserAnySubscriptions} />
              <TextInput
                controllerName="birthdate"
                mask="99-99-9999"
                labelText={birthdateLabelText}
                placeholder={birthdatePlaceholder}
                disabled={hasUserAnySubscriptions}
              />
              <TextInput
                controllerName="phoneNumber"
                labelText={phoneNumberLabelText}
                placeholder={phoneNumberPlaceholder}
                hintText={phoneNumberHintText}
                disabled={hasUserAnySubscriptions}
              />
              <div className={styles.row}>
                <TextInput
                  controllerName="street"
                  labelText={streetLabelText}
                  onBlur={checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
                <TextInput
                  controllerName="houseNumber"
                  labelText={houseNumberLabelText}
                  onBlur={checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
              </div>

              <div className={styles.row}>
                <TextInput
                  controllerName="postalCode"
                  labelText={postalCodeLabelText}
                  placeholder={postalCodePlaceholder}
                  // mask="*****" // temporary disabled - it's not working
                  onBlur={checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
                <TextInput
                  controllerName="city"
                  labelText={cityLabelText}
                  onBlur={checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
              </div>

              {Object.keys(addressSuggestion).length > 0 && (
                <div className={styles.hintContainer}>
                  {didYouMeanText}: {Object.values(addressSuggestion).join(' ')}?
                  <div className={styles.optionButtonContainer}>
                    <span className={styles.optionButton} onClick={setAddressValues}>
                      {applyChangesText}
                    </span>
                  </div>
                </div>
              )}

              <Checkbox
                controllerName="isDifferentShippingAddress"
                labelText={differentShippingAddressLabelText}
                theme={Themes.White}
              >
                <div className={styles.column}>
                  <TextInput
                    controllerName="firstName"
                    labelText={firstNameLabelText}
                    disabled
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.firstName' }}
                  />
                  <TextInput
                    controllerName="lastName"
                    labelText={lastNameLabelText}
                    disabled
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.lastName' }}
                  />
                  <TextInput
                    controllerName="differentShippingAddress.phoneNumber"
                    labelText={phoneNumberOptionalLabelText}
                    placeholder={phoneNumberPlaceholder}
                    hintText={phoneNumberHintText}
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.phoneNumber' }}
                  />

                  <div className={styles.row}>
                    <TextInput
                      controllerName="differentShippingAddress.street"
                      labelText={streetLabelText}
                      onBlur={checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.street' }}
                    />
                    <TextInput
                      controllerName="differentShippingAddress.houseNumber"
                      labelText={houseNumberLabelText}
                      onBlur={checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.houseNumber' }}
                    />
                  </div>

                  <div className={styles.row}>
                    <TextInput
                      controllerName="differentShippingAddress.postalCode"
                      labelText={postalCodeLabelText}
                      placeholder={postalCodePlaceholder}
                      // mask="*****" // temporary disabled - it's not working
                      onBlur={checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.postalCode' }}
                    />
                    <TextInput
                      controllerName="differentShippingAddress.city"
                      labelText={cityLabelText}
                      onBlur={checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.city' }}
                    />
                  </div>

                  {Object.keys(differentShippingAddressSuggestion).length > 0 && (
                    <div className={styles.hintContainer}>
                      {didYouMeanText}: {Object.values(differentShippingAddressSuggestion).join(' ')}?
                      <div className={styles.optionButtonContainer}>
                        <span className={styles.optionButton} onClick={setDifferentShippingAddressValues}>
                          {applyChangesText}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Checkbox>

              <Checkbox controllerName="isDeliveryInfo" labelText={deliveryInfoLabelText} theme={Themes.White}>
                <div className={styles.column}>
                  <TextInput
                    controllerName="deliveryInfo.note"
                    labelText={deliveryInfoNoteLabelText}
                    lengthCounter
                    maxLength={50}
                  />
                </div>
              </Checkbox>
            </div>

            {!user && (
              <>
                <div className={styles.column}>
                  <span className={styles.columnText}>{bluemovementAccountHeaderText}</span>
                  <span className={styles.columnDescriptionText}>{bluemovementAccountHeaderDescriptionText}</span>
                </div>

                <div className={styles.column}>
                  <CheckoutEmailInput
                    controllerName="email"
                    emailLabelText={emailLabelText}
                    emailIsExistErrorText={validationMessages.emailIsExist}
                  />
                  <TextInput
                    controllerName="password"
                    labelText={passwordLabelText}
                    hintText={passwordHintText}
                    password
                  />
                </div>
              </>
            )}

            <div className={styles.column}>
              <Checkbox
                controllerName="emailConsents"
                labelText={<RichText document={emailConsentsLabelRichText} />}
                theme={Themes.White}
              />
              {notificationBar?.map(component => {
                return (
                  <div className={styles.notificationBarWrapper} key={component._uid}>
                    <DynamicComponent {...component} layout="emptyLayout" />
                  </div>
                );
              })}
              {isTestingEnvironment(getRuntimeConfig('ENVIRONMENT_NAME')) && (
                <>
                  <TestCreditCheck />
                  <TestIdCheck />
                </>
              )}

              <Button
                as="button"
                variant={ButtonVariant.Basic}
                type="submit"
                isLoading={methods.formState.isSubmitting || isRedirecting}
                isFullWidth
                disabled={
                  methods.formState.isSubmitting || methods.formState.isValidating || isEmailError || isRedirecting
                }
              >
                {submitButtonText}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
