import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useApplyCoupon, useRemoveCoupon } from '@hooks/cart';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './PromoCode.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';

const PromoCode = ({ checkText, text, couponErrorMessages, className }) => {
  const { logger } = useLogger({ context: 'PromoCode' });
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const { data: cartDataWithOffer } = useCartWithOffer();
  const cart = cartDataWithOffer;
  const coupon = cart?.coupon;

  const removeCouponMutation = useRemoveCoupon();
  const applyCouponMutation = useApplyCoupon();

  const formOptions = {
    defaultValues: {
      isPromoCode: !!coupon?.code,
      promoCode: { code: cart?.couponCode?.code || coupon?.code || '' },
    },
    resolver: yupResolver(
      Yup.object().shape({
        isPromoCode: Yup.boolean(),
        promoCode: Yup.object().when('isPromoCode', {
          is: true,
          then: Yup.object().shape({
            code: Yup.string(),
          }),
        }),
      })
    ),
  };

  const methods = useForm(formOptions);

  const addPromoCode = async couponCode => {
    try {
      await applyCouponMutation.mutateAsync({ couponCode });
      methods.clearErrors('promoCode.code');
    } catch (error) {
      const errorMessage = couponErrorMessages[error.data?.errors[0]?.code];
      const genericErrorMessage = errorPhrases?.['generic-error']?.value;

      if (errorMessage || genericErrorMessage) {
        methods.setError('promoCode.code', { message: errorMessage || genericErrorMessage });
      }
    }
  };

  const checkPromoCode = () => {
    const promoCode = methods.getValues('promoCode.code');

    addPromoCode(promoCode);
  };

  const removePromoCode = async () => {
    try {
      await removeCouponMutation.mutateAsync();
    } catch (error) {
      const errorMessage = couponErrorMessages[error.data?.errors[0]?.code];
      const genericErrorMessage = errorPhrases?.['generic-error']?.value;

      if (errorMessage || genericErrorMessage) {
        methods.setError('promoCode.code', {
          message: errorMessage || genericErrorMessage,
        });
      }
    }
  };

  const deleteCoupon = () => {
    removePromoCode();
    methods.setValue('promoCode.code', '');
  };

  const isPromoCode = methods.getValues('isPromoCode');

  const onSubmit = async data => {
    addPromoCode(data?.promoCode?.code);
  };

  useEffect(() => {
    const checkTheCoupon = async () => {
      if (isPromoCode) {
        await removePromoCode();
        checkPromoCode();
      }
    };

    checkTheCoupon();
  }, []);

  useEffect(() => {
    methods.clearErrors('promoCode.code');
    if (coupon?.code) {
      methods.setValue('isPromoCode', !!coupon?.code);
      methods.setValue('promoCode.code', coupon?.code);
    }
  }, [coupon]);

  return (
    <div className={cn(styles.container, className, styles.containerV2)}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, errors =>
            logger.error({ message: 'Error in onSubmit in PromoCode', params: errors })
          )}
          className={styles.promoCodeForm}
        >
          <Checkbox
            controllerName="isPromoCode"
            labelText={text}
            theme={Themes.WhiteNoShadow}
            onClick={isChecked => !isChecked && removePromoCode()}
          >
            <div className={styles.checkboxContainer}>
              <TextInput controllerName="promoCode.code" onBlur={checkPromoCode} disabled={coupon} />

              {!coupon && (
                <div className={styles.buttonContainer}>
                  <Button
                    as="button"
                    className={styles.button}
                    variant={ButtonVariant.Basic}
                    type="submit"
                    isFullWidth
                    isLoading={methods.formState.isSubmitting}
                  >
                    {checkText}
                  </Button>
                </div>
              )}

              {coupon && (
                <Button
                  as="button"
                  variant={ButtonVariant.Icon}
                  theme={ButtonTheme.RawOnLight}
                  icon="trash"
                  size="tiny"
                  className={styles.removeButton}
                  onClick={deleteCoupon}
                />
              )}
            </div>
          </Checkbox>
        </form>
      </FormProvider>
    </div>
  );
};

export default PromoCode;
