const defaultContactSpots = {
  monday: ['morning', 'afternoon'],
  tuesday: ['morning', 'afternoon'],
  wednesday: ['morning', 'afternoon'],
  thursday: ['morning', 'afternoon'],
  friday: ['morning', 'afternoon'],
};

module.exports = {
  defaultContactSpots,
};
