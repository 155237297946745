import React from 'react';

import cn from 'classnames';

import { Image } from '@components/Image/Image';
import Loader from '@components/Loader/Loader';
import { RichText } from '@components/RichText/RichText';
import { useUser } from '@hooks/user/useUser';

import styles from './CheckoutAccountCard.module.scss';

export const CheckoutAccountCard = ({
  locationIcon,
  userIcon,
  headerIcon,
  headerText,
  currentAddressText,
  noIcons = false,
  className,
}) => {
  const { data: user, isLoading } = useUser();

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const { billingAddress, firstName, lastName, email } = user;

  return (
    <>
      {headerText && (
        <div className={styles.titleContainer}>
          <div className={styles.iconContainer}>
            <Image image={headerIcon} mobileImage={headerIcon} />
          </div>
          <RichText document={headerText} contentVariables={{ firstName }} className={styles.title} />
        </div>
      )}
      <div className={cn(styles.container, className)}>
        <div className={styles.informationContainer}>
          {!noIcons && (
            <div className={styles.iconContainer}>
              <Image image={userIcon} mobileImage={userIcon} />
            </div>
          )}
          <div className={styles.contentContainer}>
            <span data-test-id="fullName" className={styles.bold}>{`${firstName} ${lastName}`}</span>
            <span data-test-id="email" className={cn(styles.underline, styles.emailEllipsis)}>
              {email}
            </span>
          </div>
        </div>
        <div className={styles.informationContainer}>
          {!noIcons && (
            <div className={cn(styles.iconContainer, styles.iconLocationContainer)}>
              <Image image={locationIcon} mobileImage={locationIcon} />
            </div>
          )}

          <div className={styles.addressContainer}>
            <div className={styles.contentContainer}>
              <span className={styles.bold}>{currentAddressText}</span>

              <span>
                <span data-test-id="street">{billingAddress.street}</span>{' '}
                <span data-test-id="houseNumber">{` ${billingAddress.houseNumber}`}</span>
                {billingAddress.houseNumberAddon && (
                  <span data-test-id="houseNumberAddon">{`/${billingAddress.houseNumberAddon}`} </span>
                )}
              </span>
              <span>
                <span data-test-id="postalCode">{billingAddress.postalCode}</span>{' '}
                <span data-test-id="city">{billingAddress.city}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
