import React from 'react';

import { useCart } from '@hooks/cart';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import { CheckoutOneTimePaymentSummaryForOfferDe } from './CheckoutOneTimePaymentSummaryForOfferDe';
import styles from '../CheckoutOneTimePayment.module.scss';

export const CheckoutOneTimePaymentSummaryDE = ({ summaryDescription }) => {
  const { data: cart } = useCart();
  const { oneTimeFee } = usePhrases({ name: 'general' });
  const { currencyFormatter } = useCurrencyFormatter();

  if (!cart) {
    return false;
  }

  const isOfferIncluded = cart?.items?.every(item => item.offerItem);

  if (isOfferIncluded) {
    return <CheckoutOneTimePaymentSummaryForOfferDe summaryDescription={summaryDescription} />;
  }

  const rows = cart.items.map(item => {
    return {
      description: `${oneTimeFee} ${item?.content?.name}`,
      value: currencyFormatter({ price: item?.plan.depositAmount }),
      dataAttribute: { 'data-otc-deposit': currencyFormatter({ price: item?.plan.depositAmount }) },
    };
  });

  return (
    <div className={styles.container}>
      {rows?.map((row, index) => {
        return (
          <React.Fragment key={row.category}>
            <div className={styles.row}>
              <div className={styles.descriptionText}>{row.description}</div>
              <div className={styles.descriptionText} {...row.dataAttribute}>
                {row.value}
              </div>
            </div>
            {index !== rows.length - 1 && <hr className={styles.rowLine} />}
          </React.Fragment>
        );
      })}

      <div className={styles.row}>
        <div className={styles.summaryDescriptionText}>{summaryDescription}</div>
        <div className={styles.summaryDescriptionText}>{currencyFormatter({ price: cart.totalDepositPrice })}</div>
      </div>
    </div>
  );
};
