import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';
import { EnergyClassOrder } from 'constants/energyClassOrder';

const FEATURE_NAME = 'energyClass';

const filteringOptions: FilteringOptions = offersData => {
  return offersData
    .reduce((accumulatedEnergyClasses, currentOffer) => {
      const { plan } = currentOffer.storyblokContent;
      const energyClassFeature = plan?.content?.features?.find(feature => feature.key === FEATURE_NAME);
      const energyClassValue = energyClassFeature?.value;

      if (
        !!energyClassValue &&
        !accumulatedEnergyClasses.includes(energyClassValue) &&
        plan?.content?.featuresToDisplay.includes(FEATURE_NAME)
      ) {
        const index = accumulatedEnergyClasses.findIndex(
          accumulatedEnergyClass => EnergyClassOrder[accumulatedEnergyClass] > EnergyClassOrder[energyClassValue]
        );
        if (index === -1) {
          accumulatedEnergyClasses.push(energyClassValue);
        } else {
          accumulatedEnergyClasses.splice(index, 0, energyClassValue);
        }
      }

      return accumulatedEnergyClasses;
    }, [])
    .map(energyClass => energyClass.toUpperCase());
};

const filterCondition: ValidationFunction = (selectedFilters, offer) => {
  return selectedFilters.some(energyClass => {
    const { plan } = offer.storyblokContent;
    const energyClassFeature = plan?.content?.features?.find(feature => feature.key === FEATURE_NAME);
    const energyClassValue = energyClassFeature?.value?.toUpperCase();

    return energyClassValue === energyClass && plan?.content?.featuresToDisplay.includes(FEATURE_NAME);
  });
};

export const energyClassFilter = filterBuilder(filteringOptions, filterCondition);
