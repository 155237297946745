import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useContactFormMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ formData }) => {
    const { data } = await httpService.post('/help', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  });
};
