import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';

import { useCartContext } from '@context/cart';
import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';

import { useCartId, CART_STORAGE_KEY } from './useCartId';
import { CART_QUERY_ID } from './useCartWithOffer';

import { sendAddToCartEvent } from '@services/gtm/cart';
import { CartResponse } from 'types/cart.types';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export interface CreateCartItemDto {
  planId?: string; //TODO: remove it required after switch to offerItem
  pricingId: string;
  offerItemId?: string; //TODO: Make it required after switch to offerItem
  setId?: string;
}

export const useAddToCartWithOfferMutation = () => {
  const { logger } = useLogger({ context: 'useAddToCartMutation' });
  const [, setCookie] = useCookies([CART_STORAGE_KEY]);
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();
  const { data: user } = useUser();
  const { openCart, setShowItemAdded } = useCartContext();
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useMutation<CartResponse, Error, CreateCartItemDto[]>(
    async addToCartItemsDto => {
      const url = `/cart/${cartId}/add-items`;

      const { data } = await httpService.post(url, addToCartItemsDto);

      return data;
    },
    {
      onSuccess: (cartResponse, addToCartItemsDto = []) => {
        const addedItems = addToCartItemsDto.map(item => {
          const addedFullItem = cartResponse.cart.items.find(
            it => it.offerItem?.id === item.offerItemId && it.pricing.id === item.pricingId
          );

          return addedFullItem || item;
        });

        sendAddToCartEvent(plans, addedItems);

        queryClient.invalidateQueries({ queryKey: CART_QUERY_ID });
        setCookie(CART_STORAGE_KEY, cartResponse.cart.id, { path: '/', sameSite: 'strict', secure: true });
        setShowItemAdded(true);
        openCart();

        logger.info({
          message: 'Items are added to cart',
          params: {
            userId: user?.id,
            cartId: cartResponse.cart.id,
            addedItems,
            allCartItems: cartResponse.cart?.items,
          },
        });
      },
    }
  );
};
