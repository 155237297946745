import React from 'react';

import Loader from '@components/Loader/Loader';

import styles from './ChangeAddressForm.module.scss';
import { ChangeAddressFormDe } from './ChangeAddressFormDe/ChangeAddressFormDe';
import { ChangeAddressFormNl } from './ChangeAddressFormNl/ChangeAddressFormNl';

import { useUser } from '@hooks/user/useUser';

const CountryForm = {
  nl: ChangeAddressFormNl,
  de: ChangeAddressFormDe,
};

export const ChangeAddressForm = props => {
  const { data: user } = useUser();

  if (!user) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }
  const CountryChangeAddressForm = CountryForm[user.country];

  return <CountryChangeAddressForm {...props} />;
};
