import React from 'react';

import styles from './NoResults.module.scss';

import { RichText } from '@components/RichText/RichText';

export const NoResults = ({ searchParams, noResultsFoundText, noResultsFoundDescription }) => (
  <div className={styles.container}>
    <RichText contentVariables={{ searchParams }} document={noResultsFoundText} className={styles.title} />
    <RichText document={noResultsFoundDescription} className={styles.description} />
  </div>
);
