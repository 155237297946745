import { Button, ButtonTheme, ButtonVariant } from '@base-components/Button';

import styles from './BackToPreviousMenu.module.scss';
import { AnimatedListElement } from '../AnimatedListElement';
import { LinkSeparator } from './LinkSeparator';

export const BackToPreviousMenuButton = ({ title, slideFrom, onClick }) => (
  <>
    <AnimatedListElement slideFrom={slideFrom}>
      <Button
        variant={ButtonVariant.Icon}
        as="button"
        arrowPosition="none"
        theme={ButtonTheme.White}
        icon="arrowLeft"
        onClick={onClick}
        className={styles.backButton}
        isFullWidth
      >
        {title}
      </Button>
    </AnimatedListElement>
    <LinkSeparator />
  </>
);
