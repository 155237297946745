import React from 'react';

import { sortPricingsByVariant } from '@helpers/sort-pricings-by-variant';
import { OfferItemOneTimeRecurringPricing } from 'types/offer.types';

import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from './RentalOffer.module.scss';
import { InfoBox } from '../InfoBox/InfoBox';

export const RentalOffer = ({ mainItem }) => {
  const { renting: rentingText } = usePhrases({ name: 'offer-types' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const { from, oneTimeFee } = usePhrases({ name: 'general' });
  const { currencyFormatter } = useCurrencyFormatter();
  const rentingOneTimeFeeText = `+${currencyFormatter({
    price: Math.min(...mainItem.pricings.map((pricing: OfferItemOneTimeRecurringPricing) => pricing.oneTimeAmount)),
  })} ${oneTimeFee}`;
  const theLowestRentingPrice = sortPricingsByVariant(mainItem.pricings).sort(
    (a, b) => a.recurringAmount - b.recurringAmount
  )?.[0]?.recurringAmount;

  return (
    <div className={styles.content}>
      <InfoBox
        from={from}
        monthUnit={unitsTranslations?.month?.value}
        pricingRanges={[
          {
            key: rentingText,
            condition: rentingText,
            minValue: theLowestRentingPrice,
          },
        ]}
      />

      <div className={styles.content}>
        <div className={styles.deliveryContainer}>{rentingOneTimeFeeText}</div>
      </div>
    </div>
  );
};
