import { useCart } from '@hooks/cart';
import { usePageInitializationEvent } from '@hooks/gtm/usePageInitializationEvent';

import { sendCartContentEvent } from '@services/gtm/cart';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export const GtmTriggerComponent = () => {
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });
  useCart({
    onSuccess: cart => {
      sendCartContentEvent(cart || [], plans);
    },
  });

  usePageInitializationEvent();

  return null;
};
