import React from 'react';

import { Link } from '@components/Link/Link';

import styles from './InfoBar.module.scss';

export const InfoBar = ({ infoBarTitle, infoBarLink }) => {
  if (!infoBarTitle) {
    return null;
  }

  return (
    <div id="infoBar" className={styles.infoBarContainer}>
      <Link {...infoBarLink?.[0]} className={styles.infoBarLink}>
        {infoBarTitle}
      </Link>
    </div>
  );
};
