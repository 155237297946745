import React from 'react';

import { times } from 'lodash';

import styles from './CheckoutScoreInput.module.scss';

const ScoreButton = ({ text, onClick }) => {
  return (
    <button type="button" className={styles.scoreButton} onClick={onClick}>
      <>{text}</>
    </button>
  );
};

export const CheckoutScoreInput = ({ title, description, lowestScoreText, highestScoreText, maxScore = 10 }) => {
  const handleSendScore = score => {
    console.log(score); // TODO: implement score sending function here
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.scoreInputContainer}>
        {times(maxScore, index => {
          return (
            <ScoreButton
              key={index}
              text={index + 1}
              onClick={() => {
                handleSendScore(index + 1);
              }}
            />
          );
        })}
      </div>
      <div className={styles.scoreDescription}>
        <span className={styles.scoreDescriptionText}>{lowestScoreText}</span>
        <span className={styles.scoreDescriptionText}>{highestScoreText}</span>
      </div>
    </div>
  );
};
