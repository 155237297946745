import React from 'react';

import styles from './CompareHeader.module.scss';
import { Image } from '../Image/Image';

export const CompareHeader = ({ items, onItemClick }) => {
  const handleItemClick = slug => {
    if (onItemClick) {
      onItemClick(slug);
    }
  };

  return (
    <div className={styles.container}>
      {items.map(({ slug, label, image, text, buttonLabel }) => {
        return (
          <div key={slug} className={styles.item}>
            <Image image={image} alt={label} className={styles.image} />
            <div className={styles.content}>
              <div className={styles.label}>{label}</div>
              <div className={styles.text}>{text}</div>
            </div>
            <button type="button" className={styles.button} onClick={() => handleItemClick(slug)}>
              {buttonLabel}
            </button>
          </div>
        );
      })}
    </div>
  );
};
