import { useMutation, useQueryClient } from 'react-query';

import { useCartId } from '@hooks/cart/useCartId';
import { CART_QUERY_ID } from '@hooks/cart/useCartWithOffer';
import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';

import { CartItem } from 'types/cart.types';

export const useUpdateCartItemsWithOffer = () => {
  const { logger } = useLogger({ context: 'useUpdateCartItems' });
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();

  return useMutation(
    async ({ items }: { items: CartItem[] }) => {
      const { data } = await httpService.patch(`/cart/${cartId}/items`, items);

      return data;
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(CART_QUERY_ID, { ...data.cart, sendGtmEvent: false });

        logger.info({
          message: 'Items are updated in cart',
          params: { cartId: data.cart.id, allCartItems: data.cart.items },
        });
      },
    }
  );
};
