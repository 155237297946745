import React from 'react';

import { PaymentOptions } from '@components/PaymentOptions/PaymentOptions';
import { MandatoryAdditionalItems } from '@modules/PDP/components/AdditionalServices/MandatoryAdditionalItems';
import { OptionalAdditionalItems } from '@modules/PDP/components/AdditionalServices/OptionalAdditionalItems';
import { OfferCardConditions } from '@modules/PDP/components/OfferCardConditions/OfferCardConditions';
import { OfferCardPricings } from '@modules/PDP/components/OfferCardPricings/OfferCardPricings';
import OutOfStockForm from '@modules/PDP/components/OutOfStockForm/OutOfStockForm';

import styles from './PaymentPlanRentingBox.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { PaymentPlan } from 'types/frontend-financial-product.types';

export const PaymentPlanRentingBox = ({
  readMoreText,
  conditionText,
  conditionsLink,
  currencyFormatter,
  groupedPricings,
  selectedMainProductPricing,
  setSelectedMainProductPricing,
  durationText,
  durationsLink,
  mandatoryAdditionalItems,
  mandatoryAdditionalServices,
  oneTimeText,
  month,
  selectAdditionalServicePricing,
  selectedAdditionalServicesPricings,
  optionalAdditionalItems,
  optionalAdditionalServices,
  oneTimeFee,
  additionalServicesOneTimeSum,
  monthlyFeeText,
  additionalServicesRecurringSum,
  cartId,
  handleAddToCart,
  addToCartText,
  mainItem,
  isDemandTestCategory,
  paymentOptions,
  selectedPaymentPlan,
  financingLink,
  setSelectedPaymentPlan,
}) => {
  return (
    <div className={styles.offerCards}>
      <OfferCardConditions
        readMoreText={readMoreText}
        conditionText={conditionText}
        conditionsLink={conditionsLink}
        currencyFormatter={currencyFormatter}
        groupedPricings={groupedPricings}
        selectedMainProductPricing={selectedMainProductPricing}
        setSelectedMainProductPricing={setSelectedMainProductPricing}
        setSelectedPaymentPlan={setSelectedPaymentPlan}
        paymentPlan={PaymentPlan.Renting}
      />

      <OfferCardPricings
        durationText={durationText}
        durationsLink={durationsLink}
        readMoreText={readMoreText}
        groupedPricings={groupedPricings}
        selectedMainProductPricing={selectedMainProductPricing}
        setSelectedMainProductPricing={setSelectedMainProductPricing}
        selectedPaymentPlan={selectedPaymentPlan}
        financingLink={financingLink}
      />

      <MandatoryAdditionalItems
        selectedMainProductPricing={selectedMainProductPricing}
        additionalItems={mandatoryAdditionalItems}
        readMore={mandatoryAdditionalServices?.readMoreText}
        additionalServicesLink={mandatoryAdditionalServices?.descriptionLink}
        additionalServicesSheetText={mandatoryAdditionalServices?.label}
        oneTime={oneTimeText}
        perMonth={month}
        additionalServicesText={mandatoryAdditionalServices?.label}
      />

      <OptionalAdditionalItems
        selectAdditionalServicePricing={selectAdditionalServicePricing}
        selectedAdditionalServicesPricings={selectedAdditionalServicesPricings}
        selectedMainProductPricing={selectedMainProductPricing}
        additionalItems={optionalAdditionalItems}
        readMore={optionalAdditionalServices?.readMoreText}
        additionalServicesLink={optionalAdditionalServices?.descriptionLink}
        additionalServicesSheetText={optionalAdditionalServices?.label}
        oneTime={oneTimeText}
        perMonth={month}
        additionalServicesText={optionalAdditionalServices?.label}
      />

      <div className={styles.pricesContainer}>
        <div className={styles.deliveryFeeContainer}>
          <span className={styles.deliveryFeeContainerText}>{oneTimeFee}</span>

          <span
            className={styles.deliveryFeeContainerValue}
            data-deposit-amount={selectedMainProductPricing.oneTimeAmount}
          >
            {currencyFormatter({ price: selectedMainProductPricing.oneTimeAmount + additionalServicesOneTimeSum })}
          </span>
        </div>

        <div className={styles.monthlyFeeContainer}>
          <span className={styles.monthlyFeeContainerText}>{monthlyFeeText}</span>
          <span
            className={styles.monthlyFeeContainerValue}
            data-monthly-price={selectedMainProductPricing?.recurringAmount}
          >
            {currencyFormatter({
              price: selectedMainProductPricing?.recurringAmount + additionalServicesRecurringSum,
            }) || '-'}
          </span>
        </div>
      </div>

      <footer className={styles.footer}>
        {selectedMainProductPricing.isAvailable ? (
          <Button
            isFullWidth
            as="button"
            variant={ButtonVariant.Basic}
            disabled={!selectedMainProductPricing || !cartId}
            onClick={handleAddToCart}
            dataAttribute={{ 'data-cta-text': addToCartText, 'data-cta-position': 'pdp-add-to-cart' }}
          >
            {addToCartText}
          </Button>
        ) : (
          <OutOfStockForm
            vib={mainItem.contentSlug}
            className={styles.outOfStockForm}
            additionalServices={{}}
            isDemandTestCategory={isDemandTestCategory}
            url=""
          />
        )}

        <div className={styles.paymentsContainer}>
          <PaymentOptions options={paymentOptions} />
        </div>
      </footer>
    </div>
  );
};
