import { useMutation, useQueryClient } from 'react-query';

import { userQueryId } from '../user/useUser';

import { useHttpService } from '@hooks/useHttpService';

export const useUserPanelUpdateAddressMutation = () => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();

  return useMutation(
    async values => {
      const response = await httpService.put('/user', values);

      return response;
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([userQueryId]);
      },
    }
  );
};
