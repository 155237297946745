import { usePhrases } from './context/usePhrases';

export const useDeliveryTime = () => {
  const deliveryTimePhrases = usePhrases({ name: 'deliverytime', rawObject: true });

  const getDeliveryTime = cartItem => {
    const cartItemVariant =
      cartItem?.plan?.category?.groupType.toLowerCase() || cartItem?.offerItem?.groupType.toLowerCase();

    return deliveryTimePhrases?.[cartItemVariant]?.value || '';
  };

  return { getDeliveryTime };
};
