/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { useState, createContext, useEffect, useContext } from 'react';

import * as dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { useLogger } from '@hooks/useLogger';

import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useLeavePrevention } from '@hooks/useLeavePrevention';
import { useMovingPlannerMutation } from '@hooks/user-panel/useMovingPlannerMutation';

import { generateFormOptionsForStep } from './helpers';
import {
  getValidationSchema as getConfirmationCheckValidationSchema,
  getDefaultValues as getConfirmationCheckDefaultValues,
} from '../../components/MovingPlannerConfirmationCheckbox/validation';
import {
  getValidationSchema as getMovingDateValidationSchema,
  getDefaultValues as getMovingDateDefaultValues,
} from '../../components/MovingPlannerMovingDate/validation';
import {
  getValidationSchema as getNewAddressValidationSchema,
  getDefaultValues as getNewAddressDefaultValues,
} from '../../components/MovingPlannerNewAddress/validation';
import {
  getValidationSchema as getPhoneNumberValidationSchema,
  getDefaultValues as getPhoneNumberDefaultValues,
} from '../../components/MovingPlannerPhoneNumber/validation';
import {
  getValidationSchema as getSubscriptionsValidationSchema,
  getDefaultValues as getSubscriptionsDefaultValues,
} from '../../components/MovingPlannerSubscriptions/validation';

import { getStepName } from 'helpers/get-step-name';
import { scrollToTop } from 'helpers/scroll-to-top';

export const MovingPlannerStepContext = createContext();

dayjs.extend(minMax);

export const MovingPlannerStepsNames = {
  movingPlannerSelect: 'movingPlannerSelect',
  movingPlannerDetails: 'movingPlannerDetails',
  movingPlannerCheck: 'movingPlannerCheck',
  movingPlannerConfirmation: 'movingPlannerConfirmation',
};

export const MovingPlannerSteps = {
  movingPlannerSelect: {
    name: MovingPlannerStepsNames.movingPlannerSelect,
    validations: [getSubscriptionsValidationSchema],
    defaultValues: [getSubscriptionsDefaultValues],
    stepOrder: 1,
  },
  movingPlannerDetails: {
    name: MovingPlannerStepsNames.movingPlannerDetails,
    validations: [getPhoneNumberValidationSchema, getNewAddressValidationSchema, getMovingDateValidationSchema],
    defaultValues: [getPhoneNumberDefaultValues, getNewAddressDefaultValues, getMovingDateDefaultValues],
    stepOrder: 2,
  },
  movingPlannerCheck: {
    name: MovingPlannerStepsNames.movingPlannerCheck,
    validations: [getConfirmationCheckValidationSchema],
    defaultValues: [getConfirmationCheckDefaultValues],
    stepOrder: 3,
  },
  movingPlannerConfirmation: {
    name: MovingPlannerStepsNames.movingPlannerConfirmation,
    validations: [],
    defaultValues: [],
    stepOrder: 4,
  },
};

const disabledLeavePrevention = [MovingPlannerStepsNames.movingPlannerConfirmation];

const FIRST_STEP_ORDER = 1;

export const MovingPlannerFormContext = ({ validationMessages, subscriptions, user, children }) => {
  const { logger } = useLogger({ context: 'MovingPlannerFormContext' });
  const [stepNumber, setStepNumber] = useState(FIRST_STEP_ORDER);
  const [newestSubscriptionDate, setNewestSubscriptionDate] = useState();
  const availableSteps = MovingPlannerSteps;
  const currentStepName = getStepName(availableSteps, stepNumber);
  const useMovingPlanner = useMovingPlannerMutation();

  const subscriptionIds = subscriptions
    ?.filter(subscription => subscription.status === 'active')
    .map(subscription => subscription.id);

  const { watch, handleSubmit, ...methods } = useForm(
    generateFormOptionsForStep({
      steps: availableSteps,
      currentStep: availableSteps[currentStepName],
      subscriptionIds,
      newestSubscriptionDate,
      validationMessages,
    })
  );

  useEffect(() => {
    const subscription = watch(value => {
      const selectedSubscriptionsIds = value.items.filter(s => s.subscriptionToTakeover).map(s => s.id);

      const subscriptionDate = new Date(
        Math.max(
          ...subscriptions
            .filter(s => selectedSubscriptionsIds.includes(s.id))
            .map(s => new Date(s.subscriptionActivationDate))
        )
      );

      setNewestSubscriptionDate(subscriptionDate);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    scrollToTop();
  }, [stepNumber]);

  useLeavePrevention({
    isUnsafeClose: !disabledLeavePrevention.includes(currentStepName),
    isDisabled: disabledLeavePrevention.includes(currentStepName),
  });

  const setNextStep = () => {
    const [lastStep] = Object.values(MovingPlannerSteps).sort((a, b) => b.stepOrder - a.stepOrder);
    if (stepNumber === lastStep.stepOrder) {
      return;
    }
    setStepNumber(prev => prev + 1);
  };

  const submitHandler = async data => {
    const movingPlannerDto = {
      country: user.country,
      newAddressCity: data.city,
      newAddressPostalCode: data.postalCode,
      newAddressHousenumber: data.houseNumber,
      newAddressAddition: data.houseNumberAddon || '',
      newAddressStreet: data.street,
      customerBmId: user?.bmId,
      movingDate: data.movingDate,
      phone: data.phoneNumber,
      terms: data.agreedToMovingTerms,
      subscriptionsIds: data.items.filter(item => item.subscriptionToMove).map(item => item.id),
    };

    try {
      await useMovingPlanner.mutateAsync(movingPlannerDto);

      setNextStep();
    } catch (e) {
      toast(e.data.errors[0].message, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <MovingPlannerStepContext.Provider
        value={{
          setNextStep,
          step: currentStepName,
          handleSubmit: handleSubmit(submitHandler, errors =>
            logger.error({ message: 'Error in handleSubmit in MovingPlannerFormContext', params: errors })
          ),
        }}
      >
        {children}
      </MovingPlannerStepContext.Provider>
    </FormProvider>
  );
};

export const useMovingPlannerForm = () => {
  const movingPlannerForm = useContext(MovingPlannerStepContext);

  return movingPlannerForm;
};
