import React from 'react';

import { useRouter } from 'next/router';

import LogoLoader from '@components/LogoLoader/LogoLoader';
import { useSubscriptionAdditionalServices } from '@hooks/additionalServices/useSubscriptionAdditionalServices';
import { useUserSubscriptions } from '@hooks/subscriptions/useUserSubscriptions';

import { SubscriptionCard } from './SubscriptionCard';
import styles from './SubscriptionCard.module.scss';

export const UserPanelSubscriptionDetails = props => {
  const router = useRouter();
  const { data: subscriptions, isLoading } = useUserSubscriptions(router.query.id);
  const { data: additionalServices, isAdditionalServicesLoading } = useSubscriptionAdditionalServices(router.query.id);

  if (isLoading || isAdditionalServicesLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LogoLoader />
      </div>
    );
  }

  return (
    <>
      {subscriptions?.map(subscription => (
        <SubscriptionCard
          key={`user-subscription-${subscription?.id}`}
          subscription={subscription}
          additionalServices={additionalServices}
          {...props}
        />
      ))}
    </>
  );
};
