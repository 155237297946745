/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React from 'react';

import { groupBy } from 'lodash';

import { HeadingGroup, Variants } from '@components/HeadingGroup/HeadingGroup';

import { InvoiceCard } from '../InvoiceCard/InvoiceCard';
import styles from './InvoiceList.module.scss';

import Loader from '@components/Loader/Loader';
import { useUserInvoices } from '@hooks/user-panel/useUserInvoices';

export const InvoiceList = ({
  dateIcon,
  downloadInvoiceText,
  changePaymentPreferenceButtonLabel,
  changePaymentPreferenceButtonIcon,
  invoicePaymentButtonLabel,
  invoicePaymentButtonExpiredLabel,
  paidText = 'Paid',
  unpaidText = 'Unpaid',
}) => {
  const { data, isLoading } = useUserInvoices();

  if (isLoading) {
    return <Loader />;
  }

  // Invoices group based on status:
  // PAID (paidText): paid, voided
  // OPEN (unpaidText): not_paid, payment_due, pending, posted
  const invoices = groupBy(data, i =>
    ['not_paid', 'payment_due', 'pending', 'posted'].includes(i.status) ? 'not_paid' : 'paid'
  );
  invoices?.paid?.sort((a, b) => new Date(b.dateDue) - new Date(a.dateDue));
  invoices?.not_paid?.sort((a, b) => new Date(b.dateDue) - new Date(a.dateDue));

  return (
    <div className={styles.container}>
      {invoices?.not_paid?.length > 0 && (
        <HeadingGroup alignLeft label={unpaidText} variant={Variants.myBluemovementBlackLabel} />
      )}
      {invoices?.not_paid?.map(invoice => (
        <InvoiceCard
          key={`user-invoice-${invoice?.id}`}
          invoice={invoice}
          dateIcon={dateIcon}
          downloadInvoiceText={downloadInvoiceText}
          changePaymentPreferenceButtonIcon={changePaymentPreferenceButtonIcon}
          changePaymentPreferenceButtonLabel={changePaymentPreferenceButtonLabel}
          invoicePaymentButtonLabel={invoicePaymentButtonLabel}
          invoicePaymentButtonExpiredLabel={invoicePaymentButtonExpiredLabel}
          isPaymentCta={invoice?.status === 'not_paid'}
        />
      ))}
      <div className={styles.separator} />
      {invoices?.paid?.length > 0 && (
        <HeadingGroup alignLeft label={paidText} variant={Variants.myBluemovementBlackLabel} />
      )}
      {invoices?.paid?.map(invoice => (
        <InvoiceCard
          key={`user-invoice-${invoice?.id}`}
          invoice={invoice}
          dateIcon={dateIcon}
          downloadInvoiceText={downloadInvoiceText}
          changePaymentPreferenceButtonIcon={changePaymentPreferenceButtonIcon}
          changePaymentPreferenceButtonLabel={changePaymentPreferenceButtonLabel}
          invoicePaymentButtonLabel={invoicePaymentButtonLabel}
          invoicePaymentButtonExpiredLabel={invoicePaymentButtonExpiredLabel}
          isPaymentCta={invoice?.status === 'not_paid'}
        />
      ))}
    </div>
  );
};
