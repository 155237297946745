import React, { useEffect, useState } from 'react';

/* eslint-disable import/no-cycle */

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { Dropdown } from '@components/inputs/Dropdown/Dropdown';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import Loader from '@components/Loader/Loader';
import { TestCreditCheck } from '@components/TestCreditCheck/TestCreditCheck';
import { TestIdCheck } from '@components/TestIdCheck/TestIdCheck';
import { usePhrases } from '@hooks/context/usePhrases';
import { CheckoutEmailInput } from '@modules/Checkout/components/CheckoutEmailInput/CheckoutEmailInput';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import styles from './TakeoverTransferReceiverAcceptingForm.module.scss';
import { useTakeoverCreateReceiver } from '../../../hooks/useTakeoverCreateReceiver';

import { Button, ButtonVariant } from '@base-components/Button';
import { isTestingEnvironment } from '@helpers/isTestingEnvironment';
import { useTakeoverForm } from '@modules/UserPanel/Takeover/page/TakeoverFormContext/TakeoverFormContext';
import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { useUser } from '@hooks/user/useUser';
import { useLogger } from '@hooks/useLogger';

const salutationDefaultEntries = [
  { option: 'Mrs.', value: 'mrs' },
  { option: 'Mr.', value: 'mr' },
];

export const TakeoverTransferReceiverAcceptingForm = ({
  activeTakeover,
  salutationLabelText,
  firstNameLabelText,
  lastNameLabelText,
  birthdateLabelText,
  birthdatePlaceholder,
  phoneNumberLabelText,
  phoneNumberPlaceholder,
  phoneNumberHintText,
  bluemovementAccountHeaderText,
  bluemovementAccountHeaderDescriptionText,
  emailLabelText,
  passwordLabelText,
  passwordHintText,
  emailConsentsLabelText,
  notificationBar,
  submitButtonText,
}) => {
  const { createAndCheckReceiverUser, isUserCreated, isUserLoading, isSubmitting } =
    useTakeoverCreateReceiver(activeTakeover);
  const { data: user } = useUser();
  const { type } = useTakeoverForm();
  const salutationPhrases = usePhrases({ name: 'salutations', rawObject: true });
  const validationMessagesPhrases = usePhrases({ name: 'validation-messages', rawObject: true });
  const salutationPhrasesEntries = Object.entries(salutationPhrases);
  const logout = useUserLogoutMutation();
  const [isDisabled, setDisabled] = useState(false);
  const { logger } = useLogger({ context: 'TakeoverReceiverForm' });
  const salutationEntries =
    salutationPhrasesEntries.length > 0
      ? salutationPhrasesEntries.map(([value, phrase]) => {
          return { option: phrase.value, value };
        })
      : salutationDefaultEntries;

  useEffect(() => {
    if (type !== 'receiver') {
      return;
    }

    if (user && user.id === activeTakeover.originalUser.id) {
      const logoutUser = async () => {
        try {
          setDisabled(true);
          await logout.mutateAsync();
        } catch (error) {
          logger.info({ message: 'Failed to logout user' });
        } finally {
          setDisabled(false);
        }
      };
      logoutUser();
    }
  }, []);

  if (isUserLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.checkoutRegisterForm}>
        <div className={styles.column}>
          <Dropdown
            controllerName="newUserSalutation"
            labelText={salutationLabelText}
            entries={salutationEntries}
            disabled={isUserCreated}
          />
          <TextInput controllerName="newUserFirstName" labelText={firstNameLabelText} disabled={isUserCreated} />
          <TextInput controllerName="newUserLastName" labelText={lastNameLabelText} disabled={isUserCreated} />
          <TextInput
            controllerName="newUserBirthdate"
            mask="99-99-9999"
            labelText={birthdateLabelText}
            placeholder={birthdatePlaceholder}
            disabled={isUserCreated}
          />
          <TextInput
            controllerName="newUserPhoneNumber"
            labelText={phoneNumberLabelText}
            placeholder={phoneNumberPlaceholder}
            hintText={phoneNumberHintText}
            disabled={isUserCreated}
          />
        </div>

        {!isUserCreated && (
          <>
            <div className={styles.column}>
              <span className={styles.columnText}>{bluemovementAccountHeaderText}</span>
              <span className={styles.columnDescriptionText}>{bluemovementAccountHeaderDescriptionText}</span>
            </div>

            <div className={styles.column}>
              <CheckoutEmailInput
                controllerName="newUserEmail"
                emailLabelText={emailLabelText}
                emailIsExistErrorText={validationMessagesPhrases?.emailIsExist?.value}
              />
              <TextInput
                controllerName="newUserPassword"
                labelText={passwordLabelText}
                hintText={passwordHintText}
                password
              />
            </div>
          </>
        )}

        <div className={styles.column}>
          <Checkbox controllerName="emailConsents" labelText={emailConsentsLabelText} theme={Themes.White} />

          {notificationBar?.map(component => {
            return (
              <div className={styles.notificationBarWrapper} key={component._uid}>
                <DynamicComponent {...component} layout="emptyLayout" />
              </div>
            );
          })}

          {isTestingEnvironment(getRuntimeConfig('ENVIRONMENT_NAME')) && (
            <>
              <TestCreditCheck />
              <TestIdCheck />
            </>
          )}
        </div>

        <Button
          as="button"
          isFullWidth
          className={styles.takeoverProceedButton}
          variant={ButtonVariant.Icon}
          icon="none"
          disabled={isSubmitting || isDisabled}
          onClick={createAndCheckReceiverUser}
          dataAttribute={{ 'data-test-id': 'takeover-proceed-button' }}
          isLoading={isSubmitting}
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};
