import { filtersConfig } from '@components/modals/FilteringModal/config';

import { FilteringTranslations } from './useFilteringTranslations';

import { OfferItemWithStoryblokContent } from 'types/offer.types';

type FilterOffersFunction = (
  offers: OfferItemWithStoryblokContent[],
  selectedFilters: {
    [T: string]: string[];
  },
  translations: FilteringTranslations
) => OfferItemWithStoryblokContent[];

export const filterOffers: FilterOffersFunction = (offers, selectedFilters, translations) => {
  return offers.filter(offer => {
    const conditionsMatches = Object.keys(filtersConfig).map(filterSectionName => {
      return (
        !selectedFilters[filterSectionName]?.length ||
        filtersConfig[filterSectionName].validationFunction(selectedFilters[filterSectionName], offer, translations)
      );
    });

    return !conditionsMatches.includes(false);
  });
};
