import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useNotifications } from '@hooks/context/useNotifications';
import { useContent } from '@hooks/useContent';
import { buildCategoryPath } from '@utils/paths/build-paths';

import styles from './NotActiveNotification.module.scss';

import { Locale } from 'types/common.types';

export const NotActiveNotificationForOffer = ({
  locale,
  categorySlug,
  isAvailable,
  testingCategoryTitle = '',
  isDemandTestCategory = false,
}: {
  categorySlug: string;
  locale: Locale;
  isAvailable: boolean;
  testingCategoryTitle?: string;
  isDemandTestCategory?: boolean;
}) => {
  const { data: categories = {} } = useContent({ locale, name: 'categories/' });
  const notifications = useNotifications();

  return (
    !isAvailable &&
    notifications?.[isDemandTestCategory ? 'not-active-vib-for-demand-test' : 'not-active-vib'] && (
      <div className={styles.notificationContainer}>
        <NotificationBar
          {...notifications?.[isDemandTestCategory ? 'not-active-vib-for-demand-test' : 'not-active-vib']}
          contentVariables={{
            categoryName: testingCategoryTitle ? testingCategoryTitle : categories[categorySlug]?.name || '',
            categoryUrl: buildCategoryPath({ locale, category: categorySlug }),
          }}
        />
      </div>
    )
  );
};
