import React from 'react';

import styles from './ShoppingCart.module.scss';

const ShoppingCartOfferWrapper = ({ cartRef, onClose, children }) => {
  return (
    <>
      <div className={styles.container} ref={cartRef}>
        {children}
      </div>
      <div className={styles.backgroundVisible} onClick={onClose} />
    </>
  );
};

export default ShoppingCartOfferWrapper;
