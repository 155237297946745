import React from 'react';

import { DeprecatedIconButton } from '@components/Button/IconButton/IconButton';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useLocale } from '@hooks/useLocale';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './DetailedSubscriptionCard.module.scss';
import { AccountCard } from '../../../AccountCard/AccountCard';
import { filesWithVersionToDownload } from '../../constants/termsPathToDownload';

import { splitLocale } from '@services/locale-config';
import { ContractSwitchStatus } from 'types/contract-switch-status.types';

const backendUrl = getRuntimeConfig('BACKEND_URL');

const getTermsAndConditionPdf = (termsAndConditionsVersion, language) => {
  const rootPath = `${backendUrl}/static`;
  const termsAndConditionsMapping = {
    TC_39: {
      de: '/pdf/agreements/v02/de/Allgmeine_Geschaeftsbedingungen_juni_2021.pdf',
      nl: '/pdf/agreements/v01/nl/Aanvullende_Voorwaarden_BlueMovement_v3_nov2020_NL.pdf',
      en: '/pdf/agreements/v01/en/Aanvullende_Voorwaarden_BlueMovement_v3_nov2020_EN.pdf',
    },
  };

  if (Object.keys(termsAndConditionsMapping).find(tc => tc === termsAndConditionsVersion)) {
    return `${rootPath}/${termsAndConditionsMapping?.[termsAndConditionsVersion]?.[language]}`;
  }

  if (termsAndConditionsVersion?.includes('/pdf/agreements/')) {
    return `${rootPath}${termsAndConditionsVersion}`;
  }

  if (filesWithVersionToDownload[termsAndConditionsVersion]) {
    return `${rootPath}${filesWithVersionToDownload[termsAndConditionsVersion].path}`;
  }

  return `${rootPath}/pdf/${termsAndConditionsVersion}`;
};

export const DetailedSubscriptionCard = ({
  subscription,
  takeoverStatus,
  switchStatus,
  accountIcon,
  editText,
  addressText,
  deliverDetailsText,
  termsConditionIcon,
  cancelSubscriptionIcon,
  arrangeTakeoverIcon,
  requestRepairIcon,
  contractChangeIcon,
  contractRenewalIcon,
  movingPlannerIcon,
  termsConditionText = 'Download Terms & Condition',
  cancelSubscriptionText = 'Cancel Subscription',
  arrangeTakeoverText = 'Arrange Take over',
  requestRepairText = 'Request repair',
  movingPlannerText = 'Moving planner',
  contractRenewalText = 'Renewal options',
  contractChangeText = 'Change subscription',
  contractSwitchType,
}) => {
  const { locale } = useLocale();
  const { country, language } = splitLocale(locale);
  const { data: contractSwitchFlag } = useFeatureFlag('enable-contract-switch');
  const disabledCountriesOption = JSON.parse(contractSwitchFlag?.option || `{"disabledCountries": ["de"]}`);

  const contractSwitchTypeOptions = {
    change: {
      text: contractChangeText,
      icon: contractChangeIcon,
      dataAttribute: 'contract-change-button',
      pageId: Paths.UserPanelChangeSubscription,
    },
    renewal: {
      text: contractRenewalText,
      icon: contractRenewalIcon,
      dataAttribute: 'contract-renewal-button',
      pageId: Paths.UserPanelRenewalSubscription,
    },
  };
  // TODO Refactor available buttons display logic e.g. available-actions-manager
  // All buttons will be available but will move to page with information why action can't be taken
  //
  const buttonsList = [
    {
      text: termsConditionText,
      icon: termsConditionIcon,
      download: getTermsAndConditionPdf(subscription.termsAndConditionsVersion, language),
      showWithStatuses: ['future', 'active', 'paused', 'terminating', 'cancelled'],
      showWithTakeoverStatuses: ['cancelled', 'new', 'closed', 'waiting', 'provided', 'rejected', 'error'],
      showWithSwitchStatuses: [
        ContractSwitchStatus.Cancelled,
        ContractSwitchStatus.Completed,
        ContractSwitchStatus.Pending,
        ContractSwitchStatus.Requested,
      ],
      dataAttribute: 'terms-condition-button',
    },
    {
      text: cancelSubscriptionText,
      icon: cancelSubscriptionIcon,
      onClick: () => {
        window.location.href = buildPath({
          locale,
          pageId: Paths.UserPanelSubscriptionCancel,
          params: { id: subscription.id },
        });
      },
      showWithStatuses: ['future', 'active', 'paused'],
      showWithTakeoverStatuses: ['cancelled', 'new', 'waiting', 'provided', 'rejected', 'error'],
      showWithSwitchStatuses: [ContractSwitchStatus.Cancelled, ContractSwitchStatus.Completed],
      dataAttribute: 'cancel-subscription-button',
    },
    {
      text: arrangeTakeoverText,
      icon: arrangeTakeoverIcon,
      onClick: () => {
        window.location.href = buildPath({
          locale,
          pageId: Paths.UserPanelTakeover,
        }).replace(':type', 'provider');
      },
      showWithStatuses: ['active', 'paused'],
      showWithTakeoverStatuses: ['cancelled', 'new', 'waiting', 'provided', 'rejected', 'error'],
      // We show this button for all statuses because we have guard for active process inside form
      // Probably all other buttons will be adjusted this way
      showWithSwitchStatuses: [ContractSwitchStatus.Cancelled, ContractSwitchStatus.Completed],
      dataAttribute: 'arrange-takeover-button',
    },
    {
      text: movingPlannerText,
      icon: movingPlannerIcon || arrangeTakeoverIcon,
      onClick: () => {
        window.location.href = buildPath({
          locale,
          pageId: Paths.UserPanelMovingForm,
        });
      },
      showWithStatuses: ['active'],
      showWithTakeoverStatuses: ['cancelled', 'rejected', 'error'],
      showWithSwitchStatuses: [ContractSwitchStatus.Cancelled, ContractSwitchStatus.Completed],
      disabledForCountry: ['de'],
      dataAttribute: 'moving-planner-button',
    },
    {
      ...(contractSwitchFlag?.isActive && !disabledCountriesOption?.disabledCountries?.includes(country)
        ? {
            text: contractSwitchTypeOptions[contractSwitchType].text,
            icon: contractSwitchTypeOptions[contractSwitchType].icon,
            onClick: () => {
              window.location.href = buildPath({
                locale,
                pageId: contractSwitchTypeOptions[contractSwitchType].pageId,
                params: { id: subscription.id },
              });
            },
            showWithStatuses: ['active'],
            showWithTakeoverStatuses: ['cancelled', 'rejected', 'error'],
            showWithSwitchStatuses: [
              ContractSwitchStatus.Cancelled,
              ContractSwitchStatus.Completed,
              ContractSwitchStatus.Pending,
              ContractSwitchStatus.Requested,
            ],
            dataAttribute: contractSwitchTypeOptions[contractSwitchType].dataAttribute,
          }
        : {}),
    },
    {
      text: requestRepairText,
      icon: requestRepairIcon,
      onClick: () => {
        window.location.href = buildPath({
          locale,
          pageId: Paths.UserPanelSubscriptionRepair,
          params: { id: subscription.id },
        });
      },
      showWithStatuses: ['active', 'paused', 'terminating'],
      showWithTakeoverStatuses: ['cancelled', 'new', 'waiting', 'provided', 'rejected', 'error'],
      showWithSwitchStatuses: [ContractSwitchStatus.Cancelled, ContractSwitchStatus.Completed],
      dataAttribute: 'request-repair-button',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.accountCardContainer}>
        <AccountCard
          className={styles.accountCard}
          noIcon
          accountIcon={accountIcon}
          editText={editText}
          addressText={addressText}
          deliverDetailsText={deliverDetailsText}
        />
      </div>

      <div className={styles.buttonList}>
        {buttonsList
          .filter(button => !button?.disabledForCountry?.includes(country))
          .filter(button => {
            const shouldDisplayForTakeover = takeoverStatus
              ? button?.showWithTakeoverStatuses?.includes(takeoverStatus)
              : true;
            const shouldDisplayForSwitch = switchStatus ? button?.showWithSwitchStatuses?.includes(switchStatus) : true;
            const shouldDisplayForStatus = button?.showWithStatuses?.includes(subscription.status);

            return shouldDisplayForTakeover && shouldDisplayForSwitch && shouldDisplayForStatus;
          })
          .map(button => (
            <div key={button.text} className={styles.button}>
              {/* TODO BUTTON WTF */}
              <DeprecatedIconButton
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  button.onClick?.();
                }}
                link={
                  button.download ? [{ component: 'downloadLink', asset: { filename: button.download } }] : undefined
                }
                href={button.href}
                download={button.download}
                text={button.text}
                icon={button.icon}
                className={styles.iconButton}
                dataAttribute={{ 'data-test-id': button.dataAttribute }}
                fullWidth
              />
            </div>
          ))}
      </div>
    </div>
  );
};
