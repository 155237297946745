import { groupBy, flatten } from 'lodash';

import { currencyFormatter } from '@services/currency-formatter';
import { splitLocale } from '@services/locale-config';

const COUNTRY_SPECIFIC_CONTENT_VARIABLES = {
  nl: {
    email: 'info@bluemovement.com',
    phone: '088 - 424 45 67',
  },
  de: {
    email: 'hilfe@bluemovement.com',
    phone: '089 - 35 880 51 24',
  },
};

export const prepareContentVariables = ({ allPlans, locale, allOffers = [] }) => {
  const { country } = splitLocale(locale);
  const plansByCategory = groupBy(allPlans, 'category.slug');
  const offersByCategory = groupBy(
    allOffers
      .flatMap(offer => offer.offerItems)
      .filter(offerItem => offerItem.isLive && offerItem.type === 'main' && offerItem.categorySlug),
    'categorySlug'
  );

  let minPricesForCategories = Object.entries(plansByCategory).reduce((acc, [categoryName, categoryPlans]) => {
    const minPriceForCategoryInCents = Math.min(
      ...flatten(categoryPlans.map(p => p.pricings))
        .filter(pricing => pricing?.isActive)
        .map(pricing => pricing.amount)
    );
    acc[categoryName] = {
      minPrice: currencyFormatter(locale)({ price: minPriceForCategoryInCents }),
    };

    return acc;
  }, {});

  if (allOffers.length) {
    minPricesForCategories = Object.entries(offersByCategory).reduce((acc, [categoryName, categoryOffers], i) => {
      const minPriceForCategoryInCents = Math.min(
        ...categoryOffers.flatMap(offerItem => offerItem.pricings).map(pricing => pricing?.recurringAmount)
      );

      acc[categoryName] = {
        minPrice: currencyFormatter(locale)({ price: minPriceForCategoryInCents }),
      };

      return acc;
    }, {});
  }

  const contentVariables = {
    ...COUNTRY_SPECIFIC_CONTENT_VARIABLES[country],
    ...minPricesForCategories,
  };

  return contentVariables;
};
