import React from 'react';

import { useFormContext } from 'react-hook-form';

import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

import styles from './MovingPlannerSelectedSubscriptions.module.scss';

export const MovingPlannerSelectedSubscriptions = ({ subscriptions, calendarIcon }) => {
  const formContext = useFormContext();

  let selectedSubscriptions = subscriptions || [];

  if (formContext) {
    const values = formContext?.getValues();
    selectedSubscriptions = subscriptions.filter(subscription =>
      values.items.find(item => item.subscriptionToMove && item.id === subscription.id)
    );
  }

  return (
    <div className={styles.container}>
      {selectedSubscriptions.map(subscription => (
        <div key={subscription.id}>
          <SubscriptionCard
            calendarIcon={calendarIcon}
            subscription={subscription}
            noViewButton
            showItemPriceList
            additionalServices={subscription.additionalServices}
          />
        </div>
      ))}
    </div>
  );
};
