import React, { useEffect, useState } from 'react';

import { aggregateOfferItems } from '@theme/ShoppingCart/helpers/aggregateCartItems';
import { BillingType } from 'types/offer.types';
import { OrderItem } from 'types/order.types';

import { GenericCarousel } from '@components/GenericCarousel/GenericCarousel';
import Loader from '@components/Loader/Loader';
import LogoLoader from '@components/LogoLoader/LogoLoader';
import { useContent } from '@hooks/useContent';
import { useLocale } from '@hooks/useLocale';
import { useMedia } from '@hooks/useMedia';
import { useUserLastOrder } from '@hooks/user/useUserLastOrder';

import styles from './CheckoutOrderCardCarousel.module.scss';
import { CheckoutOrderCardOffer } from '../CheckoutOrderCard/CheckoutOrderCardOffer';

const delay = ms => new Promise(res => setTimeout(res, ms));

export const CheckoutOrderCardCarouselOffer = () => {
  const { locale, country } = useLocale();
  const { data: plansContent } = useContent({
    locale,
    name: `plans-${country}`,
  });
  const { data: additionalServicesContent } = useContent({ locale, name: `additional-services` }); //TODO: OFFER check if sb changed
  const { isMobile } = useMedia();
  const { data: newestOrder, isRefetching, isLoading, refetch } = useUserLastOrder();
  const [shouldRetry, setShouldRetry] = useState(true);

  useEffect(() => {
    if (isLoading || !newestOrder) {
      return;
    }

    const areOnlySda = newestOrder?.items?.every(item => item?.plan?.category?.groupType === 'SDA');

    if (areOnlySda) {
      setShouldRetry(false);

      return;
    }

    const confirmedTimeslotBooking =
      newestOrder?.timeslotBookings?.length > 0
        ? newestOrder?.timeslotBookings?.find(timeslot => timeslot.status === 'CONFIRMED')
        : undefined;

    if (!confirmedTimeslotBooking) {
      // To prevent race condition we should use the retry mechanism
      const retry = async () => {
        const MAX_RETRY_ATTEMPTS = 3;
        let attemptCounter = 0;

        // eslint-disable-next-line no-restricted-syntax
        do {
          attemptCounter = attemptCounter + 1;
          const { data: retriedNewestOrder } = await refetch();
          const retriedConfirmedTimeslotBooking = retriedNewestOrder?.timeslotBookings?.find(
            timeslot => timeslot.status === 'CONFIRMED'
          );

          if (retriedConfirmedTimeslotBooking || attemptCounter === MAX_RETRY_ATTEMPTS) {
            setShouldRetry(false);

            return;
          }

          await delay(1000);
        } while (attemptCounter < MAX_RETRY_ATTEMPTS);
      };

      retry();
    } else {
      setShouldRetry(false);
    }
  }, [newestOrder]);

  if (!newestOrder || isRefetching || isLoading || shouldRetry) {
    return (
      <div className={styles.loaderContainer}>
        <LogoLoader />
      </div>
    );
  }

  const editedOrderItems = (newestOrder?.items || []).map(item => {
    return {
      ...item,
      content:
        plansContent?.[item?.offerItem?.contentSlug?.toLowerCase()] ||
        additionalServicesContent?.[item?.offerItem?.contentSlug?.toLowerCase()],
      order: newestOrder,
    };
  });

  const aggregatedItems = aggregateOfferItems(
    editedOrderItems.map(orderItem => ({
      ...orderItem,
      pricing: {
        recurringAmount: orderItem.price,
        oneTimeAmount: orderItem.oneTimePrice,
      },
    })) as Required<OrderItem>[]
  );

  const aggregatedOrderItemsWithPossibleAdditionalServices = aggregatedItems.map(item => {
    const offerItems = editedOrderItems?.filter(offer => item.additionalItems.some(ad => ad.id === offer.id)) || [];

    return {
      ...item,
      possibleAdditionalItems: offerItems.map(({ offerItem, oneTimePrice, price }, index) => {
        return {
          ...offerItem,
          pricings: [
            {
              billingType: oneTimePrice > 0 ? BillingType.OneTime : BillingType.Recurring,
              oneTimeAmount: oneTimePrice,
              recurringAmount: price,
            },
          ],
          content: item.additionalItems[index].content,
        };
      }),
    };
  });

  if (isMobile) {
    return (
      <GenericCarousel items={aggregatedOrderItemsWithPossibleAdditionalServices} className={styles.carousel}>
        <CheckoutOrderCardOffer />
      </GenericCarousel>
    );
  }

  return (
    <div className={styles.container}>
      {aggregatedOrderItemsWithPossibleAdditionalServices.map(item => {
        return <CheckoutOrderCardOffer key={item.mainItem.id} {...item} />;
      })}
    </div>
  );
};
