import { Icon } from '@base-components/Icons/availableButtonIcons';

export type StoryblokSubMenu = {
  _uid: string;
  component: 'subMenu';
  title: string;
  items: StoryblokLink[];
} & Icon;

export enum LinkComponent {
  InternalLink = 'internalLink',
  CategoryLink = 'categoryLink',
}

export type InternalLinkType = {
  _uid: string;
  component: LinkComponent.InternalLink;
  label: string;
  link?: string;
  pageId?: string;
} & Icon;

export type CategoryLinkType = {
  _uid: string;
  component: LinkComponent.CategoryLink;
  label: string;
  category?: string;
} & Icon;

export type StoryblokLink = InternalLinkType | CategoryLinkType;

export type LinkSeparatorType = {
  _uid: string;
  component: 'linkSeparator';
};

export type StoryblokMenuItem = StoryblokSubMenu | StoryblokLink | LinkSeparatorType;
