import React from 'react';

import styles from './TakeoverReceiverConfirmationCheckbox.module.scss';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { RichText } from '@components/RichText/RichText';

export const TakeoverReceiverConfirmationCheckbox = ({ text, contentVariables }) => {
  return (
    <div className={styles.container}>
      <Checkbox
        controllerName="agreedToTakeoverTerms"
        theme={Themes.White}
        labelText={<RichText document={text} contentVariables={contentVariables} />}
        className={styles.checkboxContainer}
      />
    </div>
  );
};
