import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import LocalizationChangeButton from '@components/LocalizationChangeDropdown/components/LocalizationChangeButton';
import { buildLocalizationOptions } from '@components/LocalizationChangeDropdown/utils/localizationUtils';
import { StoryblokMenuItem } from '@components/PageHeader/types';
import { useLocale } from '@hooks/useLocale';
import { useMedia } from '@hooks/useMedia';

import { Slide } from './AnimatedListElement';
import { BackToPreviousMenuButton } from './buttons/BackToPreviousMenuButton';
import { LocalizationChange } from './buttons/LocalizationChange';
import { MenuItem } from './buttons/MenuItem';
import { SubMenu } from './buttons/SubMenu';
import styles from './MobileMenuContent.module.scss';
import { getSubMenuDetails, isLocalizationSelect, isSubMenu, MenuComponentName } from './utils/subMenuUtils';

interface MobileMenuContentProps {
  menuItems: StoryblokMenuItem[];
  isInfoBar: boolean;
}

export const MobileMenuContent = ({ menuItems, isInfoBar }: MobileMenuContentProps) => {
  const [subMenusOrder, setSubMenusOrder] = useState<string[]>([]);
  const { isMobile } = useMedia();

  const { country: currentCountry, language: currentLanguage } = useLocale();

  const slideFrom = useRef<Slide>(Slide.Left);

  const addNewSubMenu = (uid: string) => {
    setSubMenusOrder(subMenuUIds => [...subMenuUIds, uid]);
  };
  const backToPreviousSubMenu = () => {
    setSubMenusOrder(subMenuUIds => {
      const newSubMenus = [...subMenuUIds];
      newSubMenus.shift();

      slideFrom.current = Slide.Right;

      return newSubMenus;
    });
  };

  const handleClick = (uid: string) => () => addNewSubMenu(uid);

  useEffect(() => {
    // These parameters blocks scrolling when mobile menu is opened
    // TODO: When the menu opens, page is scrolled to top, we should avoid this scroll later
    document.body.style.position = 'fixed';
    document.body.style.overflowY = 'scroll';
    document.body.style.width = '100%';

    return () => {
      document.body.style.position = 'static';
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    if (slideFrom.current === Slide.Right) {
      slideFrom.current = Slide.Left;
    }
  }, [slideFrom.current]);

  const languageItems = buildLocalizationOptions((countryCode, languageCode) => {
    return {
      _uid: `${countryCode}-${languageCode}`,
      component: MenuComponentName.LocalizationSelect,
      label: (
        <LocalizationChangeButton
          classNames={styles.localizationChangeButton}
          languageCode={languageCode}
          countryCode={countryCode}
          currentCountry={currentCountry}
          currentLanguage={currentLanguage}
        />
      ),
    };
  }).flat();

  const localizationItem = {
    _uid: 'language-and-country-select',
    icon: '',
    component: MenuComponentName.SubMenu,
    label: 'Country & Language',
    title: (
      <div className={styles.localizationSelectItem}>
        Country & Language:{' '}
        <LocalizationChangeButton
          classNames={styles.currentLanguage}
          languageCode={currentLanguage}
          countryCode={currentCountry}
        />
      </div>
    ),
    items: languageItems,
  };

  let { title, menuItems: itemsToDisplay, label } = getSubMenuDetails({
    subMenusOrder,
    menuItems: [...menuItems, localizationItem],
  });

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.additionalTopPosition]: isInfoBar && isMobile,
        [styles.topPosition]: !isInfoBar && isMobile,
      })}
    >
      <ul className={styles.listWrapper}>
        {!!subMenusOrder.length && (
          <BackToPreviousMenuButton
            title={label || title}
            onClick={backToPreviousSubMenu}
            slideFrom={slideFrom.current}
          />
        )}
        {itemsToDisplay.map(menuItem => {
          if (isLocalizationSelect(menuItem)) {
            return <LocalizationChange key={menuItem._uid} title={menuItem.label} slideFrom={slideFrom.current} />;
          }

          if (isSubMenu(menuItem)) {
            return (
              <SubMenu
                key={menuItem._uid}
                title={menuItem.title}
                icon={menuItem.icon}
                onClick={handleClick(menuItem._uid)}
                slideFrom={slideFrom.current}
              />
            );
          }

          return (
            <MenuItem
              key={menuItem._uid}
              component={menuItem.component}
              link={menuItem.link}
              icon={menuItem.icon}
              pageId={menuItem.pageId}
              label={menuItem.label}
              category={menuItem.category}
              slideFrom={slideFrom.current}
            />
          );
        })}
      </ul>
    </div>
  );
};
