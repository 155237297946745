/* eslint-disable import/no-cycle */
/* eslint-disable prettier/prettier */
import React from 'react';

import * as dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

import Loader from '@components/Loader/Loader';
import { useUserActiveTakeover } from '@hooks/subscriptions/useUserActiveTakeover';
import { useUser } from '@hooks/user/useUser';

import styles from './TakeoverProviderSummaryCard.module.scss';
import { TakeoverParticipant } from '../../../page/TakeoverFormContext/TakeoverFormContext';
import { TakeoverSummaryCard } from '../../shared/TakeoverSummaryCard/TakeoverSummaryCard';

export const TakeoverProviderSummaryCard = ({
  changeIcon,
  nameText,
  locationText,
  startDateText,
  takeoverParticipant,
}) => {
  const formContext = useFormContext();

  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: userActiveTakeover, isLoading: isUserActiveTakeoverLoading } = useUserActiveTakeover();

  if (!user || isUserLoading || isUserActiveTakeoverLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const values = formContext?.getValues();
  const originalUser = userActiveTakeover?.originalUser || user;

  const address = {
    street: originalUser.shippingAddress.street || originalUser.billingAddress.street,
    houseNumber: originalUser.shippingAddress.houseNumber || originalUser.billingAddress.houseNumber,
    houseNumberAddon: originalUser.shippingAddress.houseNumberAddon || originalUser.billingAddress.houseNumberAddon,
    postalCode: originalUser.shippingAddress.postalCode || originalUser.billingAddress.postalCode,
    city: originalUser.shippingAddress.city || originalUser.billingAddress.city,
  };

  let data = {
    takeoverDate: null,
    firstName: originalUser?.firstName,
    lastName: originalUser?.lastName,
    email: originalUser?.email,
    ...address,
  };

  if (takeoverParticipant === TakeoverParticipant.receiver) {
    data = {
      takeoverDate:
        (userActiveTakeover?.takeoverDate && dayjs(userActiveTakeover?.takeoverDate).format('YYYY-MM-DD')) ||
        values?.takeoverDate,
      firstName: userActiveTakeover?.newUserFirstName || values?.newUserFirstName,
      lastName: userActiveTakeover?.newUserLastName || values?.newUserLastName,
      email: userActiveTakeover?.newUserEmail || values?.newUserEmail,
      ...address,
    };
  }

  return (
    <TakeoverSummaryCard
      data={data}
      nameText={nameText}
      startDateText={startDateText}
      locationText={locationText}
      changeIcon={changeIcon}
      userRole={takeoverParticipant}
    />
  );
};
