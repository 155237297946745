import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useCancelTakeoverMutation = () => {
  const httpService = useHttpService();

  return useMutation(async cancelTakeoverDto => {
    const response = await httpService.post('/takeover/cancel-takeover', cancelTakeoverDto);

    return response.data;
  });
};
