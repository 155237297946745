export enum Country {
  NL = 'nl',
  DE = 'de',
}

export enum Language {
  EN = 'en',
  DE = 'de',
  NL = 'nl',
}

export enum Locale {
  'NL-EN' = 'nl-en',
  'NL-NL' = 'nl-nl',
  'DE-DE' = 'de-de',
}

export interface FeatureFlag {
  id: string;
  name: string;
  isActive: boolean;
  ticketNumber: string;
  option: string;
}

export enum GroupType {
  MDA = 'MDA',
  SDA = 'SDA',
  ACCESSORY = 'ACCESSORY',
  MOCK = 'MOCK',
}
