import React from 'react';

import styles from './Header.module.scss';
import { HeadingGroup } from '../HeadingGroup/HeadingGroup';
import { Image } from '../Image/Image';

export const Header = ({ label, text, image, isFirstLevelHeading }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <HeadingGroup label={label} text={text} isFirstLevelHeading={isFirstLevelHeading} alignLeft />
      </div>
      <Image alt="header image" image={image} className={styles.image} />
    </div>
  );
};
