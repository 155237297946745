import Script from 'next/script';

import { Modal } from '@components/modals/Modal/Modal';
import { useModal } from '@hooks/component/useModal';
import { useLogger } from '@hooks/useLogger';
import { interpolateContent } from '@utils/interpolate-content';

import styles from './LoadbeeModal.module.scss';
import LoadbeeContent from '../../Loadbee/LoadbeeContent';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const LoadbeeModal = ({
  config,
  modalOpenText = 'Show information',
  modalTitleText,
  modalButtonAlign,
  ean,
  content,
  debugMode,
}) => {
  const { logger } = useLogger({ context: 'LoadbeeModal' });
  const [isModalOpen, openModal, closeModal] = useModal();

  return (
    <>
      <Modal size="large" isOpen={isModalOpen} title={content?.name} closeModal={closeModal}>
        <Script
          defer
          src="//cdn.loadbee.com/js/loadbee_integration.js"
          strategy="afterInteractive"
          onReady={async () => {
            await window?.loadbeeIntegration?.checkAvailability();
            const productCheck = await window?.loadbeeIntegration?.checkProduct();

            if (productCheck.code !== 200) {
              logger.error({ message: 'Something is wrong with the Loadbee widget', params: { productCheck } });
            }
          }}
        />

        <div className={styles.titleTextContainer}>
          <span className={styles.titleText}>{interpolateContent(modalTitleText, { ...content.content })}</span>
        </div>

        <LoadbeeContent apiKey={config.apiKey} locale={config.locale} ean={ean} debugMode={debugMode} />
      </Modal>

      <div className={styles.modalButtonContainer} style={{ justifyContent: modalButtonAlign }}>
        <Button as="button" variant={ButtonVariant.Icon} icon="none" theme={ButtonTheme.White} isElevated onClick={openModal}>
          {modalOpenText}
        </Button>
      </div>
    </>
  );
};
