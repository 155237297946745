import React, { useRef } from 'react';

import { useFormState } from 'react-hook-form';

import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useIsCouponCodeRedeemed } from '@hooks/cart/useIsCouponCodeRedemeed';
import { useIsCouponExpiredMessage } from '@hooks/cart/useIsCouponExpiredMessage';
import { useLocale } from '@hooks/useLocale';
import { interpolateContent } from '@utils/interpolate-content';
import { buildPath, buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutOneTimePaymentInputs.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { CHECKOUT_PAYMENT_INPUTS_DE } from '../CheckoutPaymentInputs/de/CheckoutPaymentInputsDe';
import { CHECKOUT_PAYMENT_INPUTS_NL } from '../CheckoutPaymentInputs/nl/CheckoutPaymentInputsNl';
import {
  CheckoutPaymentInputs,
  getPaymentMethodByComponent,
  remountComponent,
} from '../CheckoutPaymentInputs/CheckoutPaymentInputs';
import { useRouter } from 'next/router';
import { useLogger } from '@hooks/useLogger';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { usePaymentMutation } from '@hooks/payment/usePaymentMutation';
import { paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';
import { usePaymentAttemptMutation } from '@hooks/payment/usePaymentAttemptMutation';

import cn from 'classnames';
import usePaymentConfiguration from '@hooks/payment/usePaymentConfiguration';
import { sendSelectedPaymentEvent } from '@services/gtm/page-initialization';
import { usePhrases } from '@hooks/context/usePhrases';
import { toast } from 'react-toastify';

export const CheckoutOneTimePaymentInputs = ({
  showConsent = true,
  termsAndConditionConsent,
  termsAndConditionConsentDataAgreementLinkText,
  termsAndConditionConsentLinkTex,
  submitButtonText,
  isSubmitting: isSubmittingProp,
  paymentAttempt,
  ...controllerProps
}) => {
  const { logger } = useLogger({ context: 'CheckoutOneTimePaymentInputs' });
  const router = useRouter();
  const { country, locale } = useLocale();
  const phrases = usePhrases({ name: 'notifications' });

  const isCheckoutPaymentPage =
    router.pathname.includes('/checkout/payment') && !router.pathname.includes('/checkout/payment-status');
  const termsAndConditionsPath = buildPathForLocale({
    pageId: Paths[`TermsAndConditions${country.toUpperCase()}`],
    locale,
  });
  const redirectUrlRef = useRef('');

  const paymentMutation = usePaymentMutation();
  const paymentAttemptMutation = usePaymentAttemptMutation();

  const formState = useFormState();

  const dataAgreementPath = buildPathForLocale({ pageId: Paths[`PrivacyPolicy${country.toUpperCase()}`], locale });
  const isCouponExpiredMessage = useIsCouponExpiredMessage();
  const isCouponCodeRedeemedMessage = useIsCouponCodeRedeemed();
  const amountValue = paymentAttempt?.amount;

  const enabledCheckoutPaymentInputs = {
    de: [CHECKOUT_PAYMENT_INPUTS_DE.CARD],
    nl: [CHECKOUT_PAYMENT_INPUTS_NL.CARD, CHECKOUT_PAYMENT_INPUTS_NL.IDEAL],
  };

  const showErrorToast = message => {
    toast(message || 'Something went wrong. Please try again.', {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const { configuration, isSubmitting, setSubmitting, validationError, setValidationError } = usePaymentConfiguration({
    customAmountValue: amountValue,
    cart: paymentAttempt?.cart,
    user: paymentAttempt?.user,
    customOnSubmit: async (state, component, actions) => {
      try {
        setSubmitting(true);

        logger.info({
          message: 'Submitting payment form',
          params: { userId: paymentAttempt?.user?.id, cartId: paymentAttempt?.cart?.id },
        });

        const statusPagePath = buildPath({
          pageId: Paths.CheckoutPaymentStatus,
          locale,
        });
        const APP_URL = getRuntimeConfig('APP_URL');
        const paymentMethodByComponent = getPaymentMethodByComponent(component);
        const newPaymentAttempt = await paymentAttemptMutation.mutateAsync({
          depositPaymentMethod: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
          redirectPath: statusPagePath,
          transactionId: router.query.transactionId,
          depositAmount: paymentAttempt?.amount * 100,
          userId: paymentAttempt?.user?.id,
          paymentType: 'checkout',
          cartId: paymentAttempt?.cart?.id,
          bank: paymentAttempt?.params?.bank,
        });

        const redirectUrl = `${APP_URL}/redirect?url=${encodeURIComponent(
          `${APP_URL}${statusPagePath
            .replace(':paymentBMId', newPaymentAttempt?.id)
            .replace(':transactionId', newPaymentAttempt?.id)}`
        )}`;

        const paymentMutationResult = await paymentMutation.mutateAsync({
          userId: paymentAttempt?.user?.id,
          paymentMethod: state.data.paymentMethod,
          riskData: state.data.riskData,
          amountValue,
          redirectPath: redirectUrl,
          paymentAttemptUuid: newPaymentAttempt?.id,
          browserInfo: state.data.browserInfo,
          paymentType: 'checkout',
          cartId: paymentAttempt?.cart?.id,
        });

        const { resultCode, action, order, donationToken } = paymentMutationResult;

        if (resultCode === 'Refused' || !resultCode) {
          await sendSelectedPaymentEvent({
            oneTimeFee: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
            monthlyFee: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
            status: 'failed',
            isRetry: true,
          });

          // TODO Adyen: refusalReasonCode -> translation should be mapped https://docs.adyen.com/development-resources/refusal-reasons/
          // "As a rule we advise merchants to be careful with exposing refusal reasons, as malicious shoppers can use it for card testing or other acts. In general, we advice merchants to only show generic refusal reasons."
          // Waiting for Barbara's decision what to show here.
          // Probably the best way is just "Try again" without showing any reasons
          actions.reject();

          return;
        }

        if (paymentMutationResult) {
          await sendSelectedPaymentEvent({
            oneTimeFee: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
            monthlyFee: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
            status: 'success',
            isRetry: true,
          });

          redirectUrlRef.current = redirectUrl;

          actions.resolve({
            resultCode,
            action,
            order,
            donationToken,
          });

          if (action?.url) {
            logger.info({
              message: 'Redirecting to provider page',
              params: { userId: paymentAttempt?.user?.id, cartId: paymentAttempt?.cart?.id },
            });

            window.location.href = action.url;
          } else {
            logger.info({
              message: 'Triggering another action',
              params: {
                userId: user.id,
                cartId: cart.id,
                paymentAttemptId: newPaymentAttempt?.id,
                redirectUrl: createPaymentOrder.redirectUrl,
                redirectUrlRef: redirectUrlRef.current,
              },
            });
          }
        }
      } catch (error) {
        logger.error({
          message: 'Error while submitting payment form',
          params: {
            userId: paymentAttempt?.user?.id,
            cartId: paymentAttempt?.cart?.id,
            error,
            errorMessage: error.message,
            paymentAttemptId: paymentAttempt?.id,
          },
        });
      }
    },
    customOnPaymentCompleted: (result, component) => {
      logger.info({
        message: 'onPaymentCompleted is triggered',
        params: {
          result,
          userId: paymentAttempt?.user?.id,
          cartId: paymentAttempt?.cart?.id,
          redirectUrlRef: redirectUrlRef.current,
        },
      });

      setValidationError(false);

      if (redirectUrlRef.current) {
        window.location.href = redirectUrlRef.current;
      }
    },
    customOnPaymentFailed: (result, component) => {
      logger.warn({
        message: 'No result code or payment is Refused',
        params: {
          userId: paymentAttempt?.user?.id,
          cartId: paymentAttempt?.cart?.id,
          result,
          redirectUrlRef: redirectUrlRef.current,
        },
      });

      if (redirectUrlRef.current) {
        window.location.href = redirectUrlRef.current;
      } else {
        const message = result?.errorMessage?.includes('Card already present')
          ? phrases?.['exact-payment-method-exists']
          : phrases?.['payment-method-not-valid'];
        showErrorToast(message);
        remountComponent(component);
        setSubmitting(false);
        setValidationError(true);
      }
    },
  });

  return (
    <div className={styles.container}>
      {/* TODO ADYEN: Hack here with router check */}
      {!isCheckoutPaymentPage && (
        <CheckoutPaymentInputs
          enabledCheckoutPaymentInputs={enabledCheckoutPaymentInputs[country]}
          configuration={configuration}
          setValidationError={setValidationError}
          validationError={validationError}
          paymentAttempt={paymentAttempt}
          {...controllerProps}
        />
      )}

      {showConsent && (
        <div className={cn(styles.generalConsentCheckbox, styles.generalConsentCheckboxV2)}>
          <Checkbox
            controllerName="agreeTermsAndConditions"
            labelText={interpolateContent(termsAndConditionConsent, {
              termsAndConditionConsentDataAgreementLinkText: (
                <a
                  href={dataAgreementPath}
                  target="_blank"
                  className={styles.generalConsentCheckboxLink}
                  rel="noreferrer"
                >
                  {termsAndConditionConsentDataAgreementLinkText}
                </a>
              ),
              termsAndConditionConsentLinkTex: (
                <a
                  href={termsAndConditionsPath}
                  target="_blank"
                  className={styles.generalConsentCheckboxLink}
                  rel="noreferrer"
                >
                  {termsAndConditionConsentLinkTex}
                </a>
              ),
            })}
          />
        </div>
      )}

      {(isCouponExpiredMessage || isCouponCodeRedeemedMessage) && (
        <div className={styles.expiredCouponNotification}>
          <NotificationBar content={isCouponExpiredMessage || isCouponCodeRedeemedMessage} variant="error" />
        </div>
      )}

      <Button
        as="button"
        className={styles.button}
        variant={ButtonVariant.Basic}
        type="submit"
        isFullWidth
        disabled={
          isSubmittingProp ||
          isSubmitting ||
          isCouponExpiredMessage ||
          isCouponCodeRedeemedMessage ||
          formState.isSubmitting
        }
        isLoading={isSubmittingProp || isSubmitting || formState.isSubmitting}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};
