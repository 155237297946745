import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useNlAddressValidationMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ postalCode, houseNumber }) => {
    const { data } = await httpService.post('/address-validation/nl', { postalCode, houseNumber });

    return data;
  });
};

export const useDeAddressValidationMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ postalCode, city, street, houseNumber }) => {
    const { data } = await httpService.post('/address-validation/de', { postalCode, city, street, houseNumber });

    return data;
  });
};
