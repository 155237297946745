import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import throttle from 'lodash/throttle';
import NextLink from 'next/link';

import { InfoBar } from '@components/InfoBar/InfoBar';
import LocalizationChangeDropdown from '@components/LocalizationChangeDropdown/LocalizationChangeDropdown';
import { useCartContext } from '@context/cart';
import { useLocale } from '@hooks/useLocale';
import { useMedia } from '@hooks/useMedia';
import { useUser } from '@hooks/user/useUser';
import { buildPath, buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { DesktopMenu } from './DesktopMenu/DesktopMenu';
import { MobileMenu } from './MobileMenu/MobileMenu';
import styles from './PageHeader.module.scss';
import { PageHeaderBackButton } from './PageHeaderBackButton';
import { StoryblokMenuItem } from './types';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { DropdownPositions } from '@base-components/Dropdown/Dropdown';
import { BoschLogo, CheckmarkReverseIcon } from '@base-components/Icons';
import { CartCounterWithOffer } from '@theme/ShoppingCart/components/CartCounterWithOffer/CartCounterWithOffer';

const HIDE_PAGE_HEADER_POSITION = 120;

type PageHeaderProps = {
  desktopMenuItems: StoryblokMenuItem[];
  mobileMenuItems: StoryblokMenuItem[];
  infoBarTitle: string;
  infoBarLink: string;
  myAccountText: string;
};

export const PageHeader = ({
  desktopMenuItems = [],
  mobileMenuItems = [],
  infoBarTitle,
  infoBarLink,
  myAccountText,
}: PageHeaderProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const { locale } = useLocale();
  const prevOffset = useRef(0);
  const { data: user } = useUser();
  const { isCartOpen, toggleCart } = useCartContext();
  const { isMobile } = useMedia();

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isScrollingUp = prevOffset.current > currentOffset;

      setIsVisible(
        currentOffset < HIDE_PAGE_HEADER_POSITION || (isScrollingUp && currentOffset > HIDE_PAGE_HEADER_POSITION)
      );

      prevOffset.current = currentOffset;
    };

    window.addEventListener('scroll', throttle(handleScroll, 100), { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={classNames(styles.container, { [styles.visible]: isVisible, [styles.hidden]: !isVisible })}>
        <InfoBar infoBarTitle={infoBarTitle} infoBarLink={infoBarLink} />
        <div className={styles.nav}>
          <div className={styles.leftNav}>
            <NextLink href={buildPathForLocale({ pageId: Paths.Home, locale })} className={styles.logo}>
              <BoschLogo width={204} height={38} ariaHidden={true} />
            </NextLink>
            <div className={styles.desktopBackButton}>
              <PageHeaderBackButton />
            </div>
            <DesktopMenu menuItems={desktopMenuItems} />
          </div>
          <div className={styles.rightNav}>
            {!isMobile && (
              <div className={styles.localizationDropdown}>
                <LocalizationChangeDropdown
                  dropdownPosition={{ horizontal: DropdownPositions.Left, vertical: DropdownPositions.Bottom }}
                />
              </div>
            )}
            <div className={styles.iconWithBadgeWrapper}>
              <CartCounterWithOffer className={classNames(styles.badge, styles.shoppingCartBadge)} />
              <Button
                theme={ButtonTheme.SoftBlue}
                icon="shoppingCart"
                as="button"
                variant={ButtonVariant.Icon}
                onClick={toggleCart}
                className={styles.sameSizeButton}
              />
            </div>
            <div className={styles.iconWithBadgeWrapper}>
              {user && (
                <div className={styles.badge}>
                  <CheckmarkReverseIcon />
                </div>
              )}
              <Button
                theme={ButtonTheme.SoftBlue}
                icon="user"
                as="anchor"
                variant={ButtonVariant.Icon}
                href={buildPath({ locale, pageId: Paths.Login })}
                className={styles.sameSizeButton}
                dataAttribute={{ 'data-test-id': Paths.Login }}
              />
            </div>
            <MobileMenu menuItems={mobileMenuItems} isCartOpen={isCartOpen} isInfoBar={!!infoBarTitle} />
          </div>
        </div>
        <div className={styles.mobileBackButton}>
          <PageHeaderBackButton />
        </div>
      </div>
    </>
  );
};
