import React from 'react';

import { useRouter } from 'next/router';

import { RichText } from '@components/RichText/RichText';
import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './ResultItem.module.scss';

export const ResultItem = ({ question, answer, readMoreText }) => {
  const router = useRouter();
  const { locale } = useLocale();

  const goToQuestion = questionParam => {
    const faqPath = buildPath({ pageId: Paths.Faq, locale });
    router.push({ pathname: faqPath, query: { question: questionParam } });
  };

  return (
    <div className={styles.container} onClick={() => goToQuestion(question)}>
      <div className={styles.question}>{question}</div>

      <div className={styles.answerContainer}>
        <RichText className={styles.answer} document={answer} />
      </div>

      <div className={styles.readMore}>{readMoreText}</div>
    </div>
  );
};
