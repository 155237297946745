enum TermsType {
  GeneralTermsAndCondition = 'GENERAL_TERMS_AND_CONDITIONS',
  TermsNConditions = 'TERMS_AND_CONDITIONS',
  WithdrawForm = 'WITHDRAW_FORM',
}

export const filesWithVersionToDownload = {
  [`V05_${[TermsType.GeneralTermsAndCondition]}_NL_2023_03_01`]: {
    path: '/pdf/agreements/v05/nl/bluemovement-algemene-voorwaarden.pdf',
  },
  [`V05_${[TermsType.GeneralTermsAndCondition]}_EN_2023_03_01`]: {
    path: '/pdf/agreements/v05/nl/bluemovement-algemene-voorwaarden.pdf',
  },
  [`V02_${[TermsType.GeneralTermsAndCondition]}_DE_2022_06`]: {
    path: '/pdf/agreements/v02/de/20220608_AGB_BlueMovement.pdf',
  },
  [`V03_${[TermsType.GeneralTermsAndCondition]}_DE_2024_06_06`]: {
    path: '/pdf/agreements/v03/de/20240606_agb_bluemovement_MietV.pdf',
  },
  [`V04_${[TermsType.GeneralTermsAndCondition]}_DE_2025_02_19`]: {
    path: '/pdf/agreements/v04/de/20250219_agb_bluemovement_MietV.pdf',
  },
};
