import { useState, useEffect } from 'react';

const Breakpoints = {
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
};

const defaultMedia = {
  [Breakpoints.xs]: false,
  [Breakpoints.s]: false,
  [Breakpoints.m]: false,
  [Breakpoints.l]: false,
  [Breakpoints.xl]: false,
  [Breakpoints.xxl]: false,
};

const getMedia = () => {
  const media = {
    ...defaultMedia,
  };

  if (typeof window === 'undefined') {
    return media;
  }

  media.xs = true;

  if (window.matchMedia('(min-width: 376px)').matches) {
    media.s = true;
  }

  if (window.matchMedia('(min-width: 768px)').matches) {
    media.m = true;
  }

  if (window.matchMedia('(min-width: 900px)').matches) {
    media.l = true;
  }

  if (window.matchMedia('(min-width: 1200px)').matches) {
    media.xl = true;
  }

  if (window.matchMedia('(min-width: 1680px)').matches) {
    media.xxl = true;
  }

  return media;
};

export const useMedia = () => {
  const [media, setMedia] = useState(defaultMedia);
  const isMobile = media[Breakpoints.xl] !== true;

  const handleEvent = () => {
    setMedia(getMedia());
  };

  useEffect(() => {
    handleEvent();
  }, []);

  useEffect(() => {
    window.addEventListener('load', handleEvent);
    window.addEventListener('resize', handleEvent);

    return () => {
      window.removeEventListener('load', handleEvent);
      window.removeEventListener('resize', handleEvent);
    };
  }, [media]);

  return { media, isMobile };
};
