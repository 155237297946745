import React, { useContext, useEffect } from 'react';
import SepaIcon from '@assets/icons/new/sepa.svg';

import CreditCardIcon from '@assets/icons/new/creditCard.svg';
import IdealIcon from '@assets/icons/new/payment/ideal.svg';
import { OFF_SESSION_PAYMENT_METHODS, paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';

import styles from '../CheckoutPaymentInputs.module.scss';

import { Radio } from '@components/inputs/Radio/Radio';
// import { useUserPaymentMethods } from '@hooks/payment/useUserPaymentMethods';
import '@adyen/adyen-web/styles/adyen.css';
import { AdyenContext } from '@context/adyen';
import adyenOverrideStyles from './CheckoutPaymentInputsNl.module.scss';

import cn from 'classnames';
import { useLocale } from '@hooks/useLocale';
import { Country } from 'types/common.types';
import { AdyenCheckout, Card, Redirect, SepaDirectDebit } from '@adyen/adyen-web';
// import { useLogger } from '@hooks/useLogger';

export const CHECKOUT_PAYMENT_INPUTS_NL = { CARD: 'CARD', IDEAL: 'IDEAL', SEPA: 'SEPA' };

export const CheckoutPaymentInputsNl = ({
  sepaDescription,
  sepaName,
  creditCardDescription,
  creditCardName,
  recurringConsentText,
  recurringConsentRichText,
  alreadyExistingPaymentMethodInfoBarTitle,
  alreadyExistingPaymentMethodInfoBarMessage,
  setValidationError,
  validationError,
  enabledCheckoutPaymentInputs = [CHECKOUT_PAYMENT_INPUTS_NL.CARD],
  configuration,
  paymentAttempt,
}) => {
  // const { logger } = useLogger({ context: 'CheckoutPaymentInputsNl' });
  const { country } = useLocale();
  // const { data: paymentSources } = useUserPaymentMethods(paymentAttempt?.user?.cbId);
  const adyenContext = useContext(AdyenContext);
  // const storedPaymentMethod = adyenContext?.paymentMethods?.storedPaymentMethods?.[0];

  const createCardPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const card = new Card(checkout, {
      hasHolderName: true,
      holderNameRequired: true,
      positionHolderNameOnTop: true,
    });

    adyenContext.setAttachedPaymentMethod(card);

    return card;
  };

  const createIdealPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const ideal = new Redirect(checkout, {
      type: 'ideal',
    });

    adyenContext.setAttachedPaymentMethod(ideal);

    return ideal;
  };

  const createSepaPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const sepa = new SepaDirectDebit(checkout);

    adyenContext.setAttachedPaymentMethod(sepa);

    return sepa;
  };

  // const attachStoredPaymentMethod = async () => {
  //   const storedPaymentMethod = await createStoredPaymentMethod();

  //   // TODO Adyen: hack here, fix it later
  //   setTimeout(() => {
  //     const node = document.querySelector('#stored-payment-method');

  //     if (node) {
  //       storedPaymentMethod.mount('#stored-payment-method');
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    const attachDefaultPaymentMethod = async () => {
      const cardPaymentMethod = await createCardPaymentMethod();
      cardPaymentMethod.mount('#card-container');
    };

    if (
      // !paymentSources &&
      !adyenContext.attachedPaymentMethod
    ) {
      attachDefaultPaymentMethod();

      return;
    }

    // if (paymentSources && !adyenContext.attachedPaymentMethod) {
    //   attachStoredPaymentMethod();

    //   return;
    // }
  }, []);

  // const paymentMethodType = paymentMethodsAdyenToInternalTypesMap?.[storedPaymentMethod?.type];

  // const createStoredPaymentMethod = async () => {
  //   const storedPaymentMethod = adyenContext?.paymentMethods?.storedPaymentMethods?.[0];

  //   if (!storedPaymentMethod) {
  //     logger.error({
  //       message: `No stored payment method for user ${paymentAttempt?.user?.id}`,
  //       params: { userId: paymentAttempt?.user?.id },
  //     });

  //     return;
  //   }

  //   const checkout = await AdyenCheckout(configuration);
  //   let paymentMethod;

  //   if (storedPaymentMethod.type === 'scheme') {
  //     paymentMethod = new Card(checkout, storedPaymentMethod);
  //   }

  //   if (storedPaymentMethod.type === 'sepadirectdebit') {
  //     paymentMethod = new SepaDirectDebit(checkout, storedPaymentMethod);
  //   }

  //   if (storedPaymentMethod.type === 'redirect') {
  //     paymentMethod = new Redirect(checkout, storedPaymentMethod);
  //   }

  //   adyenContext.setAttachedPaymentMethod(paymentMethod);

  //   return paymentMethod;
  // };

  if (country !== Country.NL) {
    return <></>;
  }

  return (
    <>
      {/* {paymentSources && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={`default_${paymentMethodType}`}
          onClick={async () => {
            await attachStoredPaymentMethod();
          }}
          labelComponent={
            <div className={styles.radioLabel}>
              {paymentMethodType === OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD ? <CreditCardIcon /> : ''}
              <div className={styles.radioLabelTextContainer}>
                 TODO ADYEN: Need translation here
                <span className={styles.radioLabelLightText}>Default payment method</span>
                <span className={styles.radioLabelNameText}>
                  {paymentMethodType === OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD ? creditCardName : ''}
                </span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />

          <div className={styles.inputContainer}>
            <div id="stored-payment-method"></div>
          </div>
        </Radio>
      )}  */}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_NL.CARD) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD}
          onClick={async () => {
            const cardPaymentMethod = await createCardPaymentMethod();
            cardPaymentMethod.mount('#card-container');
          }}
          labelComponent={
            <div className={styles.radioLabel}>
              <CreditCardIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{creditCardDescription}</span>
                <span className={styles.radioLabelNameText}>{creditCardName}</span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />

          <div className={styles.inputContainer}>
            <div
              id="card-container"
              onFocus={() => {
                setValidationError(false);
              }}
              className={cn({
                [adyenOverrideStyles.error]: validationError,
                [adyenOverrideStyles.base]: !validationError,
              })}
            ></div>
          </div>
        </Radio>
      )}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_NL.IDEAL) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.IDEAL}
          onClick={async () => {
            const idealPaymentMethod = await createIdealPaymentMethod();
            idealPaymentMethod.mount('#ideal-container');
          }}
          labelComponent={
            <div className={styles.radioLabel}>
              <IdealIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{sepaDescription}</span>
                <span className={styles.radioLabelNameText}>iDEAL</span>
              </div>
            </div>
          }
        >
          <div id="ideal-container"></div>
        </Radio>
      )}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_NL.SEPA) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.SEPA}
          onClick={async () => {
            const sepaPaymentMethod = await createSepaPaymentMethod();
            sepaPaymentMethod.mount('#sepa-container');
          }}
          isLastRadio
          labelComponent={
            <div className={styles.radioLabel}>
              <SepaIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{sepaDescription}</span>
                <span className={styles.radioLabelNameText}>{sepaName}</span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />
          <div className={styles.inputContainer}>
            <div id="sepa-container"></div>
          </div>
        </Radio>
      )}
    </>
  );
};
