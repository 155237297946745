import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';
import get from 'lodash/get';
import range from 'lodash/range';
import { Controller, useFormContext } from 'react-hook-form';

import DropdownIcon from '@assets/icons/new/inputs/dropdownArrow.svg';
import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';

import styles from './DatePickerDropdown.module.scss';

export const DatePickerDropdown = ({
  controllerName,
  monthsRange = { start: 1, end: 12 },
  yearsRange = { start: new Date().getUTCFullYear(), end: new Date().getUTCFullYear() + 2 },
  yearText = 'YYYY',
  monthText = 'MM',
  dayText = 'DD', // To implement if needed, remember to add isDay conditional
  fullDate = false,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('01');
  const [isTouched, setIsTouched] = useState(false);

  const error = get(errors, controllerName);
  const isError = error !== undefined;
  const isValid = !error;

  useEffect(() => {
    if (isTouched) {
      const selectedDate = `${year}-${month}-${day}`;
      setValue(controllerName, selectedDate, {
        shouldTouch: false,
        shouldValidate: true,
      });
    }

    return () => {
      setIsTouched(false);
    };
  }, [isTouched, year, month, day]);

  return (
    <Controller
      render={() => {
        return (
          <div className={styles.dropdownsContainer}>
            {fullDate && (
              <div
                className={cn(styles.dropdownContainer, {
                  [styles.invalid]: isError,
                  [styles.valid]: isValid,
                })}
              >
                <div className={styles.labelText}>{dayText}</div>
                <select
                  className={styles.dropdown}
                  onChange={event => {
                    setDay(event.target.value);
                    setIsTouched(true);
                  }}
                  data-test-id="day"
                >
                  <option value="">--</option>
                  {range(
                    1,
                    dayjs()
                      .set('year', year)
                      .set('month', month - 1)
                      .daysInMonth() + 1
                  )
                    .map(i => ({
                      option: i.toString().padStart(2, '0'),
                      value: i.toString().padStart(2, '0'),
                    }))
                    .map(({ option, value }) => (
                      <option key={`dropdown-day-${value}`} value={value}>
                        {option}
                      </option>
                    ))}
                </select>
                <DropdownIcon className={styles.dropdownIcon} />

                {isError && (
                  <div className={styles.errorMessage}>
                    {error?.message && (
                      <>
                        <RectangleIcon className={styles.rectangleIcon} />
                        <span>{error.message}</span>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            <div
              className={cn(styles.dropdownContainer, {
                [styles.invalid]: isError,
                [styles.valid]: isValid,
              })}
            >
              <div className={styles.labelText}>{monthText}</div>
              <select
                className={styles.dropdown}
                onChange={event => {
                  setMonth(event.target.value);
                  setIsTouched(true);
                }}
                data-test-id="month"
              >
                <option value="">--</option>
                {range(monthsRange.start, monthsRange.end + 1)
                  .map(i => ({
                    option: i.toString().padStart(2, '0'),
                    value: i.toString().padStart(2, '0'),
                  }))
                  .map(({ option, value }) => (
                    <option key={`dropdown-month-${value}`} value={value}>
                      {option}
                    </option>
                  ))}
              </select>
              <DropdownIcon className={styles.dropdownIcon} />

              {!fullDate && isError && (
                <div className={styles.errorMessage}>
                  {error?.message && (
                    <>
                      <RectangleIcon className={styles.rectangleIcon} />
                      <span>{error.message}</span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div
              className={cn(styles.dropdownContainer, {
                [styles.invalid]: isError,
                [styles.valid]: isValid,
              })}
            >
              <div className={styles.labelText}>{yearText}</div>
              <select
                className={styles.dropdown}
                onChange={event => {
                  setYear(event.target.value);
                  setIsTouched(true);
                }}
                data-test-id="year"
              >
                <option value="">--</option>
                {range(yearsRange.start, yearsRange.end + 1)
                  .map(i => ({ option: i, value: i }))
                  .map(({ option, value }) => (
                    <option key={`dropdown-year-${value}`} value={value}>
                      {option}
                    </option>
                  ))}
              </select>
              <DropdownIcon className={styles.dropdownIcon} />
            </div>
          </div>
        );
      }}
      name={controllerName}
      type="text"
      control={control}
    />
  );
};
