/* eslint-disable import/no-cycle */
import React from 'react';

import { useLocale } from '@hooks/useLocale';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

const APP_URL = getRuntimeConfig('APP_URL');

export const InvoicePaymentButton = ({ label, expiredLabel, paymentLink, invoice }) => {
  const { language, country } = useLocale();
  const isExpired = paymentLink?.expired !== null;

  const paymentUrl = `${APP_URL}/${country}-${language}/payment/invoice/${invoice?.invoiceNumber}/create/${paymentLink?.id}`;

  return (
    <Button
      as="button"
      variant={ButtonVariant.Icon}
      icon="none"
      theme={ButtonTheme.Secondary}
      onClick={() => {
        window.open(paymentUrl, '_blank');
      }}
      disabled={isExpired}
    >
      {isExpired ? expiredLabel : label}
    </Button>
  );
};
