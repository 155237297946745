import React from 'react';

import dayjs from 'dayjs';

import { OrderCardComponentOffer } from '@components/OrderCardComponent/OrderCardComponentOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';
import { convertMonthsToTermsSlug } from '@modules/PDP/components/OfferCard/helpers/convertMonthsToTermsSlug';

import { calculatePricesForAggregatedCartItem } from '@theme/ShoppingCart/helpers/calculatePricesForAggregatedCartItem';

const getDeliveryInfo = (country, type, tsb, deliveryPhrases) => {
  if (country === 'de' || type === 'SDA') {
    return {
      notificationBarContent: undefined,
      textContent: deliveryPhrases?.[type?.toLowerCase()]?.value,
    };
  }

  const content = tsb ? deliveryPhrases.deliverytsb.content : deliveryPhrases.deliverytsbcontact.content;

  return {
    notificationBarContent: content,
    textContent: undefined,
  };
};

export const CheckoutOrderCardOffer = item => {
  const { country } = useLocale();
  const translatedConditions = usePhrases({ name: 'conditions' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const terms = usePhrases({ name: 'terms', rawObject: true });
  const general = usePhrases({ name: 'general' });
  const delivery = usePhrases({ name: 'deliverytime', rawObject: true });
  const { currencyFormatter } = useCurrencyFormatter();
  const { calculatedOneTimeFee, calculatedRecurringFee } = calculatePricesForAggregatedCartItem(item);
  const { mainItem } = item;

  const classificationFetch = mainItem.variant;
  const months = mainItem.termMonthsNumber;
  const image = mainItem.content.fullImage.filename;
  const vib = mainItem.content.subtitle;
  const productName = mainItem.content.name;
  const tsb = item.order?.timeslotBookings.find(timeslot => timeslot.status === 'CONFIRMED');
  const tsbDate = tsb && dayjs(tsb.reservationStart).format('DD.MM');
  const tsbReservationStart = tsb && dayjs(tsb.reservationStart).format('HH:mm');
  const tsbReservationEnd = tsb && dayjs(tsb.reservationStop).format('HH:mm');
  const contentVariables = tsb && { date: tsbDate, time: `${tsbReservationStart} - ${tsbReservationEnd}` };
  const type = mainItem.offerItem.groupType;
  const classification = translatedConditions[classificationFetch];
  const { notificationBarContent, textContent } = getDeliveryInfo(country, type, tsb, delivery);
  const duration = `${terms[convertMonthsToTermsSlug(months)]?.value}`;
  const price = `${currencyFormatter({ price: calculatedRecurringFee })} / ${
    unitsTranslations['per-month-short'].value
  }`;
  const oneTimeFee = `+${currencyFormatter({ price: calculatedOneTimeFee })} ${general.oneTimeFee}`;

  return (
    <OrderCardComponentOffer
      image={image}
      vib={vib}
      classification={classification}
      productName={productName}
      content={notificationBarContent}
      contentVariables={contentVariables}
      deliveryTime={textContent}
      duration={duration}
      price={price}
      oneTimeFee={oneTimeFee}
      type={type}
      aggregatedCartItem={item}
      possibleAdditionalItems={item.possibleAdditionalItems}
    />
  );
};
