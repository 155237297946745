import React from 'react';

import { useDrag } from '@use-gesture/react';
import { debounce } from 'lodash';

export const SwipeWrapper = ({ onSwipeLeft, onSwipeRight, children, style }) => {
  const bind = useDrag(({ direction, dragging }) => {
    const isSwipeLeft = direction[0] === -1;
    const isSwipeRight = direction[0] === 1;

    if (dragging) {
      return;
    }
    if (isSwipeLeft) {
      debounce(onSwipeLeft, 100)();
    }

    if (isSwipeRight) {
      debounce(onSwipeRight, 100)();
    }
  });

  return (
    <div style={{ height: 'inherit', ...style }} {...bind()}>
      {children}
    </div>
  );
};
