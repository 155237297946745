import { ReactNode } from 'react';

import styles from './SubMenu.module.scss';
import { AnimatedListElement, Slide } from '../AnimatedListElement';

import { Button, ButtonTheme, ButtonVariant } from '@base-components/Button';
import { Icon } from '@base-components/Icons/availableButtonIcons';

type SubMenuProps = {
  title: string | ReactNode;
  onClick: () => void;
  slideFrom?: Slide;
} & Icon;

export const SubMenu = ({ title, icon, slideFrom, onClick }: SubMenuProps) => {
  return (
    <AnimatedListElement slideFrom={slideFrom}>
      <Button
        variant={ButtonVariant.Icon}
        as="button"
        theme={ButtonTheme.White}
        arrowPosition="right"
        icon={icon}
        onClick={onClick}
        className={styles.mobileLinkButton}
        isFullWidth
      >
        {title}
      </Button>
    </AnimatedListElement>
  );
};
