import React from 'react';

import { RichText } from '@components/RichText/RichText';

import styles from './ContractSwitchBenefits.module.scss';

export const ContractSwitchBenefits = ({ benefits, title }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <RichText document={benefits} />
    </div>
  );
};
