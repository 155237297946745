import React, { useEffect, useState } from 'react';

import Script from 'next/script';

import Loader from '@components/Loader/Loader';
import { RichText } from '@components/RichText/RichText';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useMutationObserver } from '@hooks/useMutationObserver';
import { useTakeoverUserRiskValidationResult } from '@hooks/user-panel/takeover/useTakeoverUserRiskValidationResult';
import { useRedirect } from '@hooks/useRedirect';
import { useTakeoverForm } from '@modules/UserPanel/Takeover/page/TakeoverFormContext/TakeoverFormContext';
import { Paths } from '@utils/paths/paths';

import styles from './TakeoverIdCheckForm.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { sendIdCheckEvent } from '@services/gtm/page-initialization';

const idCheckStatus = {
  awaitingInput: 'awaiting_input',
  processing: 'processing',
  abandoned: 'abandoned',
  error: 'error',
  approved: 'approved',
  review: 'review',
  declined: 'declined',
};

const wasIdCheckPerformedInThisSession = () => {
  const savedWorkflowRunId = sessionStorage.getItem('onfido');

  return savedWorkflowRunId;
};
const MUTATIONS_NEEDED_TO_CORRECTLY_LOAD_WIDGET = 6;

export const TakeoverIdCheckForm = ({
  logo,
  preparingIdCheckText,
  waitingForResultText,
  longerThanUsualText,
  retryButtonText,
}) => {
  const { logger } = useLogger({ context: 'TakeoverIdCheckForm' });
  const { language } = useLocale();
  const wasPerformedInThisSession = wasIdCheckPerformedInThisSession();
  const [isScriptLoading, setScriptLoading] = useState(!wasPerformedInThisSession);
  const [isOnfidoInitialized, setOnfidoInitialized] = useState(false);
  const [isFormCompleted, setFormCompleted] = useState(false);
  const [isWidgetInitializedCorrectly, setWidgetInitializedCorrectly] = useState(false);
  const redirect = useRedirect();
  const {
    idCheckDetails: { workflowRunId, token },
    setNextStep,
  } = useTakeoverForm();
  const { elementRef, isIdle, numberOfMutations, removeObserver } = useMutationObserver({
    callback: (changes, observer) => {
      const removingSpinnerChange = changes.find(change =>
        [...change.removedNodes]?.some(node => node.innerHTML.includes('onfido-sdk-ui-Spinner-loader'))
      );

      const logoShowingChange = changes.find(change =>
        [...change.addedNodes]?.some(
          node =>
            node.className?.includes('onfido-sdk-ui-Theme-step') ||
            node.className?.includes('onfido-sdk-ui-Theme-defaultLogo')
        )
      );

      const areAllMutationsNeededToInitializedWidgetCorrectlyLoaded = Boolean(
        removingSpinnerChange && logoShowingChange
      );

      if (areAllMutationsNeededToInitializedWidgetCorrectlyLoaded) {
        setWidgetInitializedCorrectly(true);
        observer.disconnect();
      }
    },
  });

  useEffect(() => {
    if (!isIdle || isWidgetInitializedCorrectly) {
      return;
    }

    if (numberOfMutations <= MUTATIONS_NEEDED_TO_CORRECTLY_LOAD_WIDGET) {
      sendIdCheckEvent({ variant: 'takeover', status: 'initialization_failed' }).then(() => removeObserver());

      logger.error({
        message: `Onfido widget failed for workflow run id ${workflowRunId}`,
        params: { isWidgetInitializedCorrectly: false, workflowRunId, variant: 'takeover', userId: user.id, language },
      });
    }
  }, [isIdle, numberOfMutations, isWidgetInitializedCorrectly]);

  const handleIdCheckResult = ({ status }) => {
    if (status === idCheckStatus.approved) {
      setNextStep();

      return;
    }
    redirect({ pageId: Paths.CheckoutOrderNotAccepted });
  };

  const { isLoading: isIdCheckResultLoading, isError, refetch } = useTakeoverUserRiskValidationResult(
    isFormCompleted,
    handleIdCheckResult
  );

  useEffect(() => {
    if (wasPerformedInThisSession) {
      setFormCompleted(true);

      return;
    }
    if (!workflowRunId) {
      redirect({ pageId: Paths.Home, notificationPhraseKey: 'session-expired' });
    }
  }, [wasPerformedInThisSession]);

  useEffect(() => {
    if (!isError) {
      return;
    }
    sessionStorage.setItem('onfido', workflowRunId);
  }, [isError]);

  useEffect(() => {
    if (isOnfidoInitialized || isFormCompleted || wasPerformedInThisSession || !workflowRunId) {
      return;
    }
    if (typeof window === 'undefined' || !('Onfido' in window)) {
      return;
    }

    window.Onfido.init({
      token,
      language: language === 'en' ? 'en_US' : language,
      containerId: 'onfido-mount',
      onComplete: () => {
        setFormCompleted(true);
      },
      workflowRunId,
      crossDeviceClientIntroProductName: 'Bluemovement',
      crossDeviceClientIntroProductLogoSrc: logo?.filename || '',
    });

    setOnfidoInitialized(true);
  }, [isScriptLoading, isOnfidoInitialized, isFormCompleted]);

  return (
    <>
      {!wasPerformedInThisSession && (
        <>
          <Script src="https://sdk.onfido.com/v13" onLoad={() => setScriptLoading(false)} />
          <link href="https://sdk.onfido.com/v13/style.css" rel="stylesheet" />
        </>
      )}
      <div className={styles.container}>
        {!isOnfidoInitialized && !wasPerformedInThisSession && (
          <div className={styles.loaderInfoContainer}>
            <RichText document={preparingIdCheckText} />
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          </div>
        )}

        {!isIdCheckResultLoading && <div id="onfido-mount" ref={elementRef} />}

        {isIdCheckResultLoading && (
          <>
            <RichText document={waitingForResultText} className={styles.info} />
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          </>
        )}

        {isError && (
          <>
            <RichText document={longerThanUsualText} className={styles.info} />
            <Button as="button" variant={ButtonVariant.Basic} onClick={refetch}>
              {retryButtonText}
            </Button>
          </>
        )}
      </div>
    </>
  );
};
