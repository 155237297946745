import Pricing, { IPricing } from '@components/Pricing/Pricing';

import { RadioButton, IRadioButton } from './RadioButton';
import styles from './RadioButtonWithPricing.module.scss';

interface RadioGroupWithPricingProps extends Omit<IRadioButton, 'children'> {
  pricing: IPricing;
}

export const RadioButtonWithPricing = ({ pricing, ...radioGroupProps }: RadioGroupWithPricingProps) => {
  return (
    <RadioButton {...radioGroupProps}>
      <Pricing className={styles.pricing} {...pricing} />
    </RadioButton>
  );
};
