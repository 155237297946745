import React, { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import styles from './MobileMenu.module.scss';
import { StoryblokMenuItem } from '../types';
import { MobileMenuContent } from './MobileMenuContent/MobileMenuContent';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

interface MobileMenuProps {
  isCartOpen: boolean;
  menuItems: StoryblokMenuItem[];
  isInfoBar: boolean;
}

export const MobileMenu = ({ isCartOpen, menuItems, isInfoBar }: MobileMenuProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);
  const toggleDropdown = isDropdownOpen ? closeDropdown : openDropdown;

  useEffect(() => {
    if (isCartOpen && isDropdownOpen) {
      closeDropdown();
    }
  }, [isDropdownOpen, isCartOpen]);

  const renderPortal = () => {
    if (typeof window === 'undefined') {
      return;
    }

    const root = document.getElementById('page-content');

    if (!root) {
      throw new Error("Can't find the page-content element");
    }

    if (isDropdownOpen) {
      return createPortal(<MobileMenuContent menuItems={menuItems} isInfoBar={isInfoBar} />, root);
    }

    return null;
  };

  return (
    <div className={styles.menu} role="button">
      <div className={styles.actionIcon}>
        <Button
          variant={ButtonVariant.Icon}
          icon={isDropdownOpen ? 'close' : 'hamburger'}
          theme={ButtonTheme.SoftBlue}
          as="button"
          className={styles.sameSizeButton}
          onClick={toggleDropdown}
        />
      </div>
      {renderPortal()}
    </div>
  );
};
