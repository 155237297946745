const { Locales } = require('../utils/paths/locale');

const languageSettings = {
  [Locales.nl.nl]: { intlLocale: 'nl-NL', currency: 'EUR' },
  [Locales.de.de]: { intlLocale: 'de-DE', currency: 'EUR' },
  [Locales.nl.en]: { intlLocale: 'en-GB', currency: 'EUR' },
  [Locales.de.en]: { intlLocale: 'en-GB', currency: 'EUR' },
};

module.exports = {
  currencyFormatter: locale => ({ price, noUnit = false }) => {
    const selectedConfig = languageSettings[locale];
    if (!selectedConfig) {
      throw new Error('Locale not available');
    }

    const options = {
      style: 'currency',
      currency: selectedConfig.currency,
    };

    return new Intl.NumberFormat(selectedConfig.intlLocale, noUnit ? {} : options)
      .format(price / 100)
      .replace(/^(\D+)/, '$1 ');
  },
};
