import React from 'react';

import cn from 'classnames';
import { groupBy } from 'lodash';
import Link from 'next/link';
import Router from 'next/router';

import { Image } from '@components/Image/Image';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useLocale } from '@hooks/useLocale';
import EnergyLabel from '@modules/PDP/components/EnergyLabel/EnergyLabel';
import OutOfStockForm from '@modules/PDP/components/OutOfStockForm/OutOfStockForm';
import { buildPlanPath } from '@utils/paths/build-paths';

import { InfoBox } from './components/InfoBox/InfoBox';
import { LabelRow } from './components/LabelRow/LabelRow';
import { ProductCardTable } from './components/ProductCardTable/ProductCardTable';
import { TitleContainer } from './components/TitleContainer/TitleContainer';
import { TopBar } from './components/TopBar/TopBar';
import styles from './ProductCard.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { sortPricingsByVariant } from '@helpers/sort-pricings-by-variant';
import { useLogger } from '@hooks/useLogger';
import { isPlanAvailable } from 'helpers/isPlanAvailable';

const infoboxRangeValue = (groupedPricings, translatedConditions) => {
  const translate = Object.keys(translatedConditions)?.reduce((acc, currentKey) => {
    acc.push({
      key: currentKey,
      value: translatedConditions[currentKey],
    });

    return acc;
  }, []);

  return Object.keys(groupedPricings).reduce((acc, currentKey) => {
    const pricings = groupedPricings[currentKey];

    const pricingWithMinimumAmount = pricings.reduce((prevPricing, currPricing) => {
      return currPricing.amount < prevPricing.amount ? currPricing : prevPricing;
    });

    acc.push({
      minValue: pricingWithMinimumAmount.amount,
      key: currentKey,
      condition: translate.find(item => item?.key === currentKey)?.value || currentKey,
      referenceAmount: pricingWithMinimumAmount.referenceAmount,
      isReferenceAmountActive: pricingWithMinimumAmount.isReferenceAmountActive,
    });

    return acc;
  }, []);
};

export const ProductCard = ({ plan, featuresToDisplay, buttonText, image, topBarColor, topBarTitle, index }) => {
  const { logger } = useLogger({ context: 'ProductCard' });
  const { locale } = useLocale();
  const featurePhrases = Object.values(usePhrases({ name: 'features', rawObject: true }));
  const translatedConditions = usePhrases({ name: 'conditions' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const { from, oneTimeFee } = usePhrases({ name: 'general' });
  const isDemandTestCategory = plan.category?.isDemandTest;
  const { currencyFormatter } = useCurrencyFormatter();

  const oneTimeFeeText = `+${currencyFormatter({ price: plan.depositAmount })} ${oneTimeFee}`;

  if (!plan) {
    logger.warn({ message: `Cannot find plan ${plan.name}` });

    return null;
  }

  logger.info({ message: `Plan ${plan.name}` });
  const groupedPricings = groupBy(sortPricingsByVariant(plan.pricings?.filter(pricing => pricing.isActive)), 'variant');

  const url =
    buildPlanPath({
      locale,
      vib: plan.bmId,
      category: plan.category?.slug,
    }) || '#';

  const specialFeatureLabel = featurePhrases?.find(item => item?.key === 'specialFeature')?.label;
  const features = featuresToDisplay.map(feature => {
    const featurePhrase = featurePhrases.find(item => item?.key === feature);
    const featureLink = featurePhrase?.link?.[0];

    return {
      key: feature,
      label: featurePhrase?.label,
      value: plan.content?.features?.find(item => item?.key === feature)?.value || null,
      link: featureLink ? { ...featureLink, dataAttribute: { 'data-pcp-feature': featureLink.headingTitle } } : null,
      icon: featurePhrase?.icon?.filename ? featurePhrase.icon : null,
    };
  });
  const energyLabel = features.find(item => item.key === 'energyClass');
  const isAvailable = isPlanAvailable(plan);

  return (
    <>
      <div className={styles.container} data-plan-type={plan.type}>
        {(!isAvailable || (topBarColor && topBarTitle)) && (
          <TopBar className={styles.topBar} title={topBarTitle} color={topBarColor} available={isAvailable} />
        )}
        <div className={styles.paddingContainer}>
          {groupedPricings && translatedConditions && (
            <div className={styles.content}>
              <LabelRow
                machineCode={plan.content?.subtitle}
                pricingsKeys={infoboxRangeValue(groupedPricings, translatedConditions)}
              />
            </div>
          )}
          <div className={styles.content}>
            <TitleContainer energyLabelDocument={plan.content?.energyLabelDocument} title={plan.content?.name} />
          </div>
          <div className={cn(styles.content, styles.imageContainer)}>
            <EnergyLabel variant={energyLabel?.value} link={energyLabel?.link} productCard />
            <Image
              image={image}
              className={cn(styles.image, styles.imageClickable)}
              onClick={() => {
                Router.push(url);
              }}
              alt="energy label image"
              style={{ objectFit: 'contain', height: 'auto' }}
              lazyLoading={index !== 0}
              width={360}
              height={260}
            />
          </div>
          <div className={styles.content}>
            <ProductCardTable
              specialFeatureLabel={specialFeatureLabel}
              features={features}
              energyLabelDocument={plan.content?.energyLabelDocument}
            />
          </div>
          <div className={styles.summaryContainer}>
            {groupedPricings && translatedConditions && (
              <div className={styles.content}>
                <InfoBox
                  from={from}
                  monthUnit={unitsTranslations?.month?.value}
                  pricingRanges={infoboxRangeValue(groupedPricings, translatedConditions)}
                />
              </div>
            )}
            <div className={styles.content}>
              <div className={styles.deliveryContainer}>{oneTimeFeeText}</div>
            </div>
            <div className={styles.content}>
              {!isAvailable ? (
                <OutOfStockForm vib={plan.bmId} url={url} isDemandTestCategory={isDemandTestCategory} />
              ) : (
                <Link href={url}>
                  <Button as="button" icon="none" className={styles.widthButton} variant={ButtonVariant.Icon}>
                    {buttonText}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
