import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { isTestingEnvironment } from 'helpers/isTestingEnvironment';
import { useLocale } from '../hooks/useLocale';

interface CheckoutTestData {
  testCreditCheckValue?: string;
  testIdCheckValue?: string;
}

export const CheckoutTestData = createContext<{
  testData: CheckoutTestData;
  setTestingData: (newTestingData: CheckoutTestData) => void;
} | null>(null);

export const CheckoutTestDataProvider = ({ children }: { children: ReactNode }) => {
  const isTestingEnv = isTestingEnvironment();
  const { query } = useRouter();
  const { country } = useLocale();
  const { testCreditCheckValue, testIdCheckValue } = query;
  const defaultTestCreditCheckValue = {
    nl: 'R01',
    de: '3',
  };
  const initialState = isTestingEnv
    ? {
        testCreditCheckValue: (testCreditCheckValue as string) || defaultTestCreditCheckValue[country],
        testIdCheckValue: (testIdCheckValue as string) || 'omit',
      }
    : {};
  const [testData, setTestingDataState] = useState<CheckoutTestData>(initialState);

  const setTestingData = (newTestingData: CheckoutTestData) => {
    if (!isTestingEnv) {
      return;
    }

    setTestingDataState(prev => ({ ...prev, ...newTestingData }));
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('testCreditCheckValue');
    urlSearchParams.delete('testIdCheckValue');

    const newParams = urlSearchParams.toString();

    const newUrl = `${window.location.pathname}${newParams.length > 0 ? `?${newParams}` : ''}`;
    window.history.replaceState({}, '', newUrl);
  }, []);

  return (
    <CheckoutTestData.Provider
      value={useMemo(
        () => ({
          testData,
          setTestingData,
        }),
        [testData]
      )}
    >
      {children}
    </CheckoutTestData.Provider>
  );
};

export const useCheckoutTestData = () => {
  const testDataContext = useContext(CheckoutTestData);

  if (!testDataContext) {
    throw new Error('You are out of the testing data context provider!');
  }

  return testDataContext;
};
