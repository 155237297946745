/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { useState, createContext, useEffect, useContext } from 'react';

import * as dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { useLogger } from '@hooks/useLogger';

import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useContentContext } from '@context/content';
import { useLeavePrevention } from '@hooks/useLeavePrevention';
import { useLocale } from '@hooks/useLocale';
import { useProcessTakeoverProviderMutation } from '@hooks/user-panel/takeover/useProcessTakeoverProviderMutation';

import { generateFormOptionsForStep, getLoggedUserData, getUserDataFromTakeover } from './helpers';
import {
  getValidationSchema as getTakeoverProviderConfirmationCheckboxValidationSchema,
  getDefaultValues as getTakeoverProviderConfirmationCheckboxDefaultValues,
} from '../../components/provider/TakeoverProviderConfirmationCheckbox/validation';
import {
  getValidationSchema as getTakeoverProviderSubscriptionsValidationSchema,
  getDefaultValues as getTakeoverProviderSubscriptionsDefaultValues,
  getDefaultValuesForReceiver as getTakeoverReceiverSubscriptionsDefaultValues,
} from '../../components/provider/TakeoverSubscriptions/validation';
import {
  getValidationSchema as getTakeoverSelectTransferDateValidationSchema,
  getDefaultValues as getTakeoverSelectTransferDateDefaultValues,
} from '../../components/provider/TakeoverTransferDate/validation';
import {
  getValidationSchema as getTakeoverTransferReceiverDataValidationSchema,
  getDefaultValues as getTakeoverTransferReceiverDataDefaultValues,
} from '../../components/provider/TakeoverTransferReceiverDataForm/validation';
import {
  getValidationSchema as getTakeoverPaymentFormValidationSchema, // TODO changes required
  getDefaultValues as getTakeoverPaymentFormDefaultValues,
} from '../../components/receiver/TakeoverPaymentForm/validation';
import {
  getValidationSchema as getTakeoverReceiverConfirmationCheckboxValidationSchema,
  getDefaultValues as getTakeoverReceiverConfirmationCheckboxDefaultValues,
} from '../../components/receiver/TakeoverReceiverConfirmationCheckbox/validation';
import {
  getValidationSchema as getTakeoverTransferReceiverAcceptingValidationSchema,
  getDefaultValues as getTakeoverTransferReceiverAcceptingDefaultValues,
} from '../../components/receiver/TakeoverTransferReceiverAcceptingForm/validation';
import { scrollToTop } from 'helpers/scroll-to-top';
import { getStepName } from 'helpers/get-step-name';

dayjs.extend(minMax);

export const TakeoverParticipant = {
  provider: 'provider',
  receiver: 'receiver',
};

export const TakeoverStepsNames = {
  takeoverSelect: 'takeoverSelect',
  takeoverDetails: 'takeoverDetails',
  takeoverCheck: 'takeoverCheck',
  takeoverConfirmation: 'takeoverConfirmation',
  takeoverPayment: 'takeoverPayment',
  takeoverIdCheck: 'takeoverIdCheck',
};

export const TakeoverSteps = {
  provider: {
    takeoverSelect: {
      name: TakeoverStepsNames.takeoverSelect,
      validations: [getTakeoverProviderSubscriptionsValidationSchema],
      defaultValues: [getTakeoverProviderSubscriptionsDefaultValues],
      stepOrder: 1,
    },
    takeoverDetails: {
      name: TakeoverStepsNames.takeoverDetails,
      validations: [getTakeoverSelectTransferDateValidationSchema, getTakeoverTransferReceiverDataValidationSchema],
      defaultValues: [getTakeoverSelectTransferDateDefaultValues, getTakeoverTransferReceiverDataDefaultValues],
      stepOrder: 2,
    },
    takeoverCheck: {
      name: TakeoverStepsNames.takeoverCheck,
      validations: [getTakeoverProviderConfirmationCheckboxValidationSchema],
      defaultValues: [getTakeoverProviderConfirmationCheckboxDefaultValues],
      stepOrder: 3,
    },
    takeoverConfirmation: {
      name: TakeoverStepsNames.takeoverConfirmation,
      validations: [],
      defaultValues: [],
      stepOrder: 4,
    },
  },
  receiver: {
    takeoverCheck: {
      name: TakeoverStepsNames.takeoverCheck,
      validations: [],
      defaultValues: [getTakeoverReceiverSubscriptionsDefaultValues],
      stepOrder: 1,
    },
    takeoverDetails: {
      name: TakeoverStepsNames.takeoverDetails,
      validations: [getTakeoverTransferReceiverAcceptingValidationSchema],
      defaultValues: [getTakeoverTransferReceiverAcceptingDefaultValues],
      stepOrder: 2,
    },
    takeoverIdCheck: {
      name: TakeoverStepsNames.takeoverIdCheck,
      validations: [],
      defaultValues: [],
      stepOrder: 3,
    },
    takeoverPayment: {
      name: TakeoverStepsNames.takeoverPayment,
      validations: [getTakeoverPaymentFormValidationSchema, getTakeoverReceiverConfirmationCheckboxValidationSchema],
      defaultValues: [getTakeoverPaymentFormDefaultValues, getTakeoverReceiverConfirmationCheckboxDefaultValues],
      stepOrder: 4,
    },
    takeoverConfirmation: {
      name: TakeoverStepsNames.takeoverConfirmation,
      validations: [],
      defaultValues: [],
      stepOrder: 5,
    },
  },
};

const disabledLeavePrevention = {
  provider: [TakeoverStepsNames.takeoverConfirmation],
  receiver: [
    TakeoverStepsNames.takeoverCheck,
    TakeoverStepsNames.takeoverDetails,
    TakeoverStepsNames.takeoverConfirmation,
  ],
};

export const TakeoverStepContext = createContext();

const FIRST_STEP_ORDER = 1;

export const TakeoverFormContext = ({ children, subscriptions, validationMessages, user, activeTakeover }) => {
  const { logger } = useLogger({ context: 'TakeoverFormContext' });
  const { currentPageParams } = useContentContext();
  const [type, setType] = useState(TakeoverParticipant[currentPageParams.type]);
  const [stepNumber, setStepNumber] = useState(FIRST_STEP_ORDER);
  const [newestSubscriptionDate, setNewestSubscriptionDate] = useState();
  const [idCheckDetails, setIdCheckDetails] = useState({ token: '', workflowRunId: '' });
  const availableSteps = TakeoverSteps[type];
  const currentStepName = getStepName(availableSteps, stepNumber);
  const useProcessTakeoverProvider = useProcessTakeoverProviderMutation();
  const subscriptionIds = subscriptions
    ?.filter(subscription => subscription.status === 'active')
    .map(subscription => subscription.id);
  const userDefaultValues = user ? getLoggedUserData(user) : getUserDataFromTakeover(activeTakeover);
  const { country } = useLocale();

  const formMethods = useForm(
    generateFormOptionsForStep({
      steps: availableSteps,
      currentStep: availableSteps[currentStepName],
      subscriptionIds,
      newestSubscriptionDate,
      validationMessages,
      country,
      user: userDefaultValues,
    })
  );

  useEffect(() => {
    const subscription = formMethods.watch(value => {
      const selectedSubscriptionsIds = value.items.filter(s => s.subscriptionToTakeover).map(s => s.id);

      const subscriptionDate = new Date(
        Math.max(
          ...subscriptions
            .filter(s => selectedSubscriptionsIds.includes(s.id))
            .map(s => new Date(s.subscriptionActivationDate))
        )
      );

      setNewestSubscriptionDate(subscriptionDate);
    });

    return () => subscription.unsubscribe();
  }, [formMethods.watch]);

  useEffect(() => {
    scrollToTop();
  }, [stepNumber]);

  useLeavePrevention({
    isUnsafeClose: !disabledLeavePrevention[type].includes(currentStepName),
    isDisabled: disabledLeavePrevention[type].includes(currentStepName),
  });

  const setNextStep = () => {
    const [lastStep] = Object.values(availableSteps).sort((a, b) => b.stepOrder - a.stepOrder);
    if (stepNumber === lastStep.stepOrder) {
      return;
    }
    setStepNumber(prev => prev + 1);
  };

  const setPaymentStep = () => {
    setStepNumber(4);
  };

  const providerSubmitHandler = async data => {
    try {
      if (type === 'provider') {
        await useProcessTakeoverProvider.mutateAsync({
          items: data.items.filter(item => item.subscriptionToTakeover),
          takeoverDate: data.takeoverDate,
          newUserFirstName: data.newUserFirstName,
          newUserLastName: data.newUserLastName,
          newUserEmail: data.newUserEmail,
          agreedToTakeoverTerms: data.agreedToTakeoverTerms,
        });

        setNextStep();
      }
    } catch (e) {
      toast(e.data.errors[0].message, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <TakeoverStepContext.Provider
        value={{
          setNextStep,
          step: currentStepName,
          setType,
          type,
          handleSubmit: formMethods.handleSubmit(providerSubmitHandler, errors =>
            logger.error({ message: 'Error in handleSubmit in TakeoverFormContext', params: errors })
          ),
          idCheckDetails,
          setIdCheckDetails,
          setPaymentStep,
        }}
      >
        {children}
      </TakeoverStepContext.Provider>
    </FormProvider>
  );
};

export const useTakeoverForm = () => {
  const takeoverForm = useContext(TakeoverStepContext);

  return takeoverForm;
};
