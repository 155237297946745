import React from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Image } from '@components/Image/Image';
import { Link } from '@components/Link/Link';
import Loader from '@components/Loader/Loader';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useUser } from '@hooks/user/useUser';

import styles from './CurrentAddress.module.scss';

export const CurrentAddress = ({
  locationIcon,
  currentAddressText,
  addressNotCorrectText,
  addressNotCorrectLink,
  noIcon = false,
  className,
  activeTakeover,
}) => {
  const { data: user } = useUser();
  const { getValues } = useFormContext();

  const takeoverUser = activeTakeover?.originalUser ? activeTakeover.originalUser : user;

  // this method will find only first subscription with subscriptionToTakeover property set to true
  // we need to handle more selected subscriptions and get addresses from those subscriptions
  const takeoverProcessSubscription = getValues().items?.find(item => item.subscriptionToTakeover);

  // other method has to be used here in the future, to get more subscriptions at once
  // OR we can filter subscriptions from user (by takeoverProcessSubscription.id)
  const { data: subscriptionToTakeover, isLoading } = useRawSubscriptionById({
    subscriptionId: takeoverProcessSubscription?.id,
  });

  if (!takeoverUser || isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  let shippingAddress;

  // TODO shipping address is taken from first subscription!
  if (subscriptionToTakeover && subscriptionToTakeover.address) {
    shippingAddress = subscriptionToTakeover.address;
  } else if (takeoverUser?.shippingAddress) {
    shippingAddress = takeoverUser.shippingAddress;
  } else {
    shippingAddress = takeoverUser.billingAddress;
  }

  return (
    <div className={cn(styles.container, className)}>
      {!noIcon && (
        <div className={styles.iconContainer}>
          <Image image={locationIcon} mobileImage={locationIcon} className={styles.accountIcon} />
        </div>
      )}

      <div className={styles.addressContainer}>
        <div className={styles.shippingAddress}>
          <span className={styles.bold}>{currentAddressText}</span>

          <span>
            {shippingAddress.street} {shippingAddress.houseNumber}
            {shippingAddress.houseNumberAddon && `/${shippingAddress.houseNumberAddon}`}
          </span>

          <span>
            {shippingAddress.postalCode} {shippingAddress.city}
          </span>
        </div>

        <Link
          {...addressNotCorrectLink?.[0]}
          className={styles.link}
          dataAttributes={{ 'data-analytics-information-card': addressNotCorrectText }}
        >
          {addressNotCorrectText}
        </Link>
      </div>
    </div>
  );
};
