export const OFF_SESSION_PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  SEPA: 'SEPA',
  KLARNA: 'KLARNA',
  IDEAL: 'IDEAL',
};

export const DEPOSIT_PAYMENT_METHODS = {
  CREDIT_CARD: 'CREDIT_CARD',
  IDEAL: 'IDEAL',
  // SOFORT: 'SOFORT',
};

/**
 * Mapping from Adyen API to internal enums payment methods
 */
export const paymentMethodsAdyenToInternalTypesMap = {
  sepadirectdebit: OFF_SESSION_PAYMENT_METHODS.SEPA,
  sepa: OFF_SESSION_PAYMENT_METHODS.SEPA,
  scheme: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
  creditCard: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
  ideal: OFF_SESSION_PAYMENT_METHODS.IDEAL,
  klarna_paynow: OFF_SESSION_PAYMENT_METHODS.KLARNA,
};

export const CHARGEBEE_PAYMENT_METHODS = {
  CARD: 'card',
  DIRECT_DEBIT: 'direct_debit',
  IDEAL: 'ideal',
  SOFORT: 'sofort',
  KLARNA_PAY_NOW: 'klarna_pay_now',
};

export enum PaymentType {
  Card = 'card',
  DirectDebit = 'direct_debit',
  Ideal = 'ideal',
  Sofort = 'sofort',
  KlarnaPayNow = 'klarna_pay_now',
}

/**
 * Mapping from Adyen API to Chargebee API payment methods
 */
export const paymentMethodsAdyenToChargebeeMap = {
  scheme: CHARGEBEE_PAYMENT_METHODS.CARD,
  sepadirectdebit: CHARGEBEE_PAYMENT_METHODS.DIRECT_DEBIT,
};
