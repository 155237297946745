/* eslint-disable import/no-cycle */
import React from 'react';

import styles from './CompactLayout.module.scss';

export const CompactLayout = ({ children, style }) => {
  return (
    <div className={styles.container}>
      <div className={styles.compactLayout} style={style}>
        {children}
      </div>
    </div>
  );
};
