import React, { useState } from 'react';

import styles from './Table.module.scss';
import { Row } from '../Row/Row';

export const Table = ({ title, rows, items, renderers }) => {
  const [showRows, setShowRows] = useState(true);

  return (
    <div className={styles.table}>
      {title && (
        <div className={styles.header} onClick={() => setShowRows(!showRows)} data-table-header={title}>
          <div className={styles.headerTitle}>{title}</div>
          <div className={styles.plusSign}>{showRows ? '-' : '+'}</div>
        </div>
      )}

      <div className={styles.rowContainer}>
        {showRows && rows.map(row => <Row key={row.field} row={row} items={items} renderers={renderers} />)}
      </div>
    </div>
  );
};
