import React from 'react';

import { useCheckoutTestData } from '@context/checkout-test-data';
import { useLocale } from '@hooks/useLocale';

import { Dropdown } from '../inputs/Dropdown/Dropdown';

export const creditCheckResultsDe = [
  { option: 'Success: 3', value: '3' },
  { option: 'Deny: 1', value: '1' },
  { option: 'Not recognized person: 1', value: '1_UNRECOGNIZED_PERSON' },
  { option: 'Deny: 2', value: '2' },
  { option: 'Fail: (wrong format): 210', value: '210' },
  { option: 'Fail: (no result): 799', value: '799' },
  { option: 'Fail: (system error): 999', value: '999' },
  { option: 'Fail: Fill form again (wrong address format): 210', value: '210_WRONG_ADDRESS' },
  { option: 'WARNING! PERFORM REAL REQUEST', value: 'REAL_REQUEST' },
];

export const creditCheckResultsNl = [
  { option: 'Success: R01', value: 'R01' },
  { option: 'Success: R02', value: 'R02' },
  { option: 'Success: R03', value: 'R03' },
  { option: 'Success: R04', value: 'R04' },
  { option: 'Success: R05', value: 'R05' },
  { option: 'Success with debt risk: D01', value: 'D01' },
  { option: 'Success with debt risk: D02', value: 'D02' },
  { option: 'Deny: R06', value: 'R06' },
  { option: 'Deny: R07', value: 'R07' },
  { option: 'Deny: R08', value: 'R08' },
  { option: 'Deny: R09', value: 'R09' },
  { option: 'Fail: server error', value: 'FAILED_PROVIDER' },
  { option: 'Fail: Fill form again (wrong first name)', value: 'FAILED_FIRST_NAME' },
  { option: 'WARNING! PERFORM REAL REQUEST', value: 'REAL_REQUEST' },
];

export const creditCheckResults = {
  de: creditCheckResultsDe,
  nl: creditCheckResultsNl,
};

export const TestCreditCheck = () => {
  const { country } = useLocale();
  const { setTestingData } = useCheckoutTestData();

  return (
    <div style={{ border: '1px solid black', padding: '5px' }}>
      To force credit check result without requesting credit check provider (for tests only). Make sure it is selected,
      because we are paying for every request.
      <Dropdown
        controllerName="testCreditCheckValue"
        labelText="Credit check result"
        entries={creditCheckResults[country]}
        defaultValue={creditCheckResults[country][0].value}
        onChange={value => setTestingData({ testCreditCheckValue: value })}
      />
    </div>
  );
};
