import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { CategoryPathsConfig } from '../utils/paths/category';

const urlTree = {
  [Paths.PDP]: Paths.PCP,
  [Paths.PCP]: Paths.ProductsOverview,
  [Paths.UserPanelSubscriptionCancel]: Paths.UserPanelSubscriptions,
  [Paths.BlogPostNL]: Paths.Blog,
  [Paths.BlogPostDE]: Paths.Blog,
};

export const useRedirectToBackPath = () => {
  const { locale } = useLocale();
  const redirectToBackPath = (pageId, prevParams) => {
    const params = prevParams && { ...prevParams, category: CategoryPathsConfig[prevParams?.category]?.paths[locale] };
    const previousPath = buildPath({ pageId: urlTree[pageId] || Paths.Home, locale, params });

    window.location.href = previousPath;
  };

  return redirectToBackPath;
};
