import React from 'react';

import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from '../CheckoutOneTimePayment.module.scss';

export const CheckoutOneTimePaymentSummaryForOfferDe = ({ summaryDescription }) => {
  const { data: cart } = useCartWithOffer();
  const { currencyFormatter } = useCurrencyFormatter();

  if (!cart || !cart.items) {
    return false;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.summaryDescriptionText}>{summaryDescription}</div>
        <div className={styles.summaryDescriptionText}>{currencyFormatter({ price: cart.totalDepositPrice })}</div>
      </div>
    </div>
  );
};
