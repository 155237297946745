/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';
import { sortBy, minBy } from 'lodash';

import { OfferCardColumn } from '@components/OfferCardColumn/OfferCardColumn';
import { useContentContext } from '@context/content';

import styles from './ProductCardColumn.module.scss';
import DynamicComponent from '../DynamicComponent/DynamicComponent';

import { isPlanAvailable } from 'helpers/isPlanAvailable';

const EnergyClassOrder = {
  a: 1,
  b: 2,
  c: 3,
  d: 4,
  e: 5,
  'a+++': 6,
  'a++': 7,
  'a+': 8,
};

const Items = ({ items }) =>
  items.map((blok, i) => {
    return (
      <div key={blok._uid} className={cn(styles.item, styles.responsiveColumns)}>
        <DynamicComponent {...blok} index={i} />
      </div>
    );
  });

export const ProductCardColumn = ({ items }) => {
  const { allPlans, allOffers } = useContentContext();
  if (allOffers.length > 0) {
    //TODO: OFFER
    return <OfferCardColumn items={items} />;
  }
  const plans = [];

  for (const storyblokItem of items) {
    const slug = storyblokItem.plan.name?.toLowerCase();
    const relatedPlan = allPlans.find(plan => plan.bmId.toLowerCase() === slug);

    if (relatedPlan) {
      storyblokItem.planAvailability = !isPlanAvailable(relatedPlan);
      const energyClassValue = storyblokItem.plan.content?.features
        ?.find(f => f.key === 'energyClass')
        ?.value.toLowerCase();
      storyblokItem.energyClassValue = EnergyClassOrder[energyClassValue];
      storyblokItem.lowestPrice = minBy(relatedPlan.pricings, p => p.amount)?.amount;
      storyblokItem.plan = { ...storyblokItem.plan, ...relatedPlan };
      plans.push(storyblokItem);
    }
  }

  const sortedItems = sortBy(plans, ['planAvailability', 'energyClassValue', 'lowestPrice']);

  return (
    <div className={styles.container}>
      <div className={cn(styles.regularMargin, styles.stackingLayout)}>
        <Items items={sortedItems} />
      </div>
    </div>
  );
};
