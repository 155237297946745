import React from 'react';

import { Link } from '@components/Link/Link';

export const ConditionalLink = ({ link, children, additionalClass }) =>
  (link && (
    <Link {...link} className={additionalClass}>
      {children}
    </Link>
  )) ||
  children;
