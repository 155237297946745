import React from 'react';

import TruckIcon from '@assets/icons/new/truck.svg';
import { Image } from '@components/Image/Image';
import { Label } from '@components/Label/Label';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useFeatureFlag } from '@hooks/useFeatureFlag';

import styles from './OrderCardComponent.module.scss';

export const OrderCardComponent = ({
  image,
  vib,
  classification,
  productName,
  content,
  contentVariables,
  deliveryTime,
  duration,
  price,
  oneTimeFee,
  type,
}) => {
  const { data: isCheckoutTimeslotBookingEnabled } = useFeatureFlag('enable-checkout-timeslot-booking');

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <div className={styles.image}>
          <Image alt="order card image" image={image} />
        </div>

        <div>
          <div className={styles.topRow}>
            <div className={styles.productVib}>{vib}</div>
            <div>
              <Label
                showDot={false}
                labelBackgroundColor="#005DFF"
                text={classification}
                fontColor="white"
                className={styles.labelText}
              />
            </div>
          </div>
          <div className={styles.productName}>{productName}</div>
        </div>
      </div>

      {isCheckoutTimeslotBookingEnabled?.isActive && type === 'MDA' && content && (
        <NotificationBar content={content} contentVariables={contentVariables} variant="checkoutDelivery" />
      )}

      {!content && deliveryTime && (
        <div className={styles.defaultDelivery}>
          <div>
            <TruckIcon />
          </div>
          <div className={styles.deliverySection}>{deliveryTime}</div>
        </div>
      )}

      <div className={styles.durationPriceSection}>
        <div>{duration}</div>
        <div>{price}</div>
      </div>

      <div className={styles.oneTimeFeeSection}>
        <div>{oneTimeFee}</div>
      </div>
    </div>
  );
};
