import * as Yup from 'yup';

import { emailFilter } from '../../../../../../validation/regexps/email/index';
import { passwordComplexityRegExp } from '../../../../../../validation/regexps/password';

export const validationSchema = ({ required, email, password }) =>
  Yup.object().shape({
    loginEmail: Yup.string()
      .required(required)
      .email(email)
      .max(70)
      .matches(emailFilter, email),

    loginPassword: Yup.string()
      .required(required)
      .min(5, password)
      .matches(passwordComplexityRegExp, password),
  });
