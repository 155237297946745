import React from 'react';

import { GenericCarousel } from '@components/GenericCarousel/GenericCarousel';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { useOffers } from '@hooks/cart/useOffers';
import { useMedia } from '@hooks/useMedia';

import styles from './CheckoutCartItemsCarousel.module.scss';
import { CheckoutCartItemCardOffer } from '../CheckoutCartItemCard/CheckoutCartItemCardOffer';

import { aggregateOfferItems } from '@theme/ShoppingCart/helpers/aggregateCartItems';
import { CartItem } from 'types/cart.types';

export const CheckoutCartItemsWithOfferCarousel = () => {
  const { data: cart } = useCartWithOffer();
  const { isMobile } = useMedia();
  const { data: offersInTheCart } = useOffers(cart?.items ? cart.items.map(i => i.offerContentSlug) : []);

  const aggregatedCartItems = aggregateOfferItems((cart?.items || []) as Required<CartItem>[]);

  const aggregatedCartItemsWithPossibleAdditionalServices = aggregatedCartItems.map(item => {
    const offerItems =
      offersInTheCart?.find(offer => offer.contentSlug === item.mainItem.offerContentSlug)?.offerItems || [];
    const additionalItems = offerItems.filter(item => item.type === 'additional');
    const offerIdsInTheCart = cart?.items?.map(i => i.offerItem.id);

    return {
      ...item,
      possibleAdditionalItems: additionalItems.filter(offerItem => offerIdsInTheCart.includes(offerItem.id)),
    };
  });

  if (isMobile) {
    return (
      <GenericCarousel items={aggregatedCartItemsWithPossibleAdditionalServices} className={styles.carousel}>
        {/* @ts-ignore */}
        <CheckoutCartItemCardOffer />
      </GenericCarousel>
    );
  }

  return (
    <div className={styles.container}>
      {aggregatedCartItemsWithPossibleAdditionalServices?.map(item => {
        return <CheckoutCartItemCardOffer key={item.mainItem.id} {...item} />;
      })}
    </div>
  );
};
