import { ReactNode } from 'react';

import styles from './SubMenu.module.scss';
import { AnimatedListElement, Slide } from '../AnimatedListElement';

import { Button, ButtonTheme, ButtonVariant } from '@base-components/Button';

type LocalizationChangeProps = {
  title: ReactNode;
  slideFrom?: Slide;
};

export const LocalizationChange = ({ title, slideFrom }: LocalizationChangeProps) => {
  return (
    <AnimatedListElement slideFrom={slideFrom}>
      <Button
        variant={ButtonVariant.Icon}
        as="button"
        theme={ButtonTheme.White}
        arrowPosition="right"
        icon="none"
        className={styles.mobileLinkButton}
        isFullWidth
      >
        {title}
      </Button>
    </AnimatedListElement>
  );
};
