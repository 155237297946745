/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';

import * as dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';
import { Image } from '@components/Image/Image';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { usePhrases } from '@hooks/context/usePhrases';
import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import ItemPriceList from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/components/ItemPriceList/ItemPriceList';

import styles from './CancellationSubscriptionCard.module.scss';
import { StepContext } from '../../page/CancellationPage/CancellationPage';
import { getRecurringAdditionalServices } from '../../../utils/getRecurringAdditionalServices';

export const CancellationSubscriptionCard = ({
  title,
  contractDuration,
  till,
  notificationBar,
  mdaNotificationBar,
  sdaNotificationBar,
  notActiveNotificationBar,
}) => {
  const router = useRouter();
  const { locale } = useLocale();
  const { data: additionalServicesContent } = useContent({
    locale,
    name: `additional-services`,
  });

  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });
  const stepContext = useContext(StepContext);
  const generalTranslations = usePhrases({ name: 'general', rawObject: true });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });

  const activationDate = subscription?.subscriptionActivationDate;
  const minCancellationDate = subscription?.minimumCancellationDate;
  const status = subscription?.status;
  const groupType = subscription?.plan?.category?.groupType;

  const recurringAdditionalServices = getRecurringAdditionalServices(
    subscription?.additionalServices,
    additionalServicesContent
  );

  return (
    <>
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.wrapper}>
        <div className={styles.productName}>{subscription?.fields?.fullName}</div>

        <div className={styles.contractInfoWrapper}>
          <div className={styles.imageWrapper}>
            <Image alt="mainImage" image={subscription?.fields?.mainImage} />
          </div>

          <div className={styles.contractInfo}>
            {activationDate && minCancellationDate && (
              <div className={styles.contractDurationWrapper}>
                <h3 className={styles.contractDuration}>{contractDuration}</h3>

                <div className={styles.datePill}>
                  <div className={styles.dot}>
                    {dayjs(activationDate).format('DD-MM-YYYY')}&nbsp;<span className={styles.till}>{till}</span>&nbsp;
                    {dayjs(minCancellationDate).format('DD-MM-YYYY')}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.additionalServices}>
              <ItemPriceList
                basicCostPhrase={generalTranslations?.applianceCost?.value || 'Basic costs'}
                subscriptionMonthlyAmount={subscription.monthlyAmount}
                recurringAdditionalServices={recurringAdditionalServices}
                postfixPhrase={unitsTranslations?.month?.value || 'month'}
              />
            </div>
            {notificationBar && (
              <NotificationBar
                {...notificationBar[0]}
                contentVariables={{
                  fee: stepContext.cancellationFee,
                }}
              />
            )}
            {mdaNotificationBar && status === 'active' && groupType === 'MDA' && (
              <NotificationBar
                {...mdaNotificationBar[0]}
                contentVariables={{
                  fee: stepContext.cancellationFee,
                }}
              />
            )}
            {sdaNotificationBar && status === 'active' && groupType === 'SDA' && (
              <NotificationBar
                {...sdaNotificationBar[0]}
                contentVariables={{
                  fee: stepContext.cancellationFee,
                }}
              />
            )}
            {notActiveNotificationBar && status === 'future' && <NotificationBar {...notActiveNotificationBar[0]} />}
          </div>
        </div>
      </div>
    </>
  );
};
