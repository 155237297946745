export const EnergyClassOrder = {
  a: 1,
  'a+++': 2,
  b: 3,
  c: 4,
  'a++': 5,
  d: 6,
  e: 7,
  'a+': 8,
  g: 9,
  f: 10,
};
