import React from 'react';

import cn from 'classnames';

import styles from './FullNavigation.module.scss';
import { Image } from '../../../Image/Image';

export const FullNavigation = ({ images, currentImageIndex, setFullScreen, setCurrentImageIndex }) => {
  return (
    <div className={styles.fullNavigation}>
      {images.map(
        (image, imageIndex) =>
          imageIndex <= 3 && (
            <div
              key={imageIndex}
              className={cn(
                styles.imageWrapper,
                imageIndex === 3 && styles.showMoreContainer,
                currentImageIndex === imageIndex && styles.currentImage
              )}
              onClick={() => (imageIndex === 3 ? setFullScreen(true) : setCurrentImageIndex(imageIndex))}
            >
              {imageIndex === 3 && <span>+{images.length - imageIndex}</span>}
              <Image image={image} alt="appliance" className={styles.image} lazyLoading={false} />
            </div>
          )
      )}
    </div>
  );
};
