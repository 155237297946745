import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';

import { useContent } from '@hooks/useContent';
import { useHttpService } from '@hooks/useHttpService';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';

import { useCartId, CART_STORAGE_KEY } from './useCartId';
import { isCartItemOnStock } from '../../helpers/isCartItemOnStock';

import { Cart, CartResponse } from 'types/cart.types';

export const CART_QUERY_ID = 'cartV2offer';

export const isCreateCartResponse = (data: Cart | CartResponse): data is CartResponse => {
  return 'result' in data;
};

export const useCartWithOffer = ({
  onEmptyCart,
  onSuccess,
  isEnabled = true,
}: {
  onEmptyCart?: () => void;
  onSuccess?: (data: any) => void;
  isEnabled?: boolean;
} = {}) => {
  const [, setCookie, removeCookie] = useCookies([CART_STORAGE_KEY]);

  const httpService = useHttpService();
  const { data: user } = useUser();
  const cartId = useCartId();
  const { locale, country } = useLocale();
  const { data: plansContent } = useContent({ locale, name: `plans-${country}` }); //TODO: OFFER check if sb changed
  const { data: additionalServicesContent } = useContent({ locale, name: `additional-services` }); //TODO: OFFER check if sb changed

  return useQuery<Cart>(
    CART_QUERY_ID,
    async () => {
      if (!cartId) {
        const { data } = await httpService.post('/cart', { userId: user?.id });

        return data;
      }

      const params = {
        isProcessedToPayment: false,
        country,
      };

      const getCartUrl = user ? '/cart/user' : '/cart';
      const { data } = await httpService.get(`${getCartUrl}/${cartId}`, {
        params,
      });

      return data;
    },
    {
      enabled: !!plansContent && isEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: Response) => {
        if (error.status === 404) {
          //@ts-ignore
          removeCookie([CART_STORAGE_KEY], { path: '/', sameSite: 'strict', secure: true }); //TODO: OFFER check if it working
        }
      },
      onSuccess: data => {
        if (!isCreateCartResponse(data) && data?.items.length === 0) {
          onEmptyCart?.();
        }

        if (isCreateCartResponse(data) && data.result === 'CART_CREATED') {
          setCookie(CART_STORAGE_KEY, data.cart.id, { path: '/', sameSite: 'strict', secure: true });
        }

        if (onSuccess) {
          onSuccess(data);
        }
      },
      select: cart => {
        if (isCreateCartResponse(cart)) {
          return cart.cart;
        }
        const itemsWithStockInformationAndContent = cart?.items?.map(item => {
          return {
            ...item,
            content:
              plansContent?.[item?.offerItem?.contentSlug?.toLowerCase()] ||
              additionalServicesContent?.[item?.offerItem?.contentSlug?.toLowerCase()],
            isOnStock: isCartItemOnStock(item),
          };
        });

        return {
          ...cart,
          items: itemsWithStockInformationAndContent,
          hasOutOfStockItems: itemsWithStockInformationAndContent.some(item => !item.isOnStock),
          numberOfAvailableItems: itemsWithStockInformationAndContent.filter(item => item.isOnStock).length,
          numberOfOutOfStockItems: itemsWithStockInformationAndContent.filter(item => !item.isOnStock).length,
        };
      },
    }
  );
};
