import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const FINANCIAL_PRODUCT_EXTERNAL_CHECKOUT_URL_QUERY_ID = 'FINANCIAL_PRODUCT_EXTERNAL_CHECKOUT_URL_QUERY_ID';

export const useFinancialProductExternalCheckoutUrl = (bundleId: number) => {
  const httpService = useHttpService();

  return useQuery<string>(
    [FINANCIAL_PRODUCT_EXTERNAL_CHECKOUT_URL_QUERY_ID, bundleId],
    async () => {
      const { data } = await httpService.get(`/financial/external-checkout-url/${bundleId}`);

      return data;
    },
    {
      enabled: !!bundleId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
