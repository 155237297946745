import { ReactNode } from 'react';

import { createPortal } from 'react-dom';

import { ModalContainer, ModalContainerProps } from './ModalContainer/ModalContainer';

type ModalProps = ModalContainerProps & {
  isOpen: boolean;
};

export const Modal = ({ size, isOpen, title, children, footer, closeModal }: ModalProps) => {
  return isOpen
    ? createPortal(
        <ModalContainer size={size} title={title} footer={footer} closeModal={closeModal}>
          {children}
        </ModalContainer>,
        document.getElementById('page-content')
      )
    : null;
};
