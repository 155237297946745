import React from 'react';

import { OrderCardComponentOffer } from '@components/OrderCardComponent/OrderCardComponentOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { convertMonthsToTermsSlug } from '@modules/PDP/components/OfferCard/helpers/convertMonthsToTermsSlug';

import { calculatePricesForAggregatedCartItem } from '@theme/ShoppingCart/helpers/calculatePricesForAggregatedCartItem';
import { AggregatedItemWithOfferItem, CartItem } from 'types/cart.types';
import { OfferItem } from 'types/offer.types';

export const CheckoutCartItemCardOffer = (
  aggregatedCartItem: AggregatedItemWithOfferItem<Required<CartItem>> & { possibleAdditionalItems: OfferItem[] }
) => {
  const translatedConditions = usePhrases({ name: 'conditions' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const terms = usePhrases({ name: 'terms', rawObject: true });
  const general = usePhrases({ name: 'general' });
  const { currencyFormatter } = useCurrencyFormatter();
  const { calculatedOneTimeFee, calculatedRecurringFee } = calculatePricesForAggregatedCartItem(aggregatedCartItem);
  const { mainItem } = aggregatedCartItem;

  const classificationFetch = mainItem.pricing.variant;
  const months = mainItem.pricing.months;
  const image = mainItem.content.fullImage?.filename;
  const vib = mainItem.content.subtitle;
  const productName = mainItem.content.name;

  const classification = translatedConditions[classificationFetch];
  const duration = `${terms[convertMonthsToTermsSlug(months)]?.value}`;
  const price = `${currencyFormatter({ price: calculatedRecurringFee })} / ${
    unitsTranslations['per-month-short'].value
  }`;
  const oneTimeFee = `+${currencyFormatter({ price: calculatedOneTimeFee })} ${general.oneTimeFee}`;
  const type = mainItem.offerItem?.groupType;

  return (
    <OrderCardComponentOffer
      image={image}
      vib={vib}
      classification={classification}
      productName={productName}
      duration={duration}
      price={price}
      oneTimeFee={oneTimeFee}
      type={type}
      aggregatedCartItem={aggregatedCartItem}
      possibleAdditionalItems={aggregatedCartItem.possibleAdditionalItems}
    />
  );
};
