import React from 'react';

import { useFormContext } from 'react-hook-form';

import styles from './TakeoverTransferReceiverDataForm.module.scss';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useUser } from '@hooks/user/useUser';

const EMAIL_FIELD = 'newUserEmail';

export const TakeoverTransferReceiverDataForm = ({
  firstNameText,
  lastNameText,
  emailAddressText,
  sameEmailErrorText = 'You can not pass the same address as yours',
}) => {
  const { getValues, setError, clearErrors, getFieldState } = useFormContext();
  const { data: user } = useUser();

  const isEmailTheSameAsProvider = () => {
    const newUserEmail = getValues(EMAIL_FIELD);
    if (newUserEmail === user.email) {
      setError(EMAIL_FIELD, { type: 'custom', message: sameEmailErrorText });

      return;
    }
    const fieldError = getFieldState(EMAIL_FIELD).error || {};
    if (fieldError.message === sameEmailErrorText) {
      clearErrors(EMAIL_FIELD);
    }
  };

  return (
    <div className={styles.container}>
      <TextInput controllerName="newUserFirstName" labelText={firstNameText} />
      <TextInput controllerName="newUserLastName" labelText={lastNameText} />
      <TextInput controllerName={EMAIL_FIELD} labelText={emailAddressText} onBlur={isEmailTheSameAsProvider} />
    </div>
  );
};
