import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { Country } from 'types/common.types';
import { PaymentType } from '@helpers/payment';

export const useChangeMandateMutation = () => {
  const httpService = useHttpService();

  return useMutation(
    async ({
      country,
      userCbId,
      paymentType,
      recurringDetailReference,
      newMandateLinkId,
    }: {
      country: Country;
      userCbId: string;
      paymentType: PaymentType;
      recurringDetailReference: string;
      newMandateLinkId: string;
    }) => {
      const response = await httpService.put('/mandate/change', {
        country,
        userCbId,
        paymentType,
        recurringDetailReference,
        newMandateLinkId,
      });

      return response;
    }
  );
};
