import React from 'react';

import Script from 'next/script';

import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { usePlanData } from '@modules/PDP/hooks/usePlanData';

import LoadbeeContent from './LoadbeeContent';
import { LoadbeeModal } from '../modals/LoadbeeModal/LoadbeeModal';

const languageToConfig = {
  nl: {
    locale: 'nl_NL',
    apiKey: 'NAvT4rNZaC53w4YfDBN8YGLutu2JAyZd',
  },
  de: {
    locale: 'de_DE',
    apiKey: 'aEhp5MJrAMsegCDYsMmsgBXn8XGPaHTa',
  },
  en: {
    locale: 'en_NL,nl_NL',
    apiKey: 'F2pNAWmWFWWA2GApuUL37PnFdkEGW3ut',
  },
};

const Type = {
  Modal: 'modal',
  Nested: 'nested',
};

export const Loadbee = ({
  type,
  modalOpenText,
  modalTitleText,
  modalButtonIcon,
  elevated = true,
  fullWidth = false,
  debugMode,
  modalButtonAlign = 'center',
}) => {
  const { logger } = useLogger({ context: 'Loadbee' });
  const { language } = useLocale();
  const { plan, planContent, offerContent, offer } = usePlanData();
  const config = languageToConfig[language || 'en'];
  const mainItems = offer?.offerItems?.filter(item => item.type === 'main');
  // const additionalItems = offer.offerItems.filter(item => item.type === 'additional');
  const mainItem = mainItems?.[0];
  const ean = mainItem?.ean || plan.product.longEan;
  const content = planContent?.content || offerContent?.content;

  if (type === Type.Nested) {
    return (
      <>
        <Script
          defer
          src="//cdn.loadbee.com/js/loadbee_integration.js"
          strategy="afterInteractive"
          onReady={async () => {
            await window?.loadbeeIntegration?.checkAvailability();
            const productCheck = await window?.loadbeeIntegration?.checkProduct();

            if (productCheck.code !== 200) {
              logger.error({ message: 'Something is wrong with the Loadbee widget', params: { productCheck } });
            }
          }}
        />

        <LoadbeeContent apiKey={config.apiKey} locale={config.locale} ean={ean} debugMode={debugMode} />
      </>
    );
  }

  if (type === Type.Modal) {
    return (
      <LoadbeeModal
        config={config}
        modalOpenText={modalOpenText}
        modalTitleText={modalTitleText}
        modalButtonIcon={modalButtonIcon}
        elevated={elevated}
        fullWidth={fullWidth}
        modalButtonAlign={modalButtonAlign}
        ean={ean}
        content={content}
        debugMode={debugMode}
      />
    );
  }

  return <div>Wrong type</div>;
};
