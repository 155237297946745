import React from 'react';

import cn from 'classnames';

import { PriceTag } from './PriceTag';
import styles from './Pricing.module.scss';

export interface IPricing {
  price: number;
  isReferencePriceActive: boolean;
  referencePrice?: number;
  from?: string;
  postfix?: string;
  separator?: string;
  included?: boolean;
  grayedOut?: boolean;
  className?: string;
  includedText?: string;
}

const Pricing = ({
  separator = '/',
  from,
  referencePrice,
  price,
  postfix,
  isReferencePriceActive,
  grayedOut,
  included,
  className,
  includedText = 'Included',
}: IPricing) => {
  return (
    <div className={cn(styles.price, { [styles.grayedOut]: grayedOut, [className]: className })}>
      {isReferencePriceActive && (
        <PriceTag
          from={from}
          price={referencePrice}
          postfix={postfix}
          separator={separator}
          className={styles.referencePrice}
        />
      )}
      {included ? (
        <span className={styles.newPrice}>{includedText}</span>
      ) : (
        <PriceTag
          from={from}
          price={price}
          postfix={postfix}
          separator={separator}
          className={cn({ [styles.newPrice]: isReferencePriceActive })}
        />
      )}
    </div>
  );
};

export default Pricing;
