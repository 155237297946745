import React from 'react';

import NextHead from 'next/head';
import Router from 'next/router';
import Nprogress from 'nprogress';

import getRuntimeConfig from '@utils/getRuntimeConfig';

import { Locale } from 'types/common.types';

Router.events.on('routeChangeStart', () => Nprogress.start());
Router.events.on('routeChangeComplete', () => Nprogress.done());
Router.events.on('routeChangeError', () => Nprogress.done());

const APP_URL = getRuntimeConfig('APP_URL');
const FACEBOOK_DOMAIN_VERIFICATION_KEY = getRuntimeConfig('FACEBOOK_DOMAIN_VERIFICATION_KEY');
const GOOGLE_SITE_VERIFICATION_KEY = getRuntimeConfig('GOOGLE_SITE_VERIFICATION_KEY');

interface HeadProps {
  children?: React.ReactNode;
  seoContent: {
    seoTitle?: string;
    seoDescription?: string;
    seoImage?: { filename: string };
    seoCanonical?: string;
  };
  locale: Locale;
}

const Head = ({ children, seoContent, locale }: HeadProps) => {
  const { seoTitle = 'BlueMovement', seoDescription = 'BlueMovement', seoImage, seoCanonical } = seoContent;

  return (
    <NextHead>
      <title>{seoTitle}</title>
      <meta property="og:type" content="website" />
      <meta key="og:image" content={seoImage?.filename || `${APP_URL}/og_image.png`} property="og:image" />
      <meta key="og:title" content={seoTitle} property="og:title" />
      <meta key="description" content={seoDescription} name="description" />
      <meta key="og:description" content={seoDescription} property="og:description" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="facebook-domain-verification" content="961uza7urr9fc7c71skv22qw1galh1" />

      {seoCanonical && <link rel="canonical" href={`${APP_URL}${seoCanonical}`} />}

      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      {GOOGLE_SITE_VERIFICATION_KEY && <meta name="google-site-verification" content={GOOGLE_SITE_VERIFICATION_KEY} />}
      {FACEBOOK_DOMAIN_VERIFICATION_KEY && (
        <meta name="facebook-domain-verification" content={FACEBOOK_DOMAIN_VERIFICATION_KEY} />
      )}

      {children}
    </NextHead>
  );
};

export default Head;
