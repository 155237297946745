import React from 'react';

import { Image } from '@components/Image/Image';

import styles from './Block.module.scss';

export const Block = ({ text, icon, animated = false }) => (
  <div className={animated ? styles.animatedBlock : styles.block}>
    <div className={styles.icon}>
      <Image alt="block image" image={icon} squareImage style={{ objectFit: 'fill' }} />
    </div>
    <div className={styles.text}>{text}</div>
  </div>
);
