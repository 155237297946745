// TODO Adyen: all commented code is related with disabled default payment method
// will be restore after business decision

import React, { useContext, useEffect } from 'react';

import CreditCardIcon from '@assets/icons/new/creditCard.svg';
import KlarnaIcon from '@assets/icons/new/payment/klarna.svg';
import SepaIcon from '@assets/icons/new/sepa.svg';
import {
  OFF_SESSION_PAYMENT_METHODS,
  // paymentMethodsAdyenToInternalTypesMap
} from '@helpers/payment';

import styles from '../CheckoutPaymentInputs.module.scss';

import { Radio } from '@components/inputs/Radio/Radio';
// import { useUserPaymentMethods } from '@hooks/payment/useUserPaymentMethods';
// import { useUser } from '@hooks/user/useUser';
import '@adyen/adyen-web/styles/adyen.css';
import { AdyenContext } from '@context/adyen';
import adyenOverrideStyles from './CheckoutPaymentInputsDe.module.scss';

import cn from 'classnames';
import { useLocale } from '@hooks/useLocale';
import { Country } from 'types/common.types';

import { AdyenCheckout, Card, Klarna, Redirect, SepaDirectDebit } from '@adyen/adyen-web';
// import { useLogger } from '@hooks/useLogger';

export const CHECKOUT_PAYMENT_INPUTS_DE = { CARD: 'CARD', KLARNA: 'KLARNA', SEPA: 'SEPA' };

export const CheckoutPaymentInputsDe = ({
  sepaDescription,
  sepaName,
  creditCardDescription,
  creditCardName,
  // recurringConsentText,
  // recurringConsentRichText,
  // alreadyExistingPaymentMethodInfoBarTitle,
  // alreadyExistingPaymentMethodInfoBarMessage,
  setValidationError,
  validationError,
  enabledCheckoutPaymentInputs = [CHECKOUT_PAYMENT_INPUTS_DE.CARD],
  configuration,
  // noDefaultPayment = false,
}) => {
  // const { logger } = useLogger({ context: 'CheckoutPaymentInputsDe' });
  const { country } = useLocale();
  // const { data: user } = useUser();
  // const { data: paymentSources } = useUserPaymentMethods(user?.cbId);
  const adyenContext = useContext(AdyenContext);
  // const storedPaymentMethod = adyenContext?.paymentMethods?.storedPaymentMethods?.[0];

  const createSepaPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const sepa = new SepaDirectDebit(checkout);

    adyenContext.setAttachedPaymentMethod(sepa);

    return sepa;
  };

  const createCardPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const card = new Card(checkout, {
      hasHolderName: true,
      holderNameRequired: true,
      positionHolderNameOnTop: true,
    });

    adyenContext.setAttachedPaymentMethod(card);

    return card;
  };

  const createKlarnaPaymentMethod = async () => {
    const checkout = await AdyenCheckout(configuration);
    const klarna = new Klarna(checkout, {
      useKlarnaWidget: false,
      type: 'klarna_paynow',
    });

    adyenContext.setAttachedPaymentMethod(klarna);

    return klarna;
  };

  const attachStoredPaymentMethod = async () => {
    const storedPaymentMethod = await createStoredPaymentMethod();

    // TODO Adyen: hack here, fix it later
    setTimeout(() => {
      const node = document.querySelector('#stored-payment-method');

      if (node) {
        storedPaymentMethod.mount('#stored-payment-method');
      }
    }, 1000);
  };

  useEffect(() => {
    const attachDefaultPaymentMethod = async () => {
      const cardPaymentMethod = await createCardPaymentMethod();
      cardPaymentMethod.mount('#card-container');
    };

    if (
      // !paymentSources &&
      !adyenContext.attachedPaymentMethod
    ) {
      attachDefaultPaymentMethod();

      return;
    }

    // if (paymentSources && !adyenContext.attachedPaymentMethod) {
    //   attachStoredPaymentMethod();

    //   return;
    // }
  }, []);

  // const paymentMethodType = paymentMethodsAdyenToInternalTypesMap?.[storedPaymentMethod?.type];

  // const createStoredPaymentMethod = async () => {
  //   const storedPaymentMethod = adyenContext?.paymentMethods?.storedPaymentMethods?.[0];

  //   if (!storedPaymentMethod) {
  //     logger.error({ message: `No stored payment method for user ${user?.id}`, params: { userId: user?.id } });

  //     return;
  //   }

  //   const checkout = await AdyenCheckout(configuration);
  //   let paymentMethod;

  //   if (storedPaymentMethod.type === 'scheme') {
  //     paymentMethod = new Card(checkout, storedPaymentMethod);
  //   }

  //   if (storedPaymentMethod.type === 'sepadirectdebit') {
  //     paymentMethod = new SepaDirectDebit(checkout, storedPaymentMethod);
  //   }

  //   if (storedPaymentMethod.type === 'redirect') {
  //     paymentMethod = new Redirect(checkout, storedPaymentMethod);
  //   }

  //   adyenContext.setAttachedPaymentMethod(paymentMethod);

  //   return paymentMethod;
  // };

  if (country !== Country.DE) {
    return <></>;
  }

  return (
    <>
      {/* {paymentSources && !noDefaultPayment && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={`default_${paymentMethodType}`}
          onClick={async () => {
            await attachStoredPaymentMethod();
          }}
          labelComponent={
            <div className={styles.radioLabel}>
              {
                // paymentMethodType === OFF_SESSION_PAYMENT_METHODS.SEPA ? (
                //   <Sepa />
                // ) :
                paymentMethodType === OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD ? <CreditCardIcon /> : ''
              }
              <div className={styles.radioLabelTextContainer}>
                TODO ADYEN: Need translation here
                <span className={styles.radioLabelLightText}>Default payment method</span>
                <span className={styles.radioLabelNameText}>
                  {
                    // paymentMethodType === OFF_SESSION_PAYMENT_METHODS.SEPA
                    //   ? sepaName
                    //   :
                    paymentMethodType === OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD ? creditCardName : ''
                  }
                </span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />

          <div className={styles.inputContainer}>
            <div id="stored-payment-method"></div>
          </div>
        </Radio>
      )} */}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_DE.CARD) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD}
          onClick={async () => {
            const cardPaymentMethod = await createCardPaymentMethod();
            cardPaymentMethod.mount('#card-container');
          }}
          labelComponent={
            <div className={styles.radioLabel}>
              <CreditCardIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{creditCardDescription}</span>
                <span className={styles.radioLabelNameText}>{creditCardName}</span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />

          <div className={styles.inputContainer}>
            <div
              id="card-container"
              onFocus={() => {
                setValidationError(false);
              }}
              className={cn({
                [adyenOverrideStyles.error]: validationError,
                [adyenOverrideStyles.base]: !validationError,
              })}
            ></div>
          </div>
        </Radio>
      )}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_DE.SEPA) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.SEPA}
          onClick={async () => {
            const sepaPaymentMethod = await createSepaPaymentMethod();
            sepaPaymentMethod.mount('#sepa-container');
          }}
          isLastRadio
          labelComponent={
            <div className={styles.radioLabel}>
              <SepaIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{sepaDescription}</span>
                <span className={styles.radioLabelNameText}>{sepaName}</span>
              </div>
            </div>
          }
        >
          <hr className={styles.radioChildrenSeperator} />
          <div className={styles.inputContainer}>
            <div id="sepa-container"></div>
          </div>
        </Radio>
      )}

      {enabledCheckoutPaymentInputs.includes(CHECKOUT_PAYMENT_INPUTS_DE.KLARNA) && (
        <Radio
          controllerName="monthlyPaymentMethod"
          value={OFF_SESSION_PAYMENT_METHODS.KLARNA}
          onClick={async () => {
            const klarnaPaymentMethod = await createKlarnaPaymentMethod();
            klarnaPaymentMethod.mount('#klarna_paynow-container');
          }}
          isLastRadio
          labelComponent={
            <div className={styles.radioLabel}>
              <KlarnaIcon />
              <div className={styles.radioLabelTextContainer}>
                <span className={styles.radioLabelLightText}>{sepaDescription}</span>
                <span className={styles.radioLabelNameText}>Klarna Pay now</span>
              </div>
            </div>
          }
        >
          <div id="klarna_paynow-container"></div>
        </Radio>
      )}
    </>
  );
};
