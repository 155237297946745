import React from 'react';

import { Label } from '@components/Label/Label';
import { useContentContext } from '@context/content';

import styles from './BlogFilterLabel.module.scss';

export const LabelColorVariant = {
  lightBlue: { font: '#000000', background: '#AAD6FF' },
  blue: { font: '#FFFFFF', background: '#005DFF' },
  darkBlue: { font: '#FFFFFF', background: '#141B4A' },
  green: { font: '#000000', background: '#8AD861' },
  yellow: { font: '#000000', background: '#FFBF00' },
  pink: { font: '#000000', background: '#FF6F96' },
};

export const BlogFilterLabel = ({ blogCategory }) => {
  const { blogCategories } = useContentContext();
  const content = blogCategories.find(category => category.uuid === blogCategory)?.content;
  const { font, background } = content?.color ? LabelColorVariant[content.color] : LabelColorVariant.lightBlue;

  return (
    <Label
      text={content?.text}
      fontColor={font}
      labelBackgroundColor={background}
      showDot={false}
      className={styles.text}
    />
  );
};
