import React from 'react';

import { RadioButtonWithPricing } from '@refactoredComponents/inputs/RadioGroup/RadioButtonWithPricing';
import { PaymentPlan } from 'types/frontend-financial-product.types';
import { Offer, OfferContractType, OfferItemOneTimeRecurringPricing } from 'types/offer.types';
import { PricingVariant } from 'types/plan.types';

import { Link } from '@components/Link/Link';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './OfferCardPaymentPlans.module.scss';

export const OfferCardPaymentPlans = ({
  paymentPlanText,
  paymentPlanLink,
  selectedPaymentPlan,
  setSelectedPaymentPlan,
  readMoreText,
  groupedPricings,
  groupedPricingsForFinancial,
  setSelectedMainProductPricing,
  offer,
  isFinancialProduct,
}: {
  paymentPlanText: string;
  paymentPlanLink: any;
  groupedPricings: Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>;
  groupedPricingsForFinancial: any;
  currencyFormatter: any;
  readMoreText: string;
  setSelectedPaymentPlan: any;
  selectedPaymentPlan: PaymentPlan;
  setSelectedMainProductPricing: any;
  offer: Offer;
  isFinancialProduct: boolean;
}) => {
  const { financial, renting } = usePhrases({ name: 'offer-types' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const { from } = usePhrases({ name: 'general' });

  const options = [];

  const getTheLowestPricing = (
    groupedPricings: Partial<Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>>
  ) => {
    const itemWithTheLowestPrice = [PricingVariant.New, PricingVariant.Refurbished]
      .flatMap(variant => groupedPricings[variant] || [])
      .reduce((lowest, item) => (!lowest || item.recurringAmount < lowest.recurringAmount ? item : lowest), null);

    return itemWithTheLowestPrice;
  };

  const theLowestPricing = getTheLowestPricing(groupedPricings);

  if ([OfferContractType.Rental, OfferContractType.RentalAndFinancing].includes(offer.contractType)) {
    options.unshift({
      id: PaymentPlan.Renting,
      name: 'paymentPlans',
      label: renting,
      value: PaymentPlan.Renting,
      checked: selectedPaymentPlan === PaymentPlan.Renting,
      dataAttribute: { 'data-test-params-payment': PaymentPlan.Renting, 'data-test-id': 'payment-plan' },
      onChange: () => {
        setSelectedMainProductPricing((groupedPricings['new'] || groupedPricings['refurbished'])[0]);
        setSelectedPaymentPlan(PaymentPlan.Renting);
      },
      pricing: {
        referencePrice: theLowestPricing?.referenceRecurringAmount,
        isReferencePriceActive: theLowestPricing?.isReferenceAmountActive,
        price: theLowestPricing?.recurringAmount,
        postfix: unitsTranslations?.month?.value,
        from,
      },
    });
  }

  if (
    isFinancialProduct &&
    [OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(offer.contractType)
  ) {
    const financialPricing = groupedPricingsForFinancial['new'][0];

    options.unshift({
      id: PaymentPlan.Finance,
      name: 'paymentPlans',
      label: financial,
      value: PaymentPlan.Finance,
      checked: selectedPaymentPlan === PaymentPlan.Finance,
      dataAttribute: { 'data-test-params-payment': PaymentPlan.Finance, 'data-test-id': 'payment-plan' },
      onChange: () => {
        setSelectedMainProductPricing(financialPricing);
        setSelectedPaymentPlan(PaymentPlan.Finance);
      },
      pricing: {
        referencePrice: financialPricing?.referenceRecurringAmount,
        isReferencePriceActive: financialPricing?.isReferenceAmountActive,
        price: financialPricing?.recurringAmount,
        postfix: unitsTranslations?.month?.value,
      },
    });
  }

  return (
    <div className={styles.conditionsContainer}>
      <div className={styles.conditionsHeader}>
        <span className={styles.conditionHeaderText}>{paymentPlanText}</span>

        <Link
          {...paymentPlanLink?.[0]}
          className={styles.link}
          dataAttributes={{ 'data-analytics-read-more': paymentPlanText, 'data-analytics-modal-content': 'Financial' }}
        >
          {readMoreText}
        </Link>
      </div>

      {options.map(option => (
        <RadioButtonWithPricing key={option.id} {...option} />
      ))}
    </div>
  );
};
