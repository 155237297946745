import React from 'react';

import { useFormContext } from 'react-hook-form';

import styles from './TakeoverProviderConfirmationCheckbox.module.scss';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { RichText } from '@components/RichText/RichText';

export const TakeoverProviderConfirmationCheckbox = ({ text }) => {
  const { getValues } = useFormContext();
  const values = getValues();

  return (
    <div className={styles.container}>
      <Checkbox
        controllerName="agreedToTakeoverTerms"
        theme={Themes.White}
        labelText={
          <RichText
            document={text}
            contentVariables={{
              takeoverDate: values.takeoverDate,
              newUserEmail: values.newUserEmail,
              newUserFirstName: values.newUserFirstName,
              newUserLastName: values.newUserLastName,
            }}
          />
        }
      />
    </div>
  );
};
