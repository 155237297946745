interface StepData {
  name: string;
  stepOrder: number;
}
type Steps = Record<string, StepData>;

export const getStepName = (steps: Steps, currentStepNumber: number) => {
  const stepsObj = Object.values(steps);

  return stepsObj.find(step => step.stepOrder === currentStepNumber)?.name;
};
