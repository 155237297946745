import React from 'react';

import styles from './LoadbeeContent.module.scss';

const LoadbeeContent = ({ apiKey, locale, ean, debbugMode }) => {
  return (
    <div className={styles.container}>
      <div
        className="loadbeeTabContent"
        data-loadbee-apikey={apiKey}
        data-loadbee-gtintype="ean"
        data-loadbee-gtin={ean}
        data-loadbee-locale={locale}
        data-loadbee-debug={debbugMode}
      />
    </div>
  );
};

export default LoadbeeContent;
