import React from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './Radio.module.scss';

export const Themes = {
  White: 'white',
  Gray: 'gray',
};

export const Radio = ({
  controllerName,
  theme = Themes.White,
  children,
  value,
  labelComponent,
  isLastRadio,
  activeButtonClassName,
  onClick,
  dataAttribute,
}) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const error = get(errors, controllerName);
  const isError = error !== undefined && error?.message;
  const currentFieldVal = watch(controllerName);
  const isChecked = currentFieldVal === value;

  return (
    <Controller
      render={({ field: { ref, ...field } }) => {
        return (
          <div className={styles.fieldContainer}>
            <div
              className={cn(
                styles.labelContainer,
                {
                  [styles.grayLabel]: theme === Themes.Gray,
                  [styles.whiteLabel]: theme === Themes.White,
                  [styles.labelWithBorder]: activeButtonClassName,
                },
                activeButtonClassName && { [activeButtonClassName]: isChecked }
              )}
            >
              <label>
                <div className={styles.radioContainer} data-radio-input={`${controllerName}-${value}`}>
                  <input
                    type="radio"
                    {...register(controllerName)}
                    {...field}
                    value={value}
                    checked={isChecked}
                    onClick={onClick}
                    data-test-id={controllerName}
                    {...dataAttribute}
                  />
                  <span className={styles.checkmark} />
                  {labelComponent || value}
                </div>
              </label>
              {children && isChecked && <div className={styles.children}>{children}</div>}
            </div>

            {isLastRadio && isError ? <span className={styles.errorMessage}>({error?.message})</span> : null}
          </div>
        );
      }}
      name={controllerName}
      type="radio"
      control={control}
      value={value}
    />
  );
};
