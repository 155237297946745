import React from 'react';

import styles from './CheckoutContactMethods.module.scss';

import { Image } from '@components/Image/Image';
import { Link } from '@components/Link/Link';

export const CheckoutContactMethods = ({ titleText, items = [] }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleText}>{titleText}</div>
      <ul className={styles.infoLinks}>
        {items.map(({ icon, title, subtitle, link }) => (
          <li key={title} className={styles.itemContainer}>
            <Link label={title} link={link}>
              <div className={styles.item}>
                {icon && (
                  <div className={styles.icon}>
                    <Image style={{ objectFit: 'contain' }} image={icon} />
                  </div>
                )}
                <div className={styles.body}>
                  <div className={styles.titleBodyText}>{title}</div>
                  <div className={styles.subtitleBodyText}>{subtitle}</div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
