import DEFlag from '@assets/icons/flags/de.svg';
import ENFlag from '@assets/icons/flags/en.svg';
import NLFlag from '@assets/icons/flags/nl.svg';
import { buildLocalizedPath, buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { getEnabledCountries, getEnabledLangsForCountry } from '@services/locale-config';
import { Country, Language } from 'types/common.types';

export const flagsMap = {
  nl: NLFlag,
  en: ENFlag,
  de: DEFlag,
};

type LocalizationChangeArgs = {
  language: Language;
  country: Country;
  currentCountry?: Country;
  currentPageId?: string;
  currentPageParams?: {
    category?: string;
    vib?: string;
  };
};

export const handleLocalizationChange = ({
  language,
  country,
  currentCountry,
  currentPageId,
  currentPageParams,
}: LocalizationChangeArgs) => {
  let newPath;
  if (currentCountry === country) {
    newPath = buildLocalizedPath({
      pageId: currentPageId,
      category: currentPageParams?.category,
      vib: currentPageParams?.vib,
      country,
      language,
    });
  } else {
    newPath = buildPath({
      pageId: Paths.Home,
      country,
      language,
    });
  }

  if (newPath) {
    window.location.href = `${newPath}${window.location.search || ''}`;
  }
};

export const buildLocalizationOptions = (callback: (countryCode: Country, languageCode: Language) => any) => {
  return getEnabledCountries().map(item => {
    const countryCode = item.code as Country;
    const countryLanguages = getEnabledLangsForCountry(countryCode);

    return countryLanguages.map(({ shortCode: languageCode }) => callback(countryCode, languageCode as Language));
  });
};
