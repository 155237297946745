import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { OFF_SESSION_PAYMENT_METHODS } from '@helpers/payment';

import { defaultValues, validationSchema } from './validation';

import { mergeUserFullName } from 'helpers/mergeUserFullName';

export const getDefaultValuesForWholeProcess = (takeoverSteps, { subscriptionIds, user }) => {
  const stepProperties = Object.values(takeoverSteps);
  const defaultValuesForWholeProcess = stepProperties.reduce((acc, { defaultValues }) => {
    const defaultValuesForStep = defaultValues.reduce(
      (stepAcc, defaultValueGetter) => ({ ...stepAcc, ...defaultValueGetter({ subscriptionIds, user }) }),
      {}
    );

    return {
      ...acc,
      ...defaultValuesForStep,
    };
  }, {});

  return defaultValuesForWholeProcess;
};

export const getValidationSchemaForStep = ({
  validationsForStep,
  validationMessages,
  country,
  newestSubscriptionDate,
}) => {
  const validationSchemaForStep = validationsForStep.reduce((acc, validator) => {
    return {
      ...acc,
      ...validator({ newestSubscriptionDate, messages: validationMessages, country }),
    };
  }, {});

  return validationSchemaForStep;
};

export const generateFormOptionsForStep = ({
  steps,
  currentStep,
  subscriptionIds,
  validationMessages,
  user,
  country,
  newestSubscriptionDate,
}) => {
  const validationsForStep = getValidationSchemaForStep({
    validationsForStep: currentStep.validations,
    validationMessages,
    country,
    newestSubscriptionDate,
  });
  const defaultValuesForSteps = getDefaultValuesForWholeProcess(steps, { subscriptionIds, user });

  const formOptions = {
    resolver: yupResolver(validationSchema(validationsForStep)),
    defaultValues: defaultValues(defaultValuesForSteps),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  return formOptions;
};

export const getUserDataFromTakeover = activeTakeover => {
  const { newUserFirstName, newUserLastName } = activeTakeover;
  const userFullName = `${newUserFirstName} ${newUserLastName}`;

  return {
    newUserFirstName,
    newUserLastName,
    newUserEmail: '',
    newUserSalutation: '',
    newUserBirthdate: '',
    newUserPhoneNumber: '',
    newUserPassword: '',
    agreeTermsAndConditions: false,
    sepaAccountOwner: userFullName,
    creditCardOwner: userFullName,
    monthlyPaymentMethod: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
    reccuringPaymentMandate: false,
    customerType: 'private',
  };
};

export const getLoggedUserData = user => {
  const userFullName = mergeUserFullName({
    firstName: user?.billingAddress?.firstName,
    lastName: user?.billingAddress?.lastName,
  });

  return {
    customerType: user?.customerType || 'private',
    newUserFirstName: user?.billingAddress?.firstName || '',
    newUserLastName: user?.billingAddress?.lastName || '',
    newUserEmail: user?.email || '',
    newUserSalutation: user?.salutation || '',
    newUserBirthdate: (user?.birthdate && dayjs(user?.birthdate).format('DD-MM-YYYY')) || '',
    newUserPhoneNumber: user?.phone || '',
    newUserPassword: '',
    agreeTermsAndConditions: false,
    sepaAccountOwner: userFullName,
    creditCardOwner: userFullName,
    monthlyPaymentMethod: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
    reccuringPaymentMandate: user?.recurringPaymentMandate,
  };
};
