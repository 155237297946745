import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

export interface PriceTagProps {
  separator: string;
  from: string;
  price: number;
  postfix: string;
  className?: string;
}

export const PriceTag = ({ separator, from, price, postfix, className }: PriceTagProps) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const fromString = from ? `${from} ` : '';

  return <span className={className}>{[fromString, currencyFormatter({ price }), separator, postfix].join('')}</span>;
};
