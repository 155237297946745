/**
 * Regular expression pattern to validate names and surnames.
 * This regex pattern ensures the following:
 * - The name starts with one or more Unicode letters.
 * - The name can contain sequences of Unicode letters, spaces, apostrophes, and hyphens.
 * - The pattern does not allow any other characters, ensuring names adhere to common naming conventions across various languages.
 *
 * @constant {RegExp} nameRegExp - The regular expression pattern for validating names.
 *
 *  Valid names:
 *  - Anna Maria
 *  - Jean-Luc
 *  - O'Reilly
 *  - Łukasz
 *
 * Invalid names:
 * - John  Smith  (Contains consecutive spaces)
 * - John--Smith  (Contains consecutive hyphens)
 * - John/Smith (Contains a slash)
 * - John123    (Contains numbers)
 * - John@Smith (Contains special characters not allowed)
 */
export const nameRegExp = /^[\p{L}]+(?:[ '-]\p{L}+)*$/u;
