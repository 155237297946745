import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from './OneTimePaymentSummary.module.scss';

export const OneTimePaymentSummary = ({ price, withoutPlus = false }) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const { oneTimeFee } = usePhrases({ name: 'general' });

  return (
    <div className={styles.summary}>{`${withoutPlus ? '' : '+'} ${currencyFormatter({ price })} ${oneTimeFee ||
      'One time fee'}`}</div>
  );
};
