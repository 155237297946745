import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { usePhrases } from '@hooks/context/usePhrases';
import { useGlobalContent } from '@hooks/useGlobalContent';
import { useHttpService } from '@hooks/useHttpService';
import { useLocale } from '@hooks/useLocale';

import styles from './OutOfStockForm.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';


const OutOfStockForm = ({
  vib,
  className = '',
  url = '',
  additionalServices = {},
  isDemandTestCategory = false,
  showButton = true,
}) => {
  const { logger } = useLogger({ context: 'OutOfStockForm' });
  const { locale, country, language } = useLocale();
  const httpService = useHttpService();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const { data: globalContent } = useGlobalContent({
    locale,
  });

  const content =
    globalContent?.[isDemandTestCategory ? 'out-of-stock-form-for-demand-test' : 'out-of-stock-form']?.global?.[0];

  const [isOpen, setIsOpen] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);

  const formOptions = {
    defaultValues: {},
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(content?.requiredFieldError),
        email: Yup.string()
          .email()
          .required(content?.requiredFieldError),
      })
    ),
  };

  const methods = useForm(formOptions);

  const sendForm = async ({ email, name }) => {
    try {
      const payload = { email, name, vib, country, language, additionalServices };
      await httpService.post('/plan/availability-notification', payload);
      setIsSent(true);
    } catch (error) {
      const genericErrorMessage = errorPhrases?.['generic-error']?.value;

      methods.setError('email', { message: genericErrorMessage });
    }
  };

  const onSubmit = async formData => {
    await sendForm(formData);
  };

  return content ? (
    <div className={styles.container}>
      {showButton &&
        (url ? (
          <Button
            as="anchor"
            href={url}
            className={styles.widthButton}
            variant={ButtonVariant.Icon}
            icon="none"
            theme={ButtonTheme.Tertiary}
          >
            {content.title}
          </Button>
        ) : (
          <Button
            as="button"
            variant={ButtonVariant.Basic}
            className={styles.widthButton}
            theme={ButtonTheme.Tertiary}
            disabled
          >
            {content.title}
          </Button>
        ))}

      {!isSent ? (
        <div className={cn(styles.formContainer, className)}>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit, errors =>
                logger.error({ message: 'Error in onSubmit in OutOfStockForm', params: errors })
              )}
              className={styles.form}
            >
              <Checkbox
                controllerName={`outOfStockForm-${vib}`}
                labelText={content.checkboxText}
                theme={Themes.WhiteNoShadow}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className={styles.checkboxContainer}>
                  <div className={styles.nameInputContainer}>
                    <span className={styles.inputTitle}>{content.nameText}</span>
                    <TextInput controllerName="name" />
                  </div>
                  <div className={styles.emailInputContainer}>
                    <span className={styles.inputTitle}>{content.emailText}</span>
                    <TextInput controllerName="email" />
                  </div>
                  <Button
                    as="button"
                    variant={ButtonVariant.Icon}
                    icon="none"
                    className={styles.sendButton}
                    type="submit"
                  >
                    {content.notifyMeText}
                  </Button>
                </div>
              </Checkbox>
            </form>
          </FormProvider>
        </div>
      ) : (
        <div className={styles.notificationContainer} data-out-of-stock-form-submitted="success">
          <NotificationBar variant="success" shadow="grounded" content={content.successText} />
        </div>
      )}
    </div>
  ) : null;
};

export default OutOfStockForm;
