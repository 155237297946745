import React from 'react';

import Link from 'next/link';

import DownloadableWrapper from '@components/DownloadableWrapper/DownloadableWrapper';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './TitleContainer.module.scss';

export const TitleContainer = ({
  title,
  energyLabelDocument,
  href,
  onClick,
}: {
  title: string;
  energyLabelDocument: any;
  href?: string;
  onClick?: () => void;
}) => {
  const { energyLabel } = usePhrases({ name: 'general' });

  const documentSrc =
    (energyLabelDocument?.fieldtype === 'asset' && energyLabelDocument.filename) ||
    (typeof energyLabelDocument === 'string' && energyLabelDocument);

  return (
    <>
      {href ? (
        <Link href={href} onClick={onClick}>
          <div className={styles.titleContainer}>{title}</div>
        </Link>
      ) : (
        <div className={styles.titleContainer}>{title}</div>
      )}
      {documentSrc && (
        <DownloadableWrapper url={documentSrc}>
          <span className={styles.text}>{energyLabel}</span>
        </DownloadableWrapper>
      )}
    </>
  );
};
