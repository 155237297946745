import React from 'react';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import Loader from '@components/Loader/Loader';
import { useUser } from '@hooks/user/useUser';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { interpolateContent } from '@utils/interpolate-content';

import styles from './RefBlock.module.scss';
import { RefBlockSteps } from './RefBlockSteps/RefBlockSteps';

const APP_URL = getRuntimeConfig('APP_URL');

export const RefBlock = ({ title, descriptionText, refSteps, shareCodeTitle, socialMediaQuote }) => {
  const { data: user } = useUser();

  if (!user) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const socialMediaText = interpolateContent(socialMediaQuote, { referralCoupon: user.referralCoupon });
  const referralCoupon = user.referralCoupon;

  if (!referralCoupon) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div>{descriptionText}</div>
      <RefBlockSteps steps={refSteps} />
      <div className={styles.codeContainer}>
        <div className={styles.shareCodeContainer}>
          <div className={styles.shareCodeTitle}>{shareCodeTitle}</div>
          <div className={styles.shareCode}>{referralCoupon}</div>
        </div>
        <div className={styles.socialMediaContainer}>
          <FacebookShareButton disabled={!referralCoupon} url={APP_URL} quote={socialMediaText}>
            <FacebookIcon className={styles.icon} />
          </FacebookShareButton>
          <TwitterShareButton disabled={!referralCoupon} url={APP_URL} title={socialMediaText}>
            <TwitterIcon className={styles.icon} />
          </TwitterShareButton>
          <WhatsappShareButton disabled={!referralCoupon} url={APP_URL} title={socialMediaText}>
            <WhatsappIcon className={styles.icon} />
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );
};
