import React from 'react';

import PromoCode from '@modules/PromoCode/PromoCode';
import { useCouponMessages } from '@hooks/cart/useCouponMessages';

import styles from './CheckoutCouponForm.module.scss';

export const CheckoutCouponForm = ({ checkButtonText, discountCodeText }) => {
  const { couponErrorMessages } = useCouponMessages();

  return (
    <PromoCode
      checkText={checkButtonText}
      text={discountCodeText}
      className={styles.container}
      couponErrorMessages={couponErrorMessages}
    />
  );
};
