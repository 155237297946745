import { useCart, useCouponMessages } from '@hooks/cart';

export const useIsCouponExpiredMessage = () => {
  const { data: cart } = useCart();
  const { couponErrorMessages } = useCouponMessages();

  const isCouponExpired = cart?.coupon?.status === 'expired' || cart?.isCouponAfterValidTime;

  if (isCouponExpired) {
    return couponErrorMessages.couponExpired;
  }

  return undefined;
};
