/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';

import styles from './FaqArticle.module.scss';

import { FaqGroup } from '@components/FaqGroup/FaqGroup';
import { Image } from '@components/Image/Image';

export const FaqArticle = ({
  uid,
  label,
  icon,
  faqGroup,
  itemVisibilityUid,
  setItemVisibilityUid,
  allOpened = false,
}) => {
  const isTogglingEnabled = setItemVisibilityUid && !allOpened;
  const nonElevatedLabel = faqGroup[0].notElevated && styles.nonElevatedLabel;

  return (
    <div className={styles.container} id={label.toLowerCase()}>
      {label && (
        <div
          className={cn(styles.faqArticleHeader, nonElevatedLabel)}
          onClick={
            isTogglingEnabled
              ? () => {
                  if (uid === itemVisibilityUid) {
                    setItemVisibilityUid?.(null);
                  } else {
                    setItemVisibilityUid?.(uid);
                  }
                }
              : undefined
          }
        >
          <div
            className={styles.faqArticleLabelContainer}
            style={{ cursor: isTogglingEnabled ? 'pointer' : 'default' }}
          >
            {icon?.id && (
              <div className={styles.icon}>
                <Image image={icon} />
              </div>
            )}
            <div className={styles.faqArticleLabel}>{label}</div>
          </div>
          {isTogglingEnabled && <div className={styles.plusSign}>{itemVisibilityUid === uid ? '-' : '+'}</div>}
        </div>
      )}

      <div className={styles.faqArticleGroupContainer}>
        {(allOpened || !label || itemVisibilityUid === uid) && <FaqGroup {...faqGroup?.[0]} allOpened={allOpened} />}
      </div>
    </div>
  );
};
