/* eslint-disable no-shadow */
import React from 'react';

import cn from 'classnames';

import styles from './ComparingTable.module.scss';
import { Header } from './components/Header/Header';
import { InformationTable } from '../InformationTable/InformationTable';

export const ComparingTable = ({
  showMoreButtonText,
  showLessButtonText,
  title,
  description,
  products,
  sections,
  items,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.titleSection}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>

      <div className={cn(styles.productsSection, styles.mobile)}>
        {products?.map(product => {
          return (
            <Header
              key={product.title}
              image={product.image}
              title={product.title}
              description={product.description}
              moreImages={product.moreImages}
              type={product.type}
            />
          );
        })}
      </div>

      {products?.map(product => {
        return (
          <Header
            key={product.title}
            image={product.image}
            title={product.title}
            description={product.description}
            moreImages={product.moreImages}
            type={product.type}
          />
        );
      })}
    </div>

    <InformationTable
      sections={sections}
      items={items}
      showLessButtonText={showLessButtonText}
      showMoreButtonText={showMoreButtonText}
    />
  </div>
);
