import React from 'react';

import cn from 'classnames';
import { upperFirst } from 'lodash';

import ArrowIcon from '@assets/icons/new/arrow2.svg';
import Arrow2White from '@assets/icons/new/arrow2white.svg';
import { Image } from '@components/Image/Image';
import { BlogFilterLabel } from '@modules/Blog/BlogFilterLabel/BlogFilterLabel';

import styles from './Blogpost.module.scss';

export const Blogpost = ({ postLink, title, imageTitle, text, blogCategory, theme, wide }) => {
  const wideContainer = wide ? styles.wideContainer : styles.normalContainer;
  const arrow = ['BlueDark', 'BlueBright'].includes(theme) ? <Arrow2White /> : <ArrowIcon />;

  return (
    <a
      className={wideContainer}
      href={postLink.cached_url
        .replace('/pages-new', '')
        .replace('/posts', '')
        .replace('/de/', '/')
        .replace('/nl/', '/')}
    >
      <div
        className={cn(styles.container, styles[`contentContainerBackground${upperFirst(theme)}`], styles.flexDirection)}
      >
        {imageTitle.filename ? (
          <div className={styles.imageContainer}>
            <Image alt="blog post image" image={imageTitle} className={styles.image} />
          </div>
        ) : (
          <div className={styles.titleContainer}>{title}</div>
        )}
        <div className={styles.contentContainer}>
          <div className={styles.labelContainer}>
            <BlogFilterLabel blogCategory={blogCategory} />
          </div>
          <div className={styles.bottomContentContainer}>
            <div className={styles.textContainer}>{text}</div>
            <div className={styles.arrowContainer}>{arrow}</div>
          </div>
        </div>
      </div>
    </a>
  );
};
