import React from 'react';

import * as dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

import { SwitchStepsNames, useContractSwitchContext } from '../../page/ContractSwitchContext/ContractSwitchContext';

export const ContractSwitchSubscriptionCard = props => {
  const {
    availableOptions,
    currentSubscription,
    areOptionsLoading,
    isSubscriptionLoading,
    step,
  } = useContractSwitchContext();
  const { getValues } = useFormContext();

  const option = availableOptions?.find(option => option.pricing.id === getValues('pricingId'));

  if (areOptionsLoading || isSubscriptionLoading) {
    return null;
  }
  const subscriptionWithNewPricing = {
    ...currentSubscription,
    newConditions: {
      ...option?.newConditions,
      subscriptionActivationDate: dayjs(option?.switchDate).toDate(),
    },
  };

  return (
    <>
      <SubscriptionCard
        {...props}
        key={`user-subscription-${subscriptionWithNewPricing?.id}`}
        subscription={subscriptionWithNewPricing}
        noViewButton
        showTermsInsteadStatus={step === SwitchStepsNames.check}
        additionalServices={currentSubscription.additionalServices}
        showItemPriceList
      />
    </>
  );
};
