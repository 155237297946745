import { OfferItemOneTimeRecurringPricing, OfferItemPricing } from 'types/offer.types';
import { PricingVariant } from 'types/plan.types';

export const getSelectedPricing = (pricings: OfferItemPricing[]) => {
  const availableNewPricing = pricings.find(pricing => pricing.isAvailable && pricing.variant === PricingVariant.New);
  if (availableNewPricing) {
    return availableNewPricing as OfferItemOneTimeRecurringPricing;
  }

  const availablePricing = pricings.find(pricing => pricing.isAvailable);
  if (availablePricing) {
    return availablePricing as OfferItemOneTimeRecurringPricing;
  }

  return pricings[0] as OfferItemOneTimeRecurringPricing;
};
