import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import Script from 'next/script';

import { useCookieContext } from '@context/cookies';
import { useUser } from '@hooks/user/useUser';

import styles from './FreshChat.module.scss';

import config from '@services/config/server';
import { listenForFreshChatEvents } from '@services/gtm/page-initialization';

export const FreshChat = () => {
  const { data: user } = useUser();
  const [isLoaded, setLoaded] = useState(false);
  const {
    preferredCookies: { marketing },
  } = useCookieContext();

  useEffect(() => {
    if (isLoaded && user) {
      window.fcWidget.setExternalId(user.bmNumber);
      window.fcWidget.user.setFirstName(user.firstName);
      window.fcWidget.user.setLastName(user.lastName);
      window.fcWidget.user.setEmail(user.email);
    }
  }, [user, isLoaded]);

  return (
    marketing && (
      <Script
        id="freshchat-js-sdk"
        src="https://wchat.freshchat.com/js/widget.js"
        onLoad={() => {
          const fcWidget = window.fcWidget;
          listenForFreshChatEvents(fcWidget);
          fcWidget.on('widget:loaded', () => {
            setLoaded(true);
          });

          fcWidget.init({
            config: {
              cssNames: {
                widget: cn(styles.freshChatWidget, styles.bouncingAnimation, styles.userBar),
                open: styles.freshChatWidgetOpen,
              },
            },
            token: config.freshChat.token,
            host: 'https://wchat.eu.freshchat.com',
          });
        }}
      />
    )
  );
};
