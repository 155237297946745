export const getValidationFields = props => {
  return Object.entries(props).reduce((acc, [key, val]) => {
    if (key.includes('validation')) {
      return {
        [key]: val,
        ...acc,
      };
    }

    return acc;
  }, {});
};
