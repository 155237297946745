import React, { useState, useEffect, ReactElement } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import { Controller, useFormContext } from 'react-hook-form';
import { DataAttribute } from 'types/data-attributes';

import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';

import styles from './Checkbox.module.scss';

export const Themes = {
  White: 'white',
  WhiteNoShadow: 'whiteNoShadow',
  Gray: 'gray',
} as const;

interface CheckboxProps {
  controllerName: string;
  labelText: string | ReactElement;
  externalText?: string;
  theme?: typeof Themes[keyof typeof Themes];
  children?: ReactElement;
  dataAttribute?: DataAttribute;
  onClick: (data) => void;
  className?: string;
  handleOnChange?: (data) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  controllerName,
  labelText,
  externalText,
  theme = Themes.White,
  children,
  dataAttribute,
  onClick,
  className,
  handleOnChange = data => {},
}) => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const values = getValues();
  const fieldValue = values[controllerName];

  const [isChecked, setIsChecked] = useState(false);
  const error = get(errors, controllerName);
  const isError = error !== undefined;

  useEffect(() => {
    const isFieldValue =
      (isBoolean(fieldValue) && fieldValue !== false) ||
      (isObject(fieldValue) && !!Object.values(fieldValue).find(fv => fv && fv !== null)) ||
      false;

    setIsChecked(isFieldValue);
    handleOnChange({ controllerName, fieldValue: isFieldValue });
  }, [fieldValue]);

  return (
    <Controller
      render={({ field: { ref, ...field } }) => {
        return (
          <div
            className={cn(styles.container, className, {
              [styles.grayLabel]: theme === Themes.Gray,
              [styles.whiteLabel]: theme === Themes.White,
            })}
          >
            <label className={styles.labelContainer}>
              <input
                type="checkbox"
                onClick={() => {
                  onClick?.(!isChecked);
                  setIsChecked(!isChecked);
                }}
                {...register(controllerName)}
                {...field}
                checked={isChecked}
                data-test-id={controllerName}
                {...dataAttribute}
              />

              <span className={styles.checkmark} />
              <span className={styles.labelText}>{labelText} </span>
            </label>

            {externalText && isChecked && <span className={styles.externalText}>{externalText}</span>}
            {children && isChecked && <div className={styles.children}>{children}</div>}
            {isError && (
              <span className={styles.errorMessage}>
                <>
                  <RectangleIcon />
                  {error.message}
                </>
              </span>
            )}
          </div>
        );
      }}
      name={controllerName}
      // type="checkbox"
      control={control}
    />
  );
};
