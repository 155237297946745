import * as util from 'util';

import dayjs from 'dayjs';
import { isUndefined } from 'lodash';
import * as Yup from 'yup';

import { brithdayDateFilter } from 'validation/regexps/date';
import { emailFilter } from 'validation/regexps/email';
import { nameRegExp } from 'validation/regexps/name';
import { passwordComplexityRegExp } from 'validation/regexps/password';
import { phoneFilters } from 'validation/regexps/phone';

export function getValidationSchema({
  messages: {
    required,
    noSlash,
    maxLength,
    email,
    dateFormat,
    min18years,
    min1920year,
    phoneNumber,
    passwordMustContain,
    onlyLettersSpaceApostrophesHyphensAllowed = 'onlyLettersSpaceApostrophesHyphensAllowed',
  },
  country,
}) {
  return {
    newUserSalutation: Yup.string()
      .required(required)
      .test('noEmpty', required, val => val !== '--'),
    newUserFirstName: Yup.string()
      .max(40, util.format(maxLength, 40))
      .required(required)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('no_slash', noSlash, val => val && !val.includes('/')),
    newUserLastName: Yup.string()
      .max(80, util.format(maxLength, 80))
      .required(required)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('no_slash', noSlash, val => val && !val.includes('/')),
    newUserBirthdate: Yup.string()
      .required(required)
      .test('dateFormat', dateFormat, val => !!val && brithdayDateFilter.test(val))
      .test('validDate', dateFormat, val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isValid())
      .test(
        'min18years',
        min18years,
        val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isBefore(dayjs().subtract(18, 'years'))
      )
      .test(
        'min1920year',
        min1920year,
        val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isAfter(dayjs('1920-01-01'))
      ),
    newUserEmail: Yup.string()
      .max(70, util.format(maxLength, 70))
      .email(email)
      .required(required)
      .matches(emailFilter, email),
    newUserPhoneNumber: Yup.string()
      .required(required)
      .matches(phoneFilters[country], phoneNumber),
    newUserPassword: Yup.string()
      .required(required)
      .min(5, passwordMustContain)
      .matches(passwordComplexityRegExp, passwordMustContain),
  };
}

export function getDefaultValues({ user }) {
  return user;
}
