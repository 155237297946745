import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import Loader from '@components/Loader/Loader';
import { usePhrases } from '@hooks/context/usePhrases';
import { useUser } from '@hooks/user/useUser';
import { useUserUpdateAcceptanceOfCommercialApproachMutation } from '@hooks/user/useUserUpdateAcceptanceOfCommercialApproachMutation';

import styles from './ChangeMarketingOptin.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

export const ChangeMarketingOptin = ({ marketingOptinText, submitText, successToastMessage }) => {
  const { data: user } = useUser();
  const userUpdateAcceptanceOfCommercialApproachMutation = useUserUpdateAcceptanceOfCommercialApproachMutation();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const formOptions = {
    defaultValues: {
      marketingOptin: user?.acceptanceOfCommercialApproach,
    },
  };

  const methods = useForm(formOptions);

  const onSubmitHandler = async data => {
    try {
      await userUpdateAcceptanceOfCommercialApproachMutation.mutateAsync({
        acceptanceOfCommercialApproach: data.marketingOptin || false,
      });
      toast(successToastMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
      });
      methods.setValue('marketingOptin', data.marketingOptin);
    } catch (error) {
      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (user) {
      methods.setValue('marketingOptin', user?.acceptanceOfCommercialApproach);
    }
  }, [user]);

  if (!user) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.formContainer}>
        <Checkbox controllerName="marketingOptin" labelText={marketingOptinText} className={styles.marginBottom} />
        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Icon}
          icon="none"
          type="submit"
          isFullWidth
          isLoading={methods.formState.isSubmitting}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
