import React, { useEffect, useState } from 'react';

import { useUserLastOrder } from '@hooks/user/useUserLastOrder';

import { CheckoutOrderCardCarouselOffer } from './CheckoutOrderCardCarouselOffer';

const delay = ms => new Promise(res => setTimeout(res, ms));

export const CheckoutOrderCardCarousel = () => {
  const { data: newestOrder, isLoading, refetch } = useUserLastOrder();
  const [shouldRetry, setShouldRetry] = useState(true);

  useEffect(() => {
    if (isLoading || !newestOrder) {
      return;
    }

    const areOnlySda = newestOrder?.items?.every(item => item?.plan?.category?.groupType === 'SDA');

    if (areOnlySda) {
      setShouldRetry(false);

      return;
    }

    const confirmedTimeslotBooking =
      newestOrder?.timeslotBookings?.length > 0
        ? newestOrder?.timeslotBookings?.find(timeslot => timeslot.status === 'CONFIRMED')
        : undefined;

    if (!confirmedTimeslotBooking) {
      // To prevent race condition we should use the retry mechanism
      const retry = async () => {
        const MAX_RETRY_ATTEMPTS = 3;
        let attemptCounter = 0;

        // eslint-disable-next-line no-restricted-syntax
        do {
          attemptCounter = attemptCounter + 1;
          const { data: retriedNewestOrder } = await refetch();
          const retriedConfirmedTimeslotBooking = retriedNewestOrder?.timeslotBookings?.find(
            timeslot => timeslot.status === 'CONFIRMED'
          );

          if (retriedConfirmedTimeslotBooking || attemptCounter === MAX_RETRY_ATTEMPTS) {
            setShouldRetry(false);

            return;
          }

          await delay(1000);
        } while (attemptCounter < MAX_RETRY_ATTEMPTS);
      };

      retry();
    } else {
      setShouldRetry(false);
    }
  }, [newestOrder]);

  return <CheckoutOrderCardCarouselOffer />;
};
