import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

export const useProcessTakeoverProviderMutation = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useMutation(
    async takeoverProcessProviderDto => {
      const response = await httpService.post('/takeover/provider', takeoverProcessProviderDto);

      return response.data;
    },
    { enabled: !!jwtToken }
  );
};
