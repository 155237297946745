import * as Yup from 'yup';

const passwordValidationSchema = ({ email, required }) => {
  return {
    email: Yup.string()
      .email(email)
      .required(required),
    password: Yup.string().required(required),
  };
};

export const loginValidation = validationMessages => Yup.object().shape(passwordValidationSchema(validationMessages));
