import React, { useRef, useEffect, useState } from 'react';

import { CompareHeader } from '@components/CompareHeader/CompareHeader';

import styles from './CategoryCompareHeader.module.scss';

export const CategoryCompareHeader = ({ plansToCompare, callToAction }) => {
  const positionRef = useRef();
  const [componentVisible, setComponentVisible] = useState(false);

  const items = plansToCompare.map(p => {
    return {
      slug: p.slug,
      image: p.content.mainImage,
      label: p.content.name,
      text: p.content.description,
      buttonLabel: callToAction,
    };
  });

  const handleScroll = () => {
    const { pageYOffset, innerHeight } = window;
    const offsetTop = positionRef.current.offsetTop;
    setComponentVisible(pageYOffset + innerHeight >= offsetTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onItemClick = slug => {
    // TODO: fill with real action once it is used
    console.log({ slug });
  };

  return (
    <>
      <div ref={positionRef} />
      {componentVisible && (
        <div className={styles.container}>
          <CompareHeader items={items} onItemClick={onItemClick} />
        </div>
      )}
    </>
  );
};
