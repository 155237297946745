/* eslint-disable import/no-cycle */
import React from 'react';

import parse from 'html-react-parser';
import { StoryblokResult } from 'storyblok-js-client';

import { FreshChat } from '@components/FreshChat/FreshChat';
import PageSheet from '@components/PageSheet/PageSheet';

import { GtmTriggerComponent } from '../GtmTriggerComponent/GtmTriggerComponent';

import Head from '@theme//Head/Head';
import { Footer } from '@theme/Footer/Footer';
import { Locale } from 'types/common.types';

interface LayoutProps {
  children: React.ReactNode;
  globalStories: StoryblokResult['data']['story'];
  locale: Locale;
  storyblokContent: StoryblokResult['data']['story'];
  isPageWithoutFooter: boolean;
  layoutHeader: React.ReactNode;
  wrapperClassName?: string;
  components: StoryblokResult['data']['story'];
}

export const Layout = ({
  children,
  locale,
  storyblokContent,
  isPageWithoutFooter = false,
  layoutHeader,
  wrapperClassName,
  components,
}: LayoutProps) => {
  return (
    <>
      <Head seoContent={storyblokContent} locale={locale} />
      <div className={wrapperClassName}>
        {layoutHeader}
        <main>{children}</main>
        {!isPageWithoutFooter && <Footer components={components} />}
      </div>
      <PageSheet />
      <FreshChat />
      <GtmTriggerComponent />
      {storyblokContent.schemaOrg &&
        parse(`<script type="application/ld+json">${JSON.stringify(storyblokContent.schemaOrg)}</script>`)}
    </>
  );
};
