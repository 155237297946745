import { useMutation, useQueryClient } from 'react-query';

import { CART_QUERY_ID } from './useCart';
import { useCartId } from './useCartId';

import { useHttpService } from '@hooks/useHttpService';
import { sendRemoveFromCartEvent } from '@services/gtm/cart';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export const useRemoveItems = () => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useMutation(
    async cartItems => {
      const { data } = await httpService.delete(`/cart/${cartId}/items/${cartItems.map(item => item.id).join('+')}`);

      return data;
    },
    {
      onSuccess: (data, cartItems) => {
        sendRemoveFromCartEvent(cartItems, plans);
        queryClient.setQueryData(CART_QUERY_ID, { ...data.cart, sendGtmEvent: false });
      },
    }
  );
};
