/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';

import styles from './NewSimpleLayout.module.scss';

export const NewSimpleLayout = ({ children, style, className }) => {
  return (
    <div className={styles.container}>
      <div className={cn(styles.simpleLayout, className)} style={style}>
        {children}
      </div>
    </div>
  );
};
