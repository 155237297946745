import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

import { useTimeSlotAvailableSlotsId } from './useTimeSlotAvailableSlotsQuery';

export const useTimeSlotReservationMutation = onSuccessRedirect => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();

  return useMutation(
    async timeSlotBookingReservationDto => {
      const response = await httpService.post('/timeslot-booking/reserve-timeslot', timeSlotBookingReservationDto);

      return response.data;
    },
    {
      onError: () => {
        queryClient.refetchQueries([useTimeSlotAvailableSlotsId]);
      },
      onSuccess: () => {
        if (onSuccessRedirect) {
          onSuccessRedirect();
        }
      },
    }
  );
};
