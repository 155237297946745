import { useQuery } from 'react-query';
import Cookies from 'universal-cookie';

import { useHttpService } from '@hooks/useHttpService';
import { JWT_STORAGE_KEY } from 'constants/localStorage';

export const useNotCancelledSubscription = () => {
  const httpService = useHttpService();
  const useNotCancelledSubscriptionQueryId = 'useNotCancelledSubscriptionQueryId';
  const cookies = new Cookies();

  return useQuery(
    useNotCancelledSubscriptionQueryId,
    async () => {
      const token = cookies.get(JWT_STORAGE_KEY);
      if (!token) {
        return [];
      }
      const { data } = await httpService.get('/subscription/user?statusArray[]=active&statusArray[]=future');

      return data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
