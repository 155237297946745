import React from 'react';

import { useCart } from '@hooks/cart';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import { CheckoutOneTimePaymentSummaryNl } from './CheckoutOneTimePaymentForOfferNl';
import styles from '../CheckoutOneTimePayment.module.scss';

export const CheckoutOneTimePaymentSummaryNL = ({ summaryDescription }) => {
  const { data: cart } = useCart();
  const { oneTimeFee } = usePhrases({ name: 'general' });

  const { currencyFormatter } = useCurrencyFormatter();

  if (!cart) {
    return false;
  }

  const isOfferIncluded = cart?.items?.every(item => item.offerItem);

  if (isOfferIncluded) {
    return <CheckoutOneTimePaymentSummaryNl summaryDescription={summaryDescription} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.descriptionText}>{oneTimeFee}</div>
        <div
          className={styles.descriptionText}
          {...{
            'data-otc-deposit': currencyFormatter({
              price: cart.totalDepositPrice,
            }),
          }}
        >
          {currencyFormatter({
            price: cart.totalDepositPrice,
          })}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.summaryDescriptionText}>{summaryDescription}</div>
        <div className={styles.valueText}>
          {currencyFormatter({
            price: cart.totalDepositPrice,
          })}
        </div>
      </div>
    </div>
  );
};
