import { getGaClientId } from '@services/gtm/gtm';
import dayjs from 'dayjs';

import { defaultContactSpots } from '@helpers/contact-spots';

import { UserAddressBuilder } from './UserAddressBuilder';
import { required, sanitize } from './utils';

export class CreateUserDtoBuilder {
  constructor({ cartId } = {}) {
    this.requiredFields = [
      'firstName',
      'lastName',
      'salutation',
      'language',
      'phone',
      'birthdate',
      'email',
      'password',
      'acceptanceOfCommercialApproach',
      'billingAddress',
      'shippingAddress',
      'language',
    ];

    this.optionalFields = [
      { name: 'customerType', defaultValue: 'private' },
      { name: 'extraDeliveryInformation', defaultValue: '' },
      { name: 'dryerConnector', defaultValue: false },
      { name: 'contactSpots', defaultValue: defaultContactSpots },
      { name: 'gaClientId', defaultValue: getGaClientId() },
    ];

    this.cartId = cartId;
  }

  setFirstName(firstName) {
    this.firstName = required(sanitize(firstName), 'FirstName');

    return this;
  }

  setSalutation(salutation) {
    this.salutation = required(salutation, 'Salutation');

    return this;
  }

  setLastName(lastName) {
    this.lastName = required(sanitize(lastName), 'LastName');

    return this;
  }

  setLanguage(language) {
    this.language = required(language, 'Language');

    return this;
  }

  setPhone(phone) {
    this.phone = required(phone, 'Phone');

    return this;
  }

  setBirthdate(birthdate) {
    this.birthdate = required(dayjs(birthdate, 'DD-MM-YYYY', true).format('YYYY-MM-DD'));

    return this;
  }

  setEmail(email) {
    this.email = required(email, 'Email');

    return this;
  }

  setPassword(password) {
    this.password = required(password, 'Password');

    return this;
  }

  setAcceptanceOfCommercialApproach(acceptanceOfCommercialApproach) {
    this.acceptanceOfCommercialApproach = required(acceptanceOfCommercialApproach, 'AcceptanceOfCommercialApproach');

    return this;
  }

  setShippingAddress(address) {
    this.shippingAddress = new UserAddressBuilder(address);

    return this;
  }

  setBillingAddress(address) {
    this.billingAddress = new UserAddressBuilder(address);

    return this;
  }

  setCustomerType(customerType) {
    this.customerType = customerType;

    return this;
  }

  validate() {
    this.requiredFields.forEach(requiredField => required(this[requiredField], requiredField));

    this.optionalFields.forEach(optionalField => {
      if (typeof this[optionalField.name] === 'undefined') {
        this[optionalField.name] = optionalField.defaultValue;
      }
    });

    const { requiredFields, optionalFields, ...withoutDetails } = this;

    return withoutDetails;
  }
}
