import React from 'react';

import { useLocale } from '@hooks/useLocale';

import { CheckoutOneTimePaymentSummaryDE } from './de/CheckoutOneTimePaymentSummaryDe';
import { CheckoutOneTimePaymentSummaryNL } from './nl/CheckoutOneTimePaymentNl';

const countryLayouts = {
  de: CheckoutOneTimePaymentSummaryDE,
  nl: CheckoutOneTimePaymentSummaryNL,
};

export const CheckoutOneTimePaymentSummary = props => {
  const { country } = useLocale();

  const SelectedComponent = countryLayouts[country];

  return <SelectedComponent {...props} />;
};
