import * as Yup from 'yup';

export function getChangePaymentFormValidationSchema(messages, paymentSources) {
  const baseShape = {};

  if (!paymentSources) {
    baseShape.monthlyPaymentMethod = Yup.string().required(messages.required);
  }

  return Yup.object().shape(baseShape);
}
