import React from 'react';

import { ComparingTable } from '@components/ComparingTable/ComparingTable';
import { convertPlanToInformationItem } from '@modules/PDP/utils/convertPlanToInformationItem';
import { useSectionsWithPhraseHeaders } from '@hooks/useSectionsWithPhraseHeaders';

export const CategoryComparing = ({
  showMoreButtonText,
  showLessButtonText,
  title,
  description,
  sections,
  plansToCompare,
}) => {
  const products = plansToCompare.map(p => {
    return {
      image: p.content.mainImage,
      title: p.content.name,
      description: p.content.description,
      moreImages: '',
      type: p.content.condition,
    };
  });
  const items = plansToCompare.map(planContent => convertPlanToInformationItem({ planContent }));

  return (
    <ComparingTable
      showMoreButtonText={showMoreButtonText}
      showLessButtonText={showLessButtonText}
      title={title}
      description={description}
      sections={useSectionsWithPhraseHeaders({ sections })}
      products={products}
      items={items}
    />
  );
};
