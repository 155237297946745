import React from 'react';

import styles from './MovingPlannerPhoneNumber.module.scss';

import { TextInput } from '@components/inputs/TextInput/TextInput';

export const MovingPlannerPhoneNumber = ({ phoneNumber }) => {
  return (
    <div className={styles.container}>
      <TextInput controllerName="phoneNumber" labelText={phoneNumber} />
    </div>
  );
};
