import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '../useHttpService';
import { CART_QUERY_ID as OFFER_CART_QUERY_ID } from './useCartWithOffer';
import { useCartId } from './useCartId';

export const useApplyCoupon = () => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();

  return useMutation(
    async ({ couponCode }) => {
      const resp = await httpService.patch(`/cart/${cartId}/applyCoupon`, { couponCode });

      return resp;
    },
    {
      onSuccess: ({ data }) => {
        const queryName = OFFER_CART_QUERY_ID;
        queryClient.setQueryData(queryName, { ...data.cart, sendGtmEvent: false });
      },
    }
  );
};
