import { OfferItem, OfferItemPricing } from 'types/offer.types';

export const convertOfferItemsToDictionary = (offerItems: OfferItem[]): Record<string, OfferItemPricing> => {
  return offerItems.reduce((acc, offerItem) => {
    return {
      ...acc,
      [offerItem.id]: null,
    };
  }, {});
};
