import React, { useEffect, useMemo, useState } from 'react';

import groupBy from 'lodash/groupBy';
import { StoryData } from 'storyblok-js-client';
import { Country } from 'types/common.types';
import { PaymentPlan } from 'types/frontend-financial-product.types';
import { BillingType, Offer, OfferContractType, OfferItemOneTimeRecurringPricing } from 'types/offer.types';
import { PricingVariant } from 'types/plan.types';

import { Label } from '@components/Label/Label';
import { RichText } from '@components/RichText/RichText';
import { useCartId } from '@hooks/cart';
import { useAddToCartWithOfferMutation } from '@hooks/cart/useAddToCartWithOfferMutation';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useOfferImpressionEvent } from '@hooks/gtm/useOfferImpressionEvent';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useDeliveryTimeForOffer } from '@hooks/useDeliveryTimeForOffer';
import { useGlobalContent } from '@hooks/useGlobalContent';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';

import { convertSelectedOfferItemsToCartDto } from './helpers/converSelectedOfferItemsToCartDto';
import { sortPricingsFromLowestPrice } from './helpers/sortPricings';
import { usePricings } from './hooks/usePricings/usePricings';
import styles from './OfferCard.module.scss';
import { usePlanData } from '../../hooks/usePlanData';
import EnergyLabel from '../EnergyLabel/EnergyLabel';
import { NotActiveNotificationForOffer } from '../NotActiveNotification/NotActiveNotificationForOffer';
import { OfferCardPaymentPlans } from '../OfferCardPaymentPlans/OfferCardPaymentPlans';
import { PaymentPlanFinanceBox } from './components/PaymentPlanFinanceBox/PaymentPlanFinanceBox';
import { PaymentPlanRentingBox } from './components/PaymentPlanRentingBox/PaymentPlanRentingBox';

export const OfferCard = ({
  description,
  paymentOptions = [],
  testingCategoryTitle,
  hideHeader, // TODO: BLUEMOVE-6012 to remove after migration to one header on PDP
}) => {
  const {
    offerContent: { content },
    offer,
  } = usePlanData() as { offer: Offer; offerContent: StoryData };
  const financialProduct = offer.financial;
  const { logger } = useLogger({ context: 'PDPOfferCard' });
  const deliveryTime = useDeliveryTimeForOffer(offer.groupType);
  const { currencyFormatter } = useCurrencyFormatter();
  const addToCartMutation = useAddToCartWithOfferMutation();
  const { locale, country } = useLocale();
  const featurePhrases = usePhrases({ name: 'features', rawObject: true });
  const {
    oneTimeFee,
    yourSubscription,
    condition,
    duration,
    readMore,
    monthlyFee,
    addToCart,
    euProductDataSheet,
  } = usePhrases({ name: 'general' });
  const { month, oneTime } = usePhrases({ name: 'units' });
  const { optionalAdditionalServices, mandatoryAdditionalServices } = usePhrases({
    name: 'additionalServices',
    rawObject: true,
  });
  const isSDA = offer.groupType === 'SDA';
  const isDemandTestCategory = offer.offerItems.some(offerItem => offerItem.isDemandTestCategory === true);
  const cartId = useCartId();
  const { refetch } = useCartWithOffer();
  const { data: globalContent } = useGlobalContent({
    locale,
  });

  const financingOffer = globalContent?.['financing-offer']?.global?.[0];
  const rentalOffer = globalContent?.['rental-offer']?.global?.[0];
  const offerSlug = offer.contentSlug;
  const mainItems = offer.offerItems.filter(item => item.type === 'main');
  const additionalItems = offer.offerItems.filter(item => item.type === 'additional');
  const mandatoryAdditionalItems = additionalItems.filter(
    additionalItem => additionalItem.requirementType === 'mandatory'
  );
  const optionalAdditionalItems = additionalItems.filter(
    additionalItem => additionalItem.requirementType === 'optional'
  );
  const mainItem = mainItems[0];
  const sortedPricings = sortPricingsFromLowestPrice(mainItem.pricings);
  const groupedPricings = useMemo(
    () => groupBy(sortedPricings, 'variant') as Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>,
    [sortedPricings]
  );

  const groupedPricingsForFinancial: Partial<Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>> = {
    [PricingVariant.New]: [
      {
        id: financialProduct?.id?.toString(),
        currency: 'EUR',
        billingType: BillingType.OneTimeRecurring,
        recurringAmount: financialProduct?.paymentAmount * 100,
        months: financialProduct?.periodMonths,
        referenceRecurringAmount: null,
        isReferenceAmountActive: false,
        oneTimeAmount: 0,
        variant: PricingVariant.New,
        term: '4 years',
        isAvailable: false,
      },
    ],
  };

  const {
    selectedMainProductPricing,
    selectedAdditionalServicesPricings,
    selectedAndMandatoryAdditionServicesPricings,
    additionalServicesOneTimeSum,
    additionalServicesRecurringSum,
    setSelectedMainProductPricing,
    selectAdditionalServicePricing,
  } = usePricings({
    pricings: sortedPricings,
    mandatoryAdditionalItems,
    optionalAdditionalItems,
    offerSlug,
  });

  // TODO: in progress, adjust 'new' with "financialProduct?.assets?.[0]?.manufacturerProductCode"
  // `financialProduct?.assets?.[0]?.manufacturerProductCode` is a temporary place to store the condition
  const isFinancialProduct =
    [OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(offer.contractType) &&
    country === Country.DE &&
    financialProduct &&
    selectedMainProductPricing.variant.toLowerCase() === 'new'.toLowerCase();

  useEffect(() => {
    if (isFinancialProduct) {
      setSelectedPaymentPlan(PaymentPlan.Finance);
      setSelectedMainProductPricing(groupedPricingsForFinancial['new'][0]);
    }
  }, [isFinancialProduct]);

  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<PaymentPlan>(
    isFinancialProduct ? PaymentPlan.Finance : PaymentPlan.Renting
  );

  const handleAddToCart = () => {
    logger.info({
      message: `Adding to cart - ${offerSlug} condition: ${selectedMainProductPricing.variant}, duration: ${selectedMainProductPricing.months} months`,
      params: { offerId: offerSlug, selectedMainProductPricing },
    });
    const additionalItemsDto = convertSelectedOfferItemsToCartDto(selectedAndMandatoryAdditionServicesPricings);

    if (mainItem && selectedMainProductPricing) {
      addToCartMutation.mutate([
        { offerItemId: mainItem.id, pricingId: selectedMainProductPricing.id },
        ...additionalItemsDto,
      ]);

      refetch();
    }
  };

  useOfferImpressionEvent(offer, selectedMainProductPricing, content.name, isFinancialProduct && financialProduct);

  if (mainItems.length !== 1) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          {!hideHeader && (
            <div className={styles.desktopOnly}>
              <h2 className={styles.headerTitle} data-pdp-category-name={mainItem.categorySlug}>
                {content.name}
              </h2>

              <span className={styles.headerSubtitle}>{content.subtitle}</span>
            </div>
          )}

          <NotActiveNotificationForOffer
            locale={locale}
            categorySlug={mainItem.categorySlug}
            isAvailable={
              (isFinancialProduct && selectedPaymentPlan === PaymentPlan.Finance) ||
              selectedMainProductPricing.isAvailable
            }
            testingCategoryTitle={testingCategoryTitle}
            isDemandTestCategory={isDemandTestCategory}
          />

          {!hideHeader && !isSDA && (
            <div className={styles.desktopOnly}>
              <div className={styles.energyLabel}>
                <EnergyLabel
                  link={featurePhrases?.energyClass?.link?.[0]}
                  variant={content.features?.find(f => f.key === 'energyClass')?.value}
                  text={euProductDataSheet}
                  document={content.energyLabelDocument}
                />
              </div>
            </div>
          )}

          <div className={styles.description}>
            <RichText document={description} />
          </div>

          {deliveryTime &&
            ((isFinancialProduct && selectedPaymentPlan === PaymentPlan.Finance) ||
              selectedMainProductPricing.isAvailable) && (
              <div className={styles.deliveryTimeContainer}>
                <Label text={deliveryTime} dotColor="#8AD861" />
              </div>
            )}
        </header>

        <span className={styles.headingTitle}>{yourSubscription}</span>

        {country === Country.DE && (
          <OfferCardPaymentPlans
            readMoreText={readMore}
            paymentPlanText={rentalOffer?.paymentPlanText}
            paymentPlanLink={rentalOffer?.paymentPlanLink}
            currencyFormatter={currencyFormatter}
            groupedPricings={groupedPricings}
            groupedPricingsForFinancial={groupedPricingsForFinancial}
            selectedPaymentPlan={selectedPaymentPlan}
            setSelectedPaymentPlan={setSelectedPaymentPlan}
            setSelectedMainProductPricing={setSelectedMainProductPricing}
            offer={offer}
            isFinancialProduct={isFinancialProduct}
          />
        )}

        {isFinancialProduct && selectedPaymentPlan === PaymentPlan.Finance && (
          <PaymentPlanFinanceBox
            readMoreText={readMore}
            content={content}
            financingOfferContent={financingOffer}
            financialProduct={financialProduct}
            durationsLink={rentalOffer?.durationsLink}
            conditionText={condition}
            conditionsLink={rentalOffer?.conditionsLink}
            currencyFormatter={currencyFormatter}
            groupedPricingsForFinancial={groupedPricingsForFinancial}
            selectedMainProductPricing={selectedMainProductPricing}
            setSelectedMainProductPricing={setSelectedMainProductPricing}
            durationText={duration}
            selectedPaymentPlan={selectedPaymentPlan}
            financingLink={financingOffer?.financingLink}
            setSelectedPaymentPlan={setSelectedPaymentPlan}
          />
        )}

        {[OfferContractType.Rental, OfferContractType.RentalAndFinancing].includes(offer.contractType) &&
          selectedPaymentPlan === PaymentPlan.Renting && (
            <PaymentPlanRentingBox
              readMoreText={readMore}
              conditionText={condition}
              conditionsLink={rentalOffer?.conditionsLink}
              currencyFormatter={currencyFormatter}
              groupedPricings={groupedPricings}
              selectedMainProductPricing={selectedMainProductPricing}
              setSelectedMainProductPricing={setSelectedMainProductPricing}
              durationText={duration}
              durationsLink={rentalOffer?.durationsLink}
              mandatoryAdditionalItems={mandatoryAdditionalItems}
              mandatoryAdditionalServices={mandatoryAdditionalServices}
              oneTimeText={oneTime}
              month={month}
              selectAdditionalServicePricing={selectAdditionalServicePricing}
              selectedAdditionalServicesPricings={selectedAdditionalServicesPricings}
              optionalAdditionalItems={optionalAdditionalItems}
              optionalAdditionalServices={optionalAdditionalServices}
              oneTimeFee={oneTimeFee}
              additionalServicesOneTimeSum={additionalServicesOneTimeSum}
              monthlyFeeText={monthlyFee}
              additionalServicesRecurringSum={additionalServicesRecurringSum}
              cartId={cartId}
              handleAddToCart={handleAddToCart}
              addToCartText={addToCart}
              mainItem={mainItem}
              isDemandTestCategory={isDemandTestCategory}
              paymentOptions={paymentOptions}
              selectedPaymentPlan={selectedPaymentPlan}
              financingLink={financingOffer?.financingLink}
              setSelectedPaymentPlan={setSelectedPaymentPlan}
            />
          )}
      </div>
    </>
  );
};
