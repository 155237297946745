import React from 'react';

import cn from 'classnames';

import InformationIcon from '@assets/icons/new/information.svg';
import { Link } from '@components/Link/Link';

import styles from './Row.module.scss';

const DEFAULT_RENDERER = value => value;

const ConditionalLink = ({ link, children }) =>
  (link && (
    <Link {...link} className={styles.link}>
      {children}
    </Link>
  )) ||
  children;

export const Row = ({ row, items, renderers = {} }) => {
  return (
    <div className={styles.row}>
      <ConditionalLink link={row.link}>
        <div key={row.field} className={cn(styles.cell, styles.header)}>
          {row.header || row.key}
          {row.link && <InformationIcon />}
        </div>
      </ConditionalLink>

      {items.map(item => {
        const fieldValue = item[row.field];
        const valueRenderer = renderers?.[row.field] || DEFAULT_RENDERER;

        return (
          <div key={item.id} className={cn(styles.cell, styles.value)}>
            {valueRenderer(fieldValue)}
          </div>
        );
      })}
    </div>
  );
};
