/* eslint-disable import/no-cycle */
import React, { useState } from 'react';

import cn from 'classnames';

import { RichText } from '@components/RichText/RichText';

import styles from './FaqGroupItem.module.scss';

export const FaqGroupItem = ({ item, isOpened, onClick }) => {
  const [isItemOpened, setIsItemOpened] = useState(false);
  const isOpen = onClick ? isOpened : isItemOpened;

  return (
    <div
      key={item?.question}
      className={styles.item}
      data-faq-question={item?.question}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          setIsItemOpened(!isItemOpened);
        }
      }}
    >
      <div className={styles.header}>
        <div className={cn(styles.desktopText, styles.desktop)}>
          <>{item?.question}</>
        </div>

        <div className={cn(styles.mobileText, styles.mobile)}>
          <>{item?.question}</>
        </div>

        <div className={styles.plusSign}>{isOpen ? '-' : '+'}</div>
      </div>

      {isOpen && (
        <div className={styles.content}>
          <RichText document={item?.answer} />
        </div>
      )}
    </div>
  );
};
