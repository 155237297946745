import { Country } from './common.types';

export enum FirstExecutionType {
  Immediately = 'immediately',
  Delayed = 'delayed',
}

export enum ExecutionType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export interface AdditionalService {
  id: string;
  lastOrderDate: Date;
  nextOrderDate: Date;
  reminderSentDate: Date;
  createdDate: Date;
  updateDate: Date;
  activationDate: Date;
  cancelledDate: Date;
  minimumPeriodInMonths: number;
  stockId: string;
  stockName: string;
  executionFrequencyInMonths: number;
  firstExecutionType: FirstExecutionType;
  firstExecutionAfterMonths: number;
  executionType: ExecutionType;
  status: string;
  minimumCancellationDate?: Date;
  country: Country;
  monthlyAmount: number;
  oneTimeAmount: number;
  isHiddenFromCustomer: boolean;
}
