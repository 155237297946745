import { useState } from 'react';

import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const paymentAttemptQueryId = 'paymentAttemptQuery';

export const usePaymentAttempt = transactionId => {
  const httpService = useHttpService();
  const [isPaid, setIsPaid] = useState(false);
  const INTERVAL_MS = 2000;

  return useQuery(
    paymentAttemptQueryId,
    async () => {
      const { data } = await httpService.get(`/payment/payment-attempt/${transactionId}`);

      setIsPaid(data.status === 'paid');

      return data;
    },
    {
      refetchInterval: INTERVAL_MS,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      enabled: !isPaid && !!transactionId,
    }
  );
};
