import { useLogger } from '@hooks/useLogger';

import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

const useDownloadInvoiceId = 'useDownloadInvoice';

export const useDownloadInvoice = ({ invoiceNumber } = {}) => {
  const { logger } = useLogger({ context: 'bm:hooks:useDownloadInvoice' });
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useQuery(
    useDownloadInvoiceId,
    async () => {
      if (invoiceNumber) {
        const { data } = await httpService.get(`/invoice/${invoiceNumber}/pdf`);

        return data;
      }
    },
    {
      enabled: !!jwtToken,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      manual: true,
      onError: async error => {
        logger.error({ message: 'Error in useDownloadInvoice', params: error });
      },
    }
  );
};
