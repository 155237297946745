/* eslint-disable import/no-cycle */

import React, { useState, useRef, cloneElement } from 'react';

import cn from 'classnames';

import { ScrollNavigation } from '@components/ScrollNavigation/ScrollNavigation';
import { SwipeWrapper } from '@components/SwipeWrapper/SwipeWrapper';
import { useMedia } from '@hooks/useMedia';

import styles from './GenericCarousel.module.scss';

export const GenericCarousel = ({ children, items, showScroll = true, className }) => {
  const imageRef = useRef();
  const { isMobile } = useMedia();
  const startIndex = 1;
  const [currentImageIndex, setCurrentImageIndex] = useState(startIndex);
  const maxImageIndex = isMobile ? items.length + 1 : items.length - 3;

  const nextImage = () =>
    currentImageIndex < maxImageIndex
      ? setCurrentImageIndex(currentImageIndex + (isMobile ? 1 : 3))
      : setCurrentImageIndex(startIndex);

  const previousImage = () =>
    currentImageIndex > startIndex
      ? setCurrentImageIndex(currentImageIndex - (isMobile ? 1 : 3))
      : setCurrentImageIndex(maxImageIndex);

  return (
    <div className={cn(styles.container, className)}>
      <ScrollNavigation
        currentImageIndex={currentImageIndex}
        imageRef={imageRef}
        className={cn({ [styles.scrollNavigation]: items.length > 1, [styles.noScrollNavigation]: items.length === 1 })}
        forceVisibility
        hideScroll={!showScroll}
      >
        {items.map((blok, imageIndex) => (
          <div className={styles.itemContainer} key={imageIndex} ref={imageRef}>
            <SwipeWrapper onSwipeLeft={nextImage} onSwipeRight={previousImage}>
              <div className={styles.item} onClick={() => setCurrentImageIndex(imageIndex)}>
                {cloneElement(children, blok)}
              </div>
            </SwipeWrapper>
          </div>
        ))}
      </ScrollNavigation>
    </div>
  );
};
