/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React from 'react';

import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

import styles from './TakeoverConfirmation.module.scss';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

export const TakeoverConfirmation = ({ background, components, activeTakeover }) => {
  const formContext = useFormContext();
  const values = formContext?.getValues();
  const [backgroundImage] = background;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {components?.map(component => (
          <DynamicComponent
            {...component}
            key={component._uid}
            layout="emptyLayout"
            contentVariables={
              component.component === 'headingGroup' && {
                takeoverDate:
                  values?.takeoverDate ||
                  (activeTakeover?.takeoverDate && dayjs(activeTakeover?.takeoverDate).format('DD-MM-YYYY')),
                newUserFirstName: values?.newUserFirstName,
                newUserLastName: values?.newUserLastName,
                newUserEmail: values?.newUserEmail,
                originalUserFirstName: activeTakeover?.originalUser.firstName,
                originalUserLastName: activeTakeover?.originalUser.lastName,
              }
            }
          />
        ))}
      </div>

      <div
        className={styles.backgroundContainer}
        style={{ backgroundImage: backgroundImage.image && `url(${backgroundImage.image.filename})` }}
      />
    </div>
  );
};
