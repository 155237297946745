import React from 'react';

import * as dayjs from 'dayjs';

import { DatePicker } from '@components/inputs/DatePicker/DatePicker';

import styles from './MovingPlannerMovingDate.module.scss';

export const MovingPlannerMovingDate = ({ validationMessages }) => {
  return (
    <div className={styles.container}>
      <DatePicker
        errorText={validationMessages.dateBetween}
        controllerName="movingDate"
        minDate={dayjs().add(3, 'weeks')}
        maxDate={dayjs().add(3, 'months')}
      />
    </div>
  );
};
