import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useUserUpdateAcceptanceOfCommercialApproachMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ acceptanceOfCommercialApproach }) => {
    const response = await httpService.post('/user/update-acceptance-of-commercial-approach', {
      acceptanceOfCommercialApproach,
    });

    return response;
  });
};
