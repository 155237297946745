/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';

import { FaqArticle } from '@modules/Faq/FaqArticle/FaqArticle';
import { useRouter } from 'next/router';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

import styles from './FaqOverview.module.scss';

const scrollToTheArticleById = scrollItemId => {
  const element = document.getElementById(scrollItemId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' }, false);
  }
};

export const FaqOverview = ({ faqCategories, components }) => {
  const [itemVisibilityUid, setItemVisibilityUid] = useState(false);
  const router = useRouter();
  const scrollItemId = router.asPath
    .split('#')[1]
    ?.toLowerCase()
    ?.replace(/%20/g, ' ');

  useEffect(() => {
    if (scrollItemId) {
      scrollToTheArticleById(scrollItemId);
    }
  }, []);

  return (
    <div className={styles.container}>
      {components.map(blok => {
        return <DynamicComponent {...blok} key={blok._uid} layout="emptyLayout" />;
      })}

      {faqCategories.map(faqCategory => (
        <>
          <div className={styles.faqCategoryTitle}>{faqCategory.content.label}</div>

          <div className={styles.faqArticlesContainer}>
            {faqCategory.content.faqArticles.map(article => (
              <FaqArticle
                key={article._uid}
                uid={article._uid}
                label={article.label}
                icon={article.icon}
                faqGroup={article.faqGroup}
                itemVisibilityUid={scrollItemId === article.label.toLowerCase() ? article._uid : itemVisibilityUid}
                setItemVisibilityUid={setItemVisibilityUid}
              />
            ))}
          </div>
        </>
      ))}
    </div>
  );
};
