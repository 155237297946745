import { useQuery } from 'react-query';

import { useContent } from '@hooks/useContent';
import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

import { useLocale } from '../useLocale';

const useUserSubscriptionsQueryId = 'useUserSubscriptionsQueryId';

export const useUserSubscriptions = subscriptionId => {
  const httpService = useHttpService();
  const jwtToken = useJwt();
  const { locale, country } = useLocale();
  const { data: plansContent } = useContent({ locale, name: `plans-${country}` });
  const endpoint = subscriptionId ? `/subscription/user?subscriptionId=${subscriptionId}` : '/subscription/user';

  return useQuery(
    useUserSubscriptionsQueryId,
    async () => {
      const { data } = await httpService.get(endpoint);

      return data;
    },
    {
      enabled: !!jwtToken,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      select: data => {
        if (!data?.items) {
          if (!Array.isArray(data)) {
            return data;
          }

          return data.map(item => ({
            ...item,
            content:
              plansContent?.[item?.plan?.bmId?.toLowerCase()] ||
              plansContent?.[item?.plan?.product?.vib?.toLowerCase()] ||
              plansContent?.[item?.offerItem?.contentSlug?.toLowerCase()],
          }));
        }

        return {
          ...data,
          items: data.items.map(item => ({
            ...item,
            content:
              plansContent?.[item?.plan?.bmId?.toLowerCase()] ||
              plansContent?.[item?.plan?.product?.vib?.toLowerCase()] ||
              plansContent?.[item?.offerItem?.contentSlug?.toLowerCase()],
          })),
        };
      },
    }
  );
};
