import { useQuery } from 'react-query';

import { useContent } from '@hooks/useContent';
import { useHttpService } from '@hooks/useHttpService';
import { useLocale } from '@hooks/useLocale';

import { Offer } from 'types/offer.types';

const POSSIBLE_ADDITIONAL_SERVICES_QUERY_ID = 'possibleAdditionalServices';

export const useOffers = (offerContentSlugs: string[]) => {
  const httpService = useHttpService();
  const { locale } = useLocale();
  const uniqueOfferContentSlugs = Array.from(new Set([...offerContentSlugs]));
  const { data: additionalServicesContent } = useContent({ locale, name: `additional-services` });

  return useQuery<Offer[]>(
    [POSSIBLE_ADDITIONAL_SERVICES_QUERY_ID, uniqueOfferContentSlugs],
    async () => {
      const { data } = await httpService.get(`/offer/${uniqueOfferContentSlugs.join(',')}`);

      const offerArray = Array.isArray(data) ? data.map(offerItem => JSON.parse(offerItem)) : [data];

      return offerArray.map(offer => ({
        ...offer,
        offerItems: offer.offerItems.map(offerItem => ({
          ...offerItem,
          content: additionalServicesContent?.[offerItem?.contentSlug?.toLowerCase()],
        })),
      })) as Offer[];
    },
    {
      enabled: offerContentSlugs.length > 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
