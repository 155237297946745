/* eslint-disable no-underscore-dangle */
import React from 'react';

import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';

export const CartCounterWithOffer = ({ className }: { className: string }) => {
  const { data: cart } = useCartWithOffer();
  const cartItemsLength = cart?.items?.filter(item =>
    item?.isOnStock && item.offerItem ? item.offerItem.type === 'main' : true
  )?.length;

  return cartItemsLength > 0 ? <div className={className}>{cartItemsLength}</div> : null;
};
