import React from 'react';

import { RadioButtonWithPricing } from '@refactoredComponents/inputs/RadioGroup/RadioButtonWithPricing';
import { PaymentPlan } from 'types/frontend-financial-product.types';
import { OfferItemOneTimeRecurringPricing } from 'types/offer.types';
import { PricingVariant } from 'types/plan.types';

import { Link } from '@components/Link/Link';
import { OneTimePaymentSummary } from '@components/OneTimePaymentSummary/OneTimePaymentSummary';
import { usePhrases } from '@hooks/context/usePhrases';
import { useMonthsYearsFormatter } from '@hooks/useMonthsYearsFormatter';

import styles from './OfferCardPricings.module.scss';
import { convertMonthsToTermsSlug } from '../OfferCard/helpers/convertMonthsToTermsSlug';

export const OfferCardPricings = ({
  durationText,
  groupedPricings,
  durationsLink,
  readMoreText,
  selectedMainProductPricing,
  setSelectedMainProductPricing,
  selectedPaymentPlan,
  financingLink,
}: {
  durationText: string;
  durationsLink: any;
  groupedPricings: Partial<Record<PricingVariant, OfferItemOneTimeRecurringPricing[]>>;
  readMoreText: string;
  setSelectedMainProductPricing: any;
  selectedMainProductPricing: OfferItemOneTimeRecurringPricing;
  selectedPaymentPlan: PaymentPlan;
  financingLink: any;
}) => {
  const termsPhrases = usePhrases({ name: 'terms' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const { monthsYearsFormatter } = useMonthsYearsFormatter();

  const options = (groupedPricings[selectedMainProductPricing.variant] || []).map((pricing, index) => {
    const theSameVariantIsSelected =
      pricing.months === selectedMainProductPricing.months ||
      (!groupedPricings[selectedMainProductPricing.variant].find(
        pricing => pricing.months === selectedMainProductPricing.months
      ) &&
        index === 0);

    return {
      id: pricing.id,
      name: 'pricings',
      label:
        selectedPaymentPlan === PaymentPlan.Finance
          ? monthsYearsFormatter({ monthsValue: pricing.months })
          : termsPhrases[convertMonthsToTermsSlug(pricing.months)] || pricing.months,
      checked: theSameVariantIsSelected,
      dataAttribute: { 'data-test-params-term': pricing.months, 'data-test-id': 'pricing' },
      pricing: {
        referencePrice: pricing.referenceRecurringAmount,
        isReferencePriceActive: pricing.isReferenceAmountActive,
        price: pricing.recurringAmount,
        postfix: unitsTranslations?.month?.value,
      },
      onChange: () => setSelectedMainProductPricing(pricing),
    };
  });

  const sheetLink = selectedPaymentPlan === PaymentPlan.Finance ? financingLink?.[0] : durationsLink?.[0];

  return (
    <div className={styles.durationsContainer}>
      <div className={styles.durationsHeader}>
        <span className={styles.durationHeaderText}>{durationText}</span>
        <Link
          {...sheetLink}
          className={styles.link}
          dataAttributes={{ 'data-analytics-read-more': durationText, 'data-analytics-modal-content': 'durations' }}
        >
          {readMoreText}
        </Link>
      </div>
      <div className={styles.durationsOptionsContainer}>
        {options.map(option => (
          <RadioButtonWithPricing key={option.id} {...option} />
        ))}
        <OneTimePaymentSummary price={selectedMainProductPricing.oneTimeAmount} />
      </div>
    </div>
  );
};
