import { usePhrases } from '@hooks/context/usePhrases';

export const useSectionsWithPhraseHeaders = ({ sections }) => {
  const featurePhrases = Object.values(usePhrases({ name: 'features', rawObject: true }));

  return sections.map(section => ({
    ...section,
    rows: section.rows.map(row => {
      const featurePhrase = featurePhrases.find(f => f.key === row.field);

      return {
        ...row,
        header: featurePhrase?.label || row.header,
        link: featurePhrase?.link?.[0],
      };
    }),
  }));
};
