import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';

const filteringOptions: FilteringOptions = (offersData, { featurePhrases }) => {
  return offersData
    .reduce((accumulatesFeatures, currentOffer) => {
      const featuresToDisplay: string[] = currentOffer.storyblokContent?.plan?.content?.featuresToDisplay;
      if (!featuresToDisplay) {
        return accumulatesFeatures;
      }

      featuresToDisplay.map(featureToDisplay => {
        const translatedFeature = Object.keys(featurePhrases).find(
          phrase => featurePhrases[phrase]?.key === featureToDisplay && featurePhrases[phrase]?.icon?.filename
        );
        const featureLabel = featurePhrases[translatedFeature]?.label;
        if (featureLabel && !accumulatesFeatures.includes(featureLabel)) {
          accumulatesFeatures.push(featureLabel);
        }
      });

      return accumulatesFeatures;
    }, [])
    .sort((a, b) => a.localeCompare(b));
};

const filterCondition: ValidationFunction = (selectedFilters, offer, { featurePhrases }) => {
  return selectedFilters.every(filter => {
    const featureOriginalName = Object.keys(featurePhrases).find(phrase => featurePhrases[phrase].label === filter);

    return offer.storyblokContent?.plan?.content?.featuresToDisplay?.includes(featurePhrases[featureOriginalName]?.key);
  });
};

export const featuresFilter = filterBuilder(filteringOptions, filterCondition);
