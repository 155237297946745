import { StoryblokMenuItem, StoryblokSubMenu } from '../../../types';

export enum MenuComponentName {
  LocalizationSelect = 'localizationSelect',
  SubMenu = 'subMenu',
}

export const isSubMenu = (menuItem: StoryblokMenuItem): menuItem is StoryblokSubMenu => {
  return menuItem.component === MenuComponentName.SubMenu;
};

export const isLocalizationSelect = menuItem => {
  return menuItem.component === MenuComponentName.LocalizationSelect;
};

export const getSubMenuDetails = ({
  subMenusOrder,
  menuItems,
  title = null,
  label = null,
}: {
  subMenusOrder: string[];
  menuItems: any;
  title?: string;
  label?: string;
}) => {
  if (subMenusOrder.length) {
    const currentSubMenuUId = subMenusOrder[0];
    const leftSubMenuUIds = subMenusOrder.slice(1);

    const subMenu = getSubMenuByUId({ subMenuUId: currentSubMenuUId, menuItems });

    return getSubMenuDetails({
      subMenusOrder: leftSubMenuUIds,
      menuItems: subMenu.items,
      title: subMenu.title,
      label: subMenu?.label,
    });
  }

  return {
    title,
    menuItems,
    label,
  };
};

const getSubMenuByUId = ({
  subMenuUId,
  menuItems,
}: {
  subMenuUId: string;
  menuItems: any;
}): StoryblokSubMenu & { label?: string } => {
  return menuItems
    .filter(({ component }) => component === MenuComponentName.SubMenu)
    .find(subMenu => subMenu._uid === subMenuUId);
};
