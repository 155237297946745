import { useState, useEffect } from 'react';

import * as dayjs from 'dayjs';

const INTERVAL = 1000;

export const useTimer = ({ expectedDate, callback }) => {
  const [timer, setTimer] = useState('--:--');
  const [isTimeEnd, setIsTimerEnd] = useState(false);

  let timeCounter = Date.now() + INTERVAL;
  const timerProcess = () => {
    const duration = dayjs(expectedDate).subtract(dayjs());
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    if (duration < 0) {
      setIsTimerEnd(true);

      return callback && callback();
    }

    timeCounter += INTERVAL;

    setTimer(`${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`);
    setTimeout(timerProcess, Math.max(0, INTERVAL - Date.now() - timeCounter));
  };

  useEffect(() => {
    if (!expectedDate) {
      setIsTimerEnd(true);

      return;
    }
    const timeout = setTimeout(timerProcess, INTERVAL);

    return () => {
      clearTimeout(timeout);
      setTimer('--:--');
      setIsTimerEnd(false);
    };
  }, []);

  return { timer, isTimeEnd };
};
