import { Offer, OfferItemOneTimeRecurringPricing } from 'types/offer.types';

import { pushGtmEvent } from './gtm';
import { mapOfferAndPricingAsGtmItem } from './gtm-offer';

export const sendPlanImpressionEvent = (
  offer: Offer,
  selectedPricing: OfferItemOneTimeRecurringPricing,
  productName: string,
  financeAvailable?: boolean
) => {
  const event = {
    event: 'productDetailView',
    items: [mapOfferAndPricingAsGtmItem({ offer, selectedPricing, productName, financeAvailable })],
  };

  pushGtmEvent(event);
};

export const sendFormErrorEvent = errors => {
  const event = {
    event: 'formError',
    errors: Object.entries(errors).map(([key, val]) => ({ field: key, message: val })),
  };

  pushGtmEvent(event);
};
