import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './Checkbox.module.scss';
import { CheckboxInternals } from './CheckboxInternals/CheckboxInternals';

export interface CheckboxProps {
  name: string;
  label: ReactNode;
  className?: string;
  dataAttribute: Record<string, string>;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  variant?: 'bordered' | 'standard';
}

export const Checkbox = ({
  name,
  label,
  dataAttribute,
  className,
  checked,
  disabled = false,
  onChange,
  variant = 'bordered',
}: CheckboxProps) => {
  const handleChange = () => {
    if (disabled) {
      return;
    }

    onChange();
  };

  return (
    <div
      className={cn(styles.container, className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.bordered]: variant === 'bordered',
      })}
      onClick={handleChange}
    >
      <CheckboxInternals
        name={name}
        label={label}
        checked={checked}
        disabled={disabled}
        dataAttribute={dataAttribute}
      />
    </div>
  );
};
