import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useTimeSlotAvailableSlotsId = 'availableTimeSlots';

export const useTimeSlotAvailableSlotsQuery = cartId => {
  const httpService = useHttpService();

  return useQuery(
    useTimeSlotAvailableSlotsId,
    async () => {
      const response = await httpService.get(`/timeslot-booking/timeslots/${cartId}`);

      return response.data;
    },
    {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
