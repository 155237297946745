import React, { useRef } from 'react';

import cn from 'classnames';
import Script from 'next/script';

import { useClient } from '@hooks/useClient';
import { useLocale } from '@hooks/useLocale';

import styles from './TrustPilot.module.scss';

const mappedTrustpilotLocale = {
  en: 'en-US',
  nl: 'nl-NL',
  de: 'de-DE',
};

const widgets = {
  mini: { id: '53aa8807dec7e10d38f59f32' },
  microStar: { id: '5419b732fbfb950b10de65e5' },
  microReviewCount: { id: '5419b6a8b0d04a076446a9ad' },
  huge: { id: '54ad5defc6454f065c28af8b' },
};

export const TrustPilot = ({ type }) => {
  const { language } = useLocale();
  const trustpilotContainer = useRef(null);
  const { isClient } = useClient();

  if (!isClient) {
    return <></>;
  }

  return (
    <>
      <Script
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="beforeInteractive"
        onReady={() => {
          if (isClient && window.Trustpilot) {
            window.Trustpilot.loadFromElement(trustpilotContainer.current, true);
          }
        }}
      />

      <div className={styles.container}>
        <div
          ref={trustpilotContainer}
          className={cn('trustpilot-widget', styles[type])}
          data-locale={mappedTrustpilotLocale[language]}
          data-template-id={widgets[type]?.id}
          data-businessunit-id="60464dfd82d4930001d2cb59"
          data-style-width="100%"
          data-theme="light"
          data-stars="1,2,3,4,5"
          data-review-languages={language === 'de' ? 'en' : language} // fallback until we get some opinions on DE page
        >
          <a
            href={`https://${language === 'en' ? '' : `${language}.`}trustpilot.com/review/bluemovement.com`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </>
  );
};
