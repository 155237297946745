import { useLocale } from '@hooks/useLocale';

import LocalizationChangeButton from './components/LocalizationChangeButton';
import styles from './LocalizationChangeDropdown.module.scss';
import { buildLocalizationOptions, flagsMap } from './utils/localizationUtils';

import { Dropdown, DropdownPositions } from '@base-components/Dropdown/Dropdown';
import { Country, Language } from 'types/common.types';
import { Localization } from 'types/localization.types';
import { Button, ButtonTheme, ButtonVariant } from '@base-components/Button';
import cn from 'classnames';

type LocalizationChangeDropdownProps = {
  dropdownPosition?: {
    horizontal: DropdownPositions.Left | DropdownPositions.Right;
    vertical: DropdownPositions.Top | DropdownPositions.Bottom;
  };
  theme?: LocalizationDropdownTheme;
};

export enum LocalizationDropdownTheme {
  light,
  dark,
}

const LocalizationChangeDropdown = ({
  dropdownPosition,
  theme = LocalizationDropdownTheme.light,
}: LocalizationChangeDropdownProps) => {
  const { country: currentCountry, language: currentLanguage }: Localization = useLocale();

  const CountryFlagIcon = flagsMap[currentCountry];

  const openButtonTheme = {
    [LocalizationDropdownTheme.light]: {
      opened: ButtonTheme.SoftBlue,
      closed: ButtonTheme.White,
    },
    [LocalizationDropdownTheme.dark]: {
      opened: ButtonTheme.RawOnDark,
      closed: ButtonTheme.RawOnDark,
    },
  };

  return (
    <>
      <Dropdown
        displayPosition={dropdownPosition}
        renderButton={({ isOpen, onClick }) => (
          <Button
            variant={ButtonVariant.Basic}
            as="button"
            coloredIcon={true}
            theme={isOpen ? openButtonTheme[theme].opened : openButtonTheme[theme].closed}
            className={cn(styles.dropdownButton, {
              [styles.darkButton]: theme === LocalizationDropdownTheme.dark,
            })}
            onClick={onClick}
          >
            <CountryFlagIcon className={styles.icon} /> {`(${currentLanguage.toUpperCase()})`}
          </Button>
        )}
      >
        {buildLocalizationOptions((countryCode: Country, languageCode: Language) => {
          return (
            <LocalizationChangeButton
              countryCode={countryCode}
              languageCode={languageCode}
              currentLanguage={currentLanguage}
              currentCountry={currentCountry}
              classNames={styles.localizationChangeButton}
            />
          );
        })}
      </Dropdown>
    </>
  );
};

export default LocalizationChangeDropdown;
