import React, { createContext, useContext, useEffect } from 'react';

import { useRouter } from 'next/router';

import { Page } from '../Page/Page';

export const BlogContext = createContext();

// in storyblok we need Page, which behave as normal Page, but have additional tab with fields
// (normally there are "General", "SEO", "Configuration" and here we also need "Categories")
// for that case I wrraped Page and create new component - it works the same way, but it is different from storyblok perspective
export const BlogPostPage = ({ context, ...props }) => {
  const router = useRouter();

  useEffect(() => {
    const locale = router.query.locale;
    const translatedSlug = props.story.translated_slugs?.find(slug => slug.lang === locale);

    if (!router.query.post || !translatedSlug || !Object.keys(translatedSlug).length) {
      return;
    }

    const post = translatedSlug.path.split('/').slice(-1)[0];

    if (router.query.post !== post) {
      router.replace({ pathname: '/[locale]/blog/[post]', query: { locale, post } }, undefined, {
        shallow: true,
      });
    }
  });

  return (
    <BlogContext.Provider value={context}>
      <Page {...props} />
    </BlogContext.Provider>
  );
};

export const useBlogContext = () => {
  return useContext(BlogContext);
};
