import * as Yup from 'yup';

import { passwordComplexityRegExp } from '../../../validation/regexps/password';

const passwordSimilarityValidationSchema = ({ passwordMustContain, passwordsMustBeEqual, required }) => {
  return {
    newPassword: Yup.string()
      .matches(new RegExp(passwordComplexityRegExp), passwordMustContain)
      .required(required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], passwordsMustBeEqual)
      .required(required),
  };
};

export const resetPasswordValidation = validationMessages =>
  Yup.object().shape(passwordSimilarityValidationSchema(validationMessages));
