import * as Yup from 'yup';

import { CALL_ME_OPTION } from './helpers';

export function getCheckoutTimeSlotBookingValidationSchema(messages = { validationRequired: 'Required field' }) {
  const baseShape = {
    timeSlotDay: Yup.string().required(messages.validationRequired),
    timeSlotHours: Yup.string().when('timeSlotDay', {
      is: CALL_ME_OPTION,
      then: Yup.string(),
      otherwise: Yup.string().required(messages.validationRequired),
    }),
  };

  return Yup.object().shape(baseShape);
}
