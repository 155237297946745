/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { useState, createContext, useEffect, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { useLogger } from '@hooks/useLogger';

import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useContentContext } from '@context/content';
import { useContent } from '@hooks/useContent';
import { useSwitchMutation } from '@modules/UserPanel/Switch/hooks/useSwitchMutation';
import { addCMSFieldsToSubscriptions } from '@utils/add-cms-fields-to-subscriptions';

import { validationSchema } from './validation';

import { splitLocale } from '@services/locale-config';
import { getStepName } from 'helpers/get-step-name';
import { scrollToTop } from 'helpers/scroll-to-top';

// TODO add missing type
interface SwitchContextData {
  step?: any;
  handleSubmit?: () => any;
  setNextStep?: any;
  setPrevStep?: any;
  availableOptions?: any[];
  status?: string;
  reasons?: any[];
  currentSubscription?: any;
  subscriptions?: any[];
}

export const SwitchContext = createContext<SwitchContextData | null>(null);

dayjs.extend(minMax);

export const SwitchStepsNames = {
  select: 'select',
  check: 'check',
  confirmation: 'confirmation',
};

export const SwitchSteps = {
  select: {
    name: SwitchStepsNames.select,
    stepOrder: 1,
    F: 4,
  },
  check: {
    name: SwitchStepsNames.check,
    stepOrder: 2,
  },
  confirmation: {
    name: SwitchStepsNames.confirmation,
    stepOrder: 3,
  },
};

const FIRST_STEP_ORDER = 1;

export const ContractSwitchContext = ({
  validationMessages,
  children,
  subscriptions,
  contractSwitchProposal,
  locale,
}) => {
  const { logger } = useLogger({ context: 'ContractSwitchContext' });
  const [stepNumber, setStepNumber] = useState(FIRST_STEP_ORDER);
  const router = useRouter();
  const availableSteps = SwitchSteps;
  const currentStepName = getStepName(availableSteps, stepNumber);
  const switchMutation = useSwitchMutation();
  const { type, subscriptionId } = useContentContext();
  const { country, language } = splitLocale(locale);
  const { data: plansContent } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });
  const { subscriptions: subscriptionsWithCMSFields } = addCMSFieldsToSubscriptions({ subscriptions }, plansContent);
  const currentSubscription = subscriptionsWithCMSFields?.find(subscription => subscription.id === router.query.id);
  const defaultValues = {
    type,
    originalSubscriptionId: subscriptionId,
    pricingId: '',
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema(validationMessages)),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });

  useEffect(() => {
    scrollToTop();
  }, [stepNumber]);

  const setNextStep = () => {
    const [lastStep] = Object.values(SwitchSteps).sort((a, b) => b.stepOrder - a.stepOrder);

    if (stepNumber === lastStep.stepOrder) {
      return;
    }
    setStepNumber(prev => prev + 1);
  };

  const setPrevStep = () => {
    const [firstStep] = Object.values(SwitchSteps).sort((a, b) => a.stepOrder - b.stepOrder);
    if (stepNumber === firstStep.stepOrder) {
      return;
    }
    setStepNumber(prev => prev - 1);
  };

  const submitHandler = async data => {
    const switchDto = {
      type: data.type,
      originalSubscriptionId: data.originalSubscriptionId,
      pricingId: data.pricingId,
    };

    try {
      await switchMutation.mutateAsync(switchDto);

      setNextStep();
    } catch (e) {
      toast(e.data.errors[0].message, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <SwitchContext.Provider
        value={{
          setNextStep,
          step: currentStepName,
          handleSubmit: methods.handleSubmit(submitHandler, errors =>
            logger.error({ message: 'Error in handleSubmit in ContractSwitchContext', params: errors })
          ),
          setPrevStep,
          availableOptions: contractSwitchProposal?.options?.sort((a, b) => b.pricing?.months - a.pricing?.months),
          status: contractSwitchProposal?.status,
          reasons: contractSwitchProposal?.reasons,
          currentSubscription,
          subscriptions: subscriptionsWithCMSFields,
        }}
      >
        {children}
      </SwitchContext.Provider>
    </FormProvider>
  );
};

export const useContractSwitchContext = () => {
  const switchDataContext = useContext(SwitchContext);

  if (!switchDataContext) {
    throw new Error('You are out of the Switch Context Data provider!');
  }

  return switchDataContext;
};
