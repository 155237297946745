import React from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';

import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';

import styles from './DatePicker.module.scss';

export const DatePicker = ({
  controllerName,
  onBlur,
  onChange,
  disabled = false,
  minDate = false,
  maxDate = false,
  errorText = null,
  dataAttribute,
}) => {
  const {
    register,
    setValue,
    setError,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, controllerName);
  const isError = error !== undefined;

  const formattedMinDate = dayjs(minDate).format('DD-MM-YYYY');
  const formattedMaxDate = dayjs(maxDate).format('DD-MM-YYYY');

  return (
    <Controller
      render={({ field: { ref, ...field } }) => {
        return (
          <div className={styles.inputContainer}>
            <input
              id="date-picker"
              className={cn(styles.datePicker, { [styles.invalid]: isError })}
              placeholder="DD/MM/YYYY"
              type="date"
              {...register(controllerName)}
              {...field}
              data-test-id={controllerName}
              {...dataAttribute}
              onBlur={() => onBlur && onBlur()}
              onChange={event => {
                setValue(controllerName, event.target.value);

                if (onChange) {
                  onChange(event.target.value);
                }

                if (
                  (minDate &&
                    dayjs(minDate)
                      .subtract(1, 'day')
                      .isAfter(dayjs(event.target.value, 'YYYY-MM-DD'))) ||
                  (maxDate && dayjs(maxDate).isBefore(dayjs(event.target.value, 'YYYY-MM-DD')))
                ) {
                  setError(
                    controllerName,
                    {
                      type: 'focus',
                      message:
                        (errorText &&
                          errorText.replace(/{minDate}/, formattedMinDate).replace(/{maxDate}/, formattedMaxDate)) ||
                        `You have to select date between ${formattedMinDate} and ${formattedMaxDate}`,
                    },
                    { shouldFocus: false }
                  );
                } else {
                  clearErrors(controllerName);
                }
              }}
              disabled={disabled}
              min={minDate ? dayjs(minDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
              max={maxDate ? dayjs(maxDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
            />
            {isError && (
              <span className={styles.errorMessage}>
                <RectangleIcon className={styles.rectangleIcon} />
                {error.message}
              </span>
            )}
          </div>
        );
      }}
      name={controllerName}
      type="date"
      control={control}
    />
  );
};
