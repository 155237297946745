import { useContentContext } from '@context/content';
import { useContent } from '@hooks/useContent';

import { GroupType } from 'types/common.types';

export const useDeliveryTimeForOffer = (applianceGroupType: GroupType) => {
  const { locale } = useContentContext();
  const { data: deliveryTimePhrases } = useContent({ locale, name: 'phrases/deliverytime/' });

  const groupTypeLowerCase = applianceGroupType.toLowerCase();

  return deliveryTimePhrases?.[groupTypeLowerCase]?.value || '';
};
