/* eslint-disable import/no-cycle */
import React from 'react';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

import styles from './CheckoutContentSheet.module.scss';

export const CheckoutContentSheet = ({ items, sideItems }) => {
  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {items.map(item => {
          return <DynamicComponent {...item} key={item._uid} noLayout />;
        })}
      </div>
      {!!sideItems && (
        <div className={styles.sideItemsContainer}>
          {sideItems.map(item => {
            return <DynamicComponent {...item} key={item._uid} noLayout />;
          })}
        </div>
      )}
    </div>
  );
};
