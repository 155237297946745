import React from 'react';
import cn from 'classnames';

import { usePhrases } from '@hooks/context/usePhrases';
import { usePlanData } from '@modules/PDP/hooks/usePlanData';

import styles from './OfferCardHeader.module.scss';
import EnergyLabel from '../EnergyLabel/EnergyLabel';

export const OfferCardHeader = ({ energyLabel, isAlwaysVisible }) => {
  const {
    offer,
    offerContent: { content },
  } = usePlanData();
  const isSDA = offer.groupType === 'SDA';
  const featurePhrases = usePhrases({ name: 'features', rawObject: true });
  const link = featurePhrases?.energyClass?.link?.[0];
  const variant = content.features?.find(f => f.key === 'energyClass')?.value;

  return (
    <div className={cn({ [styles.mobileOnly]: !isAlwaysVisible })}>
      <h1 className={styles.headerTitle} data-pdp-category-name={offer.categorySlug}>
        {content.name}
      </h1>

      <span className={styles.headerSubtitle}>{content.subtitle}</span>

      {!isSDA && link && variant && (
        <div className={styles.energyLabel}>
          <EnergyLabel link={link} variant={variant} text={energyLabel} document={content.energyLabelDocument} />
        </div>
      )}
    </div>
  );
};
