import React from 'react';

import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';

import styles from './FinancingOffer.module.scss';
import { InfoBox } from '../InfoBox/InfoBox';

export const FinancingOffer = ({ financialProduct }) => {
  const { financial: financialText } = usePhrases({ name: 'offer-types' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const { oneTimeFee } = usePhrases({ name: 'general' });
  const { currencyFormatter } = useCurrencyFormatter();
  const financeOneTimeFeeText = `+${currencyFormatter({
    price: 0,
  })} ${oneTimeFee}`;

  return (
    <div>
      <InfoBox
        monthUnit={unitsTranslations?.month?.value}
        pricingRanges={[
          {
            key: financialProduct.id,
            condition: financialText,
            minValue: financialProduct.paymentAmount * 100,
          },
        ]}
      />

      <div className={styles.content}>
        <div className={styles.deliveryContainer}>{financeOneTimeFeeText}</div>
      </div>
    </div>
  );
};
