import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import Loader from '@components/Loader/Loader';
import { useSetNewPasswordMutation } from '@hooks/user/useSetNewPasswordMutation';
import { useUser } from '@hooks/user/useUser';

import styles from './ChangePasswordForm.module.scss';
import { passwordValidation } from './validation';

import { Button, ButtonVariant } from '@base-components/Button';

export const ChangePasswordForm = ({
  instructionText,
  oldPasswordText,
  newPasswordText,
  submitText,
  successToastMessage,
  failedToastMessage,
  validationMessages,
}) => {
  const { data: user } = useUser();
  const setNewPasswordMutation = useSetNewPasswordMutation();

  const formOptions = {
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
    resolver: yupResolver(passwordValidation(validationMessages)),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  const methods = useForm(formOptions);

  const onSubmitHandler = async data => {
    try {
      const response = await setNewPasswordMutation.mutateAsync({
        oldPassword: data.oldPassword,
        password: data.newPassword,
        passwordRepeat: data.newPassword,
      });
      if (response.status === 201) {
        toast(successToastMessage, {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      toast(failedToastMessage, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      methods.setValue('oldPassword', '');
      methods.setValue('newPassword', '');
    }
  };

  if (!user) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.formContainer}>
        <TextInput controllerName="oldPassword" labelText={oldPasswordText} password />
        <TextInput controllerName="newPassword" labelText={newPasswordText} password />
        <p className={styles.instructionText}>{instructionText}</p>

        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Icon}
          icon="none"
          type="submit"
          isFullWidth
          isLoading={methods.formState.isSubmitting}
          disabled={!methods.formState.isValid}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
