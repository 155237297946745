import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useUserEmailLookupMutation = () => {
  const httpService = useHttpService();

  return useMutation(async ({ email }) => {
    const { data } = await httpService.post('/checkout/email-lookup', { email });

    return data;
  });
};
