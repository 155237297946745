import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { Dropdown } from '@base-components/Dropdown/Dropdown';
import { Icon } from '@base-components/Icons/availableButtonIcons';
import { DataAttribute } from 'types/data-attributes';

import { CategoryLinkType, InternalLinkType, LinkComponent, StoryblokLink } from '@components/PageHeader/types';
import { useLocale } from '@hooks/useLocale';
import { buildCategoryPath, getHrefFromStoryblok } from '@utils/paths/build-paths';

import styles from './DropdownMenu.module.scss';

interface DropdownMenuProps {
  title: string;
  items: StoryblokLink[];
}

export const DropdownMenu = ({ title, items }: DropdownMenuProps) => {
  const { locale } = useLocale();

  const isCategoryLink = (item: StoryblokLink): item is CategoryLinkType => {
    return item.component === LinkComponent.CategoryLink;
  };

  return (
    <Dropdown
      renderButton={({ isOpen, onClick }) => (
        <Button
          variant={ButtonVariant.Basic}
          as="button"
          theme={isOpen ? ButtonTheme.SoftBlue : ButtonTheme.White}
          className={styles.link}
          onClick={onClick}
        >
          {title}
        </Button>
      )}
    >
      {items.map(menuItem =>
        isCategoryLink(menuItem) ? (
          <CategoryLink
            key={menuItem._uid}
            locale={locale}
            icon={menuItem.icon}
            label={menuItem.label}
            category={menuItem.category}
          />
        ) : (
          <InternalLink
            key={menuItem._uid}
            locale={locale}
            icon={menuItem.icon}
            label={menuItem.label}
            link={menuItem.link}
            pageId={menuItem.pageId}
          />
        )
      )}
    </Dropdown>
  );
};

const InternalLink = ({
  locale,
  icon,
  label,
  link,
  pageId,
}: Omit<InternalLinkType, '_uid' | 'component'> & { locale: string }) => {
  const href = getHrefFromStoryblok({ locale, link, pageId });

  return <MenuItem label={label} icon={icon} href={href} dataAttribute={{ 'data-test-id': pageId }} />;
};

const CategoryLink = ({
  locale,
  icon,
  label,
  category,
}: Omit<CategoryLinkType, '_uid' | 'component'> & { locale: string }) => {
  const href = buildCategoryPath({ locale, category });

  return (
    <MenuItem
      label={label}
      icon={icon}
      href={href}
      dataAttribute={{
        'data-topnav-description': label,
        'link-category': category,
      }}
    />
  );
};

type MenuItemType = {
  label: string;
  href: string;
  dataAttribute?: DataAttribute;
} & Icon;

const MenuItem = ({ label, icon, href, dataAttribute }: MenuItemType) => (
  <Button
    variant={ButtonVariant.Icon}
    as="anchor"
    theme={ButtonTheme.White}
    arrowPosition="none"
    icon={icon}
    href={href}
    onClick={e => {
      e.preventDefault();
      window.location.href = href;
    }}
    dataAttribute={dataAttribute}
    className={styles.linkButton}
    isFullWidth
  >
    {label}
  </Button>
);
