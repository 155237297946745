import React from 'react';

import cn from 'classnames';
import { createPortal } from 'react-dom';

import VectorIcon from '@assets/icons/new/vector.svg';
import { Image } from '@components/Image/Image';
import { SwipeWrapper } from '@components/SwipeWrapper/SwipeWrapper';
import CloseIcon from '@assets/icons/new/close.svg';

import styles from './FullScreen.module.scss';
import { PinchImage } from '../PinchImage/PinchImage';

export const FullScreen = ({
  images,
  closeButtonText,
  currentImageIndex,
  setFullScreen,
  setCurrentImageIndex,
  previousImage,
  nextImage,
}) => {
  const maxImageIndex = images.length;

  return createPortal(
    <div className={styles.fullScreen}>
      <SwipeWrapper style={{ overflow: 'hidden' }} onSwipeLeft={() => nextImage()} onSwipeRight={() => previousImage()}>
        <div className={styles.imageCarousel}>
          <div className={styles.imageCounterContainer}>
            {currentImageIndex + 1}/{maxImageIndex}
          </div>

          <div className={styles.closeButtonContainer}>
            <div className={styles.closeButton} onClick={() => setFullScreen(false)}>
              <span>{closeButtonText}</span>
              <div className={styles.closeIcon}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className={styles.topContainer}>
            <div className={styles.arrowsContainer}>
              <div className={styles.arrow} onClick={() => previousImage()}>
                <VectorIcon className={styles.vectorIconRotated} />
              </div>

              <div className={styles.arrow} onClick={() => nextImage()}>
                <VectorIcon className={styles.vectorIcon} />
              </div>
            </div>

            <PinchImage image={images[currentImageIndex]} alt="appliance" className={styles.image} />
          </div>

          <div className={styles.fullScreenNavigation}>
            {images.map((image, imageIndex) => (
              <Image
                key={imageIndex}
                image={image}
                alt="appliance"
                className={cn(
                  styles.desktop,
                  styles.image,
                  currentImageIndex === imageIndex ? styles.currentImage : null
                )}
                onClick={() => setCurrentImageIndex(imageIndex)}
                lazyLoading={false}
              />
            ))}
          </div>
        </div>
      </SwipeWrapper>
    </div>,
    document.getElementById('page-content')
  );
};
