import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { RichText } from '@components/RichText/RichText';
import { useLocale } from '@hooks/useLocale';
import { interpolateContent } from '@utils/interpolate-content';
import { buildPathForLocale } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './ContractSwitchSummaryForm.module.scss';

export const ContractSwitchSummaryForm = ({
  recurringConsentRichText,
  recurringConsentText,
  termsAndConditionConsent,
  termsAndConditionConsentDataAgreementLinkText,
  termsAndConditionConsentLinkTex,
}) => {
  const { country, locale } = useLocale();

  const termsAndConditionsPath = buildPathForLocale({
    pageId: Paths[`TermsAndConditions${country.toUpperCase()}`],
    locale,
  });
  const privacyPolicyPath = buildPathForLocale({ pageId: Paths[`PrivacyPolicy${country.toUpperCase()}`], locale });

  return (
    <div className={styles.recurringConsentCheckbox}>
      {/* @ts-ignore */}
      <Checkbox
        controllerName="recurringPaymentMandate"
        dataAttribute={{ 'data-test-id': 'recurringPaymentMandate' }}
        labelText={
          recurringConsentRichText ? (
            <RichText document={recurringConsentRichText} className={styles.recurringConsentRichText} />
          ) : (
            recurringConsentText
          )
        }
      />
      {/* @ts-ignore */}
      <Checkbox
        controllerName="agreeTermsAndConditions"
        dataAttribute={{ 'data-test-id': 'agreeTermsAndConditions' }}
        labelText={
          interpolateContent(termsAndConditionConsent, {
            termsAndConditionConsentDataAgreementLinkText: (
              <a
                href={privacyPolicyPath}
                target="_blank"
                className={styles.generalConsentCheckboxLink}
                rel="noreferrer"
              >
                {termsAndConditionConsentDataAgreementLinkText}
              </a>
            ),
            termsAndConditionConsent: (
              <a
                href={termsAndConditionsPath}
                target="_blank"
                className={styles.generalConsentCheckboxLink}
                rel="noreferrer"
              >
                {termsAndConditionConsentLinkTex}
              </a>
            ),
          }) as string
        }
      />
    </div>
  );
};
