import React from 'react';

import { useCart } from '@hooks/cart';

import styles from './NotificationBar2PlusItems.module.scss';

import { NotificationBar } from '../NotificationBar/NotificationBar';

export const NotificationBar2PlusItems = props => {
  const { data: cart } = useCart();

  if (cart.items.length > 1) {
    return (
      <div className={styles.container}>
        <div className={styles.compactLayout}>
          <NotificationBar {...props} />
        </div>
      </div>
    );
  }

  return null;
};
