import React, { createContext, useContext, useState } from 'react';

export const FaqContext = createContext();

export const FaqContextProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState(false);

  return (
    <FaqContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </FaqContext.Provider>
  );
};

export const useFaqContext = () => {
  return useContext(FaqContext);
};
