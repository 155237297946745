import { useEffect } from 'react';

import { useGtmContext } from '@context/gtm';

import { sendProductCategoryPageEvent } from '@services/gtm/product-category-page';
import { Offer } from 'types/offer.types';

export const useProductCategoryPageImpressionEvent = (offers: (Offer & { productName: string })[]) => {
  const { isPageInitializationEventSent } = useGtmContext();

  useEffect(() => {
    if (offers && offers.length > 0 && isPageInitializationEventSent) {
      sendProductCategoryPageEvent(offers);
    }
  }, [isPageInitializationEventSent]);
};
