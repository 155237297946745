/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { useUserLogoutMutation } from '@hooks/user/useUserLogoutMutation';
import { AdyenContext } from '@context/adyen';
import { usePaymentMutation } from '@hooks/payment/usePaymentMutation';
import { useLogger } from '@hooks/useLogger';

import { RichText } from '@components/RichText/RichText';
import { useChangeMandateMutation } from '@hooks/new-mandate/useChangeMandate';
import { getValidationFields } from '@utils/getValidationFields';
import { useUser } from '@hooks/user/useUser';
import { NewMandateFormWrapper } from '../../NewMandateForm';

import styles from './Adyen.module.scss';
import { paymentMethodsAdyenToChargebeeMap, paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';
import {
  CheckoutPaymentInputs,
  getPaymentMethodByComponent,
  remountComponent,
} from '@modules/Checkout/components/CheckoutPaymentInputs/CheckoutPaymentInputs';
import { CHECKOUT_PAYMENT_INPUTS_DE } from '@modules/Checkout/components/CheckoutPaymentInputs/de/CheckoutPaymentInputsDe';
import { CHECKOUT_PAYMENT_INPUTS_NL } from '@modules/Checkout/components/CheckoutPaymentInputs/nl/CheckoutPaymentInputsNl';
import { useLocale } from '@hooks/useLocale';
import { usePaymentAttemptMutation } from '@hooks/payment/usePaymentAttemptMutation';
import { Paths } from '@utils/paths/paths';
import { buildPath } from '@utils/paths/build-paths';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { usePhrases } from '@hooks/context/usePhrases';
import usePaymentConfiguration from '@hooks/payment/usePaymentConfiguration';
import { scrollToTop } from '@helpers/scroll-to-top';

const showErrorToast = message => {
  toast(message || 'Something went wrong. Please try again.', {
    type: toast.TYPE.ERROR,
    position: toast.POSITION.TOP_CENTER,
  });
};

export const AdyenNewMandateForm = props => {
  const { country } = useLocale();
  const phrases = usePhrases({ name: 'notifications' });
  const { logger } = useLogger({ context: 'AdyenNewMandateForm' });
  const adyenContext = useContext(AdyenContext);
  const paymentMutation = usePaymentMutation();
  const paymentAttemptMutation = usePaymentAttemptMutation();
  const logoutMutation = useUserLogoutMutation();
  const { data: loggedUser } = useUser();
  const {
    mutateAsync: changeMandateMutation,
    error: changeMandateError,
    isLoading: changeMandateIsLoading,
    isSuccess: changeMandateIsSuccess,
  } = useChangeMandateMutation();
  const firstRender = useRef(true);

  const validation = getValidationFields(props);
  const user = adyenContext.mandate.user;
  const countryCode = user.country.toUpperCase();
  const locale = `${user.country.toLowerCase()}-${countryCode}`;

  useEffect(() => {
    const logoutUser = async () => {
      await logoutMutation.mutateAsync();
    };

    if (firstRender.current) {
      firstRender.current = false;

      return;
    }

    if (loggedUser) {
      logoutUser();
    }
  }, [loggedUser]);

  const { configuration, isSubmitting, setSubmitting, setValidationError, validationError } = usePaymentConfiguration({
    customAmountValue: 0,
    cart: null,
    user,
    customOnSubmit: async (state, component, actions) => {
      scrollToTop();
      try {
        logger.info({ message: 'Submitting payment method change form', params: { userId: user?.id } });
        setSubmitting(true);

        const paymentMethodByComponent = getPaymentMethodByComponent(component);
        const newPaymentAttempt = await paymentAttemptMutation.mutateAsync({
          depositPaymentMethod: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
          redirectPath: buildPath({
            pageId: Paths.PaymentStatus,
            locale,
          }),
          depositAmount: 0,
          userId: user?.id,
          paymentType: 'checkout',
        });

        const paymentAttemptUuid = newPaymentAttempt.id;

        const statusPagePath = buildPath({
          pageId: Paths.PaymentStatus,
          locale: locale.toLowerCase(),
        });
        const APP_URL = getRuntimeConfig('APP_URL');

        const redirectUrl = `${APP_URL}/redirect?url=${encodeURIComponent(
          `${APP_URL}${statusPagePath
            .replace(':paymentBMId', paymentAttemptUuid)
            .replace(':transactionId', paymentAttemptUuid)}`
        )}`;

        const paymentMutationResult = await paymentMutation.mutateAsync({
          userId: user?.id,
          paymentMethod: state.data.paymentMethod,
          riskData: state.data.riskData || {},
          amountValue: 0,
          redirectPath: redirectUrl,
          paymentAttemptUuid,
          browserInfo: state.data.browserInfo,
          paymentType: 'change-payment-method',
        });

        const { resultCode, action, order, donationToken } = paymentMutationResult;

        if (resultCode === 'Refused' || !resultCode) {
          // TODO Adyen: refusalReasonCode -> translation should be mapped https://docs.adyen.com/development-resources/refusal-reasons/
          // "As a rule we advise merchants to be careful with exposing refusal reasons, as malicious shoppers can use it for card testing or other acts. In general, we advice merchants to only show generic refusal reasons."
          // Waiting for Barbara's decision what to show here.
          // Probably the best way is just "Try again" without showing any reasons
          actions.reject();

          return;
        }

        const mandateUpdated = await changeMandateMutation({
          country: user.country,
          userCbId: user.cbId,
          paymentType: paymentMethodsAdyenToChargebeeMap[paymentMutationResult.paymentMethod.type],
          recurringDetailReference: paymentMutationResult.additionalData['recurring.recurringDetailReference'],
          newMandateLinkId: adyenContext.mandate.id,
        });

        if (mandateUpdated) {
          actions.resolve({
            resultCode,
            action,
            order,
            donationToken,
          });
        }

        if (action.url) {
          logger.info({ message: 'Redirecting to provider page', params: { userId: user?.id } });

          window.location.href = action.url;
        } else {
          logger.info({
            message: 'Payment method changed',
            params: { userId: user?.id },
          });
        }
      } catch (error) {
        logger.error({
          message: 'Submitting payment method change form',
          params: { userId: user?.id, error, errorMessage: error.message },
        });

        actions.reject({
          errorMessage: error?.data?.errors?.map(e => e?.message).join(', '),
        });

        return error;
      }

      setSubmitting(false);
    },
    customOnPaymentCompleted: (result, component) => {
      scrollToTop();
      logger.info({ message: `User ${user?.id} updated payment method`, params: { userId: user?.id, result } });

      toast(phrases?.['payment-method-updated'] || 'Success', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    },
    customOnError: (error, component) => {
      logger.error({
        message: 'Error while submitting payment method change form',
        params: { userId: user?.id, error, errorMessage: error.message },
      });

      showErrorToast(error?.message);
      remountComponent(component);
      setSubmitting(false);
      setValidationError(true);
    },
  });

  if (!adyenContext.mandate.id || (changeMandateError && (changeMandateError as any).status === 404)) {
    return (
      <div className={styles.infoText} data-text-info="tokenDoesntExist">
        {props.tokenDoesntExist}
      </div>
    );
  }

  if (!adyenContext.mandate.isValid || adyenContext.mandate.status !== 'sent') {
    return (
      <div className={styles.infoText} data-text-info="tokenIsExpired">
        {props.tokenIsExpired}
      </div>
    );
  }

  if (changeMandateIsSuccess) {
    return (
      <div className={styles.infoText} data-text-info="paymentMethodsIsSuccesfullyChanged">
        {props.paymentMethodsIsSuccesfullyChanged}
      </div>
    );
  }

  const enabledCheckoutPaymentInputs = {
    de: [CHECKOUT_PAYMENT_INPUTS_DE.CARD, CHECKOUT_PAYMENT_INPUTS_DE.SEPA],
    nl: [CHECKOUT_PAYMENT_INPUTS_NL.CARD, CHECKOUT_PAYMENT_INPUTS_NL.SEPA],
  };

  return (
    <div className={styles.container}>
      <RichText
        className={styles.title}
        document={props.title}
        contentVariables={{
          firstName: user.firstName,
        }}
      />
      <NewMandateFormWrapper
        validation={validation}
        isLoading={isSubmitting || logoutMutation.isLoading || changeMandateIsLoading}
        {...props}
        {...props.content[0]} // TODO Adyen: hack to pass translation to CheckoutPaymentInputs
      >
        <CheckoutPaymentInputs
          configuration={configuration}
          setValidationError={setValidationError}
          validationError={validationError}
          enabledCheckoutPaymentInputs={enabledCheckoutPaymentInputs[country]}
          noDefaultPayment
          {...props}
          {...props.content[0]} // TODO Adyen: hack to pass translation to CheckoutPaymentInputs
        />
      </NewMandateFormWrapper>
    </div>
  );
};
