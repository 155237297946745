import React from 'react';

import LogoLoader from '@components/LogoLoader/LogoLoader';

import styles from './PageLoader.module.scss';

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <LogoLoader />
    </div>
  );
};

export default PageLoader;
