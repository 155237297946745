import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useUserNewPasswordMutation = () => {
  const httpService = useHttpService();

  return useMutation(async newPasswordDto => {
    const { data } = await httpService.post('/user/new-password', newPasswordDto);

    return data;
  });
};
