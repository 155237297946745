import { useMutation, useQueryClient } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';

import { useCartId } from './useCartId';
import { CART_QUERY_ID } from './useCartWithOffer';

import { sendRemoveFromCartEvent } from '@services/gtm/cart';
import { CartItem, CartResponse } from 'types/cart.types';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export const useRemoveItemsWithOffer = () => {
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();
  const { logger } = useLogger({ context: 'useRemoveItems' });
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useMutation<CartResponse, Error, CartItem[]>(
    async cartItems => {
      const { data } = await httpService.delete(`/cart/${cartId}/items/${cartItems.map(item => item.id).join('+')}`);

      return data;
    },
    {
      onSuccess: (data, cartItems) => {
        queryClient.invalidateQueries({ queryKey: CART_QUERY_ID });
        sendRemoveFromCartEvent(cartItems, plans);

        logger.info({
          message: 'Items are removed from cart',
          params: { cartId: data.cart.id, cartItems, allCartItems: data.cart.items },
        });
      },
    }
  );
};
