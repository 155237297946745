/* eslint-disable import/no-cycle */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import styles from './MovingPlannerProceedButton.module.scss';
import {
  MovingPlannerStepsNames,
  useMovingPlannerForm,
} from '../../page/MovingPlannerFormContext/MovingPlannerFormContext';

import { Button, ButtonVariant } from '@base-components/Button';

export const MovingPlannerProceedButton = ({ label }) => {
  const { setNextStep, step } = useMovingPlannerForm();
  const isSubmittingStep = step === MovingPlannerStepsNames.movingPlannerCheck;

  const {
    control,
    trigger,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const onClick = async () => {
    const isStepValid = await trigger();

    if (!isStepValid || isSubmittingStep) {
      return;
    }

    return setNextStep();
  };

  return (
    <Controller
      name="proceedButton"
      control={control}
      render={() => {
        return (
          <Button
            as="button"
            className={styles.takeoverProceedButton}
            variant={ButtonVariant.Icon}
            icon="none"
            onClick={onClick}
            disabled={isSubmitting || Object.values(errors).length > 0}
            type={isSubmittingStep ? 'submit' : 'button'}
            dataAttribute={{ 'data-test-id': 'movingPlannerProceedButton' }}
            isLoading={isSubmitting}
          >
            {label}
          </Button>
        );
      }}
    />
  );
};
