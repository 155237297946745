/* eslint-disable import/no-cycle */

import React, { useState } from 'react';

import cn from 'classnames';
import { minBy, orderBy } from 'lodash';
import { useRouter } from 'next/router';

import { FilteringModal } from '@components/modals/FilteringModal/FilteringModal';
import { filterOffers } from '@components/modals/FilteringModal/helpers/filterOffers';
import { useFilteringTranslations } from '@components/modals/FilteringModal/helpers/useFilteringTranslations';
import { useContentContext } from '@context/content';
import { useProductCategoryPageImpressionEvent } from '@hooks/gtm/useProductCategoryPageEvent';
import { useParsedFilteringQuery } from '@hooks/useParsedFilteringQuery';

import styles from './OfferCardColumn.module.scss';
import DynamicComponent from '../DynamicComponent/DynamicComponent';

import { EnergyClassOrder } from 'constants/energyClassOrder';
import { PagesRoute } from 'constants/routes';
import {
  Offer,
  OfferContractType,
  OfferItemOneTimeRecurringPricing,
  OfferItemWithStoryblokContent,
} from 'types/offer.types';
import { PlanClass } from 'types/plan.types';

const mapProductCardToOfferCard = (sortedOffersWithStoryblokContent: OfferItemWithStoryblokContent[]) => {
  return sortedOffersWithStoryblokContent.map(offer => ({
    ...offer,
    storyblokContent: {
      ...offer.storyblokContent,
      component: offer.storyblokContent.component === 'productCard' ? 'productCardOffer' : 'productCard',
    },
  }));
};

const Items = ({
  sortedOffersWithStoryblokContent,
}: {
  sortedOffersWithStoryblokContent: OfferItemWithStoryblokContent[];
}) => {
  useProductCategoryPageImpressionEvent(
    sortedOffersWithStoryblokContent.map(offer => {
      return {
        ...offer.offerData,
        productName: offer.storyblokContent.plan?.content?.name,
      };
    })
  );

  return (
    <>
      {mapProductCardToOfferCard(sortedOffersWithStoryblokContent)
        .map((offerWithStoryblokContent, i) => {
          return (
            <div
              key={offerWithStoryblokContent.storyblokContent._uid}
              className={cn(styles.item, styles.responsiveColumns)}
            >
              <DynamicComponent
                {...offerWithStoryblokContent.storyblokContent}
                offerData={offerWithStoryblokContent.offerData}
                index={i}
              />
            </div>
          );
        })
        .filter(Boolean)}
    </>
  );
};

export const OfferCardColumn = ({ items }) => {
  const { allOffers } = useContentContext() as {
    allOffers: Offer[];
  };

  const router = useRouter();
  const isInCategoryPage = router.route === PagesRoute.PCP;

  const parsedFilterQueries = useParsedFilteringQuery();
  const filteringTranslations = useFilteringTranslations();

  const offersWithStoryblokContent: OfferItemWithStoryblokContent[] = items
    .map(storyblokItem => {
      const offerEntity = allOffers.find(
        offer => offer.contentSlug.toLowerCase() === storyblokItem.plan.name.toLowerCase()
      );

      const mainProductsFromOffer = offerEntity?.offerItems?.filter(item => item.type === 'main');
      const productData = mainProductsFromOffer?.[0];

      if (productData) {
        const energyClassValue = storyblokItem.plan.content?.features
          ?.find(f => f.key === 'energyClass')
          ?.value.toLowerCase();

        const energyClass = EnergyClassOrder[energyClassValue];

        const lowestPricePricing = minBy(
          productData.pricings,
          (p: OfferItemOneTimeRecurringPricing) => p?.recurringAmount
        ) as OfferItemOneTimeRecurringPricing;

        if (offerEntity.contractType === OfferContractType.Financing && !offerEntity.financial) {
          return undefined;
        }

        return {
          storyblokContent: { ...storyblokItem, energyClass },
          offerData: {
            ...offerEntity,
            lowestPrice: lowestPricePricing?.recurringAmount,
            isAtLeastOneVariantAvailable: productData.isAtLeastOneVariantAvailable,
          },
        };
      }
    })
    .filter(Boolean);

  const [filteredOffers, setFilteredOffers] = useState(
    filterOffers(offersWithStoryblokContent, parsedFilterQueries, filteringTranslations)
  );

  const setNewOffers = (newOffers: OfferItemWithStoryblokContent[]) => {
    setFilteredOffers(newOffers);
  };

  const classPriority = {
    [PlanClass.Premium]: 1,
    [PlanClass.Comfort]: 2,
    [PlanClass.Basic]: 3,
  };

  const getClassPriority = (offer: OfferItemWithStoryblokContent) => {
    const offerItem = offer.offerData.offerItems.find(
      offerItem => offerItem.contentSlug.toLowerCase() === offer.offerData.contentSlug.toLowerCase()
    );

    return classPriority[offerItem?.class] || 3;
  };

  const sortedOffersWithStoryblokContent = orderBy(
    filteredOffers,
    [
      offer => offer.offerData.isAtLeastOneVariantAvailable,
      getClassPriority,
      offer => !!offer.offerData.financial,
      offer => offer.storyblokContent.energyClassValue,
      offer => offer.offerData.lowestPrice,
    ],
    ['desc', 'asc', 'desc', 'asc', 'asc']
  );

  return (
    <>
      <div className={styles.container}>
        {isInCategoryPage && (
          <div className={styles.filterBtn}>
            <FilteringModal offersData={offersWithStoryblokContent} setNewOffers={setNewOffers} />
          </div>
        )}
        <div className={cn(styles.regularMargin, styles.stackingLayout)}>
          <Items sortedOffersWithStoryblokContent={sortedOffersWithStoryblokContent} />
        </div>
      </div>
    </>
  );
};
