import React from 'react';

import styles from './TakeoverTransferDate.module.scss';

import { DatePickerDropdown } from '@components/inputs/DatePickerDropdown/DatePickerDropdown';

export const TakeoverTransferDate = ({ yearText, monthText }) => {
  const monthsRange = { start: 1, end: 12 };
  const yearsRange = { start: new Date().getUTCFullYear(), end: new Date().getUTCFullYear() + 1 };

  return (
    <div className={styles.container}>
      <DatePickerDropdown
        controllerName="takeoverDate"
        monthsRange={monthsRange}
        yearsRange={yearsRange}
        yearText={yearText}
        monthText={monthText}
      />
    </div>
  );
};
