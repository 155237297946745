import React from 'react';

import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useNotifications } from '@hooks/context/useNotifications';

import styles from './RemoveOutOfStockItemsNotification.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

const removeOutOfStockItemsNotificationId = 'remove-all-out-of-stock-items-from-cart';

export const RemoveOutOfStockItemsNotification = ({ ctaAction }) => {
  const notifications = useNotifications();

  return (
    notifications?.[removeOutOfStockItemsNotificationId] && (
      <div className={styles.notificationContainer}>
        <NotificationBar {...notifications?.[removeOutOfStockItemsNotificationId]}>
          {notifications?.[removeOutOfStockItemsNotificationId]?.ctaText && (
            <Button
              as="button"
              className={styles.notificationButton}
              theme={ButtonTheme.White}
              isElevated
              variant={ButtonVariant.Icon}
              icon="none"
              onClick={ctaAction}
            >
              {notifications?.[removeOutOfStockItemsNotificationId]?.ctaText}
            </Button>
          )}
        </NotificationBar>
      </div>
    )
  );
};
