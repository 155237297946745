import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const useUserBillingDateId = 'useUserBillingDate';

export const useUserBillingDate = userId => {
  const httpService = useHttpService();

  return useQuery(
    [useUserBillingDateId, userId],
    async () => {
      const { data } = await httpService.get(`/user/billing-date/${userId}`);

      return data;
    },
    { enabled: !!userId }
  );
};
