import { yupResolver } from '@hookform/resolvers/yup';

import { defaultValues, validationSchema } from './validation';

export const getDefaultValuesForWholeProcess = (takeoverSteps, subscriptionIds) => {
  const stepProperties = Object.values(takeoverSteps);
  const defaultValuesForWholeProcess = stepProperties.reduce((acc, { defaultValues }) => {
    const defaultValuesForStep = defaultValues.reduce(
      (stepAcc, defaultValueGetter) => ({ ...stepAcc, ...defaultValueGetter(subscriptionIds) }),
      {}
    );

    return {
      ...acc,
      ...defaultValuesForStep,
    };
  }, {});

  return defaultValuesForWholeProcess;
};

export const getValidationSchemaForStep = ({ validationsForStep, validationMessages, newestSubscriptionDate }) => {
  const validationSchemaForStep = validationsForStep.reduce((acc, validator) => {
    return {
      ...acc,
      ...validator({ newestSubscriptionDate, messages: validationMessages }),
    };
  }, {});

  return validationSchemaForStep;
};

export const generateFormOptionsForStep = ({
  steps,
  currentStep,
  subscriptionIds,
  newestSubscriptionDate,
  validationMessages,
}) => {
  const validationsForStep = getValidationSchemaForStep({
    validationsForStep: currentStep.validations,
    validationMessages,
    newestSubscriptionDate,
  });
  const defaultValuesForSteps = getDefaultValuesForWholeProcess(steps, subscriptionIds);

  const formOptions = {
    resolver: yupResolver(validationSchema(validationsForStep)),
    defaultValues: defaultValues(defaultValuesForSteps),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  return formOptions;
};
