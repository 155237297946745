/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';

import get from 'lodash/get';
import { useRouter } from 'next/router';
import { Controller, useFormContext } from 'react-hook-form';

import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useLocale } from '@hooks/useLocale';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CancellationProceedButton.module.scss';
import { StepContext, CancellationSteps } from '../../page/CancellationPage/CancellationPage';
import { checkIsCountryWithdrawalPeriodDate } from '../CancellationDetailsSubscriptionCard/CancellationDetailsSubscriptionCard';

import { Button, ButtonVariant } from '@base-components/Button';

export const CancellationProceedButton = ({ label }) => {
  const stepContext = useContext(StepContext);

  const router = useRouter();
  const { locale, country } = useLocale();
  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const cancellationConfirmation = getValues('cancellationConfirmation');

  useEffect(() => {
    if (getValues('cancellationConfirmation') === false) {
      stepContext.setCancellationConfirmation(false);
    }
  }, [stepContext.cancellationConfirmation]);

  const selectedPickupDateError = get(errors, 'selectedPickupDate');

  const activationDate = subscription?.subscriptionActivationDate;
  const minCancellationDate = subscription?.minimumCancellationDate;
  const isCountryWithdrawalPeriodDate = checkIsCountryWithdrawalPeriodDate({
    activationDate,
    country,
    minCancellationDate,
  });

  const stepToAction = {
    [CancellationSteps.cancellationDetails]: () => {
      if (subscription.status === 'future') {
        return stepContext.setStep(CancellationSteps.cancellationSummary);
      }

      if (isCountryWithdrawalPeriodDate) {
        return stepContext.setStep(CancellationSteps.cancellationSummary);
      }

      return stepContext.setStep(CancellationSteps.cancellationPickUpDateStep);
    },
    [CancellationSteps.cancellationPickUpDateStep]: () => stepContext.setStep(CancellationSteps.cancellationSummary),
    [CancellationSteps.cancellationSummary]: () => stepContext.setStep(CancellationSteps.cancellationConfirmation),
    [CancellationSteps.cancellationConfirmation]: () => {
      window.location.href = buildPath({
        pageId: Paths.UserPanelSubscriptions,
        locale,
      });
    },
  };

  return (
    <Controller
      name="CancellationProceedButton"
      control={control}
      render={() => {
        return (
          <Button
            isFullWidth
            as="button"
            className={styles.cancellationProceedButton}
            variant={ButtonVariant.Basic}
            onClick={
              stepContext.step === CancellationSteps.cancellationSummary ? undefined : stepToAction[stepContext.step]
            }
            disabled={
              (stepContext.step === CancellationSteps.cancellationSummary && !cancellationConfirmation) ||
              selectedPickupDateError
            }
            type={stepContext.step === CancellationSteps.cancellationSummary ? 'submit' : 'button'}
          >
            {label}
          </Button>
        );
      }}
    />
  );
};
