/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast } from 'react-toastify';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

import LogoLoader from '@components/LogoLoader/LogoLoader';
import { RichText } from '@components/RichText/RichText';
import { useCheckoutTestData } from '@context/checkout-test-data';
import { useCart } from '@hooks/cart/useCart';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import {
  useCheckoutUserRiskValidationMutation,
  ValidateUserDto,
} from '@hooks/checkout/useCheckoutUserRiskValidationMutation';
import { usePhrases } from '@hooks/context/usePhrases';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useRedirect } from '@hooks/useRedirect';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutCreditCheck.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { sendCreditCheckEvent } from '@services/gtm/page-initialization';
import Logger from '@services/logger';
import { Cart } from 'types/cart.types';
import { Locale } from 'types/common.types';
import {
  RiskValidatorStatus,
  DeniedToContinueDecision,
  RequiredIdCheckDecision,
} from 'types/user-risk-validation.types';
import { User } from 'types/user.types';

dayjs.extend(customParseFormat);
interface CheckoutCreditCheckProps {
  waitingText: string;
  retryButtonText: string;
  successfulCheckText: StoryblokRichtext;
  failedCheckText: StoryblokRichtext;
  retryCheckText: StoryblokRichtext;
}

const handleDeniedToContinue = (args: {
  isWrongPersonalDataError: boolean;
  shouldShowFailedRequestPage: boolean;
  isProviderError: boolean;
  riskResult: DeniedToContinueDecision;
  redirect: ReturnType<typeof useRedirect>;
  locale: Locale;
  logger: Logger;
  user: User;
}) => {
  const {
    isWrongPersonalDataError,
    shouldShowFailedRequestPage,
    isProviderError,
    riskResult,
    redirect,
    locale,
    logger,
    user,
  } = args;
  if (isWrongPersonalDataError) {
    redirect({
      url: buildPath({
        pageId: Paths.CheckoutCustomerDetails,
        queryParams: { creditCheckErrors: JSON.stringify({ fields: riskResult.details.fields }) },
        locale,
      }),
    });

    return;
  }

  if (shouldShowFailedRequestPage || isProviderError) {
    return;
  }

  logger.info({
    message: `Validation error during credit check for user: ${user?.id}`,
    params: { userId: user?.id, riskResult },
  });

  redirect({
    url: buildPath({
      pageId: Paths.CheckoutOrderNotAccepted,
      locale,
    }),
  });
};

const handleAllowedToContinue = (args: {
  successfulCheckText: StoryblokRichtext;
  redirect: ReturnType<typeof useRedirect>;
}) => {
  const { successfulCheckText, redirect } = args;
  redirect({
    pageId: Paths.CheckoutDelivery,
    notificationTranslatedMessage: successfulCheckText,
    notificationType: toast.TYPE.SUCCESS,
  });
};

const handleRequireIdCheck = (args: {
  riskResult: RequiredIdCheckDecision;
  successfulCheckText: StoryblokRichtext;
  redirect: ReturnType<typeof useRedirect>;
  locale: Locale;
}) => {
  const { riskResult, successfulCheckText, redirect, locale } = args;
  redirect({
    url: buildPath({
      pageId: Paths.IdConfirmation,
      queryParams: { token: riskResult.token, workflow: riskResult.workflowRunId },
      locale,
    }),
    notificationTranslatedMessage: successfulCheckText,
    notificationType: toast.TYPE.SUCCESS,
  });
};

const handleAction = {
  [RiskValidatorStatus.DeniedToContinue]: handleDeniedToContinue,
  [RiskValidatorStatus.AllowedToContinue]: handleAllowedToContinue,
  [RiskValidatorStatus.RequireIdCheck]: handleRequireIdCheck,
};

export const CheckoutCreditCheck = ({
  waitingText,
  successfulCheckText,
  failedCheckText,
  retryButtonText,
  retryCheckText,
}: CheckoutCreditCheckProps) => {
  const { logger } = useLogger({ context: 'CheckoutCreditCheck' });
  const { locale } = useLocale();
  const { data: cart } = useCart() as { data: Cart };
  const { data: cartWithOffers } = useCartWithOffer() as { data: Cart };
  const { data: user } = useUser() as { data: User };
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const { mutateAsync: validateUserMutation, data: result } = useCheckoutUserRiskValidationMutation();
  const { testData } = useCheckoutTestData();
  const redirect = useRedirect();

  useEffect(() => {
    validateUser();
  }, []);

  const validateUser = async () => {
    try {
      logger.info({
        message: `User ${user?.id} starts credit check`,
        params: { userId: user?.id, cartId: cart.id },
      });

      const validateUserDto: ValidateUserDto = {
        userId: user?.id,
        items: cartWithOffers?.items,
        ...testData,
      };
      const { riskResult, shouldShowFailedRequestPage, isProviderError, isWrongPersonalDataError } =
        await validateUserMutation(validateUserDto);

      await sendCreditCheckEvent({
        riskResult,
        shouldShowFailedRequestPage,
        isProviderError,
        isWrongPersonalDataError,
        option: { user: user ? 'loggedIn' : 'notLoggedIn' },
        locale,
      });

      handleAction[riskResult.status]({
        isWrongPersonalDataError,
        shouldShowFailedRequestPage,
        isProviderError,
        /* @ts-ignore */
        riskResult,
        redirect,
        locale,
        logger,
        user,
        successfulCheckText,
      });
    } catch (error) {
      logger.error({
        message: `Error during user validation ${user?.id}`,
        params: { userId: user?.id, cartId: cart?.id, error },
      });

      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  if (result?.shouldShowFailedRequestPage) {
    return <RichText className={styles.retryRichtext} document={failedCheckText} />;
  }

  if (result?.isProviderError) {
    return (
      <>
        <RichText className={styles.retryRichtext} document={retryCheckText} />
        <Button as="button" variant={ButtonVariant.Basic} onClick={validateUser}>
          {retryButtonText}
        </Button>
      </>
    );
  }

  return (
    <>
      <LogoLoader className={cn(styles.logoContainer, styles.noMargin)} />
      <p className={styles.waitingText}>{waitingText}</p>
    </>
  );
};
