import { useRouter } from 'next/router';

import { filtersConfig } from '@components/modals/FilteringModal/config';

import { usePhrases } from './context/usePhrases';

const stringToArray = (query: string | string[]) => {
  return query && query.length ? query.toString().split(',') : [];
};

export const useParsedFilteringQuery = () => {
  const router = useRouter();
  const filterPhrases = usePhrases({ name: 'filtering' });

  const reversedPhrases = Object.keys(filterPhrases).reduce((reversedFilters, currentFilterKey) => {
    const translatedParam = filterPhrases[currentFilterKey];
    if (translatedParam) {
      reversedFilters[translatedParam.toLowerCase().replace(/\s/, '')] = currentFilterKey;
    }

    return reversedFilters;
  }, {});

  const filtersData = Object.keys(reversedPhrases).reduce((existingFilters, localizedFilterName) => {
    const englishFilterName = reversedPhrases[localizedFilterName];
    const isFilterSupported = router.query[localizedFilterName] && filtersConfig[englishFilterName];
    if (isFilterSupported) {
      existingFilters[englishFilterName] = stringToArray(router.query[localizedFilterName]);
    }

    return existingFilters;
  }, {});

  return filtersData;
};
