import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const useUserPaymentMethodsId = 'useUserPaymentMethods';

export const useUserPaymentMethods = userCbId => {
  const httpService = useHttpService();

  return useQuery(
    [useUserPaymentMethodsId, userCbId],
    async () => {
      const { data } = await httpService.get(`/user/payment-sources/${userCbId}`);

      return data;
    },
    { enabled: !!userCbId }
  );
};
