/* eslint-disable import/no-cycle */
import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { omit } from 'lodash';
import { toast } from 'react-toastify';

import { useCart } from '@hooks/cart/useCart';
import { useCheckoutEditUserPersonalDataMutation } from '@hooks/checkout/useCheckoutEditUserPersonalDataMutation';
import { useCheckoutSignUpMutation } from '@hooks/checkout/useCheckoutSignUpMutation';
import { useUserEmailLookupMutation } from '@hooks/checkout/useUserEmailLookupMutation';
import { usePhrases } from '@hooks/context/usePhrases';
import { useNlAddressValidationMutation } from '@hooks/useAddressValidation';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useRedirect } from '@hooks/useRedirect';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { CheckoutRegisterFormDe } from './de/CheckoutRegisterFormDe';
import { CheckoutRegisterFormNl } from './nl/CheckoutRegisterFormNl';
import { streetAndHouseNumber2AddressLine } from '../../../../helpers/address-line';

import { defaultContactSpots } from '@helpers/contact-spots';
import { getGaClientId } from '@services/gtm/gtm';

dayjs.extend(customParseFormat);

const CountryForm = {
  nl: CheckoutRegisterFormNl,
  de: CheckoutRegisterFormDe,
};

const salutationDefaultEntries = [
  { option: 'Mrs.', value: 'mrs' },
  { option: 'Mr.', value: 'mr' },
];

const sanitize = value => value?.trim()?.replace(/\s+/g, ' ');

export const CheckoutRegisterForm = props => {
  const { logger } = useLogger({ context: 'CheckoutRegisterForm' });
  const { country, locale, language } = useLocale();
  const { data: cart } = useCart();
  const { data: user } = useUser();
  const errorPhrases = usePhrases({ name: 'errors', rawObject: true });
  const salutationPhrases = usePhrases({ name: 'salutations', rawObject: true });
  const salutationPhrasesEntries = Object.entries(salutationPhrases);
  const salutationEntries =
    salutationPhrasesEntries.length > 0
      ? salutationPhrasesEntries.map(([value, phrase]) => {
          return { option: phrase.value, value };
        })
      : salutationDefaultEntries;

  const signUpMutation = useCheckoutSignUpMutation();
  const updateUserMutation = useCheckoutEditUserPersonalDataMutation();
  const nlAddressValidationMutation = useNlAddressValidationMutation();
  const useUserLookupMutation = useUserEmailLookupMutation();

  const redirect = useRedirect();

  const onSubmit = async data => {
    let userIdToValidate;
    try {
      const {
        birthdate,
        customerType,
        deliveryInfo,
        isDeliveryInfo,
        isDifferentShippingAddress,
        differentShippingAddress,
        emailConsents,
        salutation,
        password,
        phoneNumber,
      } = data;
      if (country === 'nl' && ['aws-prod'].includes(getRuntimeConfig('ENVIRONMENT_NAME'))) {
        const address = await nlAddressValidationMutation.mutateAsync({
          postalCode: data.postalCode.toUpperCase().replace(/\s/g, ''),
          houseNumber: parseInt(data.houseNumber, 0),
        });

        const isAddressCorrect = address.city === data.city && address.street === data.street;

        if (!isAddressCorrect) {
          throw Error('Incorrect address');
        }
      }

      const firstName = sanitize(data.firstName);
      const lastName = sanitize(data.lastName);
      const street = sanitize(data.street);
      const postalCode = sanitize(data.postalCode);
      const city = sanitize(data.city);
      const houseNumber = sanitize(data.houseNumber);
      const houseNumberAddon = sanitize(data.houseNumberAddon);
      const email = user?.email || data?.email;
      const shippingAddress = isDifferentShippingAddress && {
        firstName,
        lastName,
        phone: differentShippingAddress.phoneNumber,
        street: sanitize(differentShippingAddress.street),
        houseNumber: sanitize(differentShippingAddress.houseNumber),
        houseNumberAddon: sanitize(differentShippingAddress.houseNumberAddon),
        postalCode: sanitize(differentShippingAddress.postalCode),
        city: sanitize(differentShippingAddress.city),
        country,
      };

      const billingAddress = {
        firstName,
        lastName,
        phone: phoneNumber,
        city,
        street,
        houseNumber,
        houseNumberAddon,
        country,
        postalCode,
      };

      const createUserDto = {
        customerType,
        salutation,
        email,
        firstName,
        lastName,
        phone: phoneNumber,
        password,
        birthdate: dayjs(birthdate, 'DD-MM-YYYY', true).format('YYYY-MM-DD'),
        postalCode,
        houseNumber,
        houseNumberAddon,
        street,
        city,
        extraDeliveryInformation: (isDeliveryInfo && deliveryInfo.note) || '',
        numberOfReturnedAssets: cart.numberOfReturnedAssets,
        acceptanceOfCommercialApproach: !!emailConsents,
        dryerConnector: cart.dryerConnector?.toString() || '',
        contactSpots: defaultContactSpots,
        cartId: cart.id,
        gaClientId: getGaClientId(),
        billingAddress,
        shippingAddress: shippingAddress || billingAddress,
        language,
      };

      if (!user) {
        const result = await useUserLookupMutation.mutateAsync({ email });

        if (result) {
          logger.warn({
            message: `User ${userIdToValidate || user?.id} have used existing email ${email}`,
            params: { userId: userIdToValidate, cartId: cart.id, result },
          });

          return;
        }

        const singedUpResponse = await signUpMutation.mutateAsync(createUserDto);
        userIdToValidate = singedUpResponse.userId;
      } else {
        logger.info({
          message: `User ${userIdToValidate || user.id} is trying to update existing data`,
          params: { userId: userIdToValidate, cartId: cart.id },
        });

        userIdToValidate = await updateUserMutation.mutateAsync({
          updateUserDto: {
            ...omit(createUserDto, ['password']),
            id: user.id,
            cbId: user.cbId,
            fdId: user.fdId,
            email: user.email,
          },
        });
      }

      logger.info({
        message: `User ${userIdToValidate || user.id} is checking risk validation`,
        params: { userId: userIdToValidate, cartId: cart.id },
      });

      const queryParams =
        data.testCreditCheckValue && data.testIdCheckValue
          ? { testCreditCheckValue: data.testCreditCheckValue, testIdCheckValue: data.testIdCheckValue }
          : {};

      redirect({
        url: buildPath({
          pageId: Paths.CheckoutCreditCheck,
          queryParams,
          locale,
        }),
      });
    } catch (error) {
      logger.error({
        message: `Error during registering by user ${userIdToValidate || user?.id}`,
        params: {
          userId: userIdToValidate || user?.id,
          cartId: cart.id,
          errorMessage: error.message || error.data?.errors?.[0]?.message,
        },
      });

      toast(errorPhrases?.['internal-server-error']?.value || 'Internal Server Error', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const CountryCheckoutRegisterForm = CountryForm[country];

  return (
    <CountryCheckoutRegisterForm
      user={user}
      cart={cart}
      salutationEntries={salutationEntries}
      onSubmit={onSubmit}
      isRedirecting={props.isRedirecting}
      validationMessages={props.validationMessages}
      {...props}
    />
  );
};
