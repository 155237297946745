import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { useForm, FormProvider } from 'react-hook-form';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { RichText } from '@components/RichText/RichText';
import { useLocale } from '@hooks/useLocale';
import { useQueryParam } from '@hooks/useQueryParam';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './FaqSearch.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';


export const FaqSearch = ({ title, description, inputPlaceholder, buttonText }) => {
  const { logger } = useLogger({ context: 'FaqSearchForm' });
  const { locale } = useLocale();
  const methods = useForm();
  const router = useRouter();
  const searchParam = useQueryParam('search');

  const onAutocompleteEnterPressed = searchPhrase => {
    const faqPath = buildPath({ pageId: Paths.Faq, locale });

    router.push({ pathname: faqPath, query: { search: searchPhrase } });
  };

  const onSubmit = ({ search }) => {
    onAutocompleteEnterPressed(search);
  };

  useEffect(() => {
    methods.setValue('search', searchParam);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>{title}</h1>

        <RichText document={description} className={styles.description} />
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, errors => {
            logger.error({ message: 'Error in submit form in FaqSearch', params: errors });
          })}
          className={styles.formContainer}
        >
          <div className={styles.inputContainer}>
            <TextInput controllerName="search" placeholder={inputPlaceholder} search />
          </div>

          <div className={styles.buttonContainer}>
            <Button className={styles.button} variant={ButtonVariant.Basic} theme={ButtonTheme.Secondary} type="submit">
              {buttonText}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
