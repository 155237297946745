/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useUserPaymentMethods } from '@hooks/payment/useUserPaymentMethods';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from '../ChangePaymentMethodForm.module.scss';
import { getChangePaymentFormValidationSchema } from '../validation';

import { Button, ButtonVariant } from '@base-components/Button';
import { OFF_SESSION_PAYMENT_METHODS, paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';
import { AdyenContext } from '@context/adyen';
import { usePaymentMutation } from '@hooks/payment/usePaymentMutation';
import { usePaymentAttemptMutation } from '@hooks/payment/usePaymentAttemptMutation';
import {
  CheckoutPaymentInputs,
  getPaymentMethodByComponent,
} from '@modules/Checkout/components/CheckoutPaymentInputs/CheckoutPaymentInputs';
import { CHECKOUT_PAYMENT_INPUTS_DE } from '@modules/Checkout/components/CheckoutPaymentInputs/de/CheckoutPaymentInputsDe';
import { CHECKOUT_PAYMENT_INPUTS_NL } from '@modules/Checkout/components/CheckoutPaymentInputs/nl/CheckoutPaymentInputsNl';
import Loader from '@components/Loader/Loader';
import { usePhrases } from '@hooks/context/usePhrases';
import usePaymentConfiguration from '@hooks/payment/usePaymentConfiguration';

export const AdyenChangePaymentMethodForm = ({
  sepaDescription,
  sepaName,
  creditCardDescription,
  creditCardName,
  submitText,
  successToastMessage,
  failedToastMessage,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'ChangePaymentMethodForm' });
  const { data: user, isLoading } = useUser();
  const { country, language } = useLocale();
  const adyenContext = useContext(AdyenContext);
  const { data: paymentSources } = useUserPaymentMethods(user?.cbId);
  const paymentMutation = usePaymentMutation();
  const router = useRouter();
  const paymentAttemptMutation = usePaymentAttemptMutation();
  const phrases = usePhrases({ name: 'notifications' });

  const countryCode = country.toUpperCase();
  const locale = `${country.toLowerCase()}-${countryCode}`;

  const { configuration, isSubmitting, setSubmitting, validationError, setValidationError } = usePaymentConfiguration({
    customAmountValue: 0,
    cart: null,
    user,
    customOnSubmit: async (state, component, actions) => {
      setSubmitting(true);

      try {
        logger.info({ message: 'Submitting payment method change form', params: { userId: user?.id } });

        const paymentMethodByComponent = getPaymentMethodByComponent(component);
        const newPaymentAttempt = await paymentAttemptMutation.mutateAsync({
          depositPaymentMethod: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent],
          redirectPath: buildPath({
            pageId: Paths.PaymentStatus,
            locale,
          }),
          depositAmount: 0,
          userId: user?.id,
          paymentType: 'checkout',
        });

        const paymentAttemptUuid = newPaymentAttempt.id;

        const statusPagePath = buildPath({
          pageId: Paths.PaymentStatus,
          locale: locale.toLowerCase(),
        });
        const APP_URL = getRuntimeConfig('APP_URL');

        const redirectUrl = `${APP_URL}/redirect?url=${encodeURIComponent(
          `${APP_URL}${statusPagePath
            .replace(':paymentBMId', paymentAttemptUuid)
            .replace(':transactionId', paymentAttemptUuid)}`
        )}`;

        const paymentMutationResult = await paymentMutation.mutateAsync({
          userId: user?.id,
          paymentMethod: state.data.paymentMethod,
          riskData: state.data.riskData || {},
          amountValue: 0,
          redirectPath: redirectUrl,
          paymentAttemptUuid,
          browserInfo: state.data.browserInfo,
          paymentType: 'change-payment-method',
        });

        const { resultCode, action, order, donationToken } = paymentMutationResult;

        if (resultCode === 'Refused' || !resultCode) {
          // TODO Adyen: refusalReasonCode -> translation should be mapped https://docs.adyen.com/development-resources/refusal-reasons/
          // "As a rule we advise merchants to be careful with exposing refusal reasons, as malicious shoppers can use it for card testing or other acts. In general, we advice merchants to only show generic refusal reasons."
          // Waiting for Barbara's decision what to show here.
          // Probably the best way is just "Try again" without showing any reasons
          actions.reject();

          return;
        }

        actions.resolve({
          resultCode,
          action,
          order,
          donationToken,
        });

        if (action?.url) {
          logger.info({ message: 'Redirecting to provider page', params: { userId: user?.id } });

          window.location.href = action.url;
        } else {
          logger.info({
            message: 'Payment method changed',
            params: { userId: user?.id },
          });
        }
      } catch (error) {
        logger.error({
          message: 'Submitting payment method change form',
          params: { userId: user?.id, error, errorMessage: error.message },
        });

        actions.reject();
      }
    },
    customOnPaymentCompleted: (result, component) => {
      logger.info({ message: `User ${user?.id} updated payment method`, params: { userId: user?.id, result } });

      toast(phrases?.['payment-method-updated'] || 'Success', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });

      router.push(
        buildPath({
          locale: `${country}-${language}`,
          pageId: Paths.UserPanelPreferences,
        })
      );
    },
  });

  const formOptions = {
    defaultValues: {
      monthlyPaymentMethod: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
    },
    resolver: yupResolver(getChangePaymentFormValidationSchema(validationMessages, paymentSources)),
    mode: 'onChange',
    reValidateMode: 'onChange',
  };
  const methods = useForm(formOptions);

  const onSubmit = async () => {
    await adyenContext.attachedPaymentMethod.submit();
  };

  const enabledCheckoutPaymentInputs = {
    de: [CHECKOUT_PAYMENT_INPUTS_DE.CARD, CHECKOUT_PAYMENT_INPUTS_DE.SEPA],
    nl: [CHECKOUT_PAYMENT_INPUTS_NL.CARD, CHECKOUT_PAYMENT_INPUTS_NL.SEPA],
  };

  const props = {
    sepaDescription,
    sepaName,
    creditCardDescription,
    creditCardName,
    submitText,
    successToastMessage,
    failedToastMessage,
    validationMessages,
  };

  if (!user || isLoading) {
    return <Loader />;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit, errors => {
          setSubmitting(false);
        })}
        className={styles.formContainer}
      >
        <CheckoutPaymentInputs
          configuration={configuration}
          setValidationError={setValidationError}
          validationError={validationError}
          enabledCheckoutPaymentInputs={enabledCheckoutPaymentInputs[country]}
          noDefaultPayment
          {...props}
        />

        <Button
          as="button"
          className={styles.button}
          variant={ButtonVariant.Icon}
          icon="none"
          type="submit"
          isFullWidth
          disabled={isSubmitting || methods.formState.isSubmitting}
          isLoading={isSubmitting || methods.formState.isSubmitting}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
