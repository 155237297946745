import { DEPOSIT_PAYMENT_METHODS, OFF_SESSION_PAYMENT_METHODS } from '@helpers/payment';
import * as Yup from 'yup';

export const getDefaultValues = () => {
  return {
    monthlyPaymentMethod: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
    oneTimePaymentMethod: DEPOSIT_PAYMENT_METHODS.CREDIT_CARD,
  };
};

export function getCheckoutFailedPaymentFormValidationSchema(messages) {
  return Yup.object().shape({
    oneTimePaymentMethod: Yup.string().required(messages.validationRequired),
  });
}
