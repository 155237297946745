/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AdyenContext } from '@context/adyen';
import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { Checkbox } from '@components/inputs/Checkbox/Checkbox';
import { getCheckoutPaymentFormValidationSchema } from './validation';
import { RichText } from '@components/RichText/RichText';
import { OFF_SESSION_PAYMENT_METHODS } from '@helpers/payment';
import { sendFormErrorEvent } from '@services/gtm/user-interaction-offer';
import { AdyenNewMandateForm } from './providers/Adyen/Adyen';

import styles from './NewMandateForm.module.scss';

export const NewMandateForm = props => {
  return <AdyenNewMandateForm {...props} />;
};

export const NewMandateFormWrapper = ({
  children,
  validation,
  button,
  isLoading,
  recurringConsentRichText,
  recurringConsentText,
}) => {
  const adyenContext = useContext(AdyenContext);
  const user = adyenContext.mandate.user;

  const formOptions = {
    defaultValues: {
      monthlyPaymentMethod: OFF_SESSION_PAYMENT_METHODS.CREDIT_CARD,
      userId: user.id,
    },
    resolver: yupResolver(getCheckoutPaymentFormValidationSchema(validation, null)),
  };

  const methods = useForm(formOptions);

  const onSubmitAdyen = async () => {
    await adyenContext.attachedPaymentMethod.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmitAdyen, errors => {
            sendFormErrorEvent(errors);
          })}
          id="newMandateForm"
        >
          {children}
          <>
            <Checkbox
              className={styles.recurringConsentCheckbox}
              controllerName="recurringPaymentMandate"
              labelText={
                recurringConsentRichText ? (
                  <RichText document={recurringConsentRichText} className={styles.recurringConsentRichText} />
                ) : (
                  recurringConsentText
                )
              }
            />

            <Button
              as="button"
              variant={ButtonVariant.Basic}
              theme={ButtonTheme.Primary}
              type="submit"
              isFullWidth
              disabled={methods.formState.isSubmitting || isLoading}
              isLoading={methods.formState.isSubmitting || isLoading}
            >
              {button}
            </Button>
          </>
        </form>
      </FormProvider>
    </>
  );
};
