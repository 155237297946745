import React from 'react';

import styles from './CheckoutSteps.module.scss';

export const CheckoutSteps = ({ steps }) => {
  return (
    <div className={styles.container}>
      <div className={styles.checkoutSteps}>
        {steps.map(step => (
          <div key={step.text} className={styles.checkoutStepName}>
            {step.text}
          </div>
        ))}
      </div>
    </div>
  );
};
