import * as yup from 'yup';

import { phoneFilters } from '../../../validation/regexps/phone';

export const contactValidationSchema = ({ translation, country }) => {
  const phoneFilter = phoneFilters[country];
  const { required, maxLength, email, phone, selectTopic } = translation;

  return yup.object().shape({
    firstName: yup
      .string()
      .required(required)
      .max(80, maxLength),

    lastName: yup
      .string()
      .required(required)
      .max(80, maxLength),

    email: yup
      .string()
      .email(email)
      .required(required),

    phone: yup
      .string()
      .nullable()
      .notRequired()
      .matches(phoneFilter, { message: phone, excludeEmptyString: true }),

    topic: yup.string().required(selectTopic),

    message: yup.string().required(required),

    consents: yup
      .bool()
      .oneOf([true], required)
      .required(required),
  });
};
