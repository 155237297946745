import React from 'react';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import cn from 'classnames';

import TrashIcon from '@assets/icons/new/trash.svg';
import { Image } from '@components/Image/Image';
import { Label } from '@components/Label/Label';
import { Link } from '@components/Link/Link';
import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useCartWithOffer } from '@hooks/cart/useCartWithOffer';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCondition } from '@hooks/useCondition';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useDeliveryTimeForOffer } from '@hooks/useDeliveryTimeForOffer';
import { useLocale } from '@hooks/useLocale';
import { NotActiveNotificationForOffer } from '@modules/PDP/components/NotActiveNotification/NotActiveNotificationForOffer';
import { convertMonthsToTermsSlug } from '@modules/PDP/components/OfferCard/helpers/convertMonthsToTermsSlug';
import OutOfStockForm from '@modules/PDP/components/OutOfStockForm/OutOfStockForm';
import { buildOfferPath, buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CartItem.module.scss';
import { AggregatedItemList } from '../AggregatedItemList/AggregatedItemList';

import { calculatePricesForAggregatedCartItem } from '@theme/ShoppingCart/helpers/calculatePricesForAggregatedCartItem';
import { AggregatedItemWithOfferItem, CartItem } from 'types/cart.types';
import { OfferItem } from 'types/offer.types';

const CartItemOffer = ({
  aggregatedCartItem,
  youPayMonthlyText,
  twoOrMoreSameAppliancesText,
  couponDiscountMessage,
  isCouponExpiredMessage,
  isCouponCodeRedeemedMessage,
  removeItems,
  possibleOfferItems,
}: {
  aggregatedCartItem: AggregatedItemWithOfferItem<CartItem>;
  youPayMonthlyText: string;
  twoOrMoreSameAppliancesText: string;
  couponDiscountMessage: string;
  isCouponExpiredMessage: boolean;
  isCouponCodeRedeemedMessage: boolean;
  removeItems: (itemsToRemove: CartItem[]) => void;
  possibleOfferItems: OfferItem[];
}) => {
  const { locale } = useLocale();
  const { currencyFormatter } = useCurrencyFormatter();
  const { data: cart } = useCartWithOffer();
  const { mainItem } = aggregatedCartItem;
  const deliveryTime = useDeliveryTimeForOffer(mainItem.offerItem.groupType);
  const termsTranslations = usePhrases({ name: 'terms', rawObject: true });
  const { getCondition } = useCondition();
  const { oneTimeFee } = usePhrases({ name: 'general' });

  const url = buildOfferPath({
    locale,
    contentSlug: mainItem?.offerItem.contentSlug,
    category: mainItem?.offerItem.categorySlug,
  });

  const monthlyPromotions = [];
  const oneTimePromotions = [];
  const isTheSameMainItemInCart = cart.items.filter(item => item.offerItem?.id === mainItem.offerItem?.id).length > 1;
  const { calculatedOneTimeFee, calculatedRecurringFee, discountPrice } = calculatePricesForAggregatedCartItem(
    aggregatedCartItem
  );

  if (cart.coupon?.code) {
    const { code, period } = cart.coupon;

    if (mainItem?.discountedPrice >= 0 && !isCouponExpiredMessage && !isCouponCodeRedeemedMessage) {
      monthlyPromotions.push({
        code,
        period,
        amount: discountPrice,
        text: couponDiscountMessage,
      });
    }
  }

  const itemSubtitle = mainItem?.content?.subtitle;

  return (
    <div className={styles.cartItem} key={mainItem?.id}>
      <div className={styles.itemHeader}>
        <Image
          image={mainItem?.content?.mainImage}
          alt={mainItem?.content?.name}
          className={cn(styles.itemImage, { [styles.outOfStockImage]: !mainItem.isOnStock })}
          lazyLoading={false}
        />
        <div className={styles.itemHeaderMain}>
          <div className={styles.itemHeaderMainText}>
            <Link href={url || buildPath({ locale, pageId: Paths.Home })} component="" className="">
              {mainItem?.content?.name || mainItem?.offerItem.contentSlug}
            </Link>
            <span className={styles.itemSubtitle}>{`${itemSubtitle ? `${itemSubtitle} - ` : ''}${termsTranslations[
              convertMonthsToTermsSlug(mainItem?.pricing.months)
            ]?.value || mainItem?.pricing.months}`}</span>
          </div>

          <span className={styles.itemLabels}>
            <Label text={getCondition(mainItem)} />
            {deliveryTime && <Label text={deliveryTime} dotColor="#8AD861" />}
          </span>
        </div>

        <div className={styles.itemActions}>
          <Button
            as="button"
            variant={ButtonVariant.Icon}
            icon="trash"
            theme={ButtonTheme.RawOnLight}
            className={styles.itemAction}
            onClick={() => {
              removeItems(cart.items.filter(item => aggregatedCartItem.includedItemsIds.includes(item.id)));
            }}
          />
        </div>
      </div>

      <AggregatedItemList
        aggregatedItem={aggregatedCartItem}
        possibleAdditionalItems={possibleOfferItems.filter(offerItem => offerItem.type === 'additional')}
        removeItems={removeItems}
      />

      <div className={styles.itemPaymentInfo}>
        <div
          className={cn(styles.itemPaymentInfoItem, {
            [styles.stroke]: oneTimePromotions?.length > 0,
          })}
        >
          <span>{oneTimeFee}</span>
          <span>{`${currencyFormatter({ price: calculatedOneTimeFee })}`}</span>
        </div>

        {oneTimePromotions?.map(promo => (
          <div className={cn(styles.itemPaymentInfoItem, styles.activePromotion)} key={promo.code}>
            <span>{promo.text}</span>
            <span>{`${currencyFormatter({ price: promo.amount })}`}</span>
          </div>
        ))}

        <div
          className={cn(styles.itemPaymentInfoSummary, {
            [styles.stroke]: monthlyPromotions?.length > 0,
          })}
        >
          <span>{youPayMonthlyText}</span>
          <span>{`${currencyFormatter({ price: calculatedRecurringFee })}`}</span>
        </div>

        {monthlyPromotions.map(promo => (
          <div className={cn(styles.itemPaymentInfoSummary, styles.activePromotion)} key={promo.code}>
            <span>{promo.text}</span>
            <span>{`${currencyFormatter({ price: promo.amount * aggregatedCartItem?.quantity })}`}</span>
          </div>
        ))}
      </div>
      {!mainItem.isOnStock && (
        <>
          <NotActiveNotificationForOffer
            locale={locale}
            isAvailable={false}
            categorySlug={mainItem.offerItem?.categorySlug}
          />
          <OutOfStockForm vib={mainItem.offerItem?.contentSlug} showButton={false} />
        </>
      )}
      {isTheSameMainItemInCart && (
        <div className={styles.itemNotification}>
          <NotificationBar variant="warning" shadow="grounded" content={twoOrMoreSameAppliancesText} />
        </div>
      )}
      <div className={styles.divider} />
    </div>
  );
};

export default CartItemOffer;
