import React, { useCallback } from 'react';

import { useLogger } from '@hooks/useLogger';

import { debounce } from 'lodash';
import { useFormContext } from 'react-hook-form';

import styles from './MovingPlannerNewAddress.module.scss';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useNlAddressValidationMutation } from '@hooks/useAddressValidation';
import { postalCodeFilters } from 'validation/regexps/postal-code';


export const MovingPlannerNewAddress = ({ postalCodeLabel, houseNumberLabel, addonLabel, streetLabel, cityLabel }) => {
  const { logger } = useLogger({ context: 'MovingPlannerNewAddress' });
  const nlAddressValidationMutation = useNlAddressValidationMutation();

  const { getValues, setValue } = useFormContext();

  const checkAddressDebounce = useCallback(
    debounce(async () => {
      const fieldsNames = {
        postalCode: 'postalCode',
        street: 'street',
        city: 'city',
        houseNumber: 'houseNumber',
      };

      const postalCode = getValues(fieldsNames.postalCode);
      const houseNumber = getValues(fieldsNames.houseNumber);

      if (postalCode?.match(postalCodeFilters.nl) && houseNumber) {
        try {
          const address = await nlAddressValidationMutation.mutateAsync({
            postalCode: postalCode.toUpperCase().replace(/\s/g, ''),
            houseNumber: parseInt(houseNumber, 0),
          });

          if (address) {
            setValue(fieldsNames.city, address.city, {
              shouldTouch: true,
              shouldValidate: true,
            });

            setValue(fieldsNames.street, address.street, { shouldTouch: true, shouldValidate: true });
          } else {
            setValue(fieldsNames.city, '', {
              shouldTouch: true,
              shouldValidate: true,
            });

            setValue(fieldsNames.street, '', {
              shouldTouch: true,
              shouldValidate: true,
            });
          }
        } catch (error) {
          setValue(fieldsNames.city, '', {
            shouldTouch: true,
            shouldValidate: true,
          });

          setValue(fieldsNames.street, '', {
            shouldTouch: true,
            shouldValidate: true,
          });

          logger.error({ message: 'Error in checkAddressDebounce in MovingPlannerNewAddress', params: error });
        }
      }
    }, 1500),
    []
  );

  const checkAddress = () => {
    checkAddressDebounce({ differentShippingAddress: false });
  };

  return (
    <div className={styles.column}>
      <div className={styles.row}>
        <TextInput
          controllerName="postalCode"
          labelText={postalCodeLabel}
          onBlur={checkAddress}
          dataAttribute={{ 'data-test-id': 'postalCode' }}
        />
        <TextInput
          controllerName="houseNumber"
          labelText={houseNumberLabel}
          onBlur={checkAddress}
          dataAttribute={{ 'data-test-id': 'houseNumber' }}
        />
      </div>
      <div className={styles.row}>
        <TextInput
          controllerName="houseNumberAddon"
          labelText={addonLabel}
          dataAttribute={{ 'data-test-id': 'houseNumberAddon' }}
        />
      </div>

      <div className={styles.row}>
        <TextInput
          controllerName="street"
          labelText={streetLabel}
          disabled
          dataAttribute={{ 'data-test-id': 'street' }}
        />
        <TextInput controllerName="city" labelText={cityLabel} disabled dataAttribute={{ 'data-test-id': 'city' }} />
      </div>
    </div>
  );
};
