import React from 'react';

import { Checkbox } from '@refactoredComponents/inputs/Checkbox/Checkbox';
import { TwoColumnText } from '@refactoredComponents/TwoColumnText/TwoColumnText';
import { AggregatedItemWithOfferItem, CartItem } from 'types/cart.types';
import { BillingType, OfferItem, OfferItemPricing } from 'types/offer.types';

import Pricing from '@components/Pricing/Pricing';
import { useAddToCartWithOfferMutation } from '@hooks/cart/useAddToCartWithOfferMutation';
import { usePhrases } from '@hooks/context/usePhrases';

import styles from './AggregatedItemList.module.scss';

export const AggregatedItemList = ({
  aggregatedItem: { mainItem, additionalItems },
  removeItems,
  possibleAdditionalItems = [],
  shouldIncludeCheckboxes = true,
}: {
  aggregatedItem: AggregatedItemWithOfferItem<CartItem>;
  removeItems?: (itemsToRemove: CartItem[]) => void;
  shouldIncludeCheckboxes?: boolean;
  possibleAdditionalItems?: OfferItem[];
}) => {
  const addToCartMutation = useAddToCartWithOfferMutation();
  const unitsTranslations = usePhrases({ name: 'units' });
  const { applianceCost } = usePhrases({ name: 'general' });
  const { included: includedText } = usePhrases({ name: 'additionalServices' });

  const billingOptionSuffix = {
    one_time: '',
    recurring: unitsTranslations['month'],
  };

  const addOrRemoveAdditionalItem = ({ offerItem, isSelected }: { offerItem: OfferItem; isSelected: boolean }) => {
    if (isSelected) {
      removeItems([additionalItems.find(item => item.offerItem.id === offerItem.id) as CartItem]);

      return;
    }

    addToCartMutation.mutate([
      {
        offerItemId: offerItem.id,
        pricingId: offerItem.pricings.find(pricing => pricing.months === mainItem.pricing.months).id,
        setId: mainItem.setId,
      },
    ]);
  };

  const mainItemPricingDetails = {
    price: (mainItem.pricing as OfferItemPricing)?.recurringAmount,
    isReferencePriceActive: false,
    referencePrice: (mainItem.pricing as OfferItemPricing)?.referenceRecurringAmount,
    postfix: billingOptionSuffix.recurring,
    separator: '/',
    includedText: includedText,
  };

  return (
    <div className={styles.additionalItemsContainer}>
      <TwoColumnText
        leftColumn={
          <p className={styles.additionalItemHeader}>
            <span>{applianceCost}</span>
          </p>
        }
        rightColumn={<Pricing {...mainItemPricingDetails} />}
      />

      {possibleAdditionalItems
        .filter(as => !as.isHiddenFromCustomer)
        .sort((prevAdditionalService, nextAdditionalService) => {
          if (
            prevAdditionalService.requirementType === 'mandatory' &&
            nextAdditionalService.requirementType === 'mandatory'
          ) {
            return 0;
          } else if (prevAdditionalService.requirementType === 'mandatory') {
            return -1;
          } else {
            return 1;
          }
        })
        .map((offerItem: OfferItem) => {
          const possiblePricing = offerItem.pricings.find(pricing => pricing.months === mainItem.pricing.months);
          const billingType = possiblePricing?.billingType;
          const isMandatory = offerItem.requirementType === 'mandatory';
          const isSelected = additionalItems.some(item => item.offerItem.id === offerItem.id);
          const pricingDetails = {
            price:
              billingType === BillingType.OneTime ? possiblePricing?.oneTimeAmount : possiblePricing?.recurringAmount,
            isReferencePriceActive: possiblePricing?.isReferenceAmountActive,
            referencePrice:
              billingType === BillingType.OneTime
                ? possiblePricing?.referenceOneTimeAmount
                : possiblePricing?.referenceRecurringAmount,
            postfix: billingOptionSuffix[billingType],
            separator: billingType === BillingType.OneTime ? ' ' : '/',
            included:
              billingType === BillingType.OneTime
                ? possiblePricing?.oneTimeAmount === 0
                : possiblePricing?.recurringAmount === 0,
            includedText: includedText,
          };

          return (
            <React.Fragment key={offerItem.id}>
              {shouldIncludeCheckboxes ? (
                <Checkbox
                  name={offerItem.contentSlug}
                  label={
                    <TwoColumnText
                      leftColumn={<span>{offerItem.content?.label || offerItem.contentSlug}</span>}
                      rightColumn={<Pricing {...pricingDetails} />}
                    />
                  }
                  dataAttribute={{ 'data-test-id': offerItem.contentSlug }}
                  checked={isMandatory || isSelected}
                  onChange={() => addOrRemoveAdditionalItem({ isSelected, offerItem })}
                  disabled={isMandatory}
                  variant="standard"
                />
              ) : (
                <TwoColumnText
                  leftColumn={
                    <span className={styles.additionalItemHeader}>
                      {offerItem.content?.label || offerItem.contentSlug}
                    </span>
                  }
                  rightColumn={<Pricing {...pricingDetails} />}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};
