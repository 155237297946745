/* eslint-disable import/no-cycle */
import React from 'react';
import { CheckoutCardsFormForOffer } from './CheckoutCardsFormForOffer';

export const CheckoutCardsForm = ({
  dryerOnTop,
  dryerOnTopExternalText,
  takeBackOldAppliance,
  takeBackOldApplianceExternalText,
  continueButtonText,
  isRedirecting,
}) => {
  return (
    <CheckoutCardsFormForOffer
      dryerOnTop={dryerOnTop}
      continueButtonText={continueButtonText}
      dryerOnTopExternalText={dryerOnTopExternalText}
      isRedirecting={isRedirecting}
      takeBackOldAppliance={takeBackOldAppliance}
      takeBackOldApplianceExternalText={takeBackOldApplianceExternalText}
    />
  );
};
