import React from 'react';

import styles from './IconTextBox.module.scss';

export const IconTextBox = ({
  icon,
  text,
  backgroundColor = 'transparent',
}: {
  icon?: React.ReactNode;
  text: string;
  backgroundColor?: 'transparent' | '#E1EFDF' | '#FDF2D9';
}) => {
  return (
    <div className={styles.iconText} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
