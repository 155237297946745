import dayjs from 'dayjs';

import { AggregatedItemWithOfferItem } from 'types/cart.types';
import { OfferItem } from 'types/offer.types';

const sortDates = (dateA: string, dateB: string) => {
  const itemACreatedDate = dayjs(dateA).unix();
  const itemBCreatedDate = dayjs(dateB).unix();

  return itemACreatedDate - itemBCreatedDate;
};

// export const aggregateCartItemsWithQuantity = (cartItems: CartItem[]): AggregatedItemWithOfferItem[] => {
//   //Not used for now, can be used for B2B
//   const cartItemsGroupedBySetId = cartItems.reduce((acc, item) => {
//     const existingGroup = acc.find(group => group.setId === item.setId);

//     if (!existingGroup) {
//       acc.push({
//         setId: item.setId,
//         items: [item],
//       });

//       return acc;
//     }

//     existingGroup.items.push(item);

//     return acc;
//   }, [] as { setId: string; items: CartItem[] }[]);

//   const aggregatedItems = cartItemsGroupedBySetId.reduce((acc, group) => {
//     const mainItem = group.items.find(item => item.offerItem.type === 'main');

//     if (!mainItem) {
//       return acc;
//     }

//     const additionalItems = group.items.filter(item => item.offerItem.type === 'additional');

//     const existingAggregatedItem = acc.find(currentlyAggregatedItem => {
//       return isAggregatedCartItemEqual(currentlyAggregatedItem, {
//         mainItem,
//         additionalItems,
//         includedItemsIds: group.items.map(item => item.id),
//       });
//     });

//     if (existingAggregatedItem) {
//       existingAggregatedItem.quantity += 1;
//       existingAggregatedItem.includedItemsIds.push(...group.items.map(item => item.id));

//       return acc;
//     }

//     acc.push({
//       mainItem,
//       additionalItems,
//       includedItemsIds: group.items.map(item => item.id),
//       quantity: 1,
//     });

//     return acc;
//   }, [] as AggregatedItemWithOfferItem[]);

//   return aggregatedItems.sort((a, b) => b.additionalItems.length - a.additionalItems.length);
// };

export const aggregateOfferItems = <T extends { setId: string; offerItem: OfferItem; id: string; createdDate: string }>(
  items: T[] = []
): AggregatedItemWithOfferItem<T>[] => {
  const itemsGroupedBySetId = items.reduce((acc, item) => {
    const existingGroup = acc.find(group => group.setId === item.setId);

    if (!existingGroup) {
      acc.push({
        setId: item.setId,
        items: [item],
      });

      return acc;
    }

    existingGroup.items.push(item);

    return acc;
  }, [] as { setId: string; items: T[] }[]);

  const aggregatedItems = itemsGroupedBySetId.reduce((acc, group) => {
    const mainItem = group.items.find(item => item.offerItem.type === 'main');

    if (!mainItem) {
      return acc;
    }

    const additionalItems = group.items.filter(item => item.offerItem.type === 'additional');

    acc.push({
      mainItem,
      additionalItems,
      includedItemsIds: group.items.map(item => item.id),
      quantity: 1,
    });

    return acc;
  }, [] as AggregatedItemWithOfferItem<T>[]);

  return aggregatedItems.sort((itemA, itemB) => {
    const mainItemACreatedDate = itemA.mainItem.createdDate;
    const mainItemBCreatedDate = itemB.mainItem.createdDate;

    return sortDates(mainItemACreatedDate, mainItemBCreatedDate);
  });
};
