import React from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';

import { Image } from '@components/Image/Image';
import { Link } from '@components/Link/Link';
import Loader from '@components/Loader/Loader';
import { useUserPaymentMethods } from '@hooks/payment/useUserPaymentMethods';
import { useUser } from '@hooks/user/useUser';
import { useUserBillingDate } from '@hooks/user/useUserBillingDate';
import { Paths } from '@utils/paths/paths';

import styles from './PaymentCard.module.scss';

const PAYMENT_SOURCES = {
  CREDIT_CARD: 'creditCard',
  SEPA: 'sepa',
  KLARNA_PAY_NOW: 'klarnaPayNow',
};

const getPaymentSource = (paymentSources, creditCardText, sepaText) => {
  if (paymentSources[PAYMENT_SOURCES.CREDIT_CARD]) {
    return {
      method: creditCardText,
      complementaryMethodInfo: `************${paymentSources[PAYMENT_SOURCES.CREDIT_CARD]?.last4}`,
    };
  }

  if (paymentSources[PAYMENT_SOURCES.SEPA]) {
    return {
      method: sepaText,
      complementaryMethodInfo: `************${paymentSources[PAYMENT_SOURCES.SEPA]?.last4}`,
    };
  }

  if (paymentSources[PAYMENT_SOURCES.KLARNA_PAY_NOW]) {
    return {
      method: 'Klarna Pay Now',
      complementaryMethodInfo: paymentSources[PAYMENT_SOURCES.KLARNA_PAY_NOW]?.email,
    };
  }

  return {
    method: '',
    complementaryMethodInfo: '',
  };
};

export const PaymentCard = ({
  cardIcon,
  editText,
  creditCardText,
  sepaText,
  creditDateText,
  noIcon = false,
  className,
  nextBillingText,
  noPaymentMethodText,
  paymentDateNotChosenYetText,
}) => {
  const { data: user } = useUser();
  const { data: paymentSources, isLoading: isPaymentLoading } = useUserPaymentMethods(user?.cbId);
  const { data: nextBillingDate } = useUserBillingDate(user?.id);

  // When sub has status 'future' next payment date is set to one year after purchase
  const todayPlusNineMonths = dayjs().add(9, 'month').unix();
  const isSubscriptionStatusFuture = nextBillingDate - todayPlusNineMonths > 0;

  if (!user || isPaymentLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  if (!paymentSources) {
    return (
      <div className={cn(styles.container, className)}>
        <span className={styles.noPaymentMethodText}>{noPaymentMethodText}</span>

        <Link
          component="internalLink"
          label={editText}
          pageId={Paths.UserPanelChangePaymentMethod}
          className={styles.editText}
        />
      </div>
    );
  }

  const { method, complementaryMethodInfo } = getPaymentSource(paymentSources, creditCardText, sepaText);

  return (
    <div className={cn(styles.container, className)}>
      {!noIcon && <Image image={cardIcon} mobileImage={cardIcon} className={styles.creditIcon} />}

      <div className={styles.creditContainer}>
        <div className={styles.creditCard}>
          {method && complementaryMethodInfo && (
            <>
              <span>{method}</span>
              <span className={styles.bold}>{complementaryMethodInfo}</span>
            </>
          )}
          {nextBillingDate && (
            <>
              <span className={styles.topMargin}>{creditDateText}</span>
              <span className={styles.bold}>
                {nextBillingText}{' '}
                {isSubscriptionStatusFuture
                  ? paymentDateNotChosenYetText
                  : dayjs.unix(nextBillingDate).format('DD-MM-YYYY')}
              </span>
            </>
          )}
        </div>
      </div>

      <Link
        component="internalLink"
        label={editText}
        pageId={Paths.UserPanelChangePaymentMethod}
        className={styles.editText}
      />
    </div>
  );
};
