import React, { useEffect } from 'react';

import cn from 'classnames';
import * as dayjs from 'dayjs';

import { Image } from '@components/Image/Image';
import { Label } from '@components/Label/Label';
import LogoLoader from '@components/LogoLoader/LogoLoader';
import { useCouponMessages } from '@hooks/cart';
import { usePhrases } from '@hooks/context/usePhrases';
import { useUserActiveTakeover } from '@hooks/subscriptions/useUserActiveTakeover';
import { useUserContractSwitches } from '@hooks/subscriptions/useUserContractSwitches';
import { useContent } from '@hooks/useContent';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useGlobalContent } from '@hooks/useGlobalContent';
import { useLocale } from '@hooks/useLocale';
import { convert2slug } from '@utils/convert2slug';
import { getCouponDiscountMessages } from '@utils/getCouponDiscountMessage';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import { DetailedSubscriptionCard } from './components/DetailedSubscriptionCard/DetailedSubscriptionCard';
import ItemPriceList from './components/ItemPriceList/ItemPriceList';
import StatusLabel from './components/StatusLabel/StatusLabel';
import styles from './SubscriptionCard.module.scss';
import { getRecurringAdditionalServices } from '../../../utils/getRecurringAdditionalServices';

import { Button, ButtonVariant } from '@base-components/Button';
import { ContractSwitchStatus } from 'types/contract-switch-status.types';

const formatDate = (value, format = 'DD-MM-YYYY') => value && dayjs(value).format(format);

const appliancesConditionColor = {
  new: '#FFBF00',
  refurbished: '#FFBF00',
  default: '#FFBF00',
};

const chooseIcon = (iconOne, iconTwo) => {
  return iconOne?.filename ? iconOne : iconTwo;
};

export const SubscriptionCard = ({
  subscription,
  additionalServices,
  accountIcon,
  calendarIcon,
  movingPlannerIcon,
  termsConditionIcon,
  cancelSubscriptionIcon,
  arrangeTakeoverIcon,
  requestRepairIcon,
  contractChangeIcon,
  contractRenewalIcon,
  termsConditionText,
  cancelSubscriptionText,
  arrangeTakeoverText,
  requestRepairText,
  movingPlannerText,
  statusText,
  cancellationText,
  takeoverText,
  viewSubscriptionButtonText,
  editText,
  addressText,
  deliverDetailsText,
  statusItems,
  takeoverStatusItems,
  deliveryStatusItems,
  detailed = false,
  noViewButton = false,
  showTermsInsteadStatus = false,
  children,
  contractRenewalText,
  contractChangeText,
  showItemPriceList = false,
}) => {
  const { locale, country } = useLocale();
  const { data: globalContent } = useGlobalContent({
    locale,
  });
  const { currencyFormatter } = useCurrencyFormatter();
  const termsPhrases = usePhrases({ name: 'terms' });
  const unitsTranslations = usePhrases({ name: 'units', rawObject: true });
  const generalTranslations = usePhrases({ name: 'general', rawObject: true });
  const conditionsTranslations = usePhrases({ name: 'conditions', rawObject: true });
  const contractSwitchTypesTranslations = usePhrases({ name: 'contract-switch-types', rawObject: true });
  const { data: takeover, isLoading: isTakeoverLoading, refetch } = useUserActiveTakeover();
  const { data: userContractSwitches, isLoading: isUserContractSwitchLoading } = useUserContractSwitches();
  const deliveryText = usePhrases({ name: 'deliverytime', rawObject: true });

  const { data: plansContent } = useContent({
    locale,
    name: `plans-${country}`,
  });

  const { data: additionalServicesContent } = useContent({
    locale,
    name: `additional-services`,
  });

  const { couponDiscountMessages } = useCouponMessages();

  let forDiscountedPeriodMessage;
  let forAfterDiscountPeriodMessage;

  if (subscription?.discount?.isActive) {
    const message = getCouponDiscountMessages(subscription?.discount, couponDiscountMessages);

    if (Array.isArray(message.forDiscountedPeriodMessage) || Array.isArray(message.forAfterDiscountPeriodMessage)) {
      forDiscountedPeriodMessage = message.forDiscountedPeriodMessage[0];
      forAfterDiscountPeriodMessage = message.forAfterDiscountPeriodMessage[0];
    } else {
      forDiscountedPeriodMessage = message.forDiscountedPeriodMessage;
      forAfterDiscountPeriodMessage = message?.forAfterDiscountPeriodMessage;
    }
  }

  useEffect(() => {
    refetch();
  }, []);

  if (!subscription || isTakeoverLoading || !globalContent || isUserContractSwitchLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LogoLoader />
      </div>
    );
  }

  const content = globalContent?.['user-panel-subscription-card']?.global?.[0];
  const CalendarIcon = () => (
    <Image
      alt="calendar"
      image={chooseIcon(calendarIcon, content.calendarIcon)}
      mobileImage={chooseIcon(calendarIcon, content.calendarIcon)}
      className={styles.calendarIcon}
    />
  );

  const {
    status,
    subscriptionActivationDate,
    minimumCancellationDate,
    preferredCancellationDate,
    cancellationRequestedDate,
    cancelledAt,
    monthlyAmount,
    applianceCondition,
    articleNumber,
    lastLspOrderItemData,
    order,
  } = subscription || {};
  const startDate = formatDate(
    showTermsInsteadStatus ? subscription.newConditions?.subscriptionActivationDate : subscriptionActivationDate
  );
  const endDate = formatDate(
    showTermsInsteadStatus ? subscription.newConditions?.minimumCancellationDate : minimumCancellationDate
  );
  const cancellationDate = formatDate(preferredCancellationDate) || formatDate(cancellationRequestedDate);
  const cancelledDate = formatDate(cancelledAt);

  const sbPlan = plansContent?.[articleNumber?.toLowerCase()];
  const mainImage = sbPlan?.fullImage;
  const fullName = sbPlan?.name;

  const isTakeover = takeover?.contractTakeoverLinks?.find(
    contractTakeoverLink => contractTakeoverLink?.originalSubscription?.id === subscription?.id
  );
  const takeoverDate = isTakeover && formatDate(takeover?.takeoverDate);
  const takeoverStatus = isTakeover && takeover?.status;

  const subscriptionContractSwitches = userContractSwitches?.filter(
    contractSwitch => contractSwitch.originalSubscriptionId === subscription.id
  );

  const activeContractSwitch = subscriptionContractSwitches?.find(contractSwitch =>
    [ContractSwitchStatus.Pending, ContractSwitchStatus.Requested].includes(contractSwitch.status)
  );

  const isAtLeastEightWeeksBeforeSubscriptionEnd = subscription => {
    const subscriptionEndDate = dayjs(subscription.minimumCancellationDate);
    const eightWeeksBeforeEndDate = subscriptionEndDate.subtract(8, 'week');

    return dayjs().isAfter(eightWeeksBeforeEndDate);
  };

  const isBeforeEightWeeksBeforeSubscriptionEnd = subscription => {
    return !isAtLeastEightWeeksBeforeSubscriptionEnd(subscription);
  };

  const contractSwitchType = isBeforeEightWeeksBeforeSubscriptionEnd(subscription) ? 'change' : 'renewal';

  const isAfterSwitchExecution = [ContractSwitchStatus.Completed, ContractSwitchStatus.Pending].includes(
    activeContractSwitch?.status
  );

  const recurringAdditionalServices = getRecurringAdditionalServices(additionalServices, additionalServicesContent);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.imageContainer}>
            <Image
              alt="mainImage"
              image={mainImage}
              mobileImage={mainImage}
              className={styles.applianceImage}
              backgroundImage={true}
            />
          </div>
        </div>

        <div className={styles.headerRight}>
          <span className={styles.applianceName}>{fullName}</span>
          <span className={styles.articleNumber}>
            {articleNumber}
            <Label
              text={conditionsTranslations?.[applianceCondition]?.value || ''}
              showDot={false}
              labelBackgroundColor={appliancesConditionColor[applianceCondition] || appliancesConditionColor.default}
              className={styles.applianceCondition}
            />
          </span>
          {startDate && endDate && (
            <span className={styles.dateRangeContainer}>
              <CalendarIcon /> {startDate} {generalTranslations?.until?.value || 'until'} {endDate}
            </span>
          )}
          {activeContractSwitch && (
            <span className={styles.processDateContainer}>
              <span className={styles.processDateText}>
                {(isAfterSwitchExecution
                  ? contractSwitchTypesTranslations?.[activeContractSwitch.type]?.pastTense
                  : contractSwitchTypesTranslations?.[activeContractSwitch.type]?.value) || activeContractSwitch.type}
              </span>
              <span className={styles.processDate}>
                {' '}
                {formatDate(activeContractSwitch.switchDate)} {generalTranslations?.until?.value || 'until'}{' '}
                {formatDate(activeContractSwitch.newConditions.minimumCancellationDate)}
              </span>
            </span>
          )}
          {takeoverDate && (
            <div className={styles.processDateContainer}>
              <CalendarIcon />
              <span className={styles.processDateText}>{takeoverText || content.takeoverText}</span>
              <span>-</span>
              <span className={styles.processDate}>{takeoverDate}</span>
            </div>
          )}

          {cancellationDate && (
            <span className={styles.cancellationDateContainer}>
              <CalendarIcon /> {cancellationText || content.cancellationText} {cancellationDate}
            </span>
          )}
        </div>
      </div>
      {showItemPriceList && (
        <ItemPriceList
          basicCostPhrase={generalTranslations?.applianceCost?.value || 'Basic costs'}
          postfixPhrase={unitsTranslations?.month?.value || 'month'}
          subscriptionMonthlyAmount={subscription.monthlyAmount}
          recurringAdditionalServices={recurringAdditionalServices}
        />
      )}
      <div className={styles.statusContainer}>
        {showTermsInsteadStatus && subscription.newConditions ? (
          <div className={styles.termsText}>
            {termsPhrases[convert2slug(subscription.newConditions?.contractTermPeriod)]}
          </div>
        ) : (
          <div className={styles.statusText}>
            {statusText || content.statusText}
            <StatusLabel
              status={status}
              statusItems={statusItems}
              takeoverStatus={takeoverStatus}
              takeoverStatusItems={takeoverStatusItems}
              deliveryStatusItems={deliveryStatusItems}
              content={content}
              country={country}
              lastLspOrderItemData={lastLspOrderItemData}
              cancelledDate={cancelledDate}
              isTakeover={isTakeover}
              order={order}
              deliveryText={deliveryText}
            />
          </div>
        )}

        <div>
          <div className={cn(styles.price, { [styles.discountIsActive]: subscription?.discount?.isActive })}>
            {subscription?.discount?.isActive && forAfterDiscountPeriodMessage}{' '}
            {currencyFormatter({
              price: showTermsInsteadStatus ? subscription.newConditions?.monthlyAmount : monthlyAmount,
            })}{' '}
            {unitsTranslations?.['per-month-short']?.value || 'p.m.'}
          </div>
          {subscription?.discount?.isActive && (
            <div className={styles.price}>
              {forDiscountedPeriodMessage} {currencyFormatter({ price: subscription?.discount?.discountedPrice })}{' '}
              {unitsTranslations?.['per-month-short']?.value || 'p.m.'}
            </div>
          )}
        </div>
      </div>
      {!detailed ? (
        !noViewButton && (
          <div className={styles.button}>
            <Button
              as="button"
              variant={ButtonVariant.Icon}
              isFullWidth
              icon="none"
              onClick={() => {
                window.location.href = buildPath({
                  locale,
                  pageId: Paths.UserPanelSubscriptionDetails,
                  params: { id: subscription?.id },
                });
              }}
              dataAttribute={{ 'data-test-id': 'view-subscription-button' }}
            >
              <span style={{ marginRight: 'auto' }}>
                {viewSubscriptionButtonText || content.viewSubscriptionButtonText}
              </span>
            </Button>
          </div>
        )
      ) : (
        <div>
          <DetailedSubscriptionCard
            subscription={{ ...subscription, status }}
            accountIcon={chooseIcon(accountIcon, content.accountIcon)}
            termsConditionIcon={chooseIcon(termsConditionIcon, content.termsConditionIcon)}
            cancelSubscriptionIcon={chooseIcon(cancelSubscriptionIcon, content.cancelSubscriptionIcon)}
            arrangeTakeoverIcon={chooseIcon(arrangeTakeoverIcon, content.arrangeTakeoverIcon)}
            movingPlannerIcon={chooseIcon(movingPlannerIcon, content.movingPlannerIcon)}
            requestRepairIcon={chooseIcon(requestRepairIcon, content.requestRepairIcon)}
            contractChangeIcon={chooseIcon(contractChangeIcon, content.contractChangeIcon)}
            contractRenewalIcon={chooseIcon(contractRenewalIcon, content.contractRenewalIcon)}
            takeoverStatus={takeoverStatus}
            switchStatus={activeContractSwitch?.status}
            editText={editText || content.editText}
            addressText={addressText || content.addressText}
            deliverDetailsText={deliverDetailsText || content.deliverDetailsText}
            termsConditionText={termsConditionText || content.termsConditionText}
            cancelSubscriptionText={cancelSubscriptionText || content.cancelSubscriptionText}
            arrangeTakeoverText={arrangeTakeoverText || content.arrangeTakeoverText}
            requestRepairText={requestRepairText || content.requestRepairText}
            movingPlannerText={movingPlannerText || content.movingPlannerText}
            contractSwitchType={contractSwitchType}
            contractRenewalText={contractRenewalText}
            contractChangeText={contractChangeText}
          />
        </div>
      )}
      {children && <div>{children}</div>}
    </div>
  );
};
