import axios from 'axios';
import { useQuery } from 'react-query';

const GLOBAL_CONTENT_QUERY_ID = 'GLOBAL_CONTENT_QUERY_ID';

export const useGlobalContent = ({ locale }) => {
  return useQuery(
    [GLOBAL_CONTENT_QUERY_ID, locale],
    async () => {
      const { data } = await axios.get(`/api/storyblok/get-global-content?locale=${locale}`);

      return data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};
