import React from 'react';

import styles from './Label.module.scss';

export const Label = ({
  dotColor = '#005DFF',
  text,
  labelBackgroundColor = '#F5F5F5',
  fontColor = '#000000',
  showDot = true,
  className = styles.text,
  dataAttribute = { 'data-test-id': 'label' },
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.label} style={{ backgroundColor: labelBackgroundColor }}>
        {showDot && <div className={styles.dot} style={{ backgroundColor: dotColor }} />}
        <div className={className} style={{ color: fontColor }} {...dataAttribute}>
          {text}
        </div>
      </div>
    </div>
  );
};
