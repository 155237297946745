import { Offer } from 'types/offer.types';

import { pushGtmEvent } from './gtm';
import { mapOfferAsGtmItem } from './gtm-offer';

export const sendProductCategoryPageEvent = (offers: (Offer & { productName: string })[]) => {
  const event = {
    event: 'productListView',
    items: offers.map(offer => mapOfferAsGtmItem(offer, offer.productName)),
  };

  pushGtmEvent(event);
};

export const sendOnClickProductEvent = (offer: Offer, productName: string) => {
  const event = {
    event: 'select_item',
    ecommerce: {
      items: mapOfferAsGtmItem(offer, productName),
    },
  };

  pushGtmEvent({ ecommerce: null });
  pushGtmEvent(event);
};
