import React from 'react';

import styles from './MovingPlannerConfirmationCheckbox.module.scss';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';

export const MovingPlannerConfirmationCheckbox = ({ text }) => {
  return (
    <div className={styles.container}>
      <Checkbox
        controllerName="agreedToMovingTerms"
        theme={Themes.White}
        labelText={text}
        dataAttribute={{ 'data-test-id': 'agreedToMovingTerms' }}
      />
    </div>
  );
};
