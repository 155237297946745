import React from 'react';

import getRuntimeConfig from '@utils/getRuntimeConfig';

const envName = getRuntimeConfig('ENVIRONMENT_NAME');

export const NoComponent = ({ componentName }) => {
  return (
    envName !== 'aws-prod' && (
      <p>
        The component <strong>{componentName}</strong> has not been created yet.
      </p>
    )
  );
};
