/* eslint-disable import/no-cycle */
import React from 'react';

import { Column } from '@components/Column/Column';
import { useLocale } from '@hooks/useLocale';
import { CategoriesAvailablePerCountry } from '@utils/paths/category';

export const CategoriesOverview = ({ items }) => {
  const { country } = useLocale();

  const categoriesInCountry = items.filter(item =>
    CategoriesAvailablePerCountry[country].includes(item?.category?.content?.category)
  );

  return <Column items={categoriesInCountry} noOfColumns={3} mobileBehavior="stacked" />;
};
