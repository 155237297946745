import { UseMutationOptions, useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

import { Pricing } from 'types/plan.types';

interface SwitchDto {
  type: 'change' | 'renewal';
  originalSubscriptionId: string;
  pricingId: string;
}

export const useSwitchMutation = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useMutation<Pricing[], unknown, unknown>(
    async (switchDto: SwitchDto) => {
      const response = await httpService.post('/subscription/switch/request', switchDto);

      return response.data;
    },
    { enabled: !!jwtToken } as UseMutationOptions<Pricing[], unknown>
  );
};
