/* eslint-disable import/no-cycle */
import React, { useState, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { debounce } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';
import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { Dropdown } from '@components/inputs/Dropdown/Dropdown';
import { TextInput } from '@components/inputs/TextInput/TextInput';
import { RichText } from '@components/RichText/RichText';
import { TestCreditCheck } from '@components/TestCreditCheck/TestCreditCheck';
import { TestIdCheck } from '@components/TestIdCheck/TestIdCheck';
import { useNlAddressValidationMutation } from '@hooks/useAddressValidation';
import { CheckoutCheckErrors } from '@modules/Checkout/components/CheckoutCheckErrors/CheckoutCheckErrors';
import { CheckoutEmailInput } from '@modules/Checkout/components/CheckoutEmailInput/CheckoutEmailInput';
import getRuntimeConfig from '@utils/getRuntimeConfig';

import { existingCustomerValidationSchema, newCustomerValidationSchema, getDefaultValues } from './validation';
import { postalCodeFilters } from '../../../../../validation/regexps/postal-code/index';
import styles from '../CheckoutRegisterForm.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';
import { useLogger } from '@hooks/useLogger';
import { isTestingEnvironment } from '@helpers/isTestingEnvironment';

export const CheckoutRegisterFormNl = ({
  user,
  cart,
  onSubmit,
  formHeaderText,
  salutationLabelText,
  firstNameLabelText,
  lastNameLabelText,
  birthdateLabelText,
  initialsLabelText,
  birthdatePlaceholder,
  phoneNumberLabelText,
  phoneNumberOptionalLabelText,
  phoneNumberPlaceholder,
  phoneNumberHintText,
  streetLabelText,
  houseNumberLabelText,
  houseNumberAddonLabelText,
  postalCodeLabelText,
  postalCodePlaceholder,
  cityLabelText,
  differentShippingAddressLabelText,
  deliveryInfoLabelText,
  deliveryInfoNoteLabelText,
  bluemovementAccountHeaderText,
  bluemovementAccountHeaderDescriptionText,
  emailLabelText,
  enterManuallyText,
  enterAlternativeText,
  enterAutomaticallyText,
  passwordLabelText,
  passwordHintText,
  emailConsentsLabelRichText,
  submitButtonText,
  notificationBar,
  salutationEntries,
  importantInfoTitle,
  importantInfoText,
  errorCreditCheckTitle,
  errorIdCheckTitle,
  isRedirecting,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'CheckoutRegisterFormNl' });
  const [isManualAddress, setIsManualAddress] = useState(false);
  const [isManualDifferentShippingAddress, setIsManualDifferentShippingAddress] = useState(false);
  const defaultValues = getDefaultValues(user, cart);
  const formOptions = {
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      user ? existingCustomerValidationSchema(validationMessages) : newCustomerValidationSchema(validationMessages)
    ),
    defaultValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };
  const methods = useForm(formOptions);
  const nlAddressValidationMutation = useNlAddressValidationMutation();

  const checkAddressDebounce = useCallback(
    debounce(async ({ differentShippingAddress }) => {
      const fieldsNames = {
        postalCode: differentShippingAddress ? 'differentShippingAddress.postalCode' : 'postalCode',
        street: differentShippingAddress ? 'differentShippingAddress.street' : 'street',
        city: differentShippingAddress ? 'differentShippingAddress.city' : 'city',
        houseNumber: differentShippingAddress ? 'differentShippingAddress.houseNumber' : 'houseNumber',
      };

      const postalCode = methods.getValues(fieldsNames.postalCode);
      const houseNumber = methods.getValues(fieldsNames.houseNumber);

      if (postalCode?.match(postalCodeFilters.nl) && houseNumber) {
        try {
          const address = await nlAddressValidationMutation.mutateAsync({
            postalCode: postalCode.toUpperCase().replace(/\s/g, ''),
            houseNumber: parseInt(houseNumber, 0),
          });

          if (address) {
            methods.setValue(fieldsNames.city, address.city, {
              shouldTouch: true,
              shouldValidate: true,
            });

            methods.setValue(fieldsNames.street, address.street, { shouldTouch: true, shouldValidate: true });
          } else {
            methods.setValue(fieldsNames.city, '', {
              shouldTouch: true,
              shouldValidate: true,
            });

            methods.setValue(fieldsNames.street, '', {
              shouldTouch: true,
              shouldValidate: true,
            });
          }
        } catch (error) {
          methods.setValue(fieldsNames.city, '', {
            shouldTouch: true,
            shouldValidate: true,
          });

          methods.setValue(fieldsNames.street, '', {
            shouldTouch: true,
            shouldValidate: true,
          });
          logger.error({ message: 'Error in checkAddressDebounce in CheckoutRegisterFormNl', params: error });
        }
      }
    }, 1500),
    []
  );

  const checkAddress = () => {
    checkAddressDebounce({ differentShippingAddress: false });
  };

  const checkDifferentShippingAddress = () => {
    checkAddressDebounce({ differentShippingAddress: true });
  };

  const hasUserAnySubscriptions = user?.subscriptions?.length > 0;

  // TODO: ¯\_(ツ)_/¯
  // for some reason handleSubmit doesn't see the email error triggered by setError method
  // so we need to disable the submit button based on the form state error
  const isEmailError = methods.formState.errors?.email;

  // Types for TSX
  // Possible fields
  // "city"
  // "sexe"
  // "street"
  // "country"
  // "zipcode"
  // "initials"
  // "lastname"
  // "birthdate"
  // "extension"
  // "extrainfo"
  // "firstname"
  // "customerid"
  // "housenumber"
  // "phonenumber"
  // "referenceid"
  // "emailaddress"
  // "referencedate"

  // Object keys come from credit check provider
  const CreditCheckErrorLabelText = {
    firstname: firstNameLabelText,
    lastname: lastNameLabelText,
    emailaddress: emailLabelText,
    initials: initialsLabelText,
    housenumber: houseNumberLabelText,
  };

  // Object keys come from credit check provider
  const IdCheckErrorLabelText = {
    gender: salutationLabelText,
    dateOfBirth: birthdateLabelText,
    firstName: firstNameLabelText,
    lastName: lastNameLabelText,
  };

  return (
    <div className={styles.container}>
      <div className={styles.checkoutRegisterForm}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit, errors =>
              logger.error({ message: 'Error in onSubmit in CheckoutRegisterFormNl', params: errors })
            )}
            id="checkoutRegisterFormNl"
          >
            <div className={styles.column}>
              <span className={styles.columnText}>{formHeaderText}</span>
            </div>

            <CheckoutCheckErrors
              importantInfoText={importantInfoText}
              importantInfoTitle={importantInfoTitle}
              errorCreditCheckTitle={errorCreditCheckTitle}
              errorIdCheckTitle={errorIdCheckTitle}
              CreditCheckErrorLabelText={CreditCheckErrorLabelText}
              IdCheckErrorLabelText={IdCheckErrorLabelText}
            />

            <div className={styles.column} data-test-id="shippingAddress">
              <Dropdown
                controllerName="salutation"
                labelText={salutationLabelText}
                entries={salutationEntries}
                disabled={hasUserAnySubscriptions}
              />
              <TextInput controllerName="firstName" labelText={firstNameLabelText} disabled={hasUserAnySubscriptions} />
              <TextInput controllerName="lastName" labelText={lastNameLabelText} disabled={hasUserAnySubscriptions} />
              <TextInput
                controllerName="birthdate"
                mask="99-99-9999"
                labelText={birthdateLabelText}
                placeholder={birthdatePlaceholder}
                disabled={hasUserAnySubscriptions}
              />
              <TextInput
                controllerName="phoneNumber"
                labelText={phoneNumberLabelText}
                placeholder={phoneNumberPlaceholder}
                hintText={phoneNumberHintText}
                disabled={hasUserAnySubscriptions}
              />
              <div className={styles.row}>
                <TextInput
                  controllerName="postalCode"
                  labelText={postalCodeLabelText}
                  placeholder={postalCodePlaceholder}
                  // mask="9999 aa" // temporary disabled - it's not working
                  onBlur={!isManualAddress && checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
                <TextInput
                  controllerName="houseNumber"
                  labelText={houseNumberLabelText}
                  onBlur={!isManualAddress && checkAddress}
                  disabled={hasUserAnySubscriptions}
                />
                <TextInput
                  controllerName="houseNumberAddon"
                  labelText={houseNumberAddonLabelText}
                  disabled={hasUserAnySubscriptions}
                />
              </div>

              <div className={styles.row}>
                <TextInput controllerName="street" labelText={streetLabelText} disabled={!isManualAddress} />
                <TextInput controllerName="city" labelText={cityLabelText} disabled={!isManualAddress} />
              </div>

              {!user &&
                (!['aws-prod'].includes(getRuntimeConfig('ENVIRONMENT_NAME')) ? (
                  <div
                    className={styles.optionButton}
                    onClick={() => setIsManualAddress(!isManualAddress)}
                    data-checkout-address="manual-toggle"
                  >
                    {!isManualAddress && (
                      <span className={cn(styles.optionButtonText, styles.optionButton)}>{enterManuallyText}</span>
                    )}
                    {isManualAddress && (
                      <span className={cn(styles.optionButtonText, styles.optionButton)}>{enterAutomaticallyText}</span>
                    )}
                  </div>
                ) : (
                  <span className={styles.optionButtonText}>{enterAlternativeText}</span>
                ))}

              <Checkbox
                controllerName="isDifferentShippingAddress"
                labelText={differentShippingAddressLabelText}
                theme={Themes.White}
              >
                <div className={styles.column}>
                  <TextInput
                    controllerName="firstName"
                    labelText={firstNameLabelText}
                    disabled
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.firstName' }}
                  />
                  <TextInput
                    controllerName="lastName"
                    labelText={lastNameLabelText}
                    disabled
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.lastName' }}
                  />
                  <TextInput
                    controllerName="differentShippingAddress.phoneNumber"
                    labelText={phoneNumberOptionalLabelText}
                    placeholder={phoneNumberPlaceholder}
                    hintText={phoneNumberHintText}
                    dataAttribute={{ 'data-test-id': 'differentShippingAddress.phoneNumber' }}
                  />

                  <div className={styles.row}>
                    <TextInput
                      controllerName="differentShippingAddress.postalCode"
                      labelText={postalCodeLabelText}
                      placeholder={postalCodePlaceholder}
                      // mask="9999 aa" // temporary disabled - it's not working
                      onBlur={!isManualDifferentShippingAddress && checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.postalCode' }}
                    />
                    <TextInput
                      controllerName="differentShippingAddress.houseNumber"
                      labelText={houseNumberLabelText}
                      onBlur={!isManualDifferentShippingAddress && checkDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.houseNumber' }}
                    />
                    <TextInput
                      controllerName="differentShippingAddress.houseNumberAddon"
                      labelText={houseNumberAddonLabelText}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.houseNumberAddon' }}
                    />
                  </div>

                  <div className={styles.row}>
                    <TextInput
                      controllerName="differentShippingAddress.street"
                      labelText={streetLabelText}
                      disabled={!isManualDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.street' }}
                    />
                    <TextInput
                      controllerName="differentShippingAddress.city"
                      labelText={cityLabelText}
                      disabled={!isManualDifferentShippingAddress}
                      dataAttribute={{ 'data-test-id': 'differentShippingAddress.city' }}
                    />
                  </div>

                  <div className={styles.optionButtonContainer}>
                    {!['aws-prod'].includes(getRuntimeConfig('ENVIRONMENT_NAME')) ? (
                      <>
                        {!isManualDifferentShippingAddress && (
                          <span
                            className={cn(styles.optionButtonText, styles.optionButton)}
                            onClick={() => setIsManualDifferentShippingAddress(!isManualDifferentShippingAddress)}
                          >
                            {enterManuallyText}
                          </span>
                        )}
                        {isManualDifferentShippingAddress && (
                          <span
                            className={cn(styles.optionButtonText, styles.optionButton)}
                            onClick={() => setIsManualDifferentShippingAddress(!isManualDifferentShippingAddress)}
                          >
                            {enterAutomaticallyText}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className={styles.optionButtonText}>{enterAlternativeText}</span>
                    )}
                  </div>
                </div>
              </Checkbox>

              <Checkbox controllerName="isDeliveryInfo" labelText={deliveryInfoLabelText} theme={Themes.White}>
                <div className={styles.column}>
                  <TextInput
                    controllerName="deliveryInfo.note"
                    labelText={deliveryInfoNoteLabelText}
                    lengthCounter
                    maxLength={50}
                  />
                </div>
              </Checkbox>
            </div>
            {!user && (
              <>
                <div className={styles.column}>
                  <span className={styles.columnText}>{bluemovementAccountHeaderText}</span>
                  <span className={styles.columnDescriptionText}>{bluemovementAccountHeaderDescriptionText}</span>
                </div>

                <div className={styles.column}>
                  <CheckoutEmailInput
                    controllerName="email"
                    emailLabelText={emailLabelText}
                    emailIsExistErrorText={validationMessages.emailIsExist}
                  />
                  <TextInput
                    controllerName="password"
                    labelText={passwordLabelText}
                    hintText={passwordHintText}
                    password
                  />
                </div>
              </>
            )}
            <div className={styles.column}>
              <Checkbox
                controllerName="emailConsents"
                labelText={<RichText document={emailConsentsLabelRichText} />}
                theme={Themes.White}
              />

              {notificationBar
                ?.filter(nb => nb.variant !== 'error')
                ?.map(component => {
                  return (
                    <div className={styles.notificationBarWrapper} key={component._uid}>
                      <DynamicComponent {...component} layout="emptyLayout" />
                    </div>
                  );
                })}
              {isTestingEnvironment(getRuntimeConfig('ENVIRONMENT_NAME')) && (
                <>
                  <TestCreditCheck />
                  <TestIdCheck />
                </>
              )}

              <Button
                as="button"
                variant={ButtonVariant.Basic}
                type="submit"
                isFullWidth
                isLoading={methods.formState.isSubmitting || isRedirecting}
                disabled={
                  methods.formState.isSubmitting || methods.formState.isValidating || isEmailError || isRedirecting
                }
              >
                {submitButtonText}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
