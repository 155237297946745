import React from 'react';

import styles from './RefBlockSteps.module.scss';

export const RefBlockSteps = ({ steps }) => {
  return (
    <div className={styles.container}>
      {steps.map(({ stepTitle, stepDescription }, i) => (
        <div className={styles.step} key={stepTitle}>
          <div className={styles.incrementContainer}>
            <div className={styles.increment}>{i + 1}</div>
          </div>
          <div>
            <div className={styles.title}>{stepTitle}</div>
            <div>{stepDescription}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
