import React from 'react';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

import styles from './BlogpostTightContent.module.scss';

export const BlogpostTightContent = ({ items }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {items.map(blok => {
          return <DynamicComponent key={blok._uid} {...blok} />;
        })}
      </div>
    </div>
  );
};
