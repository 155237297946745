import { InternalLinkType, StoryblokMenuItem, StoryblokSubMenu } from '../types';
import styles from './DesktopMenu.module.scss';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import { MenuItem } from './MenuItem/MenuItem';

const SUB_MENU_COMPONENT_NAME = 'subMenu';

interface DesktopMenuProps {
  menuItems: StoryblokMenuItem[];
}

export const DesktopMenu = ({ menuItems }: DesktopMenuProps) => {
  const isSubMenu = (menuItem: StoryblokMenuItem): menuItem is StoryblokSubMenu => {
    return menuItem.component === SUB_MENU_COMPONENT_NAME;
  };

  return (
    <nav role="navigation" className={styles.navigation}>
      {menuItems.map(menuItem =>
        isSubMenu(menuItem) ? (
          <DropdownMenu key={menuItem._uid} title={menuItem.title} items={menuItem.items as InternalLinkType[]} />
        ) : (
          <MenuItem
            key={menuItem._uid}
            link={(menuItem as InternalLinkType).link}
            pageId={(menuItem as InternalLinkType).pageId}
            label={(menuItem as InternalLinkType).label}
          />
        )
      )}
    </nav>
  );
};
