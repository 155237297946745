/**
 * Regular expression pattern to validate email addresses with additional restrictions.
 *
 * This regex pattern allows the following:
 * - Alphanumeric characters (a-z, A-Z, 0-9) in the local part and domain name.
 * - Special characters: . + ! - _
 * - A dot (.) in the local part and domain name, as long as it is not the first or last character and not repeated consecutively.
 * - An exclamation mark (!), plus sign (+), dash (-), or underscore (_) in the local part.
 * - An @ symbol between the local part and domain name.
 * - A top-level domain (TLD) consisting of two or more alphabetic characters.
 * - Additional restrictions apply to the username (local part):
 *    - Cannot start with a hyphen (-).
 *    - Cannot consist only of digits, underscores, or dots.
 *
 * Examples of valid email addresses:
 * - john.doe@domain.com
 * - jane_smith123+test@domain.co.uk
 * - alice!example123@domain.com
 * - user@domain-domain.com
 * - john-doe@domain.com
 * - jane_doe@domain.com
 *
 * Examples of invalid email addresses:
 * - invalid-email@-domain.com (Domain starts with a hyphen)
 * - 1234567890@domain.com (Username consists only of digits)
 * - invalid..email@domain.com (Consecutive dots in the local part)
 * - test@domain. (Domain ends with a dot)
 * - "@domain.com" (Missing local part)
 * - user@domain,com (Comma instead of dot in the domain)
 * - john.doe@exam_ple.com (Underscore in the domain)
 *
 * @constant {RegExp} emailFilter - The regular expression pattern for validating email addresses.
 */
export const emailFilter = /^[a-zA-Z0-9]+(?:[._+!\\-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:-?[a-zA-Z0-9]+)*(?:\.[a-zA-Z0-9]+(?:-?[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,}$/;
