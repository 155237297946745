import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';
import { OfferContractType } from 'types/offer.types';

enum ContractTypePhrasesSlug {
  rental = 'renting',
  financing = 'financial',
}

const contractTypeMap = {
  [OfferContractType.Rental]: [ContractTypePhrasesSlug.rental],
  [OfferContractType.Financing]: [ContractTypePhrasesSlug.financing],
  [OfferContractType.RentalAndFinancing]: [ContractTypePhrasesSlug.rental, ContractTypePhrasesSlug.financing],
};

const ContractTypeOrder = {
  [ContractTypePhrasesSlug.financing]: 1,
  [ContractTypePhrasesSlug.rental]: 2,
};

const filteringOptions: FilteringOptions = (offersData, { contractTypePhrases }) => {
  return offersData
    .reduce((accumulatedContractTypes, currentOffer) => {
      const { contractType, financial } = currentOffer.offerData;

      const mappedContractTypes = contractTypeMap[contractType] || [];

      mappedContractTypes.forEach(mappedContractType => {
        if (
          !accumulatedContractTypes.includes(mappedContractType) &&
          ((mappedContractType === ContractTypePhrasesSlug.financing && financial) ||
            mappedContractType === ContractTypePhrasesSlug.rental)
        ) {
          const index = accumulatedContractTypes.findIndex(
            accumulatedContractType =>
              ContractTypeOrder[accumulatedContractType] > ContractTypeOrder[mappedContractType]
          );
          if (index === -1) {
            accumulatedContractTypes.push(mappedContractType);
          } else {
            accumulatedContractTypes.splice(index, 0, mappedContractType);
          }
        }
      });

      return accumulatedContractTypes;
    }, [])
    .map(contractType => contractTypePhrases[contractType]);
};

const filterCondition: ValidationFunction = (selectedFilters, offer, translations) =>
  selectedFilters.some(contractType => {
    const { contractType: offerContractType, financial } = offer.offerData;

    const mappedContractTypes = contractTypeMap[offerContractType] || [];

    return mappedContractTypes
      .map(mappedContractType =>
        mappedContractType === ContractTypePhrasesSlug.financing && !financial ? undefined : mappedContractType
      )
      .some(mappedContractType => translations.contractTypePhrases[mappedContractType] === contractType);
  });

export const contractTypeFilter = filterBuilder(filteringOptions, filterCondition);
