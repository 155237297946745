import * as util from 'util';

import * as Yup from 'yup';

import { emailFilter } from 'validation/regexps/email';
import { nameRegExp } from 'validation/regexps/name';

export function getValidationSchema({
  messages: {
    required = 'required',
    noSlash = 'noSlash',
    maxLength = 'maxLength',
    email = 'email',
    onlyLettersSpaceApostrophesHyphensAllowed = 'onlyLettersSpaceApostrophesHyphensAllowed',
  } = {},
}) {
  return {
    newUserFirstName: Yup.string()
      .max(40, util.format(maxLength, 40))
      .required(required)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('no_slash', noSlash, val => val && !val.includes('/')),
    newUserLastName: Yup.string()
      .max(80, util.format(maxLength, 80))
      .required(required)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('no_slash', noSlash, val => val && !val.includes('/')),
    newUserEmail: Yup.string()
      .max(70, util.format(maxLength, 70))
      .email(email)
      .required(required)
      .matches(emailFilter, email),
  };
}

export function getDefaultValues() {
  return { newUserFirstName: '', newUserLastName: '', newUserEmail: '' };
}
