import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useNotifications } from '@hooks/context/useNotifications';

let leaveConfirmed = false;

export const useLeavePrevention = ({ isUnsafeClose, isDisabled }) => {
  const router = useRouter();
  const notifications = useNotifications();
  const confirmationMessage = notifications['confirmation-message']?.value;

  useEffect(() => {
    leaveConfirmed = isUnsafeClose;
  }, [isUnsafeClose]);

  useEffect(() => {
    if (!isDisabled) {
      const handleWindowClose = event => {
        if (isUnsafeClose) {
          event.returnValue = confirmationMessage;

          return confirmationMessage;
        }
      };

      const onRouteChangeStart = () => {
        if (!leaveConfirmed) {
          return undefined;
        }

        // eslint-disable-next-line no-alert
        if (window.confirm(confirmationMessage)) {
          leaveConfirmed = true;
        } else {
          router.events.emit('routeChangeError');
          // eslint-disable-next-line no-throw-literal
          throw 'routeChange aborted by user';
        }
      };

      window.addEventListener('beforeunload', handleWindowClose);
      router.events.on('routeChangeStart', onRouteChangeStart);

      return () => {
        window.removeEventListener('beforeunload', handleWindowClose);
        router.events.off('routeChangeStart', onRouteChangeStart);
      };
    }
  }, [leaveConfirmed, isDisabled, isUnsafeClose]);
};
