import React from 'react';

import { useFormContext } from 'react-hook-form';

import styles from './ContactQuestionTypes.module.scss';

import { Radio } from '@components/inputs/Radio/Radio';

const radioControllerName = 'topic';

export const ContactQuestionTypes = ({ title, questions }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.questionsWrapper}>
        {questions.map((blok, index) => (
          <div key={index} className={styles.question}>
            {/* eslint-disable-next-line no-underscore-dangle */}
            <Radio key={blok._uid} {...blok} controllerName={radioControllerName} />
          </div>
        ))}
      </div>
      {errors && errors[radioControllerName] ? (
        <span className={styles.errorMessage}>({errors[radioControllerName].message})</span>
      ) : null}
    </div>
  );
};
