import React from 'react';

import { HeadingGroup } from '@components/HeadingGroup/HeadingGroup';
import { useUserLastOrder } from '@hooks/user/useUserLastOrder';

import styles from './CheckoutBlockedOrderInfo.module.scss';

export const CheckoutBlockedOrderInfo = ({ title, text }) => {
  const { data: lastOrder } = useUserLastOrder();

  if (!lastOrder) {
    return <></>;
  }

  const isOrderBlocked = lastOrder?.paymentAttempts?.some(pa => pa.orderBlocked);

  if (!isOrderBlocked) {
    return <></>;
  }

  return (
    <div className={styles.topMargin} data-test-id="checkoutBlockedOrderInfo">
      <HeadingGroup label={title} text={text} alignLeft variant="myBluemovement" />
    </div>
  );
};
