import React from 'react';

import { useContentContext } from '@context/content';

import { RichText } from '../RichText/RichText';

export const GeneralInfo = content => {
  const { type } = useContentContext();

  return <RichText document={content[type]} />;
};
