/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';

import LogoLoader from '@components/LogoLoader/LogoLoader';
import { useUserSubscriptions } from '@hooks/subscriptions/useUserSubscriptions';

import styles from './SubscriptionList.module.scss';
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';

export const SubscriptionList = () => {
  // TODO: To improvement when subscriptions page will be ready, missed sorting etc.
  const { data: subscriptions, isLoading: isSubscriptionsLoading, refetch } = useUserSubscriptions();

  useEffect(() => {
    refetch();
  }, []);

  if (isSubscriptionsLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LogoLoader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {subscriptions
        ?.filter(subscription => !subscription.oldOneFromTheSwitchProcess)
        .map(subscription => (
          <SubscriptionCard key={`user-subscription-${subscription?.id}`} subscription={subscription} />
        ))}
    </div>
  );
};
