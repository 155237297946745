import React from 'react';

import { useCart } from '@hooks/cart/useCart';
import { useCondition } from '@hooks/useCondition';
import { useDeliveryTime } from '@hooks/useDeliveryTime';
import { useLocale } from '@hooks/useLocale';
import { CheckoutOrder } from '@modules/Checkout/components/CheckoutOrder/CheckoutOrder';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './CheckoutOrders.module.scss';

export const CheckoutOrders = ({ editText }) => {
  const { locale } = useLocale();
  const { data: cart } = useCart();
  const { getDeliveryTime } = useDeliveryTime();
  const { getCondition } = useCondition();

  return cart?.items?.map(cartItem => {
    const condition = getCondition(cartItem);
    const deliveryTime = getDeliveryTime(cartItem);

    return (
      <div className={styles.container} key={`checkoutOrder-${cartItem?.id}`}>
        <CheckoutOrder
          orderCardImage={cartItem?.content?.fullImage}
          applianceName={cartItem?.content?.name || cartItem?.plan?.bmId}
          duration={cartItem?.pricing?.term}
          price={cartItem?.pricing?.amount}
          condition={condition}
          deliveryTime={deliveryTime}
          editText={editText}
          editTextOnClick={() => {
            window.location.href = `${buildPath({ pageId: Paths.ProductsOverview, locale })}?openCart=true`;
          }}
        />
      </div>
    );
  });
};
