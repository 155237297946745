/* eslint-disable import/no-cycle */

import React, { useState, useRef } from 'react';

import { Link } from '@components/Link/Link';
import { ScrollNavigation } from '@components/ScrollNavigation/ScrollNavigation';
import { SwipeWrapper } from '@components/SwipeWrapper/SwipeWrapper';
import { useMedia } from '@hooks/useMedia';

import styles from './Carousel.module.scss';
import DynamicComponent from '../DynamicComponent/DynamicComponent';
import cn from 'classnames';

export const Carousel = ({ items, showScroll = true }) => {
  const imageRef = useRef();
  const { isMobile } = useMedia();
  const startIndex = 1;
  const [currentImageIndex, setCurrentImageIndex] = useState(startIndex);
  const maxImageIndex = isMobile ? items.length + 1 : items.length - 3;

  const nextImage = () =>
    currentImageIndex < maxImageIndex
      ? setCurrentImageIndex(currentImageIndex + (isMobile ? 1 : 3))
      : setCurrentImageIndex(startIndex);

  const previousImage = () =>
    currentImageIndex > startIndex
      ? setCurrentImageIndex(currentImageIndex - (isMobile ? 1 : 3))
      : setCurrentImageIndex(maxImageIndex);

  return (
    <div className={styles.container}>
      <ScrollNavigation
        currentImageIndex={currentImageIndex}
        imageRef={imageRef}
        className={styles.scrollNavigation}
        forceVisibility
        hideScroll={!showScroll}
        isMobile={isMobile}
      >
        {items.map((blok, imageIndex) => (
          <div className={styles.itemContainer} key={imageIndex} ref={imageRef}>
            <SwipeWrapper onSwipeLeft={nextImage} onSwipeRight={previousImage} style={{ flexGrow: 1, display: 'flex' }}>
              <div className={styles.extend} onClick={() => setCurrentImageIndex(imageIndex)}>
                <Link
                  {...blok.personalMessage?.[0]}
                  className={cn(styles.extend, { [styles.cursorPointer]: blok.personalMessage?.[0] })}
                >
                  <DynamicComponent {...blok} style={{ display: 'flex' }} />
                </Link>
              </div>
            </SwipeWrapper>
          </div>
        ))}
      </ScrollNavigation>
    </div>
  );
};
