import React from 'react';

import styles from './CheckoutTextWithLink.module.scss';

export const CheckoutTextWithLink = ({ text, linkedText, onClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <span className={styles.text}>{text}</span>

        <div
          className={styles.linkedText}
          onClick={() => {
            window.location.href = onClick.url;
          }}
        >
          <span className={styles.text}>{linkedText}</span>
        </div>
      </div>
    </div>
  );
};
