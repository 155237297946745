export enum CreditCheckFailingReason {
  ProviderFail = 'PROVIDER_FAIL',
  WrongPersonalData = 'WRONG_PERSONAL_DATA',
}

export enum RiskValidatorStatus {
  RequireIdCheck = 'REQUIRE_ID_CHECK',
  AllowedToContinue = 'ALLOWED_TO_CONTINUE',
  DeniedToContinue = 'DENIED_TO_CONTINUE',
}

export enum RiskValidatorDecisionStep {
  IdCheck = 'ID_CHECK',
  CreditCheck = 'CREDIT_CHECK',
}
export interface RequiredIdCheckDecision {
  status: RiskValidatorStatus.RequireIdCheck;
  token: string;
  workflowRunId: string;
}

export interface AllowedToContinueDecision {
  status: RiskValidatorStatus.AllowedToContinue;
}
export interface DeniedToContinueDecision {
  status: RiskValidatorStatus.DeniedToContinue;
  details: {
    step: RiskValidatorDecisionStep;
    reason: CreditCheckFailingReason;
    fields?: string[];
  };
}

export type RiskValidatorDecision = RequiredIdCheckDecision | AllowedToContinueDecision | DeniedToContinueDecision;

export enum IdCheckResultStatus {
  Error = 'error',
  Approved = 'approved',
  Review = 'review',
  Declined = 'declined',
  Omit = 'omit',
  Abandoned = 'abandoned',
}

export interface IdCheckResult {
  status: IdCheckResultStatus;
  reasons: string[];
  error: any;
}
