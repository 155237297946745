import { useEffect } from 'react';

import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Pricing } from 'types/plan.types';

import { Link } from '@components/Link/Link';
import { usePhrases } from '@hooks/context/usePhrases';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { convert2slug } from '@utils/convert2slug';

import styles from './ContractSwitchDuration.module.scss';
import { useContractSwitchContext } from '../../page/ContractSwitchContext/ContractSwitchContext';

interface ContractSwitchDurationProps {
  availablePricings: Pricing[];
  perMonthText: string;
  headerText?: string;
  durationsLink: any; // Link component frontend/components/Link/Link.jsx
  durationText: string;
  readMoreText: string;
}

export const ContractSwitchDuration = ({
  durationsLink,
  perMonthText = 'per month',
  headerText = 'Select duration',
  readMoreText = 'Read more',
  durationText = 'Duration',
}: ContractSwitchDurationProps) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const termsPhrases = usePhrases({ name: 'terms' });
  const { availableOptions } = useContractSwitchContext();
  const { control, setValue, watch } = useFormContext();
  const selectedPricingId = watch('pricingId');

  useEffect(() => {
    if (!availableOptions) {
      return;
    }

    if (!selectedPricingId) {
      setValue('pricingId', availableOptions[0]?.pricing.id);

      return;
    }
  }, [availableOptions]);

  return (
    <div className={styles.durationsContainer}>
      <div className={styles.durationsHeader}>
        <span className={styles.durationHeaderText}>{headerText}</span>

        <Link
          {...durationsLink?.[0]}
          className={styles.link}
          dataAttributes={{ 'data-analytics-read-more': durationText, 'data-analytics-modal-content': 'durations' }}
        >
          {readMoreText}
        </Link>
      </div>
      <div className={styles.durationsOptionsContainer}>
        {availableOptions?.map(option => {
          const isPriceDiscounted =
            option.newConditions?.monthlyAmount && option.newConditions?.monthlyAmount < option.pricing.amount;

          return (
            <Controller
              name="pricingId"
              key={option.pricing.id}
              control={control}
              render={() => (
                <button
                  type="button"
                  className={cn(styles.durationsOption, {
                    [styles.durationsOptionSelected]: option.pricing.id === selectedPricingId,
                  })}
                  key={option.pricing.id}
                  data-pdp-duration-selector={option.pricing.term}
                  onClick={() => setValue('pricingId', option.pricing.id)}
                >
                  <div
                    className={cn(styles.checkboxCircle, {
                      [styles.checkboxCircleSelected]: option.pricing.id === selectedPricingId,
                    })}
                  />
                  <span className={styles.durationsOptionLabel}>
                    {termsPhrases[convert2slug(option.pricing.term)] || option.pricing.term}
                  </span>

                  <div className={styles.priceContainer}>
                    <span className={cn(styles.durationsOptionValue, { [styles.lineThroughPrice]: isPriceDiscounted })}>
                      {currencyFormatter({ price: option.pricing.amount })} {perMonthText}
                    </span>
                    {isPriceDiscounted && (
                      <span className={cn(styles.durationsOptionValue, styles.discountedPrice)}>
                        {currencyFormatter({ price: option.newConditions.monthlyAmount })} {perMonthText}
                      </span>
                    )}
                  </div>
                </button>
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
