import { useQuery } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

const FEATURE_FLAG_QUERY_ID = 'FEATURE_FLAG_QUERY_ID';

export const useFeatureFlag = name => {
  const httpService = useHttpService();

  return useQuery(
    [FEATURE_FLAG_QUERY_ID, name],
    async () => {
      const { data: flag } = await httpService.get(`/feature-flag/${name}`);

      return flag;
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      retry: 0,
    }
  );
};
