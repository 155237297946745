import React, { ReactNode } from 'react';

import styles from './SpacingsTemplate.module.scss';
import cn from 'classnames';

const backgroundColor = {
  transparent: 'bgTransparent',
  white: 'bgWhite',
};

export const SpacingsTemplate = ({
  children,
  spacingVariant,
  marginVaraint = 'm0',
  bgVariant = 'transparent',
  isShadow = false,
  isPadding = false,
}: {
  children: ReactNode;
  spacingVariant: 's8' | 's16';
  marginVaraint: 'm0' | 'm8' | 'm16';
  bgVariant: 'transparent' | 'white';
  isShadow?: boolean;
  isPadding?: boolean;
}) => {
  return (
    <div
      className={cn(
        styles.container,
        styles[spacingVariant],
        styles[marginVaraint],
        styles[backgroundColor[bgVariant]],
        {
          [styles.shadow]: isShadow,
          [styles.p16]: isPadding,
          [styles.p24med]: isPadding,
        }
      )}
    >
      {children}
    </div>
  );
};
