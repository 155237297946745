import { usePhrases } from '@hooks/context/usePhrases';

const extractMessages = storyBlokPhrases =>
  storyBlokPhrases
    ? Object.entries(storyBlokPhrases).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.value,
        }),
        {}
      )
    : {};

export const useCouponMessages = () => {
  const discountMessages = usePhrases({ name: 'coupon/discountMessages/', rawObject: true });
  const errorMessages = usePhrases({ name: 'coupon/errorMessages/', rawObject: true });
  const extractedDiscountMessages = extractMessages(discountMessages);
  const extractedErrorMessages = extractMessages(errorMessages);

  return {
    couponDiscountMessages: extractedDiscountMessages,
    couponErrorMessages: extractedErrorMessages,
  };
};
