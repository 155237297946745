import React from 'react';

import styles from './LabelWithInfoButton.module.scss';

export const LabelWithInfoButton = ({ label, onInfoClick }) => {
  const handleInfoClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onInfoClick();
  };

  return onInfoClick ? (
    <span className={styles.infoWrapper}>
      {label} -{' '}
      <span className={styles.info} onClick={handleInfoClick}>
        info
      </span>
    </span>
  ) : (
    <span className={styles.infoWrapper}>{label}</span>
  );
};
