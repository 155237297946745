import React from 'react';

import { NotificationBar } from '@components/NotificationBar/NotificationBar';
import { useContentContext } from '@context/content';

import styles from './CheckoutCheckErrors.module.scss';

export const CheckoutCheckErrors = ({
  importantInfoText,
  importantInfoTitle,
  errorCreditCheckTitle,
  errorIdCheckTitle,
  CreditCheckErrorLabelText,
  IdCheckErrorLabelText,
}: {
  importantInfoText: string;
  importantInfoTitle: string;
  errorCreditCheckTitle: string;
  errorIdCheckTitle: string;
  CreditCheckErrorLabelText?: {
    firstname: string;
    lastname: string;
    emailaddress: string;
    initials: string;
    housenumber: string;
  };
  IdCheckErrorLabelText?: {
    gender: string;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
  };
}) => {
  const { creditCheckErrors, idCheckErrors } = useContentContext();

  return (
    <>
      {importantInfoText && importantInfoTitle && (
        <div className={styles.column}>
          {/* @ts-ignore */}
          <NotificationBar
            title={importantInfoTitle}
            content={importantInfoText}
            variant={creditCheckErrors || idCheckErrors ? 'error' : 'alice'}
          />
        </div>
      )}
      {creditCheckErrors && (
        <div className={styles.column}>
          {/* @ts-ignore */}
          <NotificationBar title={errorCreditCheckTitle} variant="error">
            <ul>
              {creditCheckErrors.fields.map((field: string) => (
                <li key={field}>{CreditCheckErrorLabelText?.[field.trim()] || field}</li>
              ))}
            </ul>
          </NotificationBar>
        </div>
      )}
      {idCheckErrors && (
        <div className={styles.column}>
          {/* @ts-ignore */}
          <NotificationBar title={errorIdCheckTitle} variant="error">
            <ul>
              {idCheckErrors.fields.map((field: string) => (
                <li key={field}>{IdCheckErrorLabelText?.[field.trim()] || field}</li>
              ))}
            </ul>
          </NotificationBar>
        </div>
      )}
    </>
  );
};
