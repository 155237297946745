import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePaymentAttemptMutation } from './usePaymentAttemptMutation';
import { useCheckoutCreatePaymentOrderMutation } from '@hooks/checkout/useCheckoutCreatePaymentOrderMutation';
import { usePaymentMutation } from './usePaymentMutation';
import { AdyenContext } from '@context/adyen';
import { useLogger } from '@hooks/useLogger';
import {
  getPaymentMethodByComponent,
  remountComponent,
} from '@modules/Checkout/components/CheckoutPaymentInputs/CheckoutPaymentInputs';
import { paymentMethodsAdyenToInternalTypesMap } from '@helpers/payment';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { toast } from 'react-toastify';
import { usePaymentDetailsMutation } from '@hooks/payment/usePaymentDetailsMutation';
import { useLocale } from '@hooks/useLocale';
import { Cart } from 'types/cart.types';
import { User } from 'types/user.types';
import { sendSelectedPaymentEvent } from '@services/gtm/page-initialization';
import { usePhrases } from '@hooks/context/usePhrases';

interface PaymentConfigurationProps {
  cart: Cart;
  user: User;
  customAmountValue?: number;
  customOnSubmit?: (state: any, component: any, actions: any) => Promise<void>;
  customOnPaymentCompleted?: (result: any, component: any) => void;
  customOnPaymentFailed?: (result: any, component: any) => void;
  customOnError?: (error: any, component: any) => void;
}

const usePaymentConfiguration = ({
  cart,
  user,
  customAmountValue,
  customOnSubmit,
  customOnPaymentCompleted,
  customOnPaymentFailed,
  customOnError,
}: PaymentConfigurationProps) => {
  const { logger } = useLogger({ context: 'usePaymentConfiguration' });

  const [validationError, setValidationError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const redirectUrlRef = useRef('');

  const adyenContext = useContext(AdyenContext);

  const paymentMutation = usePaymentMutation();
  const createPaymentOrderMutation = useCheckoutCreatePaymentOrderMutation();
  const paymentAttemptMutation = usePaymentAttemptMutation();
  const paymentDetailsMutation = usePaymentDetailsMutation();
  const phrases = usePhrases({ name: 'notifications' });

  const { country: localeCountry, locale } = useLocale();

  const APP_URL = getRuntimeConfig('APP_URL');

  const country = cart?.country || user?.country || localeCountry;
  const countryCode = country?.toUpperCase();
  const adyenLocale = `${country?.toLowerCase()}-${countryCode}`;
  const statusPagePath = buildPath({
    pageId: Paths.CheckoutPaymentStatus,
    locale: adyenLocale.toLowerCase(),
  });
  const amountValue = customAmountValue || cart?.totalDepositPrice;

  const showErrorToast = (message?: string) => {
    toast(message || 'Something went wrong. Please try again.', {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const configuration = useMemo(
    () => ({
      clientKey: adyenContext.clientKey,
      environment: adyenContext.environment,
      amount: {
        value: amountValue,
        currency: 'EUR',
      },
      locale,
      countryCode,
      paymentMethodsResponse: adyenContext.paymentMethods,
      showPayButton: false,
      onSubmit:
        customOnSubmit ||
        (async (state, component, actions) => {
          setSubmitting(true);

          try {
            logger.info({ message: 'Submitting payment form', params: { userId: user?.id, cartId: cart?.id } });

            const paymentMethodByComponent = getPaymentMethodByComponent(component);
            const newPaymentAttempt = await paymentAttemptMutation.mutateAsync({
              depositPaymentMethod: paymentMethodsAdyenToInternalTypesMap[paymentMethodByComponent], // for klarna and ideal use action?.paymentMethodType
              redirectPath: statusPagePath,
              depositAmount: amountValue,
              userId: user?.id,
              paymentType: 'checkout',
              cartId: cart.id,
            });

            const paymentAttemptUuid = newPaymentAttempt.id;

            logger.info({
              message: 'Payment attempt is created',
              params: { userId: user?.id, cartId: cart?.id, paymentAttemptId: newPaymentAttempt?.id },
            });

            const redirectUrl = `${APP_URL}/redirect?url=${encodeURIComponent(
              `${APP_URL}${statusPagePath
                .replace(':paymentBMId', paymentAttemptUuid)
                .replace(':transactionId', paymentAttemptUuid)}`
            )}`;

            const paymentMutationResult = await paymentMutation.mutateAsync({
              userId: user?.id,
              paymentMethod: state.data.paymentMethod,
              riskData: state.data.riskData,
              amountValue,
              redirectPath: redirectUrl,
              paymentAttemptUuid,
              browserInfo: state.data.browserInfo,
              paymentType: 'checkout',
              cartId: cart.id,
            });

            const { resultCode, action, order, donationToken } = paymentMutationResult;

            if (resultCode === 'Refused' || !resultCode) {
              await sendSelectedPaymentEvent({
                oneTimeFee: state.data.paymentMethod,
                monthlyFee: state.data.paymentMethod,
                status: 'failed',
              });

              // TODO Adyen: refusalReasonCode -> translation should be mapped https://docs.adyen.com/development-resources/refusal-reasons/
              // "As a rule we advise merchants to be careful with exposing refusal reasons, as malicious shoppers can use it for card testing or other acts. In general, we advice merchants to only show generic refusal reasons."
              // Waiting for Barbara's decision what to show here.
              // Probably the best way is just "Try again" without showing any reasons
              actions.reject();

              return;
            }

            logger.info({
              message: 'Creating payment order',
              params: { userId: user?.id, cartId: cart?.id, paymentAttemptId: newPaymentAttempt?.id },
            });

            const createPaymentOrder = await createPaymentOrderMutation.mutateAsync({
              paymentMandateUuid: paymentAttemptUuid,
              accountOwnerName: user?.firstName,
              recurringPaymentMandate: true,
              agreeTermsAndConditions: true,
              redirectPath: statusPagePath,
              userId: user?.id,
              cartId: cart?.id,
              paymentType: 'checkout',
              depositPaymentMethod:
                paymentMethodsAdyenToInternalTypesMap[
                  paymentMutationResult?.paymentMethod?.type || paymentMutationResult?.action?.paymentMethodType
                ],
              depositAmountInCents: amountValue,
              paymentGatewayId: paymentMutationResult.pspReference,
            });

            if (createPaymentOrder) {
              logger.info({
                message: 'Payment order is created',
                params: {
                  ...createPaymentOrder,
                  userId: user?.id,
                  cartId: cart?.id,
                  paymentAttemptId: newPaymentAttempt?.id,
                },
              });

              await sendSelectedPaymentEvent({
                oneTimeFee: state.data.paymentMethod,
                monthlyFee: state.data.paymentMethod,
                status: 'success',
              });

              logger.info({
                message: 'Action object is ready',
                params: {
                  action: {
                    actionType: action?.type,
                    subtype: action?.subtype,
                    url: action?.url,
                  },
                  userId: user?.id,
                  cartId: cart.id,
                  paymentAttemptId: newPaymentAttempt?.id,
                },
              });

              redirectUrlRef.current = createPaymentOrder.redirectUrl;

              actions.resolve({
                resultCode,
                action,
                order,
                donationToken,
              });

              if (action?.url) {
                logger.info({
                  message: 'Redirecting to provider page',
                  params: { userId: user.id, cartId: cart.id, paymentAttemptId: newPaymentAttempt?.id },
                });

                window.location.href = action.url;
              } else {
                logger.info({
                  message: 'Triggering another action',
                  params: {
                    userId: user.id,
                    cartId: cart.id,
                    paymentAttemptId: newPaymentAttempt?.id,
                    redirectUrl: createPaymentOrder.redirectUrl,
                    redirectUrlRef: redirectUrlRef.current,
                  },
                });
              }
            }
          } catch (error) {
            logger.error({
              message: 'Error while submitting payment form',
              params: {
                userId: user.id,
                cartId: cart.id,
                error,
                errorMessage: error.message,
              },
            });

            actions.reject();
          }
        }),
      onEnterKeyPressed: async (activeEl, component) => {
        // TODO: check if this is needed
        // const isValid = await trigger(['recurringPaymentMandate', 'agreeTermsAndConditions'], { shouldFocus: true });

        // if (isValid) {
        //   await handleSubmit(async () => {
        //     try {
        //       await (adyenContext?.attachedPaymentMethod || component).submit();
        //     } catch (error) {
        //       logger.error({
        //         message: 'Error while submitting payment form',
        //         params: { userId: user.id, cartId: cart.id, error, errorMessage: error.message },
        //       });

        //       showErrorToast(error?.message);
        //       remountComponent(component);
        //       setSubmitting(false);
        //       setValidationError(true);
        //     }
        //   })();
        // }
        return;
      },
      onAdditionalDetails: async (state, component, actions) => {
        try {
          logger.info({
            message: 'onAdditionalDetails is triggered',
            params: { userId: user?.id, cartId: cart?.id },
          });

          const response = await paymentDetailsMutation.mutateAsync({
            ...state.data,
            userId: user?.id,
            cartId: cart?.id,
          });

          actions.resolve({ resultCode: response.resultCode });
        } catch (error) {
          logger.error({
            message: 'Error while submitting payment form',
            params: {
              userId: user?.id,
              cartId: cart?.id,
              error,
              errorMessage: error.message,
            },
          });

          actions.reject();
        }
      },
      onPaymentCompleted:
        customOnPaymentCompleted ||
        ((result, component) => {
          logger.info({
            message: 'onPaymentCompleted is triggered',
            params: { result, userId: user?.id, redirectUrlRef: redirectUrlRef.current },
          });

          setValidationError(false);

          if (redirectUrlRef.current) {
            window.location.href = redirectUrlRef.current;
          }
        }),
      onPaymentFailed:
        customOnPaymentFailed ||
        ((result, component) => {
          logger.warn({
            message: 'No result code or payment is Refused',
            params: { userId: user?.id, cartId: cart?.id, result, redirectUrlRef: redirectUrlRef.current },
          });

          if (redirectUrlRef.current) {
            window.location.href = redirectUrlRef.current;
          } else {
            const message = result?.errorMessage?.includes('Card already present')
              ? phrases?.['exact-payment-method-exists']
              : phrases?.['payment-method-not-valid'];
            showErrorToast(message);
            remountComponent(component);
            setSubmitting(false);
            setValidationError(true);
          }
        }),
      onError:
        customOnError ||
        ((error, component) => {
          logger.error({
            message: 'Error while submitting payment form',
            params: { userId: user?.id, cartId: cart?.id, error, errorMessage: error.message },
          });

          showErrorToast(error?.message);
          remountComponent(component);
          setSubmitting(false);
          setValidationError(true);
        }),
    }),
    [user, logger, isSubmitting, redirectUrlRef]
  );

  return { configuration, isSubmitting, setSubmitting, validationError, setValidationError, redirectUrlRef };
};

export default usePaymentConfiguration;
