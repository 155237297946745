import React, { useCallback } from 'react';

import { useLogger } from '@hooks/useLogger';

import { debounce } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useUserEmailLookupMutation } from '@hooks/checkout/useUserEmailLookupMutation';


export const CheckoutEmailInput = ({ controllerName, emailLabelText, emailIsExistErrorText, value, disabled }) => {
  const { logger } = useLogger({ context: 'CheckoutEmailInput' });
  const { getValues, setError, clearErrors, getFieldState } = useFormContext();
  const useUserLookupMutation = useUserEmailLookupMutation();

  const checkEmailDebounce = useCallback(
    debounce(async () => {
      const email = getValues(controllerName);

      if (email) {
        try {
          useUserLookupMutation.mutate(
            { email },
            {
              onSuccess: result => {
                if (result) {
                  setError(controllerName, { type: 'custom', message: emailIsExistErrorText }, { shouldFocus: true });

                  return;
                }
                const fieldError = getFieldState(controllerName).error || {};
                if (fieldError.message === emailIsExistErrorText) {
                  clearErrors(controllerName);
                }
              },
            }
          );
        } catch (error) {
          logger.error({ message: 'Error in CheckoutEmailInput', params: error });
        }
      }
    }, 100)
  );

  const checkEmail = () => {
    checkEmailDebounce();
  };

  return (
    <TextInput
      controllerName={controllerName}
      labelText={emailLabelText}
      customValidationCheckOnMount={checkEmail}
      onBlur={checkEmail}
      value={value}
      disabled={disabled}
    />
  );
};
