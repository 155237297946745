/* eslint-disable import/no-cycle */
import React from 'react';

import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';

import styles from './TakeoverSubscriptions.module.scss';

import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import Loader from '@components/Loader/Loader';
import { SubscriptionCard } from '@modules/UserPanel/Subscriptions/components/SubscriptionCard/SubscriptionCard';

export const TakeoverSubscriptions = ({ selectForText, subscriptions, calendarIcon }) => {
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  if (!subscriptions) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  const values = getValues();
  const filteredSubscriptions = subscriptions.filter(subscription => subscription.status === 'active');

  const error = errors?.items;

  return (
    <div className={styles.container}>
      <Controller
        name="TakeoverSubscriptions"
        control={control}
        render={() => {
          return (
            <div>
              {filteredSubscriptions.map((subscription, index) => (
                <div
                  key={subscription.id}
                  className={cn(styles.subscriptionCardContainer, {
                    [styles.selected]: values.items?.[index]?.subscriptionToTakeover,
                  })}
                >
                  <SubscriptionCard subscription={subscription} noViewButton calendarIcon={calendarIcon}>
                    <div className={styles.separator} />

                    <Checkbox
                      labelText={selectForText}
                      controllerName={`items.${index}.subscriptionToTakeover`}
                      theme={Themes.White}
                      onClick={() => {
                        trigger('items');
                      }}
                    />
                  </SubscriptionCard>
                </div>
              ))}
              {error && (
                <span className={styles.errorMessage}>
                  <RectangleIcon className={styles.rectangleIcon} />
                  {error.message}
                </span>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};
