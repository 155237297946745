import React, { createContext, useContext, useState } from 'react';

export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [showItemAdded, setShowItemAdded] = useState(false);
  const [isShareButtonTriggered, setIsShareButtonTriggered] = useState(false);

  const toggleCart = () => setIsCartOpen(prevState => !prevState);
  const openCart = () => setIsCartOpen(true);

  return (
    <CartContext.Provider
      value={{
        isCartOpen,
        setIsCartOpen,
        toggleCart,
        showItemAdded,
        setShowItemAdded,
        openCart,
        isShareButtonTriggered,
        setIsShareButtonTriggered,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  return useContext(CartContext);
};
