import { useEffect, useState } from 'react';

import { sum } from 'lodash';
import { OfferItemPricing, OfferItemOneTimePricing, OfferItemRecurringPricing } from 'types/offer.types';

import { useLogger } from '@hooks/useLogger';

import { convertOfferItemsToDictionary } from './convertOfferItemsPricingsToDictionary';
import { getSelectedPricing } from './getSelectedAvailableCondition';

export const usePricings = ({ pricings, mandatoryAdditionalItems, optionalAdditionalItems, offerSlug }) => {
  const { logger } = useLogger({ context: 'usePricings' });

  const [selectedMainProductPricing, setSelectedMainProductPricing] = useState(getSelectedPricing(pricings));
  const [selectedAdditionalServicesPricings, setSelectedAdditionalServicesPricings] = useState<
    Record<string, OfferItemPricing>
  >(convertOfferItemsToDictionary(optionalAdditionalItems));

  const mandatoryAdditionalServicesPricings = mandatoryAdditionalItems.reduce((pricings, mandatoryItem) => {
    const mandatoryItemPricing = mandatoryItem.pricings.find(
      pricing => pricing.months === selectedMainProductPricing.months
    );

    return Object.assign(pricings, { [mandatoryItem.id]: mandatoryItemPricing });
  }, {});
  const selectedAndMandatoryAdditionServicesPricings = Object.assign(
    selectedAdditionalServicesPricings,
    mandatoryAdditionalServicesPricings
  );

  const additionalServicesOneTimeSum = sum(
    Object.values(selectedAndMandatoryAdditionServicesPricings)
      .filter((pricing: OfferItemPricing) => pricing?.billingType === 'one_time')
      .map((pricing: OfferItemOneTimePricing) => pricing.oneTimeAmount)
  );
  const additionalServicesRecurringSum = sum(
    Object.values(selectedAndMandatoryAdditionServicesPricings)
      .filter((pricing: OfferItemPricing) => pricing?.billingType === 'recurring')
      .map((pricing: OfferItemRecurringPricing) => pricing.recurringAmount)
  );

  const selectAdditionalServicePricing = (id: string, pricing: OfferItemPricing) => {
    setSelectedAdditionalServicesPricings(prev => {
      if (prev[id] === pricing) {
        return { ...prev, [id]: null };
      }

      return { ...prev, [id]: pricing };
    });
  };

  useEffect(() => {
    logger.info({ message: 'Selected pricing', params: { selectedMainProductPricing, offerSlug } });
    setSelectedAdditionalServicesPricings(convertOfferItemsToDictionary(optionalAdditionalItems));
  }, [selectedMainProductPricing]);

  return {
    selectedMainProductPricing,
    selectedAdditionalServicesPricings,
    selectedAndMandatoryAdditionServicesPricings,
    additionalServicesOneTimeSum,
    additionalServicesRecurringSum,
    setSelectedMainProductPricing,
    selectAdditionalServicePricing,
  };
};
