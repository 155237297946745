import { FilteringOptions, ValidationFunction } from '../config';

import { filterBuilder } from '.';

const filteringOptions: FilteringOptions = (offersData, { subCategoryPhrases }) =>
  offersData
    .reduce((accumulatedFilters, currentOffer) => {
      const subCategory: string = currentOffer.storyblokContent?.plan?.content?.subCategory;
      if (subCategory?.length && !accumulatedFilters.includes(subCategoryPhrases[subCategory])) {
        accumulatedFilters.push(subCategoryPhrases[subCategory]);
      }

      return accumulatedFilters;
    }, [])
    .sort((a, b) => a.localeCompare(b));

const filterCondition: ValidationFunction = (selectedFilters, offer, { subCategoryPhrases }) => {
  return selectedFilters.some(filter => {
    const originalName = Object.keys(subCategoryPhrases).find(phrase => subCategoryPhrases[phrase] === filter);

    return offer.storyblokContent?.plan?.content?.subCategory?.includes(originalName);
  });
};

export const subCategoryFilter = filterBuilder(filteringOptions, filterCondition);
