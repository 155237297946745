import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';

export const useAcceptTakeoverMutation = () => {
  const httpService = useHttpService();

  return useMutation(async acceptTakeoverDto => {
    const response = await httpService.post('/takeover/receiver-accept', acceptTakeoverDto);

    return response.data;
  });
};
