import React from 'react';

import cn from 'classnames';
import upperFirst from 'lodash/upperFirst';

import ArrowRight from '@assets/icons/arrows/arrow-right.svg';

import styles from './InformationCard.module.scss';
import { Image } from '../Image/Image';
import { Link } from '../Link/Link';

import { Button, ButtonVariant, possibleButtonThemes } from '@base-components/Button';

export const InformationCard = ({
  icon,
  headerText,
  contentText,
  linkText,
  link,
  //TODO not used probably
  linkIcon,
  theme = 'grey',
  rotation = 'none',
  fixedHeight = false,
  noButton = false,
}) => {
  const isNewThemeVariants = possibleButtonThemes.includes(theme);

  return (
    <div
      className={cn(
        styles.container,
        styles[`informationCardBackground${upperFirst(theme)}`],
        styles[`rotation${upperFirst(rotation)}`]
      )}
    >
      <Link
        {...link?.[0]}
        className={cn(styles.link, { [styles.fixedHeight]: fixedHeight })}
        dataAttribute={{ 'data-analytics-information-card': headerText }}
      >
        <div className={styles.informationCard}>
          <div className={cn(styles.informationCardContent, styles[`informationCardContent${upperFirst(theme)}`])}>
            {icon?.filename && (
              <div className={cn(styles[`icon${upperFirst(theme)}`], { [styles.icon]: theme !== 'greyCenterText' })}>
                <Image alt="link icon" className={cn(styles[`image${upperFirst(theme)}`])} image={icon} />
              </div>
            )}
            {headerText && <div className={cn(styles.header, styles[`header${upperFirst(theme)}`])}>{headerText}</div>}
            {contentText && (
              <div className={cn(styles.content, styles[`content${upperFirst(theme)}`])}>{contentText}</div>
            )}
          </div>

          {noButton ? (
            <></>
          ) : linkText ? (
            <div style={{ marginTop: '8px' }}>
              <Button
                theme={
                  isNewThemeVariants
                    ? theme
                    : ['BlueDark', 'BlueBright'].includes(theme)
                    ? 'raw-on-dark'
                    : 'raw-on-light'
                }
                as="button"
                variant={ButtonVariant.Icon}
                icon="none"
                isFullWidth
              >
                {linkText}
              </Button>
            </div>
          ) : (
            <div className={styles.arrow}>
              <ArrowRight />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
