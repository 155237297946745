import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isUndefined, isMatchWith, omit } from 'lodash';
import * as Yup from 'yup';

import { brithdayDateFilter } from '../../../../../validation/regexps/date';
import { emailFilter } from '../../../../../validation/regexps/email';
import { noSpecialCharactersRegex } from '../../../../../validation/regexps/no-special-characters';
import { passwordComplexityRegExp } from '../../../../../validation/regexps/password';
import { phoneFilters } from '../../../../../validation/regexps/phone';
import { postalCodeFilters } from '../../../../../validation/regexps/postal-code';

import { nameRegExp } from 'validation/regexps/name';

dayjs.extend(customParseFormat);

const customerValidationSchema = ({
  required,
  maxLength,
  noSlash,
  birthdateFormat,
  min18years,
  min1920year,
  phoneNumberNlDe,
  houseNumber,
  postalCodeDe,
  noSemicolon,
  noSpecialCharacters,
  onlyLettersSpaceApostrophesHyphensAllowed = 'onlyLettersSpaceApostrophesHyphensAllowed',
}) => {
  return {
    // Temporary disabled due of business requirements
    // customerType: Yup.string()
    //   .required(required)
    //   .nullable(),

    salutation: Yup.string()
      .required(required)
      .test('noEmpty', required, val => val !== '--'),

    firstName: Yup.string()
      .required(required)
      .max(80, maxLength)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('noSlash', noSlash, val => !!val && !val.includes('/')),

    lastName: Yup.string()
      .required(required)
      .max(80, maxLength)
      .transform(value => value.trim())
      .matches(nameRegExp, onlyLettersSpaceApostrophesHyphensAllowed)
      .test('noSlash', noSlash, val => !!val && !val.includes('/')),

    birthdate: Yup.string()
      .required(required)
      .test('dateFormat', birthdateFormat, val => !!val && brithdayDateFilter.test(val))
      .test('validDate', birthdateFormat, val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isValid())
      .test(
        'min18years',
        min18years,
        val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isBefore(dayjs().subtract(18, 'years'))
      )
      .test(
        'min1920year',
        min1920year,
        val => !isUndefined(val) && dayjs(val, 'DD-MM-YYYY', true).isAfter(dayjs('1920-01-01'))
      ),

    phoneNumber: Yup.string()
      .required(required)
      .max(30, maxLength)
      .matches(phoneFilters.de, phoneNumberNlDe),

    street: Yup.string()
      .required(required)
      .max(140, maxLength),

    houseNumber: Yup.string()
      .matches(noSpecialCharactersRegex, noSpecialCharacters)
      .required(required)
      .max(20, maxLength)
      .typeError(houseNumber),

    postalCode: Yup.string()
      .required(required)
      .matches(postalCodeFilters.de, postalCodeDe),

    city: Yup.string()
      .required(required)
      .max(40, maxLength),

    isDifferentShippingAddress: Yup.boolean(),

    differentShippingAddress: Yup.object().when('isDifferentShippingAddress', {
      is: true,
      then: Yup.object().shape({
        phoneNumber: Yup.string()
          .max(30, maxLength)
          .matches(phoneFilters.de, {
            excludeEmptyString: true,
            message: phoneNumberNlDe,
          }),

        street: Yup.string()
          .required(required)
          .max(140, maxLength),

        houseNumber: Yup.string()
          .required(required)
          .max(20, maxLength)
          .typeError(houseNumber),

        postalCode: Yup.string()
          .required(required)
          .matches(postalCodeFilters.de, postalCodeDe),

        city: Yup.string()
          .required(required)
          .max(40, maxLength),
      }),
    }),

    isDeliveryInfo: Yup.boolean(),

    deliveryInfo: Yup.object().when('isDeliveryInfo', {
      is: true,
      then: Yup.object().shape({
        note: Yup.string()
          .required(required)
          .max(50, maxLength)
          .test('noSemicolon', noSemicolon, val => !!val && !val.includes(';')),
      }),
    }),

    emailConsents: Yup.boolean(),
  };
};

export const existingCustomerValidationSchema = validationMessages =>
  Yup.object().shape({ ...customerValidationSchema(validationMessages) });

export const newCustomerValidationSchema = validationMessages =>
  Yup.object().shape({
    ...customerValidationSchema(validationMessages),

    email: Yup.string()
      .required(validationMessages.required)
      .email(validationMessages.email)
      .max(70, validationMessages.maxLength)
      .matches(emailFilter, validationMessages.email),

    password: Yup.string()
      .required(validationMessages.required)
      .min(5, validationMessages.password)
      .matches(passwordComplexityRegExp, validationMessages.password),
  });

export const getDefaultValues = (user, cart) => {
  const isDifferentShippingAddress = !isMatchWith(
    omit(user?.billingAddress, 'houseNumberAddon'),
    omit(user?.shippingAddress, 'houseNumberAddon'),
    (b, s) => b?.toLowerCase() === s?.toLowerCase()
  );

  const isDeliveryInfo = cart?.extraDeliveryInformation !== null && cart?.extraDeliveryInformation !== '';

  return {
    customerType: user?.customerType || 'private',
    salutation: user?.salutation || '',
    firstName: user?.billingAddress?.firstName || '',
    lastName: user?.billingAddress?.lastName || '',
    birthdate: (user?.birthdate && dayjs(user.birthdate).format('DD-MM-YYYY')) || '',
    phoneNumber: user?.phone || '',
    street: user?.billingAddress?.street || '',
    houseNumber: user?.billingAddress?.houseNumber || '',
    postalCode: user?.billingAddress?.postalCode || '',
    city: user?.billingAddress?.city || '',
    isDifferentShippingAddress: isDifferentShippingAddress || false,
    differentShippingAddress: (isDifferentShippingAddress && {
      firstName: user?.shippingAddress?.firstName || '',
      lastName: user?.shippingAddress?.lastName || '',
      city: user?.shippingAddress?.city || '',
      houseNumber: user?.shippingAddress?.houseNumber || '',
      postalCode: user?.shippingAddress?.postalCode || '',
      street: user?.shippingAddress?.street || '',
      phoneNumber: user?.shippingAddress?.phone || '',
    }) || {
      firstName: '',
      lastName: '',
      city: '',
      houseNumber: '',
      postalCode: '',
      street: '',
      phoneNumber: '',
    },
    isDeliveryInfo: (user && isDeliveryInfo) || false,
    deliveryInfo: (user && { note: cart?.extraDeliveryInformation }) || { note: '' },
    email: user?.email || '',
    password: '',
    emailConsents: !!user?.acceptanceOfCommercialApproach,
  };
};
