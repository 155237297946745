import { usePhrases } from './context/usePhrases';

const PricingVariants = {
  Standard: 'standard',
  New: 'new',
  Refurbished: 'refurbished',
};

export const useCondition = () => {
  const conditionsPhrases = usePhrases({ name: 'conditions', rawObject: true });

  const getCondition = cartItem => {
    const cartItemVariant = cartItem?.pricing?.variant;

    if (cartItemVariant !== PricingVariants.Standard) {
      return conditionsPhrases?.[cartItemVariant]?.value;
    }

    const cartItemIsRefurbished = cartItem?.plan?.isRefurbished || cartItemVariant === PricingVariants.Refurbished;

    return conditionsPhrases?.[cartItemIsRefurbished ? PricingVariants.Refurbished : PricingVariants.New]?.value;
  };

  return { getCondition };
};
