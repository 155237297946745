import React from 'react';

import * as dayjs from 'dayjs';

import { Label } from '@components/Label/Label';

import styles from './StatusLabel.module.scss';

const takeoverStatusesColor = {
  cancelled: { background: '#8AD861', fontColor: '#000000' },
  new: { background: '#005DFF', fontColor: '#FFFFFF' },
  closed: { background: '#E23232', fontColor: '#FFFFFF' },
  waiting: { background: '#005DFF', fontColor: '#FFFFFF' },
  provided: { background: '#005DFF', fontColor: '#FFFFFF' },
  rejected: { background: '#8AD861', fontColor: '#000000' },
  error: { background: ' #005DFF', fontColor: '#FFFFFF' },
  default: { background: ' #FFBF00', fontColor: '#000000' },
};

const statusesColor = {
  future: { background: '#005DFF', fontColor: '#FFFFFF' },
  active: { background: '#8AD861', fontColor: '#000000' },
  paused: { background: '#141B4A', fontColor: '#FFFFFF' },
  terminating: { background: '#FFBF00', fontColor: '#000000' },
  cancelled: { background: '#E23232', fontColor: '#FFFFFF' },
  default: { background: '#FFBF00', fontColor: '#000000' },
};

const orderType = {
  order: 'order',
  change: 'change',
  return: 'return',
};

const lspOrderStatus = {
  imp: 'IMP',
  wen: 'WEN',
  avs: 'AVS',
  con: 'CON',
  dtp: 'DTP',
  abg: 'ABG',
  kna: 'KNA',
};

const additionalDescriptionMap = {
  de: {
    [orderType.order]: {
      [lspOrderStatus.imp]: 'IMP',
      [lspOrderStatus.wen]: 'WEN',
      [lspOrderStatus.avs]: 'AVS ORDER',
      [lspOrderStatus.con]: 'CON',
      [lspOrderStatus.dtp]: 'DTP',
    },
    [orderType.change]: {
      [lspOrderStatus.avs]: 'AVS CHANGE',
    },
    [orderType.return]: {
      [lspOrderStatus.avs]: 'AVS RETURN',
      [lspOrderStatus.imp]: 'IMP RETURN',
    },
  },
  nl: {
    [orderType.order]: {
      [lspOrderStatus.wen]: 'WEN',
      [lspOrderStatus.avs]: 'AVS ORDER',
      [lspOrderStatus.dtp]: 'DTP',
      [lspOrderStatus.con]: 'CON',
      [lspOrderStatus.abg]: 'ABG',
      [lspOrderStatus.kna]: 'KNA',
    },
  },
};

const getMappedStatus = (status, statusItems, defaultStatus = undefined) =>
  statusItems?.find(statusItem => statusItem.status === status)?.text || defaultStatus || status;

const getLabelText = ({
  takeoverStatus,
  status,
  statusItems,
  content,
  country,
  lastLspOrderItemData,
  deliveryStatusItems,
  takeoverStatusItems,
  cancelledDate,
  isTakeover,
  order,
  deliveryText,
}) => {
  const timeslotBookings = order?.timeslotBookings?.find(timeslot => timeslot.status === 'CONFIRMED');
  if (status === 'future' && timeslotBookings) {
    const text = deliveryText?.deliverytsb?.content?.content?.[0]?.content;
    const date = dayjs(timeslotBookings.reservationStart).format('DD.MM');
    const startTime = dayjs(timeslotBookings.reservationStart).format('HH:mm');
    const endTime = dayjs(timeslotBookings.reservationStop).format('HH:mm');

    return `${text?.[0]?.text} ${date} ${text?.[2]?.text} ${startTime} - ${endTime}`;
  }
  if (cancelledDate && !isTakeover) {
    return `${status} ${cancelledDate}`;
  }

  if (isTakeover) {
    const takeoverStatusDescription = getMappedStatus(
      takeoverStatus,
      takeoverStatusItems?.length > 0 ? takeoverStatusItems : content.takeoverStatusItems
    );

    return takeoverStatusDescription;
  }

  const additionalStatusDescription =
    additionalDescriptionMap[country]?.[lastLspOrderItemData?.orderType]?.[lastLspOrderItemData?.status];

  if (additionalStatusDescription) {
    const lspOrderStatusDescription = getMappedStatus(
      additionalStatusDescription,
      deliveryStatusItems?.length > 0 ? deliveryStatusItems : content.deliveryStatusItems,
      status
    );

    if (lastLspOrderItemData.status === lspOrderStatus.avs) {
      return `${lspOrderStatusDescription} ${lastLspOrderItemData.arrangedDate}`;
    }

    return lspOrderStatusDescription;
  }

  const defaultDescription = getMappedStatus(status, statusItems?.length > 0 ? statusItems : content.statusItems) || '';

  return defaultDescription;
};

const getFontColor = ({ status, takeoverStatus }) => {
  return takeoverStatus
    ? takeoverStatusesColor[takeoverStatus]?.fontColor || takeoverStatusesColor.default.fontColor
    : statusesColor[status]?.fontColor || statusesColor.default.fontColor;
};

const getBackgroundColor = ({ status, takeoverStatus }) => {
  return takeoverStatus
    ? takeoverStatusesColor[takeoverStatus]?.background || takeoverStatusesColor.default.background
    : statusesColor[status]?.background || statusesColor.default.background;
};

const StatusLabel = ({
  takeoverStatus,
  status,
  statusItems,
  content,
  country,
  lastLspOrderItemData,
  deliveryStatusItems,
  takeoverStatusItems,
  cancelledDate,
  isTakeover,
  order,
  deliveryText,
}) => {
  const statusLabelText = getLabelText({
    status,
    takeoverStatus,
    cancelledDate,
    isTakeover,
    statusItems,
    content,
    country,
    lastLspOrderItemData,
    deliveryStatusItems,
    takeoverStatusItems,
    order,
    deliveryText,
  });
  const fontColor = getFontColor({ status, takeoverStatus });
  const labelBackgroundColor = getBackgroundColor({ status, takeoverStatus });

  return (
    <Label
      text={statusLabelText}
      showDot={false}
      fontColor={fontColor}
      labelBackgroundColor={labelBackgroundColor}
      className={styles.status}
      dataAttribute={{ 'data-test-id': 'subscription-status-label' }}
    />
  );
};

export default StatusLabel;
