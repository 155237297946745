import { conditionFilter } from './filteringOptions/condition';
import { contractTypeFilter } from './filteringOptions/contractType';
import { energyClassFilter } from './filteringOptions/energyClass';
import { featuresFilter } from './filteringOptions/features';
import { installationTypeFilter } from './filteringOptions/installationType';
import { subCategoryFilter } from './filteringOptions/subCategory';
import { FilteringTranslations } from './helpers/useFilteringTranslations';

import { OfferItemWithStoryblokContent } from 'types/offer.types';

export type FilteringOptions = (
  offersData: OfferItemWithStoryblokContent[],
  translation: FilteringTranslations
) => string[];

export type ValidationFunction = (
  selectedFilters: string[],
  offer: OfferItemWithStoryblokContent,
  translation: FilteringTranslations
) => boolean;

interface FiltersConfig {
  [T: string]: {
    filteringOptions: FilteringOptions;
    validationFunction: ValidationFunction;
  };
}

export const filtersConfig: FiltersConfig = {
  'payment-plan': contractTypeFilter,
  conditions: conditionFilter,
  'sub-category': subCategoryFilter,
  'installation-type': installationTypeFilter,
  'energy-class': energyClassFilter,
  features: featuresFilter,
};
