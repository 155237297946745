import { useMutation } from 'react-query';

import { useHttpService } from '@hooks/useHttpService';
import { useJwt } from '@hooks/user/useJwt';

export const useMovingPlannerMutation = () => {
  const httpService = useHttpService();
  const jwtToken = useJwt();

  return useMutation(
    async movingPlannerDto => {
      const response = await httpService.post('/moving/process', movingPlannerDto);

      return response.data;
    },
    { enabled: !!jwtToken }
  );
};
