import { useEffect } from 'react';

import { useGtmContext } from '@context/gtm';

import { sendPlanImpressionEvent } from '@services/gtm/user-interaction-offer';
import { FrontendFinancialProduct } from 'types/frontend-financial-product.types';
import { Offer, OfferItemOneTimeRecurringPricing } from 'types/offer.types';

export const useOfferImpressionEvent = (
  offer: Offer,
  selectedPricing: OfferItemOneTimeRecurringPricing,
  productName: string,
  frontendFinancialProduct?: FrontendFinancialProduct
) => {
  const { isPageInitializationEventSent } = useGtmContext();

  // TODO: in progress, adjust 'new' with "frontendFinancialProduct.assets?.[0]?.manufacturerProductCode"
  // `frontendFinancialProduct.assets?.[0]?.manufacturerProductCode` is a temporary place to store the condition
  const isFinancialProduct = frontendFinancialProduct && selectedPricing.variant.toLowerCase() === 'new'.toLowerCase();

  useEffect(() => {
    if (offer && selectedPricing && isPageInitializationEventSent) {
      sendPlanImpressionEvent(offer, selectedPricing, productName, isFinancialProduct);
    }
  }, [offer, selectedPricing, isPageInitializationEventSent, isFinancialProduct]);
};
