import { AggregatedItemWithOfferItem } from 'types/cart.types';
import { OfferItem, OfferItemPricing } from 'types/offer.types';

export const calculatePricesForAggregatedCartItem = <
  T extends {
    setId: string;
    offerItem: OfferItem;
    pricing: OfferItemPricing;
    discountedPrice?: number;
  }
>({
  additionalItems,
  mainItem,
  quantity,
}: AggregatedItemWithOfferItem<T>) => {
  const oneTimeFee = [...additionalItems, mainItem].reduce((acc, item) => {
    return acc + (item.pricing as OfferItemPricing).oneTimeAmount;
  }, 0);

  const recurringFee = [...additionalItems, mainItem].reduce((acc, item) => {
    return acc + (item.pricing as OfferItemPricing).recurringAmount;
  }, 0);

  const discountPrice = [...additionalItems, mainItem].reduce((acc, item) => {
    return acc + item.discountedPrice || 0;
  }, 0);

  return {
    calculatedOneTimeFee: oneTimeFee * quantity,
    calculatedRecurringFee: recurringFee * quantity,
    discountPrice: discountPrice * quantity,
  };
};
