/* eslint-disable no-underscore-dangle */
import React from 'react';

import { useContentContext } from '@context/content';
import { useRedirect } from '@hooks/useRedirect';
import { FaqArticle } from '@modules/Faq/FaqArticle/FaqArticle';
import { Paths } from '@utils/paths/paths';

import styles from './Category.module.scss';

import { Button, ButtonVariant } from '@base-components/Button';

const findFaqArticleItemByCategoryName = (faqCategories, categoryName) => {
  const faqCategoriesItems = faqCategories.map(faqCategory => faqCategory.content.faqArticles).flat();

  const foundFaqArticleItem = faqCategoriesItems.find(
    faqArticleItem => faqArticleItem.label.toLowerCase() === categoryName.toLowerCase()
  );

  return foundFaqArticleItem;
};

export const Category = ({ categoryParam, categoryText, backButtonOverviewText }) => {
  const redirect = useRedirect();
  const { faqCategories } = useContentContext();
  const faqArticleItem = findFaqArticleItemByCategoryName(faqCategories, categoryParam);

  if (!faqArticleItem) {
    // TODO: Should redirect to 404 page (create it!)
    return (
      <div className={styles.container}>
        <div className={styles.buttonContainer}>
          <Button
            as="button"
            variant={ButtonVariant.Icon}
            icon="arrow-left"
            className={styles.mobileBackButton}
            onClick={() => redirect({ pageId: Paths.Faq })}
          >
            {backButtonOverviewText}
          </Button>
        </div>

        <div className={styles.notFound}>Not found</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.topic}>{categoryText}</div>

      <div className={styles.buttonContainer}>
        <Button
          as="button"
          variant={ButtonVariant.Icon}
          icon="arrow-left"
          onClick={() => redirect({ pageId: Paths.Faq })}
        >
          {backButtonOverviewText}
        </Button>
      </div>

      <div className={styles.faqArticlesContainer}>
        <FaqArticle
          key={faqArticleItem._uid}
          uid={faqArticleItem._uid}
          label={faqArticleItem.label}
          icon={faqArticleItem.icon}
          faqGroup={faqArticleItem.faqGroup}
          allOpened
        />
      </div>
    </div>
  );
};
