export const hasAttributeOrParentWithAttribute = ({ target, attributeName, attributeValue }) => {
  if (!target) {
    return false;
  }

  if (target.getAttribute(attributeName) === attributeValue) {
    return true;
  }

  return hasAttributeOrParentWithAttribute({ target: target.parentElement, attributeName, attributeValue });
};
