import React from 'react';

import cn from 'classnames';
import Link from 'next/link';

import { Image } from '@components/Image/Image';
import { useLocale } from '@hooks/useLocale';
import { buildCategoryPath } from '@utils/paths/build-paths';

import styles from './CategoryCard.module.scss';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const CategoryCard = ({ category, image, mobileImage, contentText, mobileViewOnly, linkText }) => {
  const { category: categorySlug, name: categoryName = '', colorHex: backgroundColor = '#ffffff' } =
    category?.content || {};

  const { locale } = useLocale();

  return (
    <Link
      data-category={categorySlug}
      href={buildCategoryPath({ locale, category: categorySlug })}
      className={cn(styles.container, { [styles.onlyMobile]: mobileViewOnly })}
    >
      <div className={styles.categoryCard}>
        <div className={styles.imageContainer}>
          <Image image={image} mobileImage={mobileImage} alt="appliance" className={styles.image} />
          <div className={styles.background} style={{ backgroundColor }} />
        </div>

        <div className={styles.header}>
          <span className={styles.headerText}>{categoryName}</span>
        </div>

        <div className={styles.content}>{contentText}</div>
        <div className={cn(styles.button, { [styles.mobileButton]: mobileViewOnly })}>
          {mobileViewOnly ? (
            <Button
              isFullWidth
              as="button"
              variant={ButtonVariant.Icon}
              icon="pointerUp"
              theme={ButtonTheme.SoftBlue}
            />
          ) : (
            <Button isFullWidth as="button" variant={ButtonVariant.Icon} theme={ButtonTheme.SoftBlue}>
              {linkText}
            </Button>
          )}
        </div>
        <div className={styles.mobileButton}>
          <Button isFullWidth as="button" variant={ButtonVariant.Icon} icon="pointerUp" theme={ButtonTheme.SoftBlue} />
        </div>
      </div>
    </Link>
  );
};
