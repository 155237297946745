import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';

dayjs.extend(localeData);

const capitalizeFirstLetter = string => {
  if (string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sortByHours = hours => {
  return hours.sort((a, b) => {
    const firstHours = a.time.slice(0, 2);
    const secondHours = b.time.slice(0, 2);

    return firstHours - secondHours;
  });
};

export const mapToViewModel = (availableTimeSlots, language = 'en') => {
  dayjs.locale(language);
  const months = dayjs.months();
  const weekDays = dayjs.weekdays(true);

  return availableTimeSlots?.timeslots?.reduce((acc, timeSlot) => {
    const weekDay = dayjs(timeSlot.start).weekday();
    const translatedWeekDay = weekDays[weekDay];

    const month = dayjs(timeSlot.start).month();
    const translatedMonth = capitalizeFirstLetter(months[month]);

    const startHour = dayjs(timeSlot.start).format('HH:mm');
    const endHour = dayjs(timeSlot.stop).format('HH:mm');

    const dayWithMonth = `${dayjs(timeSlot.start).format('DD')} ${translatedMonth}`;

    if (dayWithMonth in acc) {
      return {
        ...acc,
        [dayWithMonth]: {
          ...acc[dayWithMonth],
          availableTimeSlots: [
            ...acc[dayWithMonth].availableTimeSlots,
            { time: `${startHour} - ${endHour}`, timeslotId: timeSlot.timeslotId, carrier: timeSlot.carrier },
          ],
        },
      };
    }

    return {
      ...acc,
      [dayWithMonth]: {
        weekDay: translatedWeekDay,
        availableTimeSlots: [
          { time: `${startHour} - ${endHour}`, timeslotId: timeSlot.timeslotId, carrier: timeSlot.carrier },
        ],
      },
    };
  }, {});
};
export const CALL_ME_OPTION = 'call-me';
