/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */

import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useLogger } from '@hooks/useLogger';

import FormData from 'form-data';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useContactFormMutation } from '@hooks/useContactFormMutation';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';
import { Paths } from '@utils/paths/paths';

import styles from './ContactPageContainer.module.scss';
import { contactValidationSchema } from './validation';
// eslint-disable-next-line import/no-cycle
import DynamicComponent from '../../../components/DynamicComponent/DynamicComponent';


export const ContactPageContainer = ({
  leftSideBarComponents,
  mainSectionComponents,
  requiredValueError,
  maximumLengthError,
  emailFormatError,
  phoneFormatError,
  noTopicSpecifiedError,
  successToastMessage,
  failToastMessage,
  consentsLabelRichText,
}) => {
  const { logger } = useLogger({ context: `ContactPageContainer` });
  const { country } = useLocale();
  const { data: user } = useUser();

  const defaultValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    message: '',
    topic: '',
    attachments: null,
    consents: false,
  };

  const translation = {
    required: requiredValueError,
    maxLength: maximumLengthError,
    email: emailFormatError,
    phone: phoneFormatError,
    selectTopic: noTopicSpecifiedError,
  };
  const formOptions = {
    resolver: yupResolver(contactValidationSchema({ translation, country })),
    defaultValues,
  };
  const methods = useForm(formOptions);
  const contactMutation = useContactFormMutation();

  const sendMessage = async data => {
    logger.info({
      message: 'Send contact form.',
      params: {
        country,
        fistName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        availableAttachments: data.attachments?.length || 0,
      },
    });

    try {
      const upload = new FormData();
      if (data.attachments) {
        for (const attachment of data.attachments) {
          upload.append('attachments', attachment);
        }
      }

      upload.append('country', country);
      upload.append('firstName', data.firstName);
      upload.append('lastName', data.lastName);
      upload.append('email', data.email);
      upload.append('phone', data.phone);
      upload.append('message', data.message);
      upload.append('topic', data.topic);
      upload.append('userId', user?.cbId);

      await contactMutation.mutateAsync({ formData: upload });

      toast(successToastMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
      });
      methods.reset();
    } catch (error) {
      logger.error({
        message: `Sending contact form failed. Error: ${error}`,
        params: {
          country,
          fistName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          availableAttachments: (data.attachments && data.attachments.length) || 0,
        },
      });
      toast(failToastMessage, {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {leftSideBarComponents.map(blok => (
          <DynamicComponent key={blok._uid} {...blok} />
        ))}
      </div>
      <div className={styles.mainSection}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(sendMessage)}>
            {mainSectionComponents.map(blok => (
              <DynamicComponent
                key={blok._uid}
                {...blok}
                isSubmitting={methods.formState.isSubmitting}
                consentsLabelRichText={consentsLabelRichText}
              />
            ))}
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
