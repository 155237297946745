import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { TextInput } from '@components/inputs/TextInput/TextInput';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUserLoginMutation } from '@hooks/user/useUserLoginMutation';
import { buildPath } from '@utils/paths/build-paths';
import { Paths } from '@utils/paths/paths';

import styles from './Login.module.scss';
import { loginValidation } from './validation';
import { HeadingGroup } from '../../../components/HeadingGroup/HeadingGroup';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';

export const Login = ({
  headerText,
  emailText,
  passwordText,
  submitText,
  forgotPasswordText,
  invalidCredentialsMessage,
  failMessage,
  validationMessages,
}) => {
  const { logger } = useLogger({ context: 'Login' });
  const userLoginMutation = useUserLoginMutation();
  const router = useRouter();
  const { locale } = useLocale();

  const formOptions = {
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginValidation(validationMessages)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  };

  const methods = useForm(formOptions);

  const onSubmitHandler = async data => {
    try {
      logger.info({
        message: `User ${data.email} is trying to login`,
        params: { context: 'Login', userEmail: data.email },
      });

      const response = await userLoginMutation.mutateAsync({
        username: data.email,
        password: data.password,
      });
      if (response.authToken && response.refreshToken) {
        router.push(
          buildPath({
            locale,
            pageId: Paths.MyBluemovement,
          })
        );
      }
    } catch (error) {
      const errorMessage = error.message || error.data?.errors?.[0]?.message;
      if (errorMessage === 'invalidCredentials') {
        toast(invalidCredentialsMessage, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });

        logger.info({
          message: 'User passed wrong credentials',
          params: { userEmail: data.email, errorMessage },
        });
      } else {
        toast(failMessage, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        });

        logger.error({
          message: `There is a problem with login ${data.email}`,
          params: { userEmail: data.email, errorMessage },
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)} className={styles.formContainer}>
        <HeadingGroup variant="default" alignLeft label={headerText} />
        <TextInput controllerName="email" labelText={emailText} dataAttribute={{ 'data-test-id': 'email-input' }} />
        <TextInput
          controllerName="password"
          labelText={passwordText}
          password
          dataAttribute={{ 'data-test-id': 'password-input' }}
        />
        <Button
          as="button"
          variant={ButtonVariant.Basic}
          theme={ButtonTheme.RawOnLight}
          className={styles.secondaryButton}
          dataAttribute={{ 'data-test-id': 'RESET_PASSWORD' }}
          onClick={() =>
            router.push(
              buildPath({
                locale,
                pageId: Paths.ForgotPassword,
              })
            )
          }
          data-e2e-element-name="RESET_PASSWORD"
        >
          {forgotPasswordText}
        </Button>
        <Button
          as="button"
          variant={ButtonVariant.Basic}
          theme={ButtonTheme.Secondary}
          type="submit"
          isFullWidth
          disabled={!methods.formState.isValid}
          isLoading={methods.formState.isSubmitting}
          className={styles.primaryButton}
        >
          {submitText}
        </Button>
      </form>
    </FormProvider>
  );
};
