import React from 'react';

import { usePhrases } from '@hooks/context/usePhrases';
import { usePlanData } from '@modules/PDP/hooks/usePlanData';
import { interpolateContent } from '@utils/interpolate-content';

import styles from './SubscriptionCardHeader.module.scss';
import EnergyLabel from '../EnergyLabel/EnergyLabel';
import { OfferCardHeader } from '../OfferCardHeader/OfferCardHeader';

export const SubscriptionCardHeader = ({ title, subtitle, energyLabel, isAlwaysVisible }) => {
  const { plan, planContent, offer, offerContent } = usePlanData();
  const isSDA = plan?.category?.groupType === 'SDA';
  const featurePhrases = usePhrases({ name: 'features', rawObject: true });
  const contentVariables = planContent.content;
  if (offer && offerContent) {
    return <OfferCardHeader energyLabel={energyLabel} isAlwaysVisible={isAlwaysVisible} />;
  }

  return (
    <div className={styles.mobileOnly}>
      <h1 className={styles.headerTitle} data-pdp-category-name={plan?.category?.slug}>
        {interpolateContent(title, contentVariables)}
      </h1>

      <span className={styles.headerSubtitle}>{interpolateContent(subtitle, contentVariables)}</span>

      {!isSDA && (
        <div className={styles.energyLabel}>
          <EnergyLabel
            link={featurePhrases?.energyClass?.link?.[0]}
            variant={contentVariables.features?.find(f => f.key === 'energyClass')?.value}
            text={energyLabel}
            document={planContent.content.energyLabelDocument}
          />
        </div>
      )}
    </div>
  );
};
