import React from 'react';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import LensImage, { ZoomPosition } from '@components/LensImage/LensImage';

export const PinchImage = ({ alt, image, className }) => {
  return (
    <TransformWrapper panning={{ disabled: true }} doubleClick={{ disabled: true }}>
      <TransformComponent contentStyle={{ maxWidth: '45rem' }}>
        <LensImage alt={alt} image={image} className={className} zoomPosition={ZoomPosition.ORIGINAL} />
      </TransformComponent>
    </TransformWrapper>
  );
};
