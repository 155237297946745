export interface FrontendFinancialProduct {
  id: number;
  status: string;
  paymentAmount: number;
  periodMonths: number;
  totalAmount: number;
  renewalPeriodMonths: number;
  vib: string;
  condition: string;
  services: {
    skuCode: string;
    name: string;
    quantity: number;
    description: string;
    displayIndex: number;
  }[];
}

export enum PaymentPlan {
  Finance = 'finance',
  Renting = 'renting',
}
