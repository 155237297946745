/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { RiDeleteBin6Line as DeleteIcon } from 'react-icons/ri';

import RectangleIcon from '@assets/icons/new/inputs/rectangle.svg';
import UploadIcon from '@assets/icons/new/upload.svg';

import styles from './ContactIssueInput.module.scss';

const ATTACHMENTS_MAX_SIZE = 18 * 1024 * 1024; // 18 MB
const FILE_MAX_NUMBER = 5;

const truncateFilename = name => {
  const maxLength = 35;

  if (name.length <= maxLength) {
    return name;
  }
  const type = name.split('.')[name.split('.').length - 1];

  return `${name.slice(0, maxLength - 5)}...${type}`;
};

export const ContactIssueInput = ({ title, uploadPrompt, maxFilesError, maxSizeError }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [filenames, setFilenames] = useState([]);
  const [inputKey, setInputKey] = useState(1);

  const {
    register,
    setValue,
    formState: { isSubmitted, errors },
  } = useFormContext();

  const fileChangedHandler = event => {
    const files = event.target.files;

    if (Object.keys(files).length === 0) {
      setErrorMessage('');
      setFilenames([]);

      return;
    }

    if (Object.keys(files).length > FILE_MAX_NUMBER) {
      event.target.value = null;
      setErrorMessage(maxFilesError);
      setFilenames([]);

      return;
    }

    let attachmentsSize = 0;
    Object.keys(files).forEach(key => {
      attachmentsSize += files[key].size;
    });

    if (attachmentsSize > ATTACHMENTS_MAX_SIZE) {
      event.target.value = null;
      setErrorMessage(maxSizeError);
      setFilenames([]);

      return;
    }

    setFilenames(Object.keys(files).map(key => files[key].name));
    setErrorMessage('');
  };

  const resetFiles = () => {
    setInputKey(Math.random().toString(36));
    setValue('attachments', null);
    setFilenames([]);
  };

  useEffect(() => {
    if (isSubmitted) {
      setErrorMessage('');
      resetFiles();
    }
  }, [isSubmitted]);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{title}</p>
      </div>
      <textarea
        className={cn(styles.textarea, {
          [styles.invalid]: errors?.message,
        })}
        {...register('message')}
      />
      {errors?.message && (
        <span className={styles.errorMessage}>
          <RectangleIcon className={styles.rectangleIcon} />
          {errors.message.message}
        </span>
      )}
      <div className={styles.uploadSection}>
        <label htmlFor="attachments" className={styles.uploadWrapper}>
          <UploadIcon />
          <p className={styles.uploadPrompt}>{uploadPrompt}</p>
          <input
            key={inputKey || ''}
            type="file"
            id="attachments"
            name="attachments"
            accept="image/*, .pdf"
            className={styles.noDisplay}
            {...register('attachments', { onChange: fileChangedHandler })}
            multiple
          />
        </label>
        {filenames.length > 0 && (
          <div className={styles.iconWrapper}>
            <DeleteIcon
              size={25}
              onClick={() => {
                resetFiles();
              }}
            />
          </div>
        )}
      </div>
      <p className={cn(styles.errorMessage, { [styles.visibilityHidden]: !errorMessage })}>{errorMessage}</p>
      {filenames.length > 0 &&
        filenames.map((name, index) => (
          <div key={`${name}-${index}`} className={styles.attachment}>
            <span className={styles.attachmentName}>{truncateFilename(name)}</span>
          </div>
        ))}
    </div>
  );
};
