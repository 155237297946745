/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React from 'react';

import styles from './MovingPlannerConfirmation.module.scss';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

export const MovingPlannerConfirmation = ({ background, components }) => {
  const [backgroundImage] = background;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {components?.map(component => (
          <DynamicComponent {...component} key={component._uid} layout="emptyLayout" />
        ))}
      </div>

      <div
        className={styles.backgroundContainer}
        style={{ backgroundImage: backgroundImage.image && `url(${backgroundImage.image.filename})` }}
      />
    </div>
  );
};
