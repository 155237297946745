import * as Yup from 'yup';

export const validationSchema = ({ required }) => {
  return Yup.object().shape({
    pricingId: Yup.string().required(),
    agreeTermsAndConditions: Yup.boolean()
      .required()
      .oneOf([true], required),
    recurringPaymentMandate: Yup.boolean()
      .required()
      .oneOf([true], required),
  });
};

export const defaultValues = values => {
  return values;
};
