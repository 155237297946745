import React from 'react';

import useQualtrics from '@hooks/useQualtrics';

import styles from './QualtricsContainer.module.scss';

export const QualtricsContainer = ({ creativeInsertionPoint }) => {
  useQualtrics();

  return <div id={creativeInsertionPoint} className={styles.container} />;
};
