/* eslint-disable import/no-cycle */
import React from 'react';

import * as dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useRawSubscriptionById } from '@hooks/subscriptions/useRawSubscriptionById';
import { useLocale } from '@hooks/useLocale';

import { CancellationSubscriptionCard } from '../CancellationSubscriptionCard/CancellationSubscriptionCard';

import { FREE_DAYS_DE, FREE_DAYS_NL } from '@helpers/fee';

const countryWithdrawalPeriodDate = activationDate => ({
  de: dayjs(activationDate).add(FREE_DAYS_DE, 'day'),
  nl: dayjs(activationDate).add(FREE_DAYS_NL, 'day'),
});

export const checkIsCountryWithdrawalPeriodDate = ({ activationDate, country, minCancellationDate }) => {
  return !(
    dayjs().isAfter(countryWithdrawalPeriodDate(activationDate)[country]) &&
    dayjs().isBefore(dayjs(minCancellationDate))
  );
};

export const CancellationDetailsSubscriptionCard = ({
  title,
  contractDuration,
  till,
  notificationBarNotActive,
  notificationBarBeforeContractEnds,
  notificationBarRegularMda,
  notificationBarRegularSda,
}) => {
  const router = useRouter();
  const { data: subscription } = useRawSubscriptionById({
    subscriptionId: router.query.id,
  });
  const { country } = useLocale();

  const groupType = subscription?.plan?.category?.groupType;
  const status = subscription?.status;
  const activationDate = subscription?.subscriptionActivationDate;
  const minCancellationDate = subscription?.minimumCancellationDate;
  const isCountryWithdrawalPeriodDate = checkIsCountryWithdrawalPeriodDate({
    activationDate,
    country,
    minCancellationDate,
  });

  const getNotificationBar = () => {
    if (status === 'future') {
      return notificationBarNotActive;
    }

    if (isCountryWithdrawalPeriodDate) {
      if (groupType === 'MDA') {
        return notificationBarRegularMda;
      }

      if (groupType === 'SDA') {
        return notificationBarRegularSda;
      }
    }

    return notificationBarBeforeContractEnds;
  };

  return <CancellationSubscriptionCard {...{ title, contractDuration, till }} notificationBar={getNotificationBar()} />;
};
