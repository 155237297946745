import React from 'react';

import cn from 'classnames';

import { Image } from '@components/Image/Image';
import { Label } from '@components/Label/Label';
import { RichText } from '@components/RichText/RichText';

import styles from './Header.module.scss';

export const Header = ({ image, title, description, moreImages, type, className }) => (
  <div className={cn(styles.product, className)}>
    <div className={styles.imageContainer}>
      <Image image={image} alt={title} className={styles.image} />
    </div>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>
      <RichText document={description} />
    </div>
    <div className={styles.moreImagesText}>
      <a href={moreImages.url}>
        <span className={styles.textLink}>{moreImages.text}</span>
      </a>
    </div>
    {type && (
      <div className={styles.type}>
        <Label dotColor="#005DFF" text={type} />
      </div>
    )}
  </div>
);
