import dayjs from 'dayjs';

import { useCart } from '@hooks/cart';
import { useTimeSlotAvailableSlotsQuery } from '@hooks/checkout/useTimeSlotAvailableSlotsQuery';
import { useTimeSlotReservationMutation } from '@hooks/checkout/useTimeSlotReservationMutation';
import { useLocale } from '@hooks/useLocale';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';
import { useRedirect } from '@hooks/useRedirect';
import getRuntimeConfig from '@utils/getRuntimeConfig';
import { Paths } from '@utils/paths/paths';

import { CALL_ME_OPTION, mapToViewModel } from '../helpers/helpers';

const createMockedTimeslots = () => {
  const timeslots = [];

  for (let i = 1; i < 15; i++) {
    const element = {
      timeslotId: i.toString(),
      start: dayjs()
        .add(14 * i, 'hour')
        .toDate(),
      stop: dayjs()
        .add(15 * i, 'hour')
        .toDate(),
    };

    timeslots.push(element);
  }

  return timeslots;
};

export const useTimeSlotBooking = ({ mode }) => {
  const { logger } = useLogger({ context: 'useTimeSlotBooking' });
  const { data: cart } = useCart();
  const { data: user } = useUser();
  const redirect = useRedirect();
  const { language } = useLocale();
  const redirectToPaymentPage = () => {
    redirect({ pageId: Paths.CheckoutPayment });
  };
  const timeSlotReservationMutation = useTimeSlotReservationMutation(redirectToPaymentPage);
  const { data: availableTimeSlots = [], isLoading } = useTimeSlotAvailableSlotsQuery(cart.id);
  const areTimeSlotsEmpty = availableTimeSlots.length === 0;
  const isSdaIncluded = cart.items?.some(item => item?.offerItem?.groupType === 'SDA');
  const isTestMode = mode !== 'REAL';

  const reserveTimeSlot = async formData => {
    const isCallMeOptionSelected = formData.timeSlotDay === CALL_ME_OPTION;

    logger.info({
      message: `User ${user?.id} is trying to reserve timeslot`,
      params: {
        userId: user?.id,
        userEmail: user?.email,
        cartId: cart?.id,
        timeslotId: formData.timeSlotHours,
        carrier: formData.carrier,
        isCallMeOptionSelected,
        isTestMode,
      },
    });

    if (isCallMeOptionSelected || isTestMode) {
      redirectToPaymentPage();

      return;
    }

    try {
      await timeSlotReservationMutation.mutateAsync({
        timeslotId: formData.timeSlotHours,
        cartId: cart.id,
        userId: user.id,
        carrier: formData.carrier,
      });
    } catch (error) {
      logger.error({
        message: 'Error during time slot reservation',
        params: {
          userId: user?.id,
          userEmail: user?.email,
          cartId: cart?.id,
          timeslotId: formData.timeSlotHours,
          carrier: formData.carrier,
          errorMessage: error?.message,
        },
      });
    }
  };

  if (isTestMode && getRuntimeConfig('ENVIRONMENT_NAME') !== 'aws-prod') {
    return {
      isError: false,
      redirectToPaymentPage,
      reserveTimeSlot,
      areTimeSlotsLoading: false,
      timeSlots:
        mode === 'MOCKED'
          ? mapToViewModel(
              {
                timeslots: createMockedTimeslots(),
              },
              language
            )
          : [],
      isSdaIncluded,
      areTimeSlotsEmpty: false,
    };
  }

  return {
    isError: timeSlotReservationMutation.isError,
    redirectToPaymentPage,
    reserveTimeSlot,
    areTimeSlotsLoading: isLoading,
    timeSlots: mapToViewModel(availableTimeSlots, language),
    isSdaIncluded,
    areTimeSlotsEmpty,
  };
};
