import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { Checkbox } from '@refactoredComponents/inputs/Checkbox/Checkbox';
import { TwoColumnText } from '@refactoredComponents/TwoColumnText/TwoColumnText';

import { RichText } from '@components/RichText/RichText';
import { useCurrencyFormatter } from '@hooks/useCurrencyFormatter';
import { useMonthsYearsFormatter } from '@hooks/useMonthsYearsFormatter';

import styles from './FinanceContent.module.scss';
import { useFinancialProductExternalCheckoutUrl } from './hooks/useFinancialProductExternalCheckoutUrl';

export const FinanceContent = ({
  planSummary,
  financingDataProcessingInfo,
  buttonText,
  monthlyRateText,
  financingPeriodText,
  completionRateText,
  totalPriceAfterText,
  includedText,
  payTodayText,
  frontendFinancialProduct,
}) => {
  const { currencyFormatter } = useCurrencyFormatter();
  const { monthsYearsFormatter } = useMonthsYearsFormatter();
  const { refetch } = useFinancialProductExternalCheckoutUrl(frontendFinancialProduct.id);

  return (
    <div className={styles.cartContainer}>
      <div className={styles.price}>
        <span className={styles.priceTitle}>{payTodayText}:</span>
        <div>
          <span className={styles.lineThroughPrice}>
            {' '}
            {currencyFormatter({
              price: frontendFinancialProduct?.totalAmount * 100,
            }) || '-'}
          </span>
          <span className={styles.finalPrice}>
            {' '}
            {currencyFormatter({
              price: 0,
            })}
          </span>
        </div>
      </div>

      {frontendFinancialProduct?.services?.map(service => {
        return (
          <Checkbox
            key={service.displayIndex}
            variant="standard"
            name="name"
            dataAttribute={{ 'data-test-id': `frontendFinancialProduct-${service.displayIndex}` }}
            checked
            disabled
            label={
              <TwoColumnText
                className={styles.TwoColumnText}
                leftColumn={<span className={styles.smallText}>{service.name}</span>}
                rightColumn={<span className={styles.greenBold}>{includedText}</span>}
              />
            }
          />
        );
      })}

      <div className={styles.pricingContainer}>
        <div className={styles.pricingMonthlyPayment}>
          <span>{monthlyRateText}:</span>
          <span>
            {currencyFormatter({
              price: frontendFinancialProduct?.paymentAmount * 100,
            }) || '-'}
          </span>
        </div>

        <div className={styles.pricingItem}>
          <span>{financingPeriodText}:</span>
          <span>{monthsYearsFormatter({ monthsValue: frontendFinancialProduct?.periodMonths })}</span>
        </div>

        <div className={styles.pricingItem}>
          <span>{completionRateText}:</span>
          <span>
            {currencyFormatter({
              price: 0,
            })}
          </span>
        </div>

        <div className={styles.pricingItem}>
          <span>
            {totalPriceAfterText}{' '}
            {monthsYearsFormatter({ monthsValue: frontendFinancialProduct?.periodMonths }).replace(
              // Hack here
              // "Jahre" is what you'll see when the noun is in the dative case, as many dative nouns add an "n" to their plural forms.
              'Jahre',
              'Jahren'
            )}
            :
          </span>
          <span>
            {currencyFormatter({
              price: frontendFinancialProduct?.totalAmount * 100,
            }) || '-'}
          </span>
        </div>
      </div>

      <div className={styles.planSummary}>
        <RichText
          document={planSummary}
          className={styles.planSummaryText}
          contentVariables={{
            renewalPeriodMonths: frontendFinancialProduct?.renewalPeriodMonths.toString(),
            threeFourths: Math.round(frontendFinancialProduct?.totalAmount * 0.75).toString(),
            oneFourth: Math.round(frontendFinancialProduct?.totalAmount * 0.25).toString(),
          }}
        />
      </div>

      <div className={styles.FinancingDataProcessingInfo}>
        <RichText document={financingDataProcessingInfo} className={styles.financingDataProcessingInfoText} />
      </div>

      <Button
        variant={ButtonVariant.Basic}
        as="button"
        theme={ButtonTheme.Secondary}
        onClick={async () => {
          const { data: externalCheckoutUrl } = await refetch();

          try {
            window.open(externalCheckoutUrl, '_blank').focus();
          } catch (e) {
            window.location.href = externalCheckoutUrl;
          }
        }}
        isFullWidth
      >
        {buttonText}
      </Button>
    </div>
  );
};
