import { useEffect, useRef, useState } from 'react';

export const useMutationObserver = ({
  callback,
  observerOptions = {
    attributes: false,
    characterData: false,
    childList: true,
    subtree: true,
  },
  idleAfterMs = 2000,
}) => {
  const [numberOfMutations, setNumberOfMutations] = useState(0);
  const [isIdle, setIdle] = useState(false);
  const elementRef = useRef(null);
  const timer = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    observer.current = new MutationObserver((mutations, observerInstance) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setIdle(true), idleAfterMs);
      setNumberOfMutations(prev => prev + 1);
      callback(mutations, observerInstance);
    });

    observer.current.observe(elementRef.current, observerOptions);

    return () => {
      clearTimeout(timer.current);

      return observer.current.disconnect();
    };
  }, []);

  const removeObserver = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  return { elementRef, numberOfMutations, isIdle, removeObserver };
};
