import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';

import { useCartContext } from '@context/cart';
import { useHttpService } from '@hooks/useHttpService';
import { useLogger } from '@hooks/useLogger';
import { useUser } from '@hooks/user/useUser';

import { CART_QUERY_ID } from './useCart';
import { useCartId, CART_STORAGE_KEY } from './useCartId';

import { sendAddToCartEvent } from '@services/gtm/cart';
import { useLocale } from '@hooks/useLocale';
import { useContent } from '@hooks/useContent';

export const useAddToCartMutation = () => {
  const { logger } = useLogger({ context: 'useAddToCartMutation' });
  const [, setCookie] = useCookies([CART_STORAGE_KEY]);
  const httpService = useHttpService();
  const queryClient = useQueryClient();
  const cartId = useCartId();
  const { data: user } = useUser();
  const { openCart, setShowItemAdded } = useCartContext();
  const { language, country } = useLocale();
  const { data: plans } = useContent({
    locale: `${country}-${language}`,
    name: `plans-${country}`,
  });

  return useMutation(
    async addToCartItem => {
      const url = `/cart/${cartId}/add-item`;

      let body = {
        items: [addToCartItem],
        userId: user?.id,
      };

      if (cartId) {
        body = addToCartItem;
      }

      const { data } = await httpService.post(url, body);

      return data;
    },
    {
      onSuccess: (data, variables) => {
        const addedCartItem = data?.cart?.items.find(
          cartItem => cartItem.plan.id === variables.planId && cartItem.pricing.id === variables.pricingId
        );

        sendAddToCartEvent(plans, addedCartItem);

        queryClient.setQueryData(CART_QUERY_ID, { ...data.cart, sendGtmEvent: false });
        setCookie(CART_STORAGE_KEY, data.cart.id, { path: '/', sameSite: 'strict', secure: true });
        setShowItemAdded(true);
        openCart();

        logger.info({
          message: 'Item is added to cart',
          params: {
            userId: user?.id,
            cartId: data.cart.id,
            addedCartItem,
            allCartItems: data.cart.items,
          },
        });
      },
    }
  );
};
