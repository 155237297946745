import React from 'react';

import cn from 'classnames';

import DynamicComponent from '@components/DynamicComponent/DynamicComponent';

import styles from './BlogpostColumn.module.scss';
import { useLocale } from '../../hooks/useLocale';

import { useBlogContext } from '@theme/BlogPostPage/BlogPostPage';

export const BlogpostColumn = ({ items }) => {
  const { filter } = useBlogContext();
  const { country } = useLocale();

  return (
    <div className={styles.container}>
      <div className={cn(styles.regularMargin, styles.stackingLayout)}>
        {items
          .filter(
            blok => blok.postLink.cached_url.includes('/blog') && blok.postLink.cached_url.includes(`/${country}/`)
          )
          .map(blok => {
            const { productCategory, blogCategory } = blok;

            return (
              <div
                key={blok._uid}
                className={cn(styles.item, {
                  [styles.wideColumn]: blok.wide,
                  [styles.responsiveColumns]: !blok.wide,
                  [styles.hide]: ![blogCategory, productCategory].includes(filter) && filter,
                })}
              >
                <DynamicComponent {...blok} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
