/* eslint-disable import/no-cycle */
import React from 'react';

import { Image } from '@components/Image/Image';
import { Checkbox, Themes } from '@components/inputs/Checkbox/Checkbox';
import { Label } from '@components/Label/Label';
import { RichText } from '@components/RichText/RichText';
import { usePhrases } from '@hooks/context/usePhrases';
import { convert2slug } from '@utils/convert2slug';

import styles from './CheckoutCard.module.scss';

export const CheckoutCard = ({
  checkoutCardImage,
  checkoutCardMobileImage,
  backgroundColor = '#9DD570',
  applianceName,
  description,
  duration,
  condition,
  deliveryTime,
  dryerOnTop,
  dryerOnTopExternalText,
  takeBackOldAppliance,
  takeBackOldApplianceExternalText,
  currentIndex = 0,
  categorySlug,
}) => {
  const termsTranslations = usePhrases({ name: 'terms', rawObject: true });

  return (
    <div className={styles.container}>
      <div className={styles.checkoutCard}>
        <div className={styles.imageContainer} style={{ backgroundColor }}>
          <Image
            alt="checkoutCardImage"
            image={checkoutCardImage}
            mobileImage={checkoutCardMobileImage}
            className={styles.image}
          />
        </div>

        <div className={styles.content}>
          <div className={styles.header} data-category-name={categorySlug} data-duration={duration}>
            <div className={styles.titleContainer}>
              <div className={styles.titleText}>{applianceName}</div>
            </div>

            <div className={styles.infoContainer}>
              <div className={styles.duration}> {termsTranslations[convert2slug(duration)]?.value || duration}</div>
            </div>
          </div>

          <div className={styles.descriptionText}>
            <RichText document={description} />
          </div>

          <div className={styles.labelsContainer} data-condition={condition}>
            {condition && <Label dotColor="#005DFF" text={condition} />}
            {deliveryTime && <Label dotColor="#8AD861" text={deliveryTime} />}
          </div>

          {dryerOnTop && (
            <Checkbox
              labelText={dryerOnTop}
              externalText={dryerOnTopExternalText}
              controllerName={`items.${currentIndex}.dryerOnTop`}
              theme={Themes.Gray}
            />
          )}

          {takeBackOldAppliance && (
            <Checkbox
              labelText={takeBackOldAppliance}
              externalText={takeBackOldApplianceExternalText}
              controllerName={`items.${currentIndex}.takeBackOldAppliance`}
              theme={Themes.Gray}
            />
          )}
        </div>
      </div>
    </div>
  );
};
