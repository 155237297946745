import React from 'react';

import Script from 'next/script';

import { TitleWithText } from '@components/TitleWithText/TitleWithText';
import { useLogger } from '@hooks/useLogger';

import styles from './Cookiebot.module.scss';

export const CookiebotScript = ({ header }) => {
  const { logger } = useLogger({ context: 'CookiebotScript' });

  return (
    <>
      <TitleWithText header={header} className={styles.title} variant="Bigger" />
      <div id="CookieDeclaration"></div>
      <Script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/47db945e-df13-435c-85cd-4f852e2879c1/cd.js"
        type="text/javascript"
        async
        strategy="afterInteractive"
        onError={error => {
          logger.error({ message: 'Cookiebot script loading error', params: { errorMessage: error.message } });
        }}
      />
    </>
  );
};
