import React from 'react';

// eslint-disable-next-line import/no-cycle
import { useContentContext } from '@context/content';

import DynamicComponent from './DynamicComponent/DynamicComponent';

export const GlobalReference = ({ reference }) => {
  const { globalStories } = useContentContext();
  const story = Object.values(globalStories).find(s => s.uuid === reference);

  return story?.global?.map(blok => <DynamicComponent {...blok} key={blok._uid} />) || <></>;
};
