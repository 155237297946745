import { useRef, useState, ReactNode } from 'react';

import cn from 'classnames';

import styles from './Dropdown.module.scss';
import { DropdownContent } from './DropdownContent';

export enum DropdownPositions {
  Left,
  Right,
  Top,
  Bottom,
}

interface DropdownProps {
  renderButton: ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => ReactNode;
  children: ReactNode;
  displayPosition?: {
    horizontal: DropdownPositions.Left | DropdownPositions.Right;
    vertical: DropdownPositions.Top | DropdownPositions.Bottom;
  };
}

export const Dropdown = ({
  renderButton,
  children,
  displayPosition = { horizontal: DropdownPositions.Right, vertical: DropdownPositions.Bottom },
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>();

  const openDropdown = () => setIsOpen(true);
  const closeDropdown = () => setIsOpen(false);
  const toggleDropdown = isOpen ? closeDropdown : openDropdown;

  return (
    <div ref={dropdownRef} className={styles.wrapper}>
      {renderButton({ isOpen, onClick: toggleDropdown })}
      <DropdownContent
        ref={dropdownRef}
        isOpen={isOpen}
        closeDropdown={closeDropdown}
        className={cn({
          [styles.showOnTop]: displayPosition.vertical === DropdownPositions.Top,
          [styles.showOnBottom]: displayPosition.vertical === DropdownPositions.Bottom,
          [styles.showOnLeft]: displayPosition.horizontal === DropdownPositions.Left,
          [styles.showOnRight]: displayPosition.horizontal === DropdownPositions.Right,
        })}
      >
        {children}
      </DropdownContent>
    </div>
  );
};
