import React from 'react';

import cn from 'classnames';

import { Link } from '@components/Link/Link';

import styles from './BlogFilter.module.scss';
import { BlogFilterButtonAllCategories } from './components/BlogFilterButtonAllCategories';
import { BlogFilterButtonBlogCategory } from './components/BlogFilterButtonBlogCategory';
import { SheetFilters } from './components/SheetFilters';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { useBlogContext } from '@theme/BlogPostPage/BlogPostPage';

export const BlogFilter = ({
  allCategoryFilterLabelText,
  filterByBlogCategoryText,
  filterByProductCategoryText,
  openFiltersButtonText,
  sheetHeadingTitle,
}) => {
  const { filter, setFilter, resetFilter, blogCategories } = useBlogContext();

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.regularMargin, styles.stackingLayout, styles.innerWrapper)}>
        <div>
          <BlogFilterButtonAllCategories
            isSheet={false}
            filter={filter}
            onClick={() => resetFilter()}
            allCategoryFilterLabelText={allCategoryFilterLabelText}
          />
          {blogCategories.map(category => (
            <BlogFilterButtonBlogCategory
              key={category.uuid}
              isSheet={false}
              filter={filter}
              onClick={() => setFilter(category.uuid)}
              category={category}
            />
          ))}
        </div>
        <Link
          headingTitle={sheetHeadingTitle || ' '}
          component="sheetLink"
          sheetChildren={
            <SheetFilters
              filterByBlogCategoryText={filterByBlogCategoryText}
              allCategoryFilterLabelText={allCategoryFilterLabelText}
              filterByProductCategoryText={filterByProductCategoryText}
            />
          }
          className={styles.link}
        >
          <Button icon="eye" theme={ButtonTheme.Primary} arrowPosition="none" as="button" variant={ButtonVariant.Icon}>
            {openFiltersButtonText}
          </Button>
        </Link>
      </div>
    </div>
  );
};
