import { useState } from 'react';

import { Button, ButtonVariant, ButtonTheme } from '@base-components/Button';
import { Checkbox } from '@refactoredComponents/inputs/Checkbox/Checkbox';
import { OfferItemWithStoryblokContent } from 'types/offer.types';

import { Modal } from '@components/modals/Modal/Modal';
import { useParsedFilteringQuery } from '@hooks/useParsedFilteringQuery';
import { interpolateContent } from '@utils/interpolate-content';

import { filtersConfig } from './config';
import styles from './FilteringModal.module.scss';
import { filterOffers } from './helpers/filterOffers';
import { useFilteringTranslations } from './helpers/useFilteringTranslations';

interface FilteringModalProps {
  offersData: OfferItemWithStoryblokContent[];
  setNewOffers: (newOffers: OfferItemWithStoryblokContent[]) => void;
}

export const FilteringModal = ({ offersData, setNewOffers }: FilteringModalProps) => {
  const parsedQueries = useParsedFilteringQuery();
  const [filtersState, setFiltersState] = useState(parsedQueries);

  const [isOpen, setIsOpen] = useState(false);
  const translations = useFilteringTranslations();

  const { filterPhrases } = translations;

  const filteringSections = Object.keys(filtersConfig).reduce((accumulatedSections, filterSectionName) => {
    accumulatedSections[filterSectionName] = {
      filtersData: filtersConfig[filterSectionName].filteringOptions(offersData, translations),
      title: filterPhrases[filterSectionName],
      filterState: filtersState[filterSectionName],
      parameterName: filterPhrases[filterSectionName]?.toLowerCase().replace(' ', '-'),
      filterSectionName,
    };

    return accumulatedSections;
  }, {});

  const filteredOffers = filterOffers(offersData, filtersState, translations);

  const toggleCheckbox = (filterName: string, sectionName: string) => {
    const newFilters = filtersState;
    if (!newFilters[sectionName]?.includes(filterName)) {
      if (!newFilters[sectionName]) {
        newFilters[sectionName] = [];
      }

      newFilters[sectionName].push(filterName);
      setFiltersState({ ...newFilters });
    } else if (newFilters[sectionName]) {
      const indexToRemove = newFilters[sectionName].indexOf(filterName);

      newFilters[sectionName].splice(indexToRemove, 1);
      setFiltersState({ ...newFilters });
    }
  };

  const replaceUrl = (params: URLSearchParams) => {
    const url = new URL(window.location.toString());

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value);
    }

    window.history.replaceState(null, '', url);
  };

  const clearFilters = () => {
    const params = new URLSearchParams(window.location.search);

    Object.keys(filteringSections).map(sectionName => {
      const { parameterName } = filteringSections[sectionName];
      delete params[parameterName];
    });

    setFiltersState({});
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const filterItems = () => {
    const params = new URLSearchParams(window.location.search);

    Object.keys(filteringSections).map(sectionName => {
      const { parameterName, filterSectionName } = filteringSections[sectionName];
      if (filtersState[filterSectionName]?.length) {
        params[parameterName] = filtersState[filterSectionName].join(',');
      } else {
        delete params[parameterName];
      }
    });

    replaceUrl(params);
    setNewOffers(filteredOffers);

    closeModal();
  };

  const appliedFiltersAmount = Object.keys(filtersState).reduce((acc, curr) => acc + filtersState[curr].length, 0);

  return (
    <>
      <Button
        variant={ButtonVariant.Icon}
        as="button"
        icon="none"
        onClick={openModal}
        className={styles.filteringButton}
      >
        {filterPhrases['filters']} {!!appliedFiltersAmount && `(${appliedFiltersAmount})`}
      </Button>
      <Modal
        title={filterPhrases['filters']}
        isOpen={isOpen}
        closeModal={closeModal}
        size="large"
        footer={{
          type: 'custom',
          component: (
            <div className={styles.footerContainer}>
              <Button
                variant={ButtonVariant.Basic}
                as="button"
                theme={ButtonTheme.SoftBlue}
                onClick={clearFilters}
                className={styles.rejectButton}
              >
                {filterPhrases['clear-all']}
              </Button>
              <Button
                variant={ButtonVariant.Basic}
                as="button"
                onClick={filterItems}
                className={styles.confirmButton}
                disabled={!filteredOffers.length}
              >
                {interpolateContent(filterPhrases['show-x-products'], {
                  length: `(${filteredOffers.length.toString()})`,
                })}
              </Button>
            </div>
          ),
        }}
      >
        {Object.keys(filteringSections).map(sectionName => {
          const section = filteringSections[sectionName];

          return (
            section.filtersData.length > 1 && (
              <div key={section.title} className={styles.filteringSection}>
                {!!section.filtersData.length && <h3 className={styles.sectionTitle}>{section.title}</h3>}
                {section.filtersData.map((filter, index) => {
                  return (
                    <Checkbox
                      key={`${section.title}-${index}`}
                      name={filter}
                      label={filter}
                      dataAttribute={{ 'data-test-id': `contract-type-${index}` }}
                      checked={!!filtersState[section.filterSectionName]?.includes(filter)}
                      variant="standard"
                      onChange={() => toggleCheckbox(filter, section.filterSectionName)}
                      className={styles.checkboxContainer}
                    />
                  );
                })}
              </div>
            )
          );
        })}
      </Modal>
    </>
  );
};
